import * as yup from "yup";

import { MFA_SMS_CODE_LENGTH } from "constants/mfa";

import { ESMSWidgetSteps } from "./types";

const commonPrefix = `components.multi_factor_authorization.sms`;
const translationPrefix = `${commonPrefix}.steps.${ESMSWidgetSteps.VERIFY_CODE}.code_verification_input.errors`;

export const validationSchema = yup.object({
  code: yup
    .string()
    .required(`${translationPrefix}.required`)

    .test(
      "invalid length",
      `${translationPrefix}.wrong_length`,
      (value) => !value || value.toString().length === MFA_SMS_CODE_LENGTH,
    ),
});

export type FormType = yup.InferType<typeof validationSchema>;
