import {
  ForgoingTransactionDto,
  RepaymentTransactionDto,
  WithdrawalTransactionDto,
} from "utils/swagger_react_query";

export enum ITransactionTypes {
  FORGOING = "FORGOING",
  REPAYMENT = "REPAYMENT",
  BONUS = "BONUS",
}

export interface ITransactionAdditionalFields {
  _transactionType?: ITransactionTypes;
  _loanId?: string;
}

export type TransactionDto = Partial<
  RepaymentTransactionDto &
    WithdrawalTransactionDto &
    ForgoingTransactionDto &
    ITransactionAdditionalFields
>;
