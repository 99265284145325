import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import WelcomeImage from "assets/png/company_welcome_message_image.png";

import {
  CIRImage,
  CIRImageContainer,
  Container,
  ContainerInner,
  ContainerInnerLeft,
  ContainerInnerRight,
  ContinueButton,
  Message,
  Step,
  StepContainerLeft,
  StepContainerRight,
  StepIndexContainer,
  StepMessage,
  StepsListContainer,
  StepTitle,
  StyledLoader,
  Title,
} from "./styles";

interface IProps {
  className?: string;
  onContinue?: () => void;
  onImageLoad?: () => void;
}

interface IWelcomeStep {
  id: number;
  title: ReactNode | string;
  message: ReactNode | string;
}

const WelcomeMessage: FC<IProps> = ({ className, onContinue, onImageLoad }) => {
  const translationPrefix = `admin_setup_page.setup_states.welcome_message`;
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const steps: IWelcomeStep[] = [
    {
      id: 1,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p1.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.steps_list.p1.message`} />,
    },
    {
      id: 2,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p2.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.steps_list.p2.message`} />,
    },
    {
      id: 3,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p3.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.steps_list.p3.message`} />,
    },
  ];

  const renderStep = (item: IWelcomeStep) => {
    return (
      <Step key={item.id}>
        <StepContainerLeft>
          <StepIndexContainer>{item.id}</StepIndexContainer>
        </StepContainerLeft>
        <StepContainerRight>
          <StepTitle>{item.title}</StepTitle>
          <StepMessage>{item.message}</StepMessage>
        </StepContainerRight>
      </Step>
    );
  };

  const handleImageLoad = () => {
    setImageIsLoading(false);
    onImageLoad?.();
  };

  useEffect(() => {
    if (imageRef.current?.complete) {
      handleImageLoad();
    }
  }, []);

  return (
    <Container className={className}>
      {imageIsLoading && <StyledLoader />}
      <ContainerInner className={imageIsLoading ? "hidden" : ""}>
        <ContainerInnerLeft>
          <Title>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </Title>
          <Message>
            <Trans i18nKey={`${translationPrefix}.message`} />
          </Message>
          <StepsListContainer>
            {steps.map((item) => (
              <>{renderStep(item)}</>
            ))}
          </StepsListContainer>
          <ContinueButton onClick={onContinue}>
            <Trans i18nKey={`${translationPrefix}.button`} />
          </ContinueButton>
        </ContainerInnerLeft>
        <ContainerInnerRight>
          <CIRImageContainer>
            <CIRImage ref={imageRef} onLoad={handleImageLoad} src={WelcomeImage} />
          </CIRImageContainer>
        </ContainerInnerRight>
      </ContainerInner>
    </Container>
  );
};

export default WelcomeMessage;
