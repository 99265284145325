import { UserIcon } from "assets/svg";
import styled, { ExecutionContext } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { SecondaryButton } from "uikit/Buttons";
import { ErrorContainer as InputError } from "uikit/Input/styles";
import { P } from "uikit/Typography/Typography";

interface IWrapperType {
  type?: "avatar" | "logo" | "image";
}

export const Wrapper = styled.div<IWrapperType>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${breakpoint("xs", "md")`
      flex-direction: ${({ type }: IWrapperType & ExecutionContext) =>
        type === "logo" ? "row" : "column"};
      justify-content: space-between;
   `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 248px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.contentBg};
  margin-bottom: 26px;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }

  &.logo {
    border-radius: 0;
    background: transparent;
    border: 1.5px solid ${({ theme }) => theme.colors.inputBorder};

    & img {
      border-radius: 0;
      background: transparent;
      border: 1.5px solid ${({ theme }) => theme.colors.inputBorder};

      & img {
        border-radius: 0;
      }
    }
  }

  ${breakpoint("md", "xl")`
      width: 184px;
      height: 184px;
   `}

  ${breakpoint("xs", "md")`
      width: 146px;
      height: 146px;
   `}
`;

export const StyledUserIcon = styled(UserIcon)`
  ${breakpoint("xs", "md")`
		width: 86px;
  `}

  ${breakpoint("md", "xl")`
		width: 109px;
  `}
`;

export const BottomWrapper = styled.div`
  text-align: center;
`;

export const LogoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.inputBorder};

  & p {
    font-size: ${({ theme }) => theme.size.subtitle};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 5px;
  }

  & span {
    font-style: italic;
    font-size: ${({ theme }) => theme.size.caption};
    text-align: center;
  }
`;

export const Description = styled(P)`
  font-size: ${({ theme }) => theme.size.caption};
  text-align: center;
  margin-top: 18px;

  ${breakpoint("xs", "md")`
      text-align: left;
   `}
`;

export const StyledButton = styled(SecondaryButton)`
  width: auto;
  font-size: ${({ theme }) => theme.size.caption};
  padding: 5px 20px;
  margin: 0 auto 18px auto;

  ${breakpoint("xs", "xl")`
      width: 146px;
   `}
`;

export const StyledInputError = styled(InputError)`
  text-align: center;
  padding-left: 0;
`;
