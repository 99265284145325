import { FC } from "react";

import IconProps from "./IconProps";

const EqualLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 5.24537e-07L12 2L-8.74243e-08 2L0 0L12 5.24537e-07Z" fill="#C4C4C4" />
    <path
      transform="translate(0,6)"
      d="M12 5.24537e-07L12 2L-8.74243e-08 2L0 0L12 5.24537e-07Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default EqualLarge;
