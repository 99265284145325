import { IColumn } from "uikit/Table/types";

import { AdminResponseDto, UserResponseDto } from "utils/swagger_react_query";

export interface IUseColumns {
  translationPrefix: string;
  currentUser?: UserResponseDto;
  resendInvitationPermission: Record<string, number>[];
  actions: {
    onDisabledAccount: (row: AdminResponseDto) => void;
    onResendInvitation: (row: AdminResponseDto) => void;
    handleUpdateResendInvitationTimeout: (userId: string, remainingTime: number) => void;
  };
}

export interface IColumnExtended extends IColumn {
  component?: (row: AdminResponseDto) => JSX.Element;
}

export enum TeamMembersTableContextMenuAction {
  DISABLE_ACCOUNT = "disableAccount",
  RESEND_INVITATION = "resendInvitation",
}
