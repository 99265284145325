import styled, { css } from "styled-components";

const OpenOptionMixin = css`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separatorLine};
`;

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled.div``;

export const DDWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  position: relative;
  box-sizing: border-box;

  p {
    max-width: 305px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const DDWrapperInner = styled.div<{ isOpen: boolean }>`
  width: 100%;
  padding: 12px 12px 12px 16px;
  z-index: ${({ isOpen }) => (isOpen ? 100 : 0)};
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.contentBg};

  border-radius: 8px;

  position: absolute;
  //box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.contentBoxShadow};
`;

export const SelectedBankAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.isOpen {
    ${OpenOptionMixin}

    & > svg {
      transform: rotate(180deg);
    }
  }
`;

export const OptionsList = styled.div`
  width: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.disabled};
    border-radius: 12px;
  }

  //For Firefox
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.disabled} transparent;
`;

export const OptionsListItem = styled.div`
  ${OpenOptionMixin};
  position: relative;
  cursor: pointer;
  width: 100%;
`;

export const AddBankBtn = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding: 8px 0 2px 2px;
  cursor: pointer;
`;

export const DropDownArrowWrapper = styled.span`
  cursor: pointer;

  & > svg {
    width: 15px !important;
    height: 12px !important;
  }
`;
