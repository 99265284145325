import { t } from "i18next";

import { Step } from "uikit/StepIndicator/types";

import { IEarlyPayFormData } from "./types";

export enum COMPANY_CARD_CREATION_STEPS {
  KYC_VERIFICATION = 1,
  EARLY_PAY = 2,
  SHIPPING_DETAILS = 3,
  MFA_CONFIGURATION = 4,
}

export const FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY = "failedKycUserData";

export const getSteps = (isMFACompleted: boolean): Step[] => {
  const _prefix = `components.company_bank_account_components.shared.steps.list`;

  return [
    {
      index: COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION,
      label: t(`${_prefix}.kyc_verification.title`) as string,
    },
    {
      index: COMPANY_CARD_CREATION_STEPS.EARLY_PAY,
      label: t(`${_prefix}.early_pay.title`) as string,
    },
    {
      index: COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS,
      label: t(`${_prefix}.shipping_details.title`) as string,
    },
    {
      index: COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION,
      label: t(`${_prefix}.mfa_configuration.title`) as string,
      isCompleted: isMFACompleted,
    },
  ];
};

export const promoStepsDescriptionsList = () => {
  const _prefix = `components.company_bank_account_components.shared.promo_section.steps_descriptions_list`;
  return [
    {
      title: `${_prefix}.personal_information.title`,
      description: `${_prefix}.personal_information.description`,
    },
    {
      title: `${_prefix}.early_pay.title`,
      description: `${_prefix}.early_pay.description`,
    },
    {
      title: `${_prefix}.order_physical_card.title`,
      description: `${_prefix}.order_physical_card.description`,
    },
    {
      title: `${_prefix}.mfa_configuration.title`,
      description: `${_prefix}.mfa_configuration.description`,
    },
  ];
};

export const earlyPayFormInitialValues: IEarlyPayFormData = {
  isChecked: false,
  isTouched: false,
};
