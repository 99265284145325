import { FC } from "react";
import { Trans } from "react-i18next";

import { COMPANY_ESIGN_CONSENT_DISCLOSURE } from "constants/assets";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/shared";

import { ContainerAlt, CopyrightTextAlt, LinkAlt, LinksContainerAlt } from "./styles";

interface Props {
  className?: string;
}

const CopyrightAlt: FC<Props> = ({ className }) => {
  const translationPrefix = "copyright";

  return (
    <ContainerAlt className={className}>
      <CopyrightTextAlt>
        <Trans i18nKey={`${translationPrefix}.copyright`} />
      </CopyrightTextAlt>
      <LinksContainerAlt>
        <LinkAlt href={PRIVACY_POLICY} target="_blank">
          <Trans i18nKey={`${translationPrefix}.privacy_policy`} />
        </LinkAlt>

        <CopyrightTextAlt className={"separator"}>
          <Trans i18nKey={`common.and`} />
        </CopyrightTextAlt>

        <LinkAlt href={`${COMPANY_ESIGN_CONSENT_DISCLOSURE}?t=${Date.now()}`} target="_blank">
          <Trans i18nKey={`${translationPrefix}.e_sign_agreement`} />
        </LinkAlt>

        <LinkAlt href={TERMS_AND_CONDITIONS} target="_blank">
          <Trans i18nKey={`${translationPrefix}.terms_conditions`} />
        </LinkAlt>
      </LinksContainerAlt>
    </ContainerAlt>
  );
};

export default CopyrightAlt;
