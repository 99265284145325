import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import { NavigationCardWithDetailsAlt } from "components/NavigationCardWithDetails";

import { FullScreenLoader } from "uikit";

import useEmployeeSettingsPage from "./useEmployeeSettingsPage";
import { OptionsSection } from "./styles";

interface IProps {}

const EmployeeSettingsPage: FC<IProps> = () => {
  const { metadata, pageData } = useEmployeeSettingsPage();
  const { optionsList } = pageData;

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <>
        <OptionsSection>
          {optionsList.map((option) => {
            return <NavigationCardWithDetailsAlt {...option} key={option.id} />;
          })}
        </OptionsSection>
      </>
    </DashboardLayout>
  );
};

export default EmployeeSettingsPage;
