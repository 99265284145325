import { RootState } from "store/store";

export const userMetadataSelector = (state: RootState) => state.metaData.userData;
export const companyMetadataSelector = (state: RootState) => state.metaData.companyData;
export const salsaSelector = (state: RootState) => state.metaData.salsaData;
export const workerKycOnboardingInProgressSelector = (state: RootState) =>
  state.metaData.workerKycOnboardingInProgress;
export const routerUserRequestInProgressSelector = (state: RootState) =>
  state.metaData.routerUserRequestInProgress;
export const rewardPointsSelector = (state: RootState) => state.metaData.rewardPointsData;
