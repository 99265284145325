import styled from "styled-components";

import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";

import { buttonHeight, buttonRadius } from "uikit/Buttons/Buttons";

export const Wrapper = styled.div``;

export const StringWrapper = styled.div`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &.${EComprehensibleUserStatuses.EMPLOYED} {
    color: ${({ theme }) => theme.colors.userStatusEmployedTextAndBg};
  }

  &.${EComprehensibleUserStatuses.INVITED} {
    color: ${({ theme }) => theme.colors.userStatusInvitedTextAndBg};
  }

  &.${EComprehensibleUserStatuses.PROSPECT} {
    color: ${({ theme }) => theme.colors.userStatusProspectTextAndBg};
  }

  &.${EComprehensibleUserStatuses.NOT_READY} {
    color: ${({ theme }) => theme.colors.userStatusNotReadyTextAndBg};
  }

  &.${EComprehensibleUserStatuses.PENDING_EXIT} {
    font-style: italic;
    color: ${({ theme }) => theme.colors.userStatusPendingExitTextAndBg};
  }

  &.${EComprehensibleUserStatuses.EXITED} {
    color: ${({ theme }) => theme.colors.userStatusExitedTextAndBg};
  }

  &.${EComprehensibleUserStatuses.IN_DEFAULT} {
    color: ${({ theme }) => theme.colors.userStatusInDefaultTextAndBg};
  }
`;

export const Label = styled.span<{ withBg: boolean }>`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  white-space: nowrap;

  ${({ withBg }) =>
    withBg &&
    `
    height: ${buttonHeight}px;
    border-radius: ${buttonRadius}px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center; 
  `}

  &.${EComprehensibleUserStatuses.EMPLOYED} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusEmployedTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusEmployedTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.PENDING_EXIT} {
    color: ${({ withBg, theme }) =>
      withBg
        ? theme.colors.userStatusTextColorWithBg
        : theme.colors.userStatusPendingExitTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusPendingExitTextAndBg : "none"};
    font-style: italic;
  }

  &.${EComprehensibleUserStatuses.EXITED} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusExitedTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusExitedTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.IN_DEFAULT} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusInDefaultTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusInDefaultTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.INVITED} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusInvitedTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusInvitedTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.PROSPECT} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusProspectTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusProspectTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.NOT_READY} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusNotReadyTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusNotReadyTextAndBg : "none"};
  }
`;
