import { FC } from "react";
import { Trans } from "react-i18next";
import { Formik } from "formik";

import { EButtonsFlex } from "uikit/Modal";
import { ButtonsContainer, MainActionButton, SecondaryActionButton } from "uikit/Modal/styles";

import { ListEmployeesEmployeeResponseDto } from "utils/swagger_react_query";

import { FormContainer, Modal } from "../styles";
import { InviteTeamMemberForm } from "./InviteTeamMemberForm";
import { InviteTeamMemberFormType, InviteTeamMemberValidationSchema } from "./validationSchema";

interface IProps {
  translationPrefix: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: InviteTeamMemberFormType) => void;
  employeesList: ListEmployeesEmployeeResponseDto[];
  handleSearch: (value: string) => void;
  fetchMoreUsers: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

export const InviteTeamMemberModal: FC<IProps> = ({
  translationPrefix,
  isOpen,
  onClose,
  onSubmit,
  employeesList,
  handleSearch,
  fetchMoreUsers,
  hasMore,
  isLoading,
}) => {
  const modalTranslationPrefix = `${translationPrefix}.modals.invite_team_member`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.modals.invite_team_member.title`} />}
      buttonsFlex={EButtonsFlex.ROW_REVERSE}
      onCloseOnButtons={false}
    >
      <Formik<InviteTeamMemberFormType>
        onSubmit={(values) => onSubmit(values)}
        validateOnChange={false}
        initialValues={{ email: "" }}
        validationSchema={InviteTeamMemberValidationSchema}
      >
        {(formikProps) => {
          return (
            <FormContainer onSubmit={formikProps.handleSubmit}>
              <InviteTeamMemberForm
                translationPrefix={translationPrefix}
                employeesList={employeesList}
                handleSearch={handleSearch}
                fetchMoreUsers={fetchMoreUsers}
                hasMore={hasMore}
                isLoading={isLoading}
              />

              <ButtonsContainer className={`flex-${EButtonsFlex.ROW_REVERSE}`}>
                <MainActionButton type={"submit"}>
                  <Trans i18nKey={`${modalTranslationPrefix}.submit_btn`} />
                </MainActionButton>

                <SecondaryActionButton onClick={onClose} type={"button"}>
                  <Trans i18nKey={"buttons.cancel"} />
                </SecondaryActionButton>
              </ButtonsContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </Modal>
  );
};
