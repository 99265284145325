import { UserRole } from "types/BETypes";

export const roleSwitcherTranslations = {
  "en-US": {
    roles: {
      [UserRole.OWNER]: "Owner Account",
      [UserRole.ADMIN]: "Admin Account",
      [UserRole.SUPERADMIN]: "Admin Account",
      [UserRole.EMPLOYEE]: "Worker Account",
    },
  },
};

export default roleSwitcherTranslations;
