import { FC } from "react";

import IconProps from "./IconProps";

const SmallBonusIconUnitAlt: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.5009C0 5.60734 5.60734 0 12.5009 0C19.3927 0 25 5.60905 25 12.5009C25 19.3927 19.3944 25 12.5009 25C5.60734 25 0 19.3944 0 12.5009ZM2.73225 12.5009C2.73225 17.8865 7.11349 22.2678 12.5009 22.2678C17.8865 22.2678 22.2678 17.8869 22.2678 12.5009C22.2678 7.11483 17.8882 2.73225 12.5009 2.73225C7.11349 2.73225 2.73225 7.1152 2.73225 12.5009ZM10.4825 6.08804C9.23863 6.1443 7.99433 6.20158 7.01677 7.39891L7.01721 7.39879C6.86574 7.45033 6.77724 7.60732 6.81786 7.75891L9.91892 19.3322C9.96142 19.4909 10.1289 19.5838 10.2931 19.5398C10.4572 19.4959 10.5558 19.3318 10.5133 19.173L8.85826 12.9964C9.65075 11.9798 10.7628 11.9297 11.8807 11.8798C13.1823 11.8215 14.4904 11.7627 15.4852 10.4147C15.5454 10.3422 15.5715 10.2448 15.5458 10.1488L14.1516 4.94836C14.1341 4.883 14.0939 4.82393 14.0329 4.78278C13.8953 4.68958 13.7045 4.72403 13.6061 4.85903C12.7858 5.98287 11.6351 6.03541 10.4837 6.08798L10.4825 6.08804Z"
      fill="#40B380"
    />
  </svg>
);

export default SmallBonusIconUnitAlt;
