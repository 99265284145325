import { Trans } from "react-i18next";
import CashIcon from "assets/png/icons/cash_icon.png";
import CongratulationsIcon from "assets/png/icons/congratulations_icon.png";
import MoneyFaceIcon from "assets/png/icons/money_face_icon.png";

import { IBenefit } from "./types";

const translationPrefix = `components.payment_methods`;

export const COMPANY_ACCOUNT_PROMOTION_POINTS: IBenefit[] = [
  {
    id: "point_1",
    icon: <img src={CongratulationsIcon} alt="money_face" />,
    text: <Trans i18nKey={`${translationPrefix}.promotion_points.point_1`} />,
    traditionalBank: false,
    companyAccount: true,
  },
  {
    id: "point_2",
    icon: <img src={CashIcon} alt="cash" />,
    text: <Trans i18nKey={`${translationPrefix}.promotion_points.point_2`} />,
    traditionalBank: false,
    companyAccount: true,
  },
  {
    id: "point_3",
    icon: <img src={MoneyFaceIcon} alt="congratulations" />,
    text: <Trans i18nKey={`${translationPrefix}.promotion_points.point_3`} />,
    traditionalBank: false,
    companyAccount: true,
  },
];
