import styled from "styled-components";

import { SectionAltRounded } from "uikit";

export const Wrapper = styled(SectionAltRounded)`
  width: 100%;
  position: relative;
`;

export const WrapperInner = styled.div``;
