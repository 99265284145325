import styled from "styled-components";

import { breakpoint } from "helpers";

export const Content = styled.div`
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint("xs", "md")`
    padding: 0;
  `}

  & > img {
    width: 91px;
    height: 91px;
    margin-bottom: 24px;

    ${breakpoint("xs", "md")`
      width: 61px;
      height: 61px;
    `}
  }

  p {
    font-size: ${({ theme }) => theme.size.body2};
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    ${breakpoint("xs", "md")`
      font-size: ${({ theme }) => theme.size.body2};
    `}
  }

  & > div:nth-child(2) {
    margin-bottom: 57px;

    & > p:nth-child(1) {
      font-size: ${({ theme }) => theme.size.title};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      margin-bottom: 3px;
    }
  }

  & > div:nth-child(3) {
    margin-bottom: 57px;
    min-height: 37px;

    & > p:nth-child(1) {
      margin-bottom: 3px;

      & > span:nth-child(1) {
        font-size: ${({ theme }) => theme.size.subtitleLarge};
      }

      & > span:nth-child(2) {
        color: ${({ theme }) => theme.colors.danger};
      }
    }

    & > p:nth-child(2) {
      & > span {
        color: ${({ theme }) => theme.colors.accentMain};
        cursor: pointer;
        position: relative;
        z-index: 100;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  & > button {
    margin-bottom: 50px;
    height: 55px;

    ${breakpoint("xs", "md")`
      height: 45px;
    `}
  }
`;
