import { FC } from "react";
import { Trans } from "react-i18next";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { getBankLogo } from "helpers/index";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import {
  BankLogo,
  InfoSection,
  PendingVerifyLink,
  Row,
  StyledBankIconPlaceholder,
  Text,
  VerificationStatusSection,
  VerifyBtn,
  VSSText,
  Wrapper,
} from "./styles";

interface IProps {
  className?: string;
  bankAccountInfo?: GetPlaidBankAccountsResponseDto;
  secondaryBtn?: {
    needRender: boolean;
    label?: string;
    onClick?: () => void;
  };
  onVerify?: (bankAccountId: string, verificationToken: string) => void;
  onPendingVerificationClick?: (
    verificationStatus:
      | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
      | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
  ) => void;
}

const BankAccountInfo: FC<IProps> = ({
  className,
  bankAccountInfo,
  onVerify,
  onPendingVerificationClick,
}) => {
  const translationPrefix = `components.bank_account_info_long`;
  const bankLogo = bankAccountInfo ? getBankLogo(bankAccountInfo) : undefined;
  const shouldShowContentToVerify =
    (bankAccountInfo?.verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION ||
      bankAccountInfo?.renewLoginRequired) &&
    onVerify;
  const isBankAccountExpiredOrFailed = [
    BankAccountVerifyStatus.VERIFICATION_FAILED,
    BankAccountVerifyStatus.VERIFICATION_EXPIRED,
  ].includes(bankAccountInfo?.verificationStatus as BankAccountVerifyStatus);

  const shouldShowVerificationExplanationModal =
    [
      BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
      BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    ].includes(bankAccountInfo?.verificationStatus as BankAccountVerifyStatus) &&
    onPendingVerificationClick;

  const renderBankName = () => {
    if (bankAccountInfo?.institution?.name) return bankAccountInfo?.institution?.name;
    return <Trans i18nKey={`${translationPrefix}.bank_name_placeholder`} />;
  };

  const renderPendingVerifyLink = () => {
    if (shouldShowVerificationExplanationModal) {
      return (
        <PendingVerifyLink
          onClick={() =>
            onPendingVerificationClick(
              bankAccountInfo?.verificationStatus as
                | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
                | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
            )
          }
        >
          <Trans i18nKey={`${translationPrefix}.pending_verification_link`} />
        </PendingVerifyLink>
      );
    }
  };

  const verifyBtnLabel = bankAccountInfo?.renewLoginRequired ? "reconnect_btn" : "verify_btn";

  return (
    <Wrapper className={className}>
      <InfoSection>
        {bankLogo ? <BankLogo src={bankLogo} alt="Bank logo" /> : <StyledBankIconPlaceholder />}
        <Row>
          <Text>
            {renderBankName()}
            {renderPendingVerifyLink()}
          </Text>

          <Text>**********{(bankAccountInfo?.last4Digits as string) || ""}</Text>
        </Row>

        {isBankAccountExpiredOrFailed && (
          <VerificationStatusSection>
            <VSSText>
              <Trans
                i18nKey={`${translationPrefix}.verification_status.${bankAccountInfo?.verificationStatus}`}
              />
            </VSSText>
          </VerificationStatusSection>
        )}

        {shouldShowContentToVerify && (
          <VerifyBtn
            type={"button"}
            onClick={() =>
              onVerify(bankAccountInfo?.bankAccountId, bankAccountInfo?.accessToken || "")
            }
          >
            <Trans i18nKey={`${translationPrefix}.${verifyBtnLabel}`} />
          </VerifyBtn>
        )}
      </InfoSection>
    </Wrapper>
  );
};

export default BankAccountInfo;
