import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 6px 8px;
  border-radius: 100px;
  cursor: pointer;

  border: 1px solid ${({ theme }) => theme.colors.rewardPointsDisplayBorder};
  background: ${({ theme }) => theme.colors.rewardPointsDisplayBg};
  color: ${({ theme }) => theme.colors.rewardPointsDisplayText};
`;

export const PointsAmount = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const PointsLabel = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
