import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { Body2, Body3 } from "uikit";
import { TableContainer as TableContainerInner } from "uikit/Table/components/Table/styles";

export const Container = styled.div``;

export const TableContainer = styled.div`
  ${TableContainerInner} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  thead {
    border-bottom: none;
  }

  tr td {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.size.caption};
    color: ${({ theme }) => theme.colors.textEmphasized};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  td {
    box-sizing: border-box;
    &.icon {
      width: 7%;
      max-width: 7%;
      text-align: left;
      padding-left: 20px;

      ${breakpoint("xs", "md")`
        display: none;
      `}
    }
    &.transaction {
      width: 49%;
      max-width: 49%;
      box-sizing: border-box;

      ${breakpoint("xs", "md")`
        width: 30%;
        max-width: 30%;
        padding: 8px 0 8px 12px;
      `}
    }
    &.type {
      width: 12%;
      max-width: 12%;
      box-sizing: border-box;
      text-align: center;

      ${breakpoint("xs", "md")`
        width: 12%;
        max-width: 12%;
      `}
    }

    &.date {
      width: 14%;
      max-width: 14%;

      ${breakpoint("xs", "md")`
        width: 20%;
        max-width: 20%;
      `}
    }
    &.status {
      width: 18%;
      max-width: 18%;

      ${breakpoint("xs", "md")`
        width: 20%;
        max-width: 20%;
      `}
    }
  }
`;

export const TransactionInfo = styled.div``;
export const TransactionTypeText = styled.div``;

export const TIAmount = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textEmphasized};
`;

export const TIBankInfo = styled(Body3)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const TIBankInfoPrefix = styled.span``;

export const TIBankName = styled.span`
  ${breakpoint("xs", "md")`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;
export const TIBankAccount = styled.span`
  ${breakpoint("xs", "md")`
    display: block;
  `}
`;
