import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { isNull, isUndefined } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { KycStatus, MFAFactorType } from "types/BETypes";
import { showErrorModal } from "helpers";
import {
  isKycAccepted as getIsKycAccepted,
  isKycPending as getIsKycPending,
  isKycRejected as getIsKycRejected,
} from "helpers/bonus/kyc";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import useAuth from "hooks/useAuth";
import {
  EKycResponseType,
  IKYCProfileFormRef,
  KYCProfileFormType,
} from "components/CompanyBankAccountComponents/KYCVerificationWidget";
import {
  IPaidCardShippingDetailsFormRef,
  PaidCardShippingDetailsFormType,
} from "components/CompanyBankAccountComponents/PaidCardShippingDetailsForm";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { EWidgetType as MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  BankCardResDto,
  KycVerifyReqDto,
  mutationBankCardsControllerIssueBankCard,
  mutationBankCardsControllerKycVerify,
  mutationUsersControllerUpdateEarlyPay,
  queryBankCardsControllerListBankCards,
} from "utils/swagger_react_query";

import { COMPANY_CARD_CREATION_STEPS, earlyPayFormInitialValues } from "./constants";
import {
  getCombinedUserDataWithFailedKycData,
  getFailedKycUserDataFromLocalStorage,
  getKycResponseType,
  isPhysicalCardActiveOrUnactivated,
  profileFormValuesToKycRequestPayload,
  removeFailedKycUserDataFromLocalStorage,
  setFailedKycUserDataToLocalStorage,
  shippingDetailsFormValuesToRequestPayload,
  userDetailsToKycProfileFormInitialValues,
} from "./helpers";
import { IEarlyPayFormData, IUseCompanyBankAccountCreationModal } from "./types";

export const useCompanyBankAccountCreationModal = ({
  isOpen,
  setIsOpen,
  onFinished,
  onClose,
  refetchPageContentCallback,
  showShippingStepOnly,
  hookData,
}: IUseCompanyBankAccountCreationModal) => {
  const translationPrefix = `components.company_bank_account_components`;

  const { getCurrentUser } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(true);
  const userDetailsFromStore = useAppSelector(userMetadataSelector);
  const currentUser = getCombinedUserDataWithFailedKycData(
    userDetailsFromStore,
    getFailedKycUserDataFromLocalStorage(),
  );

  const isKycAccepted = getIsKycAccepted(currentUser);
  const isKycPending = getIsKycPending(currentUser);
  const isKycRejected = getIsKycRejected(currentUser);

  const isEarlyPayStepCompleted = !isNull(currentUser?.isEarlyPayEnabled);
  const [companyBankCards, setCompanyBankCards] = useState<BankCardResDto[]>([]);

  const doesUserHaveActivePhysicalBankCard = companyBankCards.some(
    isPhysicalCardActiveOrUnactivated,
  );

  const determineInitialStep = (bankCards: BankCardResDto[]) => {
    const _doesUserHaveActivePhysicalBankCard = bankCards.some(isPhysicalCardActiveOrUnactivated);
    if (showShippingStepOnly) return COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS;

    if (isKycRejected || isKycPending || !currentUser?.kycStatus)
      return COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION;
    if (!isEarlyPayStepCompleted) return COMPANY_CARD_CREATION_STEPS.EARLY_PAY;
    if (isEarlyPayStepCompleted && !_doesUserHaveActivePhysicalBankCard) {
      return COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS;
    }

    return COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION;
  };

  const [currentStep, setCurrentStep] = useState<COMPANY_CARD_CREATION_STEPS>(
    COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION,
  );
  const _userDetailsInitialValue = useMemo(() => {
    return userDetailsToKycProfileFormInitialValues(currentUser);
  }, [currentUser]);

  //KYC
  const profileFormRef = useRef<IKYCProfileFormRef>(null);
  const [isKycSubmitting, setIsKycSubmitting] = useState<boolean>(false);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState<boolean>(false);
  const [kycResponseType, setKycResponseType] = useState<EKycResponseType | undefined>(
    isKycRejected ? EKycResponseType.ERROR : undefined,
  );
  const [kycFormData, setKycFormData] = useState<KYCProfileFormType>(_userDetailsInitialValue);
  const isKycDirty =
    useMemo(
      () => JSON.stringify(kycFormData) !== JSON.stringify(_userDetailsInitialValue),
      [kycFormData],
    ) || !!kycResponseType;

  // SHIPPING DETAILS
  const shippingDetailsFormRef = useRef<IPaidCardShippingDetailsFormRef>(null);
  const [isShippingDetailsSubmitting, setIsShippingDetailsSubmitting] = useState<boolean>(false);
  const [isCardholderAgreementAccepted, setIsCardholderAgreementAccepted] =
    useState<boolean>(false);
  const [physicalCardSuccessfullyOrdered, setPhysicalCardSuccessfullyOrdered] =
    useState<boolean>(false);
  const [shippingDetailsFormData, setShippingDetailsFormData] =
    useState<PaidCardShippingDetailsFormType>(_userDetailsInitialValue);
  const [isShippingDetailsDirty, setIsShippingDetailsDirty] = useState(
    JSON.stringify(shippingDetailsFormData) !== JSON.stringify(_userDetailsInitialValue),
  );

  // EARLY PAY
  const [isEarlyPaySubmitting, setIsEarlyPaySubmitting] = useState<boolean>(false);
  const [earlyPayFormData, setEarlyPayFormData] =
    useState<IEarlyPayFormData>(earlyPayFormInitialValues);
  const isEarlyPayDirty = earlyPayFormData.isTouched || isEarlyPayStepCompleted;
  const isEarlyPayChecked = earlyPayFormData.isChecked;
  const setIsEarlyPayChecked = (value?: boolean) =>
    setEarlyPayFormData((prev) => ({ ...prev, isChecked: value || !prev.isChecked }));
  const setIsEarlyPayTouched = (value: boolean) =>
    setEarlyPayFormData((prev) => ({ ...prev, isTouched: value }));

  //MFA
  const { fetchData: fetchMfaFactors } = useMFASMS({
    fetchDataOnMount: false,
    widgetType: MFAWidgetType.MISC,
  });
  const [isMfaFinished, setIsMfaFinished] = useState<boolean>(false);

  // COMMON
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [showPromoSection, setShowPromoSection] = useState<boolean>(!currentUser?.kycStatus);
  const [isBankAccountSetupCompletedSectionVisible, setIsBankAccountSetupCompletedSectionVisible] =
    useState<boolean>(false);
  const isSomeFormLoading = isKycSubmitting || isShippingDetailsSubmitting || isEarlyPaySubmitting;
  const isPrimaryBtnDisabled = useCallback(() => {
    if (isSomeFormLoading) return true;

    const config = {
      [COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION]:
        (!currentUser?.kycStatus || !kycResponseType) && !isKycSubmitting
          ? !isTermsAndConditionsAccepted
          : isKycSubmitting
            ? true
            : false,
      [COMPANY_CARD_CREATION_STEPS.EARLY_PAY]: false,
      [COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS]: !doesUserHaveActivePhysicalBankCard
        ? !isCardholderAgreementAccepted
        : false,
      [COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION]: !isMfaFinished,
    };

    return config[currentStep];
  }, [
    currentUser?.kycStatus,
    isCardholderAgreementAccepted,
    isTermsAndConditionsAccepted,
    kycResponseType,
    isSomeFormLoading,
    isMfaFinished,
    currentStep,
  ]);

  useEffect(() => {
    fetchMainData(true);

    return () => removeFailedKycUserDataFromLocalStorage();
  }, [hookData?.bankCardsList, isOpen]);

  //Common
  const fetchMainData = async (isInit?: boolean) => {
    const { bankCardsList } = hookData || {};

    try {
      const shouldFetchUserData = !isInit;
      const shouldFetchCompanyBankCards =
        isKycAccepted && ((isInit && isUndefined(bankCardsList)) || !isInit);
      setLoading(true);

      if (isKycPending) {
        setKycResponseType(EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG);
      }

      if (shouldFetchUserData) {
        await getCurrentUser();
      }
      let companyCardResponse: BankCardResDto[];
      if (shouldFetchCompanyBankCards) {
        companyCardResponse = await fetchCompanyBankCards();
      } else {
        companyCardResponse = bankCardsList || [];
      }
      setCompanyBankCards(companyCardResponse);

      const _initialStep = determineInitialStep(companyCardResponse);
      if (_initialStep !== currentStep) {
        setCurrentStep(_initialStep);
      }
    } catch (error) {
      handleCloseModal();
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const showBackClickWarningModal = () => {
    const _prefix = `${translationPrefix}.shared.close_form_confirmation`;
    const message =
      currentStep === COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS ? "shipping" : "common";

    AppEvents.emit("SetGlobalModal", {
      className: "pay-distribution-redirection-warning-modal",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_prefix}.title`} />,
      message: <Trans i18nKey={`${_prefix}.${message}`} />,
      mainButton: {
        text: <Trans i18nKey={`${_prefix}.continue_btn`} />,
        onClick: () => {
          setIsOpen(true);
        },
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        onClick: () => {
          handleCloseModal();
        },
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
    });
  };

  const handleBackBtnClick = () => {
    if (isMfaFinished) {
      handleCloseModal();
      return;
    }

    handleCloseModal(false);
    showBackClickWarningModal();
  };

  const handleCloseModal = (clearData: boolean = true) => {
    const shouldRefetchOnClose: Record<COMPANY_CARD_CREATION_STEPS, boolean> = {
      [COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION]: !!currentUser?.kycStatus,
      [COMPANY_CARD_CREATION_STEPS.EARLY_PAY]: isKycDirty,
      [COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS]: isEarlyPayDirty,
      [COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION]:
        isShippingDetailsDirty || isBankAccountSetupCompletedSectionVisible || isMfaFinished,
    };
    if (clearData) {
      if (shouldRefetchOnClose[currentStep]) {
        refetchPageContentCallback?.();
      }
      setIsTermsAndConditionsAccepted(false);
      setIsCardholderAgreementAccepted(false);
      setCurrentStep(COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION);
      setKycResponseType(undefined);
      setEarlyPayFormData(earlyPayFormInitialValues);
      onClose?.();
      setKycFormData(_userDetailsInitialValue);
      setShippingDetailsFormData(_userDetailsInitialValue);
      removeFailedKycUserDataFromLocalStorage();
      setErrorMessage(undefined);
      setIsBankAccountSetupCompletedSectionVisible(false);
      setIsMfaFinished(false);
    }

    setIsOpen(false);
  };

  const handlePrimaryBtnClick = () => {
    if (currentStep === COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION) {
      if (isKycRejected) {
        setKycResponseType(undefined);
      } else if (isKycPending) {
        handleCloseModal();
      } else if (isKycAccepted) {
        setCurrentStep(COMPANY_CARD_CREATION_STEPS.EARLY_PAY);
      }
    }

    if (currentStep === COMPANY_CARD_CREATION_STEPS.EARLY_PAY) {
      setShippingDetailsFormData(_userDetailsInitialValue);
      handleSubmitEarlyPay();
    }

    if (currentStep === COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION) {
      setIsBankAccountSetupCompletedSectionVisible(true);
    }
  };

  const handleShowPromoSection = () => {
    setShowPromoSection((prev) => !prev);
  };

  //KYC STEP
  const handleProfileFormSubmit = async (values: KYCProfileFormType) => {
    //Submit KYC verification
    try {
      setIsKycSubmitting(true);

      const payload = profileFormValuesToKycRequestPayload(values);
      const failedUserData: KycVerifyReqDto | undefined = getFailedKycUserDataFromLocalStorage();

      if (isKycRejected && failedUserData) {
        const _kycResponseStatus = getKycResponseType(
          currentUser?.kycStatus as KycStatus,
          payload,
          failedUserData,
        );
        if (_kycResponseStatus === EKycResponseType.INCORRECT_INFO) {
          setKycResponseType(_kycResponseStatus);
          return;
        }
      }

      const response = await mutationBankCardsControllerKycVerify()(payload);
      setKycResponseType(EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG);

      const _kycResponseStatus = getKycResponseType(response?.kycStatus as KycStatus);

      //Result handling
      if (_kycResponseStatus === EKycResponseType.SUCCESS) {
        setKycResponseType(EKycResponseType.SUCCESS);
        removeFailedKycUserDataFromLocalStorage();
      } else if (_kycResponseStatus === EKycResponseType.ERROR) {
        setKycResponseType(_kycResponseStatus);
        setFailedKycUserDataToLocalStorage(payload);
      } else if (_kycResponseStatus === EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG) {
        setKycResponseType(EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG);
      }
      await getCurrentUser();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      await fetchMainData();
      setIsKycSubmitting(false);
      setIsTermsAndConditionsAccepted(false);
    }
  };

  const handleChangeTermsAndConditions = () => {
    setIsTermsAndConditionsAccepted((prev) => !prev);
  };

  const handleChangeCardholderAgreement = () => {
    setIsCardholderAgreementAccepted((prev) => !prev);
  };

  // SHIPPING DETAILS STEP
  const handleSubmitShippingDetails = async (values: PaidCardShippingDetailsFormType) => {
    try {
      setIsShippingDetailsSubmitting(true);
      const payload = shippingDetailsFormValuesToRequestPayload(values);
      await mutationBankCardsControllerIssueBankCard()(payload);
      await getCurrentUser();
      await fetchCompanyBankCards();

      //Result handling
      if (showShippingStepOnly) {
        setPhysicalCardSuccessfullyOrdered(true);
        setIsShippingDetailsDirty(true);
      } else {
        const _factors = await fetchMfaFactors();
        const shouldShowMfa = !_factors?.some(
          (factor) => factor.type === MFAFactorType.SMS && !!factor.isVerified,
        );

        if (shouldShowMfa) {
          setCurrentStep(COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION);
          setIsShippingDetailsDirty(true);
        } else {
          setIsBankAccountSetupCompletedSectionVisible(true);
        }
      }
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsShippingDetailsSubmitting(false);
      setIsCardholderAgreementAccepted(false);
    }
  };

  const fetchCompanyBankCards = async (saveToState: boolean = true): Promise<BankCardResDto[]> => {
    try {
      setLoading(true);
      const companyBankCardsResponse = await queryBankCardsControllerListBankCards();
      if (saveToState) setCompanyBankCards(companyBankCardsResponse?.cards || []);
      return companyBankCardsResponse?.cards || [];
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
      return [];
    } finally {
      setLoading(false);
    }
  };

  // EARLY PAY STEP
  const handleChangeEarlyPayValue = () => {
    if (!isEarlyPayDirty) setIsEarlyPayTouched(true);
    setIsEarlyPayChecked();
  };

  const handleSubmitEarlyPay = async () => {
    try {
      setIsEarlyPaySubmitting(true);
      await mutationUsersControllerUpdateEarlyPay()({ isEarlyPayEnabled: isEarlyPayChecked });
      await getCurrentUser();
      setCurrentStep(COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS);
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsEarlyPaySubmitting(false);
    }
  };

  const formSubmitter = () => {
    setErrorMessage(undefined);
    //Trigger form validation for KYC verification and shipping details
    if (currentStep === COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION) {
      profileFormRef?.current?.onSubmit();
    }
    if (currentStep === COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS) {
      shippingDetailsFormRef?.current?.onSubmit();
    }
  };

  //MFA
  const handleSkipMfaStep = () => {
    setIsBankAccountSetupCompletedSectionVisible(true);
  };

  const handleMfaStepSubmitSuccess = () => {
    setIsMfaFinished(true);
  };

  const handleFinishedSetupSectionBtnClick = () => {
    handleCloseModal();
    onFinished?.();
  };

  return {
    metadata: {
      isLoading,
      currentUser,
      isSomeFormLoading,
      step: {
        currentStep,
        setCurrentStep,
      },
      isPrimaryBtnDisabled,
      kyc: {
        isKycAccepted,
        isKycPending,
        isKycRejected,
      },
    },
    pageData: {
      verificationStep: {
        isTermsAndConditionsAccepted,
        handleChangeTermsAndConditions,
        handleProfileFormSubmit,
        ref: profileFormRef,
        isKycSubmitting,
        kycResponseType,
        kycFormData,
        setKycFormData,
      },
      shippingDetails: {
        isCardholderAgreementAccepted,
        handleChangeCardholderAgreement,
        handleSubmitShippingDetails,
        ref: shippingDetailsFormRef,
        isShippingDetailsSubmitting,
        shippingDetailsFormData,
        setShippingDetailsFormData,
        physicalCardSuccessfullyOrdered,
      },
      earlyPay: {
        isEarlyPayChecked,
        isEarlyPaySubmitting,
      },
      mfa: {
        isMfaFinished,
      },
      errorMessage,
      isBankAccountSetupCompletedSectionVisible,
      promoSection: {
        showPromoSection,
      },
    },
    actions: {
      formSubmitter,
      handleBackBtnClick,
      handleShowPromoSection,
      handlePrimaryBtnClick,
      handleChangeEarlyPayValue,
      handleSkipMfaStep,
      handleMfaStepSubmitSuccess,
      handleFinishedSetupSectionBtnClick,
    },
  };
};
