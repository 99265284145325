import { FC, ReactNode } from "react";
import { Tooltip as TooltipIcon } from "assets/svg";

import { Container, StyledTooltip, Text } from "./styles";

interface Props {
  className?: string;
  id?: string;
  children?: string | ReactNode;
  tooltipContent?: ReactNode;
}

const TextWithTooltip: FC<Props> = ({ className, id, children, tooltipContent }) => (
  <Container className={className}>
    <Text>{children}</Text>
    {!!tooltipContent && (
      <StyledTooltip id={id || ""} tooltipContent={tooltipContent}>
        <TooltipIcon />
      </StyledTooltip>
    )}
  </Container>
);

export default TextWithTooltip;
