import { APPLICATION_NAME } from "constants/systemConstants";

export const employeeSetupPageTranslations = {
  "en-US": {
    setup_complete_states: {
      no_physical_card: {
        title: "Setup Completed",
        message: `Congratulations on completing the setup! To start using your ${APPLICATION_NAME} Bank Account you need to request a Physical Card.`,
        main_button: "Request a Physical Card",
      },
      no_virtual_card: {
        title: "Setup Completed",
        message:
          "Your physical card has been successfully shipped! While you wait for its arrival, you can request a virtual card now and start using it immediately.",
        main_button: "Request a Virtual Card",
      },
      onboarding_complete: {
        title: "Setup Completed",
        message:
          "Congratulations on completing the setup! You're all set to get paid. Let's get started!",
      },
    },

    steps: {
      setup_payroll: {
        title: "PayRoll Setup",
        back_button: "Back",
      },
      bank_accounts: {
        title: "Bank Accounts",
        connect_bank_account_btn: "Connect Bank Account",
        modals: {
          no_accounts_warning: {
            title: "Are you sure you want to continue ?",
            message:
              "Without connecting and verifying your bank account your pay distribution will be selected as a paper check.",
          },
        },
      },
      pay_distribution: {
        title: "Pay Distribution",
      },
    },
  },
};

export default employeeSetupPageTranslations;
