import { BonusChallengeRewardType, ChallengeGoalConditionOperator } from "types/BETypes";

export const translations = {
  "en-US": {
    condition_operators: {
      [ChallengeGoalConditionOperator.EQUAL_TO]: "equal to",
      [ChallengeGoalConditionOperator.LESS_THAN]: "less than",
      [ChallengeGoalConditionOperator.LESS_THAN_OR_EQUAL]: "less than or equal to",
      [ChallengeGoalConditionOperator.GREATER_THAN]: "greater than",
      [ChallengeGoalConditionOperator.GREATER_THAN_OR_EQUAL]: "greater than or equal to",
    },
    goal_templates: {
      [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: {
        goal: "<1>IF</1> {{goalName}}  is {{conditionOperator}} {{conditionValue}}, <1>THEN</1> reward <1>{{rewardAmount}}</1>",
        goal_text: "<1>IF</1> {{goalName}}, <1>THEN</1> award <2>{{rewardAmount}}</2>",
        stretch_goal:
          "<1>IF</1> {{conditionOperator}} {{conditionValue}}, <1>THEN</1> reward <1>{{rewardAmount}}</1>",
      },
      [BonusChallengeRewardType.PAY_TIERS]: {
        goal: "<1>IF</1> {{goalName}}  is {{conditionOperator}} {{conditionValue}}, <1>THEN</1> reward <1>{{rewardAmount}}</1> per event",
        goal_text: "<1>IF</1> {{goalName}}, <1>THEN</1> award <2>{{rewardAmount}}</2> per event",
        stretch_goal:
          "<1>IF</1> {{conditionOperator}} {{conditionValue}}, <1>THEN</1> reward <1>{{rewardAmount}}</1> per event",
      },
    },
    goal_templates_short: {
      [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: {
        goal: "<1>IF</1> {{goalName}}  is {{conditionOperator}} {{conditionValue}}",
        goal_text: "<1>IF</1> {{goalName}}",
        stretch_goal: "<1>IF</1> {{conditionOperator}} {{conditionValue}}",
      },
      [BonusChallengeRewardType.PAY_TIERS]: {
        goal: "<1>IF</1> {{goalName}}  is {{conditionOperator}} {{conditionValue}}",
        goal_text: "<1>IF</1> {{goalName}}",
        stretch_goal: "<1>IF</1> {{conditionOperator}} {{conditionValue}}",
      },
    },
  },
};

export default translations;
