import React, { FC } from "react";

import IconProps from "./IconProps";

const CloseIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59093 7.00195L13.0441 2.54883C13.2554 2.33785 13.3743 2.05157 13.3745 1.75295C13.3748 1.45432 13.2564 1.16783 13.0455 0.956483C12.8345 0.745138 12.5482 0.626258 12.2496 0.625995C11.951 0.625731 11.6645 0.744105 11.4531 0.955076L6.99999 5.4082L2.54687 0.955076C2.33553 0.743732 2.04888 0.625 1.75 0.625C1.45111 0.625 1.16447 0.743732 0.953123 0.955076C0.741779 1.16642 0.623047 1.45306 0.623047 1.75195C0.623047 2.05084 0.741779 2.33748 0.953123 2.54883L5.40625 7.00195L0.953123 11.4551C0.741779 11.6664 0.623047 11.9531 0.623047 12.2519C0.623047 12.5508 0.741779 12.8375 0.953123 13.0488C1.16447 13.2602 1.45111 13.3789 1.75 13.3789C2.04888 13.3789 2.33553 13.2602 2.54687 13.0488L6.99999 8.5957L11.4531 13.0488C11.6645 13.2602 11.9511 13.3789 12.25 13.3789C12.5489 13.3789 12.8355 13.2602 13.0469 13.0488C13.2582 12.8375 13.3769 12.5508 13.3769 12.2519C13.3769 11.9531 13.2582 11.6664 13.0469 11.4551L8.59093 7.00195Z"
      fill="#007D7B"
    />
  </svg>
);

export default CloseIcon;
