import { FC, ReactNode } from "react";
import { CheckmarkWhiteThin } from "assets/svg";

import { CheckboxInput, Checkmark, Container, Text } from "./styles";

interface Props {
  text?: string | ReactNode;
  className?: string;
  checked?: boolean;
  onCheck?: () => void;
  onUncheck?: () => void;
  disabled?: boolean;
  children?: ReactNode;
}

const Checkbox: FC<Props> = ({
  className,
  text,
  checked = false,
  onCheck,
  onUncheck,
  disabled,
  children,
  ...props
}) => {
  const handleCheck = () => {
    if (!checked) {
      onCheck && onCheck();
    } else {
      onUncheck && onUncheck();
    }
  };
  return (
    <Container className={className} {...props}>
      <CheckboxInput type="checkbox" checked={checked} disabled={disabled} />
      <Checkmark data-testid="checkbox-checkmark" onClick={handleCheck}>
        <CheckmarkWhiteThin />
      </Checkmark>
      {text && <Text onClick={handleCheck}>{text}</Text>}
      {children && children}
    </Container>
  );
};

export default Checkbox;
