import { FC } from "react";

import IconProps from "./IconProps";

const CheckmarkWhiteThin: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
      fill="white"
    />
  </svg>
);

export default CheckmarkWhiteThin;
