import { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";

const ServiceRoute: FC<RouteProps> = ({ children }) => {
  const { getDefaultRoute } = useRouteHelpers();
  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT === "tst" ? (
        <>{children}</>
      ) : (
        <Navigate to={getDefaultRoute()} />
      )}
    </>
  );
};

export default ServiceRoute;
