import * as yup from "yup";

import { MAX_INPUT_LENGTH } from "constants/form";
import { validatePhoneNumber } from "helpers";

export const ContactDetailsFormTypeValidationSchema = yup.object({
  firstName: yup
    .string()
    .required("validationErrors.common.is_required")
    .test("long first name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    })
    .test("is required", "validationErrors.common.is_required", function (value) {
      return (value?.trim() || "").length > 0;
    }),
  lastName: yup
    .string()
    .required("validationErrors.common.is_required")
    .test("long last name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    })
    .test("is required", "validationErrors.common.is_required", function (value) {
      return (value?.trim() || "").length > 0;
    }),

  jobTitle: yup
    .string()
    .test("long last name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    }),

  phone: yup
    .string()
    .test(
      "wrong phone format",
      "validationErrors.phone.phone_wrong_format",
      (value) => !value || validatePhoneNumber(value),
    ),
});

export type ContactDetailsFormType = yup.InferType<typeof ContactDetailsFormTypeValidationSchema>;
