import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ChevronRight } from "assets/svg";
import { Formik, useFormikContext } from "formik";

import { getFieldError, getPhoneNumberOrNull } from "helpers";

import { ButtonsContainer, FormLabel, InputContainer, PhoneInput } from "uikit";

import { ESMSWidgetSteps, EWidgetType } from "../../types";
import {
  BottomButtonsContainer,
  CancelButton,
  Description,
  FormContainer,
  InputBesideButtonsContainer,
  InputBesideSubmitButton,
  MainInputContainer,
  MainInputContainerInner,
  SubmitButton,
  Title,
  TitleContainer,
} from "../stepsStyles";
import { IEnterPhoneStepProps, IEnterPhoneStepPropsInner } from "./types";
import { FormType, validationSchema } from "./validationSchema";
import { Container } from "./styles";

const EnterPhone: FC<IEnterPhoneStepPropsInner> = (props) => {
  const { className, widgetType, defaultPhone, onSubmit, onCancel, formSubmitCallback } = props;
  const { t } = useTranslation();
  const commonPrefix = `components.multi_factor_authorization.sms`;
  const translationPrefix = `${commonPrefix}.steps.${ESMSWidgetSteps.ENTER_PHONE}`;
  const formikContext = useFormikContext<FormType>();
  const { values, setFieldValue } = formikContext;

  const handleSubmit = () => {
    formSubmitCallback?.();
    onSubmit(formikContext);
  };

  const handleCancel = () => {
    formSubmitCallback?.();
    onCancel();
  };

  return (
    <Container className={className}>
      <TitleContainer>
        <Title>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </Title>
        <Description>
          <Trans i18nKey={`${translationPrefix}.description`} />
        </Description>
      </TitleContainer>

      <FormContainer>
        <InputContainer>
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.phone_input.label`} />
          </FormLabel>
          <MainInputContainer className={`${widgetType}`}>
            <MainInputContainerInner>
              <PhoneInput
                name="phoneNumber"
                onChange={(value) =>
                  setFieldValue("phoneNumber", getPhoneNumberOrNull(value as string))
                }
                value={values.phoneNumber || undefined}
                error={getFieldError("phoneNumber", formikContext)}
                errorDataTestId="mfa-smsm-enter-phone-step-error-phone"
                placeholder={t(`${translationPrefix}.phone_input.placeholder`)}
                alwaysShowErrorBlock={false}
              />
            </MainInputContainerInner>

            {widgetType === EWidgetType.SETUP && (
              <InputBesideButtonsContainer>
                <InputBesideSubmitButton
                  data-testid={"mfa-sms-enter-phone-step-submit-button"}
                  onClick={handleSubmit}
                >
                  <Trans i18nKey={`${translationPrefix}.confirm_button.${widgetType}`} />
                </InputBesideSubmitButton>
              </InputBesideButtonsContainer>
            )}
          </MainInputContainer>
        </InputContainer>

        {widgetType === EWidgetType.SETTINGS && (
          <BottomButtonsContainer>
            <CancelButton
              data-testid={"mfa-sms-enter-phone-step-cancel-button"}
              onClick={handleCancel}
            >
              <Trans i18nKey={`buttons.cancel`} />
            </CancelButton>
            <SubmitButton
              data-testid={"mfa-sms-enter-phone-step-confirm-button"}
              onClick={handleSubmit}
            >
              <Trans i18nKey={`${translationPrefix}.confirm_button.${widgetType}`} />
              <ChevronRight />
            </SubmitButton>
          </BottomButtonsContainer>
        )}
      </FormContainer>
    </Container>
  );
};

const EnterPhoneContainer: FC<IEnterPhoneStepProps> = (props) => {
  const { defaultPhone } = props;
  const initialFormData = { phoneNumber: defaultPhone || "" };

  const [svoc, setSvoc] = useState(false);

  const handleSubmitAttempt = () => {
    setSvoc(true);
  };

  return (
    <>
      <Formik
        initialValues={initialFormData}
        onSubmit={() => undefined}
        validationSchema={validationSchema}
        validateOnChange={svoc}
        validateOnBlur={false}
      >
        <EnterPhone {...props} formSubmitCallback={handleSubmitAttempt} />
      </Formik>
    </>
  );
};

export default EnterPhoneContainer;
