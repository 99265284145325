import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikProps, useFormikContext } from "formik";

import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import { MAX_INPUT_LENGTH } from "constants/form";
import { StateSelectOptions, TERMS_AND_CONDITIONS, zipInputFormat } from "constants/shared";
import { getFieldError, getPhoneNumberOrNull } from "helpers";

import {
  Checkbox,
  FormLabelSmall,
  Input,
  InputContainer,
  InputGroupContainerHalved,
  Loader,
  NumberInput,
  PhoneInput,
} from "uikit";
import { renderExternalLabel } from "uikit/Input/helpers";
import CustomSelect from "uikit/Input/Select";

import { ScrollableContainer } from "../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage } from "../components/renderElements";
import {
  CheckboxText,
  CheckboxTextLink,
  CheckboxWrapper,
  HDescription,
  Header,
  HTitle,
  StyledLoaderContainer,
} from "../components/styles";
import { IPaidCardShippingDetailsFormRef } from "./types";
import {
  PaidCardShippingDetailsFormType,
  PaidCardShippingDetailsFormValidationSchema,
} from "./validationSchema";
import {
  CardOwnerText,
  CardOwnerTextContainer,
  CardTemplateContainer,
  CardTemplateIcon,
  CardTemplateSection,
  Container,
  ContainerInner,
  CTSHDescription,
  CTSHeader,
  CTSHTitle,
  FormSection,
  FormSectionContainer,
} from "./styles";

interface IProps {
  className?: string;
  formData: PaidCardShippingDetailsFormType;
  setFormData?: (data: PaidCardShippingDetailsFormType) => void;
  onSubmit: (values: PaidCardShippingDetailsFormType) => void;
  agreementCheckboxProps: {
    isChecked: boolean;
    onChange: () => void;
  };
  isLoading?: boolean;
  errorMessage?: string;
  showCardTemplate?: boolean;
}

const PaidCardShippingDetailsForm = forwardRef<IPaidCardShippingDetailsFormRef, IProps>(
  (props, ref) => {
    const originTranslationPrefix = "components.company_bank_account_components";
    const translationPrefix = `${originTranslationPrefix}.company_bank_account_form`;
    const [svoc, setSvoc] = useState<boolean>(false);
    const { className, onSubmit, showCardTemplate, errorMessage, formData } = props;

    const formikRef = useRef<FormikProps<PaidCardShippingDetailsFormType>>(null);

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        setSvoc(true);
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Container className={className}>
        <Header>
          <HTitle>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </HTitle>
          <HDescription>
            <Trans i18nKey={`${translationPrefix}.description`} />
          </HDescription>
        </Header>

        {renderErrorMessage(errorMessage)}

        <ContainerInner>
          <Formik<PaidCardShippingDetailsFormType>
            innerRef={formikRef}
            onSubmit={(values) => onSubmit(values)}
            initialValues={formData}
            validateOnChange={svoc}
            validationSchema={PaidCardShippingDetailsFormValidationSchema}
          >
            <ShippingDetailsForm {...props} />
          </Formik>

          {showCardTemplate && (
            <CardTemplateSection>
              <CTSHeader>
                <CTSHTitle>
                  <Trans i18nKey={`${translationPrefix}.card_template_section.title`} />
                </CTSHTitle>
                <CTSHDescription>
                  <Trans i18nKey={`${translationPrefix}.card_template_section.description`} />
                </CTSHDescription>
              </CTSHeader>

              <CardTemplateContainer>
                <CardTemplateIcon />

                <CardOwnerTextContainer>
                  <CardOwnerText>{formData?.firstName || ""}</CardOwnerText>
                  <CardOwnerText>{formData?.lastName || ""}</CardOwnerText>
                </CardOwnerTextContainer>
              </CardTemplateContainer>
            </CardTemplateSection>
          )}
        </ContainerInner>
      </Container>
    );
  },
);

PaidCardShippingDetailsForm.displayName = "PaidCardShippingDetailsForm";

export default PaidCardShippingDetailsForm;

const ShippingDetailsForm: FC<IProps> = ({ setFormData, isLoading, agreementCheckboxProps }) => {
  const { t } = useTranslation();
  const originTranslationPrefix = "components.company_bank_account_components";
  const formLabelTranslationPrefix = `${originTranslationPrefix}.shared.profile_form.form_labels`;
  const { isChecked, onChange } = agreementCheckboxProps;

  const formikProps = useFormikContext<PaidCardShippingDetailsFormType>();
  const { setFieldValue, handleChange, values } = formikProps;

  useEffect(() => {
    setFormData?.(values);
  }, [values]);

  return (
    <FormSectionContainer>
      <ScrollableContainer>
        <FormSection>
          {isLoading && (
            <StyledLoaderContainer>
              <Loader />
            </StyledLoaderContainer>
          )}
          <InputGroupContainerHalved>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.first_name`} />
              </FormLabelSmall>
              <Input
                name="firstName"
                onChange={(e) => setFieldValue("firstName", e.currentTarget.value.trimStart())}
                onBlur={(e) => setFieldValue("firstName", e.currentTarget.value.trimEnd())}
                value={values.firstName}
                error={getFieldError("firstName", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.first_name`),
                  maxInputLength: MAX_INPUT_LENGTH,
                })}
                errorDataTestId="error-first-name"
              />
            </InputContainer>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.last_name`} />
              </FormLabelSmall>
              <Input
                name="lastName"
                onChange={(e) => setFieldValue("lastName", e.currentTarget.value.trimStart())}
                onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
                value={values.lastName}
                error={getFieldError("lastName", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.last_name`),
                  maxInputLength: MAX_INPUT_LENGTH,
                })}
                errorDataTestId="error-last-name"
              />
            </InputContainer>
          </InputGroupContainerHalved>

          <InputGroupContainerHalved>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.address`} />
              </FormLabelSmall>
              <Input
                name="address"
                onChange={handleChange}
                value={values.address}
                error={getFieldError("address", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.address`),
                  maxInputLength: MAX_INPUT_LENGTH,
                })}
                errorDataTestId="error-address"
              />
            </InputContainer>

            <InputContainer>
              <FormLabelSmall>
                {renderExternalLabel(
                  <Trans i18nKey={`${formLabelTranslationPrefix}.suite`} />,
                  true,
                )}
              </FormLabelSmall>
              <Input
                name="suite"
                onChange={handleChange}
                value={values.suite || ""}
                error={getFieldError("suite", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.suite`),
                  maxInputLength: MAX_INPUT_LENGTH,
                })}
                errorDataTestId="error-suit"
                optional
              />
            </InputContainer>
          </InputGroupContainerHalved>

          <InputGroupContainerHalved>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.state`} />
              </FormLabelSmall>
              <CustomSelect<SelectOption<TStates>>
                name="state"
                onChange={(option) => setFieldValue("state", option)}
                value={values.state as any}
                options={StateSelectOptions}
                placeholder={""}
                error={getFieldError("state.value", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.state`),
                })}
                isSearchable={true}
                errorDataTestId="error-state"
              />
            </InputContainer>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.city`} />
              </FormLabelSmall>
              <Input
                name="city"
                onChange={handleChange}
                value={values.city || ""}
                error={getFieldError("city", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.city`),
                  maxInputLength: MAX_INPUT_LENGTH,
                })}
                errorDataTestId="error-city"
              />
            </InputContainer>
          </InputGroupContainerHalved>

          <InputGroupContainerHalved>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.zip`} />
              </FormLabelSmall>
              <NumberInput
                name="zip"
                onChange={(val) => setFieldValue("zip", val.toString().split(" ").join(""))}
                value={values.zip}
                error={getFieldError("zip", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.zip`),
                })}
                errorDataTestId="error-zip"
                format={zipInputFormat}
              />
            </InputContainer>

            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${formLabelTranslationPrefix}.phone`} />
              </FormLabelSmall>
              <PhoneInput
                name="phone"
                onChange={(value) => setFieldValue("phone", getPhoneNumberOrNull(value as string))}
                value={values.phone || undefined}
                error={getFieldError("phone", formikProps, {
                  field: t(`${formLabelTranslationPrefix}.phone`),
                })}
                errorDataTestId="error-phone"
              />
            </InputContainer>
          </InputGroupContainerHalved>
        </FormSection>
      </ScrollableContainer>

      <CheckboxWrapper>
        <Checkbox
          checked={isChecked}
          onCheck={onChange}
          onUncheck={onChange}
          data-testid="company-shipping-details-agreement-checkbox"
        >
          <CheckboxText>
            <Trans
              i18nKey={`${originTranslationPrefix}.company_bank_account_form.shipping_details.cardholder_agreement`}
              components={{
                1: <CheckboxTextLink href={TERMS_AND_CONDITIONS} target="_blank" />,
              }}
            />
          </CheckboxText>
        </Checkbox>
      </CheckboxWrapper>
    </FormSectionContainer>
  );
};
