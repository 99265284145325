import styled from "styled-components";

import { BankAccountInfoRow } from "components/BankAccountComponents/BankAccountInfoRows";
import { ActionsColumn } from "components/BankAccountComponents/BankAccountInfoRows/styles";
import BankAccountsSelect from "components/BankAccountsSelect";

import {
  InputContainer,
  Link,
  LinkTextMixin,
  PrimaryButton,
  PrimaryButtonIconed,
  SecondaryButton,
  TextWithTooltip,
} from "uikit";

export const Container = styled.div``;

export const SectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const EditItemsButton = styled(PrimaryButtonIconed)`
  width: auto;
  gap: 8px;

  svg {
    path {
      &:first-of-type {
        fill: none;
      }
    }
  }
`;

export const EditCancelButton = styled(SecondaryButton)`
  width: auto;
`;

export const EditSaveButton = styled(PrimaryButton)`
  width: auto;
`;

export const BankAccountsContainer = styled.div``;

export const StyledExternalBankAccountInfoRow = styled(BankAccountInfoRow)`
  margin-bottom: 24px;

  ${ActionsColumn} {
    width: auto;
  }
`;

export const BACItem = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};
  margin-bottom: 24px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;

    ${StyledExternalBankAccountInfoRow} {
      margin-bottom: 0;
    }
  }
`;

export const BACItemTopButtons = styled.div``;

export const BACItemDeleteButton = styled.div`
  cursor: pointer;

  svg {
    height: 24px;
    width: auto;
  }
`;

export const BACItemInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 4px;
`;

export const BACItemValueLabelBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  margin-right: 16px;
  gap: 8px;
  white-space: nowrap;

  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const BACItemEarlyPayContainerEnabled = styled(BACItemValueLabelBase)`
  background: ${({ theme }) => theme.colors.bankAccountEarlyPayEnabledBg};
  color: ${({ theme }) => theme.colors.bankAccountEarlyPayEnabledText};
`;

export const BACItemEarlyPayContainerDisabled = styled(BACItemValueLabelBase)`
  background: ${({ theme }) => theme.colors.bankAccountEarlyPayDisabledBg};
  color: ${({ theme }) => theme.colors.bankAccountEarlyPayDisabledText};
`;

export const BACItemValueLabelContainer = styled.div`
  display: flex;
`;

export const BACItemValueLabel = styled(BACItemValueLabelBase)`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.bankAccountIconedValueCotainerText};

  background: ${({ theme }) => theme.colors.bankAccountIconedValueCotainerBg};

  svg {
    height: 20px;
    width: auto;
    path {
      stroke: ${({ theme }) => theme.colors.bankAccountIconedValueCotainerText};
    }
  }
`;

export const BankInputContainer = styled.div`
  flex: 1;
`;

export const DistributionRuleTypeInputContainer = styled.div`
  flex: 1;
`;

export const DistributionValueInputContainer = styled.div`
  flex: 1;
`;

export const AdditionalButtonsContainer = styled.div`
  margin-bottom: 80px;
`;

//Early Pay Section

export const EarlyPayContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const EarlyPaySwitchContainer = styled.div`
  margin-right: 8px;
`;

export const EarlyPayLabelContainer = styled.div``;

export const EarlyPayLabel = styled(TextWithTooltip)`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const EarlyPayLabelDescription = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.33;
`;

export const EarlyPayChangeAccountModalBankAccountsSelectContainer = styled(InputContainer)`
  margin-top: 16px;
`;

//Split Button Section

export const SplitButtonContainer = styled.div``;

export const SplitButton = styled.div`
  ${LinkTextMixin}
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 20px;
    width: auto;
    margin-right: 10px;

    path {
      stroke: ${({ theme }) => theme.colors.accentMain};
    }
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.disabled};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.disabled};
      }
    }
  }
`;
