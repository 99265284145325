import { FC } from "react";

import IconProps from "./IconProps";

const SmallBonusIconTimeAlt: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1510_36399)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.5009C0 5.60734 5.60734 0 12.5009 0C19.3927 0 25 5.60905 25 12.5009C25 19.3927 19.3944 25 12.5009 25C5.60734 25 0 19.3944 0 12.5009ZM2.73225 12.5009C2.73225 17.8865 7.11349 22.2678 12.5009 22.2678C17.8865 22.2678 22.2678 17.8869 22.2678 12.5009C22.2678 7.11483 17.8882 2.73225 12.5009 2.73225C7.11349 2.73225 2.73225 7.1152 2.73225 12.5009ZM13.5837 7.27623V12.5153L17.2163 16.1479C17.6313 16.5615 17.6299 17.2327 17.2163 17.6462C16.8041 18.0598 16.1329 18.0598 15.7193 17.6462L11.7759 13.7026C11.5557 13.4827 11.4556 13.1903 11.4703 12.902C11.4698 12.8957 11.4688 12.8895 11.4678 12.8832C11.4664 12.8734 11.4648 12.8634 11.4648 12.8528V7.27623C11.4648 6.69053 11.9399 6.2168 12.5243 6.2168C13.1086 6.2168 13.5837 6.69046 13.5837 7.27623Z"
        fill="#40B380"
      />
    </g>
    <defs>
      <clipPath id="clip0_1510_36399">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SmallBonusIconTimeAlt;
