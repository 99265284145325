import { FC } from "react";
import { Trans } from "react-i18next";
import { CheckmarkRoundFilledDark, CloseRoundIconDanger, LogoFull } from "assets/svg";

import { IBenefit } from "components/PaymentMethods/types";

import { useNoAccountState } from "./useNoAccountState";
import {
  BenefitIconContainer,
  BenefitsTable,
  BenefitsTableContainer,
  BTCell,
  BTHeaderCell,
  BTHeaderRow,
  BTRow,
  Container,
  CreateAccountButton,
  LogoContainer,
  MBCBenefit,
  MBCBIcon,
  MBCBTitle,
  MBCContent,
  MBCList,
  MBCListItem,
  MBCTitle,
  MobileBenefitCard,
  MobileBenefitsContainer,
  PromoCard,
  PromoCardIcon,
  PromoCardsContainer,
  PromoCardText,
  SubTitle,
  Title,
  TitleAlt,
} from "./styles";

interface Props {
  className?: string;
  onCreateAccount?: () => void;
}

const NoAccountState: FC<Props> = ({ className, onCreateAccount }) => {
  const translationPrefix = `components.payment_methods.no_account_state`;
  const { benefitsList, benefitsListSame, benefitsListWithDifference } = useNoAccountState();

  const renderBenefitState = (isPresent: boolean) => {
    return (
      <BenefitIconContainer className={`${isPresent ? "checked" : "unchecked"}`}>
        {isPresent ? <CheckmarkRoundFilledDark /> : <CloseRoundIconDanger />}
      </BenefitIconContainer>
    );
  };

  const renderMobileBenefitCard = (item: IBenefit) => {
    return (
      <MobileBenefitCard key={item.id}>
        <MBCTitle>{item.text}</MBCTitle>
        <MBCContent>
          <MBCBenefit>
            <MBCBTitle>
              <Trans i18nKey={`${translationPrefix}.table_headers.traditional_banking`} />
            </MBCBTitle>
            <MBCBIcon>{renderBenefitState(!!item.traditionalBank)}</MBCBIcon>
          </MBCBenefit>
          <MBCBenefit>
            <MBCBTitle>
              <Trans i18nKey={`${translationPrefix}.table_headers.company_banking`} />
            </MBCBTitle>
            <MBCBIcon>{renderBenefitState(!!item.companyAccount)}</MBCBIcon>
          </MBCBenefit>
        </MBCContent>
      </MobileBenefitCard>
    );
  };

  const renderMobileBenefitsListCard = () => {
    return (
      <MobileBenefitCard>
        <MBCList>
          {benefitsListSame.map((item) => (
            <MBCListItem key={item.id}>{item.text}</MBCListItem>
          ))}
        </MBCList>
        <MBCContent>
          <MBCBenefit>
            <MBCBTitle>
              <Trans i18nKey={`${translationPrefix}.table_headers.traditional_banking`} />
            </MBCBTitle>
            <MBCBIcon>{renderBenefitState(true)}</MBCBIcon>
          </MBCBenefit>
          <MBCBenefit>
            <MBCBTitle>
              <Trans i18nKey={`${translationPrefix}.table_headers.company_banking`} />
            </MBCBTitle>
            <MBCBIcon>{renderBenefitState(true)}</MBCBIcon>
          </MBCBenefit>
        </MBCContent>
      </MobileBenefitCard>
    );
  };

  return (
    <Container className={className}>
      <LogoContainer>
        <LogoFull />
      </LogoContainer>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>
      <TitleAlt>
        <Trans i18nKey={`${translationPrefix}.subtitle_1`} />
      </TitleAlt>
      <PromoCardsContainer>
        {benefitsList.slice(0, 3).map((item) => {
          return (
            <PromoCard key={item.id}>
              <PromoCardIcon>{item.icon}</PromoCardIcon>
              <PromoCardText>{item.text}</PromoCardText>
            </PromoCard>
          );
        })}
      </PromoCardsContainer>
      <SubTitle>
        <Trans i18nKey={`${translationPrefix}.subtitle_2`} />
      </SubTitle>
      <BenefitsTableContainer>
        <BenefitsTable>
          <BTHeaderRow>
            <BTHeaderCell>
              <Trans i18nKey={`${translationPrefix}.table_headers.functions`} />
            </BTHeaderCell>
            <BTHeaderCell>
              <Trans i18nKey={`${translationPrefix}.table_headers.traditional_banking`} />
            </BTHeaderCell>
            <BTHeaderCell>
              <Trans i18nKey={`${translationPrefix}.table_headers.company_banking`} />
            </BTHeaderCell>
          </BTHeaderRow>
          {benefitsList.map((item) => {
            return (
              <BTRow key={item.id}>
                <BTCell>{item.text}</BTCell>
                <BTCell>{renderBenefitState(!!item.traditionalBank)}</BTCell>
                <BTCell>{renderBenefitState(!!item.companyAccount)}</BTCell>
              </BTRow>
            );
          })}
        </BenefitsTable>
      </BenefitsTableContainer>
      {/* Mobile benefits list */}
      <MobileBenefitsContainer>
        {benefitsListWithDifference.map((item) => {
          return renderMobileBenefitCard(item);
        })}
      </MobileBenefitsContainer>

      <MobileBenefitsContainer>{renderMobileBenefitsListCard()}</MobileBenefitsContainer>

      <CreateAccountButton
        onClick={onCreateAccount}
        data-testid="payment-method-no-account-state-create-company-account-button"
      >
        <Trans i18nKey={`${translationPrefix}.create_account_button`} />
      </CreateAccountButton>
    </Container>
  );
};

export default NoAccountState;
