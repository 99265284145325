import styled, { css } from "styled-components";

import EmptyList from "components/EmptyList";

import { Body1, Body2, SecondaryButtonIconed } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

const rowHeight = 54;
const emptyStateHeight = 190;

const RowMixin = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
`;

export const Container = styled.div``;

export const ListHeader = styled.div``;

export const HeaderCellText = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const HeaderRow = styled.div`
  ${RowMixin};
  padding: 0;
`;

export const List = styled.div``;

export const Row = styled.div`
  ${RowMixin};
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};
  height: ${rowHeight};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowCell = styled.div`
  display: flex;
  width: 25%;

  &:last-child {
    width: auto;
  }
`;

export const RowCellText = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const DownloadButton = styled(SecondaryButtonIconed)`
  width: auto;
`;

export const NewMark = styled.span`
  display: flex;
  align-items: center;

  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 100px;
`;

export const StyledLoaderContainer = styled(LoaderContainer)`
  height: ${emptyStateHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyList = styled(EmptyList)`
  height: ${emptyStateHeight}px;
  min-height: ${emptyStateHeight}px;
`;
