import { FC } from "react";

import {
  getContainerClassName,
  renderAdditionalContent,
  renderBelowContent,
  renderButton,
  renderLabels,
  renderLargeFooterContent,
  renderTextBlock,
} from "./renderElements";
import { IProps } from "./types";
import { ContainerLarge, ContainerLargeInner, ContainerOuter, MainContent } from "./styles";

const NavigationCardWithDetails: FC<IProps> = (props: IProps) => {
  const translationPrefix = `components.navigation_card_with_details`;

  const { onClick, button, largeContent, largeFooterContent, additionalContent, isActive, id } =
    props;

  const _className = getContainerClassName(props);
  return (
    <ContainerOuter
      className={_className}
      data-testid={id ? `${id}_card ${id}_card_large` : undefined}
    >
      <ContainerLarge onClick={onClick} className={_className}>
        <ContainerLargeInner>
          <MainContent>
            {largeContent ? <>{largeContent}</> : renderTextBlock(props)}

            {button || renderButton(props)}
          </MainContent>

          {!!additionalContent && renderAdditionalContent(props)}
        </ContainerLargeInner>

        {!!largeFooterContent && renderLargeFooterContent(props)}

        {!!isActive && renderLabels(props)}
      </ContainerLarge>

      {renderBelowContent(props)}
    </ContainerOuter>
  );
};

export default NavigationCardWithDetails;
