import styled from "styled-components";

import { breakpoint } from "helpers";

export const BankingDisclosureContainer = styled.div`
  margin-top: 96px;

  ${breakpoint("xs", "md")`
    width: 100%;
    margin-top: 36px;
  `}

  ${breakpoint("md", "xlm")`
    width: 100%;
    margin-top: 64px;
  `}
`;
