import { configureStore } from "@reduxjs/toolkit";

import metaDataReducer from "./reducers/metaData";

export const store = configureStore({
  reducer: {
    metaData: metaDataReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
