import { FC } from "react";

import IconProps from "./IconProps";

const PhoneFilled: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="phone-filled" clip-path="url(#clip0_9600_37698)">
      <path
        id="Vector"
        d="M6.00004 2.37891C6.11956 2.37887 6.2369 2.41097 6.33976 2.47184C6.44261 2.53271 6.52722 2.62012 6.58471 2.72491L6.61871 2.79824L7.95204 6.13157C8.00574 6.26568 8.01443 6.4136 7.9768 6.55307C7.93916 6.69254 7.85725 6.81601 7.74337 6.90491L7.67671 6.95024L6.56071 7.61957L6.60271 7.68824C7.13388 8.53157 7.84738 9.24507 8.69071 9.77624L8.75871 9.81757L9.42871 8.70291C9.50296 8.57896 9.61546 8.48249 9.74929 8.42803C9.88311 8.37356 10.031 8.36404 10.1707 8.40091L10.2474 8.42691L13.5807 9.76024C13.6917 9.80452 13.7887 9.87782 13.8617 9.97247C13.9346 10.0671 13.9808 10.1796 13.9954 10.2982L14 10.3789V13.0456C14 14.1502 13.1047 15.0456 11.96 15.0442C6.24071 14.6969 1.68137 10.1376 1.33337 4.37891C1.33335 3.86877 1.52826 3.3779 1.87823 3.00673C2.2282 2.63557 2.70678 2.41217 3.21604 2.38224L3.33337 2.37891H6.00004Z"
        fill="#627293"
      />
    </g>
    <defs>
      <clipPath id="clip0_9600_37698">
        <rect width="16" height="16" fill="white" transform="translate(0 0.378906)" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneFilled;
