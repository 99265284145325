import styled, { css } from "styled-components";

import { breakpoint } from "helpers";
import EmailWithIcon from "components/EmailWithIcon";

import { CustomLinkSmall, LinkSmall, PrimaryButtonLarge, SecondaryButton } from "uikit";
import { ButtonBaseMixin, LargeButtonMixin } from "uikit/Buttons/Buttons";

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.titleSemiLargeAlt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 23px;
  line-height: 40px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.titleSemiLarge};
    margin-bottom: 4px;

  `}
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 24px;
  line-height: 24px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.body2};
    margin-bottom: 16px;
  `}
`;

export const FormItem = styled.div`
  margin-bottom: 16px;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledSecondaryBtn = styled(SecondaryButton)`
  ${LargeButtonMixin};
  width: 99px;

  ${breakpoint("xs", "md")`
    ${ButtonBaseMixin}
    width: 100%;
    max-width: 100%;
  `}
`;

export const StyledPrimaryBtn = styled(PrimaryButtonLarge)`
  margin-left: auto;
  max-width: 480px;

  ${breakpoint("xs", "md")`
  ${ButtonBaseMixin}
    width: 100%;
    max-width: 100%;
  `}
`;

export const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterText = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20px;
  margin-bottom: 16px;
`;

export const FooterLinkInner = styled(LinkSmall)`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20px;
`;

export const FooterLinkExternal = styled(CustomLinkSmall)`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20px;
`;

export const TermsAndPrivacyDisclaimer = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 24px;

  a {
    font-size: ${({ theme }) => theme.size.body2};
  }
`;

export const EmailContainer = styled.div`
  padding: 16px;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.primary}30;
  width: 100%;
  box-sizing: border-box;
`;

export const ECLabel = styled.span`
  line-height: 20px;
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const StyledEmailWithIcon = styled(EmailWithIcon)``;

export const BoxMixin = css`
  padding: 16px;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.primary}10;
`;
