export const myProfileTranslation = {
  "en-US": {
    admin_form_labels: {
      first_name: "First Name",
      last_name: "Last Name",
      job_title: "Title",
      phone: "Phone Number",
      email: "Email",
    },

    employee_form_label: {
      email: "Email",
    },

    save_changes_btn: "Save Changes",

    profile_update_success_modal: {
      title: "Profile updated",
      message: "Your profile information was successfully updated.",
    },
  },
};

export default myProfileTranslation;
