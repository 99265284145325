import React, { FC } from "react";

import IconProps from "./IconProps";

const WarningLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="93"
    fill="none"
    viewBox="0 0 92 93"
  >
    <mask id="mask0" width="92" height="93" x="0" y="0" maskUnits="userSpaceOnUse">
      <path fill="#fff" fillRule="evenodd" d="M0 0h91.838v92.068H0V0z" clipRule="evenodd"></path>
    </mask>
    <g mask="url(#mask0)">
      <path
        fill="#999"
        fillRule="evenodd"
        d="M45.919 92.068c-12.265 0-23.796-4.787-32.47-13.483C4.777 69.89 0 58.33 0 46.034c0-12.296 4.776-23.856 13.45-32.55C22.123 4.787 33.654 0 45.919 0c7.42 0 14.791 1.815 21.318 5.25.534.282.74.945.459 1.481-.28.535-.942.742-1.476.46a43.74 43.74 0 00-20.301-5c-24.114 0-43.732 19.668-43.732 43.843 0 24.175 19.618 43.842 43.732 43.842 24.115 0 43.733-19.667 43.733-43.842 0-1.478-.075-2.97-.221-4.43a1.094 1.094 0 112.176-.218 46.92 46.92 0 01.231 4.648c0 12.296-4.777 23.856-13.45 32.551-8.673 8.696-20.203 13.483-32.47 13.483z"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#999"
      fillRule="evenodd"
      d="M89.695 37.423c-.502 0-.954-.348-1.067-.861a47.34 47.34 0 00-.195-.85 1.093 1.093 0 112.125-.514c.072.298.141.599.206.894a1.097 1.097 0 01-1.069 1.33zM88.259 32.342c-.453 0-.875-.283-1.032-.735a44.162 44.162 0 00-9.724-15.895 1.097 1.097 0 01.033-1.55 1.09 1.09 0 011.546.034 46.364 46.364 0 0110.21 16.69 1.098 1.098 0 01-1.033 1.456z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F93"
      fillRule="evenodd"
      d="M18.497 66.407h54.844c3.008 0 4.239-2.137 2.735-4.749L48.654 14.042c-1.504-2.61-3.965-2.61-5.47 0l-27.42 47.616c-1.505 2.612-.275 4.749 2.733 4.749zm4.194-5.553l7.493-13.012L45.92 20.52l23.229 40.334H22.69z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M73.342 67.502H18.498c-1.863 0-3.285-.69-4.006-1.942-.722-1.253-.607-2.834.324-4.45L42.24 13.495c.93-1.615 2.238-2.505 3.68-2.505 1.444 0 2.75.89 3.681 2.505l27.423 47.617c.931 1.616 1.046 3.196.325 4.449-.722 1.252-2.145 1.942-4.006 1.942zM45.919 13.18c-.62 0-1.271.514-1.787 1.41L16.71 62.206c-.516.896-.634 1.72-.325 2.257.31.538 1.08.848 2.113.848h54.844c1.033 0 1.802-.31 2.112-.848.31-.537.192-1.36-.325-2.257L47.707 14.59c-.515-.896-1.168-1.41-1.788-1.41z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M48.653 36.327c0 1.514-1.224 13.634-2.732 13.634-1.51 0-2.733-12.12-2.733-13.634a2.736 2.736 0 012.733-2.74 2.736 2.736 0 012.732 2.74zM48.195 54.345a2.28 2.28 0 01-2.277 2.282 2.28 2.28 0 01-2.277-2.282 2.28 2.28 0 012.277-2.284 2.28 2.28 0 012.277 2.284z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default WarningLarge;
