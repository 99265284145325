import styled from "styled-components";

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.avatarBg};
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.avatarText};
  text-transform: uppercase;
  background-size: cover;

  img {
    height: 100%;
    border-radius: 50%;
  }
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;

  &.small ${Image} {
    width: 33px;
    height: 33px;
    font-size: ${({ theme }) => theme.size.body1};
  }
`;
