import { FC } from "react";

import IconProps from "./IconProps";

const SmallBonusIconTime: FC<IconProps> = ({ className }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="#9B9B9B"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="17" cy="17" r="17" />
    <rect
      x="17.4004"
      y="8"
      width="11.2358"
      height="3.00752"
      rx="1.50376"
      transform="rotate(89.702 17.4004 8)"
      fill="white"
    />
    <rect
      x="26.7188"
      y="25.3516"
      width="14.0554"
      height="3.00752"
      rx="1.50376"
      transform="rotate(-154.808 26.7188 25.3516)"
      fill="white"
    />
  </svg>
);

export default SmallBonusIconTime;
