enum routes {
  ROOT = "/",

  //Auth
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  SIGN_IN_COMPLETE = "/sign-in/complete",
  SIGN_UP_COMPLETE = "/sign-up/complete",
  SIGN_IN_COMPLETE_MFA = "/sign-in/complete-mfa",
  RECOVERY_ACCOUNT = "/recovery-account",
  COMPLETE_RECOVERY_ACCOUNT = "/complete-recovery/:internalUserId",
  EMPLOYEE_SIGN_UP = "/sign-up/employee/complete",
  ADMIN_SIGN_UP = "/sign-up/admin/complete",
  CHECK_EMAIL = "/check-email",

  //Main Menu
  ADMIN_SETUP = "/setup",
  EMPLOYEE_SETUP = "/setup/employee",
  PEOPLE = "/people",
  PEOPLE_EDIT_WORKER = "/people/edit-worker/:workerId",
  PEOPLE_WORKER_DETAILS = "/people/worker-details/:workerId",
  ADMIN_PAY = "/pay",
  EMPLOYEE_PAY = "/pay/employee",
  ADMIN_SETTINGS = "/settings",
  EMPLOYEE_SETTINGS = "/settings/employee",
  EMPLOYEE_SETTINGS_PAY_DISTRIBUTION = "/settings/employee/pay-distribution",
  EMPLOYEE_BANK_ACCOUNTS = "/settings/employee/bank-accounts",
  EMPLOYEE_SETTINGS_MFA_AUTHENTICATION = "/settings/employee/authentication",
  ADMIN_BANKING = "/banking",
  EMPLOYEE_BANKING = "/employee/banking",
  EMPLOYEE_BANKING_MONTHLY_STATEMENTS = "/employee/banking/monthly-statements",
  EMPLOYEE_BANKING_CARDS = "/employee/banking/cards",
  EMPLOYEE_TRANSFER_FUNDS = "/employee/banking/transfer-funds",
  EMPLOYEE_BANKING_REWARDS = "/banking/my-rewards",
  EMPLOYEE_BANKING_REWARDS_OLD = "/rewards",

  ADMIN_PROFILE = "/my-profile",
  ADMIN_BANK_ACCOUNTS = "/settings/bank-accounts",
  EMPLOYEE_PROFILE = "/my-profile/employee",

  DOCUMENTS = "/settings/documents",
  PAYROLL_AND_BENEFITS = "/settings/payroll-and-benefits",
  ADMIN_TEAM_MEMBERS = "/settings/team-members",

  //Service
  PAGE_404 = "/404",
  INSUFFICIENT_PERMISSIONS = "/insufficient-permissions",
  HELP_CENTER = "/help-center",

  //Debug routes
  DEBUG_ICONS_PAGE = "/debug-icons",

  //OLD
  EMPLOYEES = "/employees",
  CREATE_UPDATE_EMPLOYEE_GROUP = "/employees/group/:groupId",
  CREATE_EMPLOYEE = "/employees/create-employee",
  EDIT_EMPLOYEE = "/employees/edit-employee/:employeeId",
  FUNDING = "/funding",
  MY_BONUSES = "/my-bonuses",
  EDIT_SETTINGS = "/settings/edit-setting/:companyId",
  SERVICE_AGREEMENT = "/service-agreement",
  COMPANY_BONUS_SETTINGS = "/settings/bonus",
  COMPANY_INTEGRATIONS = "/settings/integrations",
  COMPANY_INTEGRATIONS_HRIS = "/settings/integrations/hris",
  COMPANY_INTEGRATIONS_SSO = "/settings/integrations/sso",
  ADMIN_EMPLOYEE_BONUS = "/employees/admin-employee-bonus/:employeeId/:id", //Remove later
  EMPLOYEE_LIST_WITH_BONUSES = "/employees/list-bonuses",
  EMPLOYEE_LOAN_DETAILS = "/employees/loan/:loanId",
  EMPLOYEE_BONUSES_LIST = "/employees/bonuses/:employeeId",
  EMPLOYEE_MAKE_PAYMENTS_LIST = "/employees/make-payments",
  EMPLOYEE_MAKE_PAYMENT = "/employees/make-payments/:employeeId",
  EMPLOYEE_MAKE_PAYMENT_LOAN = "/employees/make-payments/:employeeId/:loanId",
  EMPLOYEE_CONFIGURE_SPECIAL_PAYMENT_PLAN = "/employees/special-payment-plan/:employeeId",
  EMPLOYEE_VESTING_BONUS_FUNDS = "/employees/bonuses/vesting-bonus-funds",
  EMPLOYEE_TERMINATION = "/employees/termination/:employeeId",
  EMPLOYEE_TERMINATION_BONUS = "/employees/termination/:employeeId/:bonusId",
  FUND_BONUSES = "/funding/bonuses",
  FUNDING_TRANSACTION = "/funding/transactions/:transactionId",
  PLAID_OAUTH_CALLBACK = "/plaid-oauth-callback",
  CREATE_UPDATE_BONUS_TEMPLATE = "/bonus-templates/create-update-bonus/:employeeId/:id", //New bonus / edit existing one
  CREATE_UPDATE_PERFORMANCE_CHALLENGE = "/bonus-templates/create-update-challenge/:employeeId/:id",
  EMPLOYEE_CHALLENGE_DETAILS = "/employees/performance-challenge/:employeeId/:id",
  BONUS_TEMPLATES_LIST = "/bonus-templates",
  BONUS_TEMPLATE_DETAILS = "/bonus-templates/:bonusTemplateId",
  EMPLOYEE_SPECIFIC_BONUS_TEMPLATE_DETAILS = "/bonus-templates/:bonusTemplateId/employees/:employeeId/:bonusId",
  UPDATE_CHALLENGE_TEMPLATE_PROGRESS = "/bonus-templates/update-challenge-template-progress/:id",
}

// export const getDefaultAdminRoute() = routes.ADMIN_SETUP;
// export const getDefaultEmployeeRoute() = routes.EMPLOYEE_SETUP;

export default routes;
