import { FC } from "react";
import { Trans } from "react-i18next";

import { TabsItem } from "./types";
import { Container, TabItem, TabsSection, TILabel } from "./styles";

interface IProps {
  tabs: TabsItem[];
  selectedTabIndex: number;
  onSelectTab: (index: number) => void;
  className?: string;
}

const TabsContainer: FC<IProps> = ({ className, tabs, selectedTabIndex, onSelectTab }) => {
  return (
    <Container className={className}>
      <TabsSection>
        {tabs.map((item) => (
          <TabItem
            key={item.index.toString()}
            id={item.id || ""}
            data-testid={item.id || ""}
            onClick={() => onSelectTab(item.index)}
            className={`${item.index === selectedTabIndex ? "active" : "inactive"}`}
          >
            <TILabel>
              <Trans i18nKey={item.label} />
            </TILabel>
          </TabItem>
        ))}
      </TabsSection>
      {tabs[selectedTabIndex].content}
    </Container>
  );
};

export default TabsContainer;
