import styled from "styled-components";

import { ActiveLabel } from "components/NavigationCardWithDetails/styles";

import { Link, PrimaryButton, SecondaryButton } from "uikit";

export const Container = styled.div`
  width: auto;
  min-width: auto;
`;

export const MethodSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

export const MethodSelectionItem = styled.div`
  position: relative;
  height: 92px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  cursor: pointer;

  &:not(.disabled):hover {
    border: 1px solid ${({ theme }) => theme.colors.navigationCardBorder};
    background: ${({ theme }) => theme.colors.payDistributionCardBgHover};
  }

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.payDistributionCardIcon};
    }
  }

  &.active {
    border: 4px solid ${({ theme }) => theme.colors.navigationCardBorder};

    &:hover {
      border: 4px solid ${({ theme }) => theme.colors.navigationCardBorder};
      background: inherit;
    }

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.payDistributionCardIconActive};
      }
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const MSIActiveLabel = styled(ActiveLabel)`
  right: -4px;
`;

export const MSIIcon = styled.div`
  margin-right: 10px;
  svg {
    height: 24px;
    width: auto;
  }
`;

export const MSIText = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.3;
`;

export const MainContainer = styled.div``;

export const CompanyAccountCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.primaryAlt6};
  border: 1px solid ${({ theme }) => theme.colors.primaryAlt4};
  border-radius: 8px;
  gap: 24px;
  margin-bottom: 16px;
`;

export const CACLogoContainer = styled.div`
  svg {
    height: 28px;
    width: auto;
  }
`;

export const CACContentContainer = styled.div`
  flex: 1;
`;

export const CACTitle = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.3;
  margin-bottom: 4px;
`;

export const CACText = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-style: ${({ theme }) => theme.fontStyle.italic};
  line-height: 1.3;
`;

export const CACTextLink = styled(Link)`
  font-style: ${({ theme }) => theme.fontStyle.normal};
`;

export const CACButtonsContainer = styled.div``;

export const CACCreateAccountButton = styled(Link)``;

export const MainFormContainer = styled.div``;

export const BackButton = styled(SecondaryButton)`
  width: 100px;
`;

export const ConfirmPayDistributionButton = styled(PrimaryButton)`
  width: auto;
`;
