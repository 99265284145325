import { FC } from "react";

import IconProps from "./IconProps";

const IncreaseProductivity: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="45"
    height="39"
    viewBox="0 0 45 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_230_729)">
      <path
        d="M44.0655 38.3995H1.53256C1.33873 38.3995 1.15293 38.323 1.01587 38.1866C0.878813 38.0501 0.801758 37.8649 0.801758 37.6719C0.801758 37.4789 0.878813 37.294 1.01587 37.1575C1.15293 37.0211 1.33873 36.9443 1.53256 36.9443H44.0655C44.2594 36.9443 44.4452 37.0211 44.5822 37.1575C44.7193 37.294 44.7963 37.4789 44.7963 37.6719C44.7963 37.8649 44.7193 38.0501 44.5822 38.1866C44.4452 38.323 44.2594 38.3995 44.0655 38.3995Z"
        fill="#C2C2C2"
      />
      <path
        d="M8.44957 34.341H3.72496C3.53113 34.341 3.34533 34.2643 3.20827 34.1278C3.07121 33.9914 2.99416 33.8064 2.99416 33.6134V29.591C2.99348 29.495 3.01202 29.3999 3.04844 29.311C3.08487 29.2221 3.13833 29.1414 3.20626 29.0733C3.2742 29.0052 3.35499 28.9511 3.444 28.9142C3.53301 28.8773 3.62855 28.8584 3.72496 28.8584H8.44957C8.64476 28.8584 8.83225 28.9354 8.97028 29.0728C9.1083 29.2102 9.1859 29.3966 9.1859 29.591V33.6134C9.18454 33.8069 9.10614 33.992 8.96827 34.1283C8.83039 34.2646 8.64387 34.341 8.44957 34.341ZM4.45577 32.8858H7.71876V30.3238H4.47638L4.45577 32.8858Z"
        fill="#C2C2C2"
      />
      <path
        d="M16.639 34.341H11.9144C11.8171 34.343 11.7203 34.3258 11.6299 34.2902C11.5394 34.2545 11.4571 34.2011 11.3876 34.1333C11.3181 34.0655 11.263 33.9847 11.2253 33.8954C11.1876 33.8061 11.1679 33.7103 11.168 33.6134V27.7412C11.168 27.5483 11.245 27.3633 11.3821 27.2268C11.5191 27.0904 11.7049 27.0137 11.8988 27.0137H16.6234C16.8172 27.0137 17.0035 27.0904 17.1406 27.2268C17.2776 27.3633 17.3542 27.5483 17.3542 27.7412V33.6134C17.3542 33.8037 17.2798 33.9865 17.1461 34.1225C17.0124 34.2586 16.8301 34.3369 16.639 34.341ZM12.6452 32.8858H15.9082V28.4893H12.6452V32.8858Z"
        fill="#C2C2C2"
      />
      <path
        d="M24.8271 34.3409H20.1025C19.9082 34.3409 19.7217 34.2645 19.5838 34.1282C19.446 33.9919 19.3676 33.8067 19.3662 33.6133V24.5694C19.3676 24.3759 19.446 24.1908 19.5838 24.0545C19.7217 23.9182 19.9082 23.8418 20.1025 23.8418H24.8271C25.0206 23.8431 25.2056 23.9201 25.3423 24.0562C25.4791 24.1924 25.5566 24.3768 25.558 24.5694V33.6133C25.5566 33.8058 25.4791 33.99 25.3423 34.1262C25.2056 34.2624 25.0206 34.3395 24.8271 34.3409ZM20.8334 32.8857H24.0913V25.302H20.8334V32.8857Z"
        fill="#C2C2C2"
      />
      <path
        d="M33.0156 34.341H28.2855C28.0921 34.3397 27.9071 34.2625 27.7703 34.1263C27.6335 33.9902 27.556 33.806 27.5547 33.6134V20.1065C27.556 19.9139 27.6335 19.7295 27.7703 19.5933C27.9071 19.4572 28.0921 19.3802 28.2855 19.3789H33.0156C33.209 19.3802 33.394 19.4572 33.5308 19.5933C33.6676 19.7295 33.7451 19.9139 33.7464 20.1065V33.6134C33.7451 33.806 33.6676 33.9902 33.5308 34.1263C33.394 34.2625 33.209 34.3397 33.0156 34.341ZM29.0218 32.8858H32.2692V20.8341H29.0113L29.0218 32.8858Z"
        fill="#C2C2C2"
      />
      <path
        d="M41.1995 34.3413H36.4749C36.2811 34.3413 36.0953 34.2646 35.9583 34.1282C35.8212 33.9917 35.7441 33.8067 35.7441 33.6137V12.0569C35.7441 11.864 35.8212 11.6787 35.9583 11.5423C36.0953 11.4058 36.2811 11.3291 36.4749 11.3291H41.1995C41.3939 11.3291 41.5804 11.4057 41.7182 11.542C41.8561 11.6783 41.9345 11.8635 41.9359 12.0569V33.6137C41.9345 33.8072 41.8561 33.9923 41.7182 34.1287C41.5804 34.265 41.3939 34.3413 41.1995 34.3413ZM37.2058 32.8862H40.4687V12.7895H37.2058V32.8862Z"
        fill="#C2C2C2"
      />
      <path
        d="M3.72531 27.0651C3.54579 27.0671 3.372 27.003 3.23676 26.8855C3.10152 26.7679 3.01426 26.605 2.99199 26.4276C2.96972 26.2502 3.01361 26.071 3.11563 25.924C3.21765 25.7769 3.37089 25.6723 3.54537 25.6302C3.83874 25.5534 33.0828 17.8878 38.137 0.927165C38.1916 0.741664 38.318 0.585302 38.4883 0.492571C38.6587 0.39984 38.8589 0.378414 39.0452 0.432774C39.2316 0.487133 39.3884 0.612944 39.4815 0.782551C39.5746 0.952158 39.5964 1.15149 39.5418 1.33699C34.2509 19.097 5.146 26.7216 3.91077 27.0393C3.85029 27.0554 3.78789 27.0641 3.72531 27.0651Z"
        fill="#FFC808"
      />
      <path
        d="M40.9569 5.75394C40.8248 5.75375 40.6952 5.71803 40.5819 5.65035C40.4687 5.58268 40.3759 5.4856 40.3135 5.36963L38.5433 2.12106L35.2748 3.88371C35.1042 3.97544 34.9042 3.99601 34.7184 3.94075C34.5326 3.8855 34.3763 3.75885 34.2842 3.58897C34.192 3.4191 34.1714 3.21977 34.2269 3.03478C34.2824 2.8498 34.4096 2.69442 34.5802 2.60269L38.4865 0.491522C38.571 0.44556 38.6638 0.416646 38.7595 0.406454C38.8551 0.396262 38.9516 0.405087 39.0439 0.432225C39.1362 0.459362 39.2225 0.504266 39.2973 0.564579C39.372 0.624893 39.4339 0.699494 39.4797 0.783753L41.6002 4.67784C41.6464 4.7619 41.6755 4.85428 41.6857 4.94955C41.6959 5.04482 41.6873 5.14114 41.6601 5.23302C41.6328 5.32491 41.5874 5.41053 41.5269 5.48497C41.4663 5.55942 41.3914 5.62126 41.3067 5.66687C41.1987 5.7232 41.0789 5.75302 40.9569 5.75394Z"
        fill="#FFC808"
      />
    </g>
    <defs>
      <clipPath id="clip0_230_729">
        <rect width="44" height="38" fill="white" transform="translate(0.796875 0.404297)" />
      </clipPath>
    </defs>
  </svg>
);

export default IncreaseProductivity;
