import { FC } from "react";
import { Trans } from "react-i18next";

import { CardForm } from "types/CardShipping";

import { CardImage, Container, Message, PrimaryBtn, Title } from "./styles";

interface IProps {
  originTranslationPrefix: string;
  cardType: CardForm;
  onRequestClick: () => void;
}

export const RequestCard: FC<IProps> = ({ originTranslationPrefix, cardType, onRequestClick }) => {
  const translationPrefix = `${originTranslationPrefix}.request_card_component`;

  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>

      <CardImage />

      <Message>
        <Trans i18nKey={`${translationPrefix}.message.${cardType}`} />
      </Message>

      <PrimaryBtn onClick={onRequestClick} data-testId={`request_card_button`}>
        <Trans i18nKey={`${translationPrefix}.request_card_btn.${cardType}`} />
      </PrimaryBtn>
    </Container>
  );
};
