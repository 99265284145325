import styled, { css } from "styled-components";

import CurrencySumComponent from "components/CurrencySumComponent";

export const valueMixin = css`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.success};
`;

export const ProgressTooltipContent = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.small};
  line-height: 1.65;
`;

export const PTCLabel = styled.p``;

export const PTCValue = styled.p`
  ${valueMixin}
`;

export const PTCValueAlt = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
`;

export const PTCValueSum = styled(CurrencySumComponent)`
  ${valueMixin}
`;

export const PTCValueSumAlt = styled(CurrencySumComponent)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
`;
