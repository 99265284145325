import * as yup from "yup";

import { MAX_INPUT_LENGTH_LONG } from "constants/form";

export const TransferFundsFormValidationSchema = yup.object({
  amount: yup.string().required("validationErrors.common.is_required"),
  description: yup.string().max(MAX_INPUT_LENGTH_LONG, "validationErrors.common.too_long"),
});

export type TransferFundsFormType = yup.InferType<typeof TransferFundsFormValidationSchema>;
