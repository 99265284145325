import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "react-tooltip";
import copy from "copy-to-clipboard";

import { TooltipContainer } from "uikit/Tooltip/styles";

import { Container, StyledTooltipContainer } from "./styles";

interface Props {
  className?: string;
  children: string | ReactNode;
  copyContent: string;
  id: string;
}

const CopyText: FC<Props> = ({ className, children, copyContent, id }) => {
  const { t } = useTranslation();
  const renderTooltip = (children: ReactNode) => {
    return (
      <StyledTooltipContainer data-for={id} data-html={false} data-tip={t("common.copied")}>
        {children}
        <Tooltip
          id={id}
          place="top"
          type="dark"
          effect="float"
          event="click"
          afterShow={() => {
            copy(copyContent);
            setTimeout(Tooltip.hide, 2000);
          }}
        />
      </StyledTooltipContainer>
    );
  };

  return <Container className={className}>{renderTooltip(children)}</Container>;
};

export default CopyText;
