import styled from "styled-components";

import { breakpoint } from "helpers";

import { UIModal } from "uikit";

export const PromptModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 560px !important;
  `}
`;
