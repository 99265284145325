import { FC } from "react";

import IconProps from "./IconProps";

const CloseRoundIconAlt: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7222_26561)">
      <path
        d="M12 21.5088C14.3869 21.5088 16.6761 20.5606 18.364 18.8727C20.0518 17.1849 21 14.8957 21 12.5088C21 10.1218 20.0518 7.83266 18.364 6.14483C16.6761 4.457 14.3869 3.50879 12 3.50879C9.61305 3.50879 7.32387 4.457 5.63604 6.14483C3.94821 7.83266 3 10.1218 3 12.5088C3 14.8957 3.94821 17.1849 5.63604 18.8727C7.32387 20.5606 9.61305 21.5088 12 21.5088Z"
        stroke="#A3ADC2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.50879L15 16.5088"
        stroke="#A3ADC2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.50879L9 16.5088"
        stroke="#A3ADC2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7222_26561">
        <rect width="24" height="24" fill="white" transform="translate(0 0.508789)" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseRoundIconAlt;
