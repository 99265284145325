import { PaidCardFront } from "assets/svg";
import styled from "styled-components";

import { H2, SectionAltRounded } from "uikit";

import { HDescription } from "../components/styles";

const cardTemplateSectionWidth = 382;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const ContainerInner = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
  overflow: hidden;
`;

export const FormSectionContainer = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FormSection = styled(SectionAltRounded)`
  position: relative;
`;

export const CardTemplateSection = styled.div`
  width: ${cardTemplateSectionWidth}px;
`;

export const CTSHeader = styled.div``;

export const CTSHTitle = styled(H2)`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
`;

export const CTSHDescription = styled(HDescription)``;

export const CardTemplateContainer = styled.div`
  position: relative;
  margin-top: 24px;
`;

export const CardTemplateIcon = styled(PaidCardFront)`
  width: ${cardTemplateSectionWidth}px;
  height: 240px;
`;

export const CardOwnerTextContainer = styled.div`
  position: absolute;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  left: 30px;
  bottom: 19px;
`;

export const CardOwnerText = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
