import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";

import { getComprehensibleStatus, StatusToComprehensibleMap } from "helpers/employee/userStatusMap";

import { UserResponseDto } from "utils/swagger_react_query";

import { Label, StringWrapper, Wrapper } from "./styles";

interface IProps {
  data: Partial<UserResponseDto>;
  className?: string;
  children?: ReactNode;
}

const UserStatus: FC<IProps> = ({ data, className }) => {
  const transformedStatus = getComprehensibleStatus(data);
  const translationPrefix = "common.user_status";

  return (
    <StringWrapper className={`${className} ${transformedStatus}`}>
      <Trans i18nKey={`${translationPrefix}.${transformedStatus}`} />
    </StringWrapper>
  );
};

export default UserStatus;
