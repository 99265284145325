import { FC } from "react";

import IconProps from "./IconProps";

const DepositTransactionIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1647 7.60253L11.6839 0.234145C11.6873 0.20278 11.6836 0.171059 11.6731 0.141305C11.6626 0.111551 11.6456 0.0845167 11.6233 0.0621801C11.6011 0.0398434 11.5741 0.0227699 11.5443 0.0122093C11.5146 0.00164868 11.4829 -0.00213201 11.4515 0.00114428L4.08315 0.520365C4.01443 0.526856 3.95012 0.557113 3.90131 0.605923C3.8525 0.654734 3.82225 0.719041 3.81575 0.787763L6.07696 2.7251L0.244166 8.56503C0.0650354 8.74416 0.0494585 9.01481 0.20133 9.16993L2.51576 11.4889C2.67152 11.644 2.94217 11.6245 3.12065 11.4461L8.95798 5.60872L10.8953 7.86993C10.9643 7.86374 11.029 7.83364 11.0782 7.78481C11.1274 7.73598 11.158 7.67151 11.1647 7.60253Z"
      fill="#339900"
    />
    <path
      d="M7.60196 18.5121L0.23357 19.0313C0.20223 19.0347 0.17053 19.031 0.140802 19.0206C0.111073 19.0101 0.0840704 18.9931 0.0617809 18.9708C0.0394915 18.9485 0.0224815 18.9215 0.0120008 18.8917C0.00152015 18.862 -0.00216468 18.8303 0.00121805 18.799L0.520438 11.4306C0.527075 11.3618 0.557465 11.2975 0.606384 11.2487C0.655302 11.1999 0.719692 11.1697 0.788486 11.1632L2.72778 13.4263L8.56511 7.58901C8.74424 7.41053 9.01488 7.39106 9.17 7.54618L11.4883 9.86515C11.6434 10.0203 11.6246 10.2909 11.4461 10.4694L5.6049 16.3074L7.86676 18.2447C7.86013 18.3129 7.83017 18.3768 7.78193 18.4255C7.73369 18.4742 7.67012 18.5048 7.60196 18.5121Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default DepositTransactionIcon;
