import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ButtonText, Container, StyledBackIcon } from "./styles";

interface Props {
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const BackButton: FC<Props> = ({ className, onClick, children }) => {
  const { t } = useTranslation();
  return (
    <Container className={className} onClick={onClick}>
      <StyledBackIcon />
      <ButtonText>{children || t("common.back")}</ButtonText>
    </Container>
  );
};

export default BackButton;
