import styled from "styled-components";

import { CustomLink } from "uikit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyrightText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 14px;
`;

export const LinksContainer = styled.div`
  display: flex;
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: baseline;
  border: 1px solid ${({ theme }) => theme.colors.primaryText}17;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const Link = styled(CustomLink)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.colors.primaryText} !important;
  line-height: 24px;

  text-decoration: underline;
`;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.colors.primaryText} !important;
  margin: 0 2px;
`;
