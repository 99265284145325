import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton, PrimaryButtonDangerMixin, SecondaryButton, UIModal } from "uikit";
import { ContentContainerMixin } from "uikit/Containers/Containers";

export const Container = styled.div``;

export const Content = styled.div``;

export const CardDetailsContainer = styled.div`
  min-height: 200px;
`;

export const PhysicalCardDetailsContainer = styled(CardDetailsContainer)``;

export const VirtualCardDetailsContainer = styled(CardDetailsContainer)``;

export const BackButtonContainer = styled.div`
  margin-top: 24px;
`;

export const BackBtn = styled(SecondaryButton)`
  width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 100%;
  `}
`;

export const CancelCardModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 560px !important;
  `}
`;

export const CancelCardBtnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  /* margin-top: 32px; */
`;

export const DangerBtn = styled(PrimaryButton)`
  ${PrimaryButtonDangerMixin};
`;
