import { FC } from "react";

import { Container, Image } from "./styles";

interface Props {
  className?: string;
  src?: string | null;
  ratingPosition?: number;
  firstName?: string | null;
  lastName?: string | null;
  username?: string | null;
}

const Avatar: FC<Props> = ({ className, src, firstName, lastName, username }) => (
  <Container className={className}>
    {src ? (
      <Image style={{ backgroundImage: `url("${src}")` }}></Image>
    ) : firstName && lastName ? (
      <Image>
        {(firstName || "").substr(0, 1)}
        {(lastName || "").substr(0, 1)}
      </Image>
    ) : (
      <Image>{(username || "").substr(0, 1)}</Image>
    )}
  </Container>
);

export default Avatar;
