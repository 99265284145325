import styled, { CSSProperties } from "styled-components";
import { theme } from "styles/theme";

import { breakpoint } from "helpers/shared/breakpoint";

import { ButtonBaseMixin } from "uikit/Buttons/Buttons";

import {
  CloseModalButton,
  PrimaryButton,
  PrimaryButtonDangerMixin,
  SecondaryButton,
} from "../Buttons";
import { Body1Mixin } from "../Typography/Typography";
import { EButtonsAlign, EButtonsFlex, EModalTypes } from "./types";

export const Container = styled.div`
  position: relative;
  padding: 20px 0;
  text-align: center;
  background: ${({ theme }) => theme.colors.contentBg};

  &.no-close-button {
  }
`;

export const StyledCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  opacity: 0.8;
`;

export const Image = styled.img`
  display: block;
  margin-bottom: 40px;
`;

export const IconContainer = styled.div`
  text-align: center;
  margin: 0 0 24px 0;
`;
export const StatusIconContainer = styled.div`
  text-align: center;
`;

export const StatusIconContainerImage = styled.img`
  height: 100px;
  margin-bottom: 17px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.modalTitle};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.title};
  line-height: 1.34;
  margin-bottom: 16px;
`;

export const Message = styled.div`
  ${Body1Mixin}
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 32px;
  line-height: 1.34;
`;

export const MainActionButton = styled(PrimaryButton)`
  ${ButtonBaseMixin}
  display: block;
  width: 100%;
  min-width: 154px;
  max-width: 273px;

  &.auto-width {
    width: auto;
    max-width: auto;
  }
`;

export const SecondaryActionButton = styled(SecondaryButton)`
  ${ButtonBaseMixin}
  width: 100%;
  min-width: 154px;
  max-width: 273px;

  &.auto-width {
    width: auto;
    max-width: auto;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  &.flex-${EButtonsFlex.COLUMN} {
    flex-direction: column;
  }

  &.flex-${EButtonsFlex.COLUMN_REVERSE} {
    flex-direction: column-reverse;
  }

  &.flex-${EButtonsFlex.ROW} {
    flex-direction: row;
    justify-content: space-between;
  }

  &.flex-${EButtonsFlex.ROW_REVERSE} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &.align-${EButtonsAlign.START} {
    justify-content: flex-start;
  }

  &.align-${EButtonsAlign.END} {
    justify-content: flex-end;
  }

  &.align-${EButtonsAlign.CENTER} {
    justify-content: center;
  }

  &.align-${EButtonsAlign.SPACE_BETWEEN} {
    justify-content: space-between;
  }

  &.${EModalTypes.DELETE}, &.${EModalTypes.ERROR} {
    ${MainActionButton} {
      ${PrimaryButtonDangerMixin}
    }
  }

  ${breakpoint("xs", "md")`
    &.mobile-flex-${EButtonsFlex.COLUMN} {
      flex-direction: column;
    }

    &.mobile-flex-${EButtonsFlex.COLUMN_REVERSE} {
      flex-direction: column-reverse;
    }

    &.mobile-flex-${EButtonsFlex.ROW} {
      flex-direction: row;
    }

    &.mobile-flex-${EButtonsFlex.ROW_REVERSE} {
      flex-direction: row-reverse;
    }
  `}
`;

export const overlayStyle: CSSProperties = {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  zIndex: theme.zIndex.modal,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};
export const contentStyle: CSSProperties = {
  position: "relative",
  width: "560px",
  boxSizing: "border-box",
  borderRadius: "8px",
  maxHeight: "90%",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
  margin: "auto auto",
  padding: "20px",
  inset: "0",
  background: "rgb(255, 255, 255)",
  overflow: "auto",
};
