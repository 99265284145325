import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  PlaidAccount,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink as usePlaidLinkSetup,
} from "react-plaid-link";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { ErrorConst, PayDistributionRuleType, PayDistributionType, UserRole } from "types/BETypes";
import { PlaidExitErrors } from "types/plaid";
import { HELP_EMAIL } from "constants/shared";
import { showErrorModal } from "helpers/index";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  GetPlaidBankAccountsResponseDto,
  mutationPlaidControllerChangeToken,
  mutationPlaidControllerDeleteBankAccount,
  mutationPlaidControllerRenewLoginBankAccount,
  mutationPlaidControllerSetEmployerDefaultBankAccount,
  mutationPlaidControllerVerifyBankAccount,
  mutationUsersControllerUpdateDefaultPayDistribution,
  PlaidControllerGetLinkTokenQueryParams,
  queryPlaidControllerGetLinkToken,
  queryPlaidControllerListBankAccounts,
  UserResponseDto,
} from "utils/swagger_react_query";

/**
 * <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"></script>
 * need to be added to index.html
 */

type ISuccessActionCallback = (
  bankAccountsList?: GetPlaidBankAccountsResponseDto[],
  newBankAccount?: PlaidAccount[],
  oldBankAccountsList?: GetPlaidBankAccountsResponseDto[],
) => void;

interface IParams {
  shouldFetchBankAccountsListOnMount?: boolean;
  loadingCallback: (state: boolean) => void;
  onSuccessAddedCallback?: ISuccessActionCallback;
  onSuccessVerifiedCallback?: ISuccessActionCallback;
}

export interface IUseBankAccountLinkReturn {
  metadata: {
    currentUser: UserResponseDto | null | undefined;
    isReady: boolean;
  };
  data: {
    bankAccounts: GetPlaidBankAccountsResponseDto[] | undefined;
  };
  actions: {
    openModal: () => Promise<void>;
    refetchBankAccountsList: (
      withLoading?: boolean,
    ) => Promise<GetPlaidBankAccountsResponseDto[] | undefined>;
    handleRemoveBankAccount: (
      bankAccountId: string,
      withConfirmationModal?: boolean,
    ) => Promise<void>;
    handleSetDefaultBankAccount: (bankAccountId: string) => Promise<void>;
    handleVerifyBankAccount: (bankAccountId: string, accessToken: string) => Promise<void>;
  };
}

export const useBankAccountLink = ({
  shouldFetchBankAccountsListOnMount = true,
  ...params
}: IParams): IUseBankAccountLinkReturn => {
  const componentsPrefix = "components.bank_account_components.external_bank_account_modals";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);
  const [plaidPublicToken, setPlaidPublicToken] = useState<string | null>(null);
  const [bankAccounts, setBankAccounts] = useState<GetPlaidBankAccountsResponseDto[] | undefined>(
    undefined,
  );

  //It is used to verify bank account after exit from Plaid Link
  const [targetBankAccountId, setTargetBankAccountId] = useState<string | null>(null);

  //Plaid Configuration
  const _fetchPlaidLinkToken = async (accessToken?: string) => {
    try {
      let payload: PlaidControllerGetLinkTokenQueryParams | undefined;
      if (accessToken) {
        payload = {
          accessToken,
        };
      }

      const linkTokenResponse = await queryPlaidControllerGetLinkToken(payload);
      setPlaidPublicToken(linkTokenResponse.linkToken);
    } catch (error) {
      console.log(error);
      showErrorModal(error);
    }
  };

  const _onSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    try {
      const isNeedRenewBankAccountLogin = (bankAccounts || []).find(
        (it) => it.bankAccountId === targetBankAccountId,
      )?.renewLoginRequired;
      params?.loadingCallback(true);

      const isVerificationFlow =
        metadata.accounts[0].verification_status === BankAccountVerifyStatus.MANUALLY_VERIFIED;

      if (isVerificationFlow) {
        const _bankAccountId = bankAccounts?.find(
          (it) => it?.plaidAccountId === metadata.accounts[0].id,
        )?.bankAccountId;
        if (!_bankAccountId) throw new Error("");

        await mutationPlaidControllerVerifyBankAccount({ accountId: _bankAccountId })();
      } else if (isNeedRenewBankAccountLogin) {
        await mutationPlaidControllerRenewLoginBankAccount({
          accountId: targetBankAccountId ?? "",
        })();
      } else {
        await mutationPlaidControllerChangeToken()({
          publicToken,
        });
      }

      const bankAccountsListRes = await fetchBankAccountsList();

      if (isVerificationFlow) {
        if (params?.onSuccessVerifiedCallback)
          return params?.onSuccessVerifiedCallback?.(
            bankAccountsListRes,
            metadata.accounts,
            bankAccounts,
          );

        return AppEvents.emit("SetGlobalModal", {
          isOpen: true,
          type: EModalTypes.SUCCESS,
          title: t(`${componentsPrefix}.success_verified.title`),
          message: t(`${componentsPrefix}.success_verified.message`),
          customContentStyle: { width: "560px" },
          mainButton: {
            text: t(`buttons.done`),
          },
        });
      }

      if (params?.onSuccessAddedCallback)
        return params?.onSuccessAddedCallback?.(
          bankAccountsListRes,
          metadata.accounts,
          bankAccounts,
        );

      return AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.SUCCESS,
        title: t(`${componentsPrefix}.success_added.title`),
        message: t(`${componentsPrefix}.success_added.message`),
        customContentStyle: { width: "560px" },
        mainButton: {
          text: t(`buttons.done`),
        },
      });
    } catch (error: any) {
      params?.loadingCallback(false);
      if (error?.data?.error === ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED) {
        showErrorModal(ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED, undefined, {
          type: EModalTypes.ERROR,
          message: (
            <Trans
              i18nKey={`errors.${ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED}`}
              values={{ helpEmail: HELP_EMAIL }}
            />
          ),
        });
      } else {
        showErrorModal();
      }
    } finally {
      setTargetBankAccountId(null);
      sessionStorage.clear();
    }
  };

  const _onExit: PlaidLinkOnExit = async (error, metadata) => {
    try {
      if (error?.error_code === PlaidExitErrors.TOO_MANY_VERIFICATION_ATTEMPTS) {
        params.loadingCallback(true);
        if (!targetBankAccountId) throw new Error("");
        await mutationPlaidControllerVerifyBankAccount({ accountId: targetBankAccountId })();
        await fetchBankAccountsList();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setTargetBankAccountId(null);
    }
  };

  const config: PlaidLinkOptions = {
    token: plaidPublicToken,
    onSuccess: _onSuccess,
    onExit: _onExit,
  };

  const { open, ready } = usePlaidLinkSetup(config);

  useEffect(() => {
    if (ready) open();
  }, [ready, open]);

  useEffect(() => {
    if (shouldFetchBankAccountsListOnMount) {
      fetchBankAccountsList();
    }
  }, []);

  const fetchBankAccountsList = async (withLoading: boolean = true) => {
    try {
      if (withLoading) {
        params?.loadingCallback(true);
      }
      const existingBankAccounts =
        (await queryPlaidControllerListBankAccounts()) as unknown as GetPlaidBankAccountsResponseDto[];
      setBankAccounts(existingBankAccounts || []);
      return existingBankAccounts;
    } catch (error) {
      console.log(error);
    } finally {
      if (withLoading) {
        params?.loadingCallback(false);
      }
    }
  };

  const handleRemoveBankAccount = async (
    bankAccountId: string,
    withConfirmationModal: boolean = true,
  ) => {
    const _removeBankAccount = async (bankAccountId: string) => {
      try {
        params?.loadingCallback(true);
        await mutationPlaidControllerDeleteBankAccount({ accountId: bankAccountId })();
        await fetchBankAccountsList();
      } catch (error) {
        params?.loadingCallback(false);
        showErrorModal(error);
      }
    };

    if (withConfirmationModal) {
      const isBankAccountInUse = currentUser?.defaultPayDistribution?.some(
        (it) => it.id === bankAccountId,
      );
      if (isBankAccountInUse) {
        return AppEvents.emit("SetGlobalModal", {
          isOpen: true,
          type: EModalTypes.WARNING,
          title: t(`${componentsPrefix}.bank_in_use_delete_modal.title`),
          message: t(`${componentsPrefix}.bank_in_use_delete_modal.message`),
          mainButton: {
            text: t(`${componentsPrefix}.bank_in_use_delete_modal.btn`),
            onClick: () => navigate(routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION),
          },
          secondaryButton: {
            text: t("buttons.cancel"),
          },
          buttonsFlex: EButtonsFlex.ROW_REVERSE,
          customContentStyle: { width: "560px" },
        });
      } else {
        return AppEvents.emit("SetGlobalModal", {
          isOpen: true,
          type: EModalTypes.DELETE,
          title: t(`${componentsPrefix}.remove_conformation.title`),
          message: t(`${componentsPrefix}.remove_conformation.message`),
          mainButton: {
            text: t(`buttons.delete`),
            onClick: () => {
              _removeBankAccount(bankAccountId);
            },
          },
          customContentStyle: { width: "560px" },
          secondaryButton: {
            text: t(`buttons.cancel`),
            onClick: () => {
              AppEvents.emit("SetGlobalModal", { isOpen: false });
            },
          },
          buttonsFlex: EButtonsFlex.ROW_REVERSE,
        });
      }
    }

    _removeBankAccount(bankAccountId);
  };

  const handleSetDefaultBankAccount = async (bankAccountId: string) => {
    try {
      params?.loadingCallback(true);

      if (currentUser?.lastActiveRole !== UserRole.EMPLOYEE) {
        await mutationPlaidControllerSetEmployerDefaultBankAccount({ accountId: bankAccountId })();
      }

      await fetchBankAccountsList();
    } catch (error) {
      console.log(error);
      showErrorModal(error);
      params?.loadingCallback(false);
    }
  };

  const openPlaidModal = async () => {
    await _fetchPlaidLinkToken();
  };

  const handleVerifyBankAccount = async (bankAccountId: string, accessToken: string) => {
    await _fetchPlaidLinkToken(accessToken);
    setTargetBankAccountId(bankAccountId);
  };

  return {
    metadata: {
      currentUser,
      isReady: ready,
    },
    data: {
      bankAccounts,
    },
    actions: {
      openModal: openPlaidModal,
      refetchBankAccountsList: fetchBankAccountsList,
      handleRemoveBankAccount,
      handleSetDefaultBankAccount,
      handleVerifyBankAccount,
    },
  };
};
