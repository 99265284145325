import styled from "styled-components";

import { FormItem } from "pages/Auth/_shared/components/styles";

export const Container = styled.div`
  ${FormItem} {
    &:nth-last-of-type(1) {
      margin-bottom: 16px;
    }
  }
`;
