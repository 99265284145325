import { isEmail } from "class-validator";
import * as yup from "yup";

import { MAX_INPUT_LENGTH } from "constants/form";

export const InviteTeamMemberValidationSchema = yup.object({
  email: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required")
    .test(
      "invalid email",
      "validationErrors.common.wrong_format",
      (value) => !value || isEmail(value),
    )
    .required("validationErrors.email.is_required"),
});

export type InviteTeamMemberFormType = yup.InferType<typeof InviteTeamMemberValidationSchema>;
