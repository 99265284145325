import { EmployeeExitReason } from "types/BETypes";

export interface IReasonForLeavingOptions {
  label: string;
  value: EmployeeExitReason | null;
}

export const ReasonForLeavingOptions: IReasonForLeavingOptions[] = [
  {
    label: "termination_page.reason_for_living_options.pleaseChoose",
    value: null,
  },
  {
    label: "termination_page.reason_for_living_options.resignation",
    value: EmployeeExitReason.RESIGNATION,
  },
  {
    label: "termination_page.reason_for_living_options.termination",
    value: EmployeeExitReason.TERMINATION,
  },
  {
    label: "termination_page.reason_for_living_options.death",
    value: EmployeeExitReason.DEATH,
  },
  {
    label: "termination_page.reason_for_living_options.longTermDisability",
    value: EmployeeExitReason.LONG_TERM_DISABILITY,
  },
  {
    label: "termination_page.reason_for_living_options.military_leave",
    value: EmployeeExitReason.MILITARY_LEAVE,
  },
];
