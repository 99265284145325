import React, { FC } from "react";

import { OverlayAbsolute, StyledLoader } from "./styles";

interface IProps {
  className?: string;
}

const BlockLoader: FC<IProps> = ({ className }) => (
  <OverlayAbsolute className={className}>
    <StyledLoader />
  </OverlayAbsolute>
);

export default BlockLoader;
