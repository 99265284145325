import { FC } from "react";

import IconProps from "./IconProps";

const EmptyInboxIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.25 23.5875C36.2563 23.5335 36.2563 23.479 36.25 23.425L31.25 4.675C31.25 4.675 31.25 4.675 31.25 4.6L31.1375 4.375L31 4.175L30.8375 4.0375C30.7728 3.97927 30.7015 3.92892 30.625 3.8875H30.425L30.075 3.75H9.925L9.65 3.8125H9.45C9.37348 3.85392 9.30215 3.90427 9.2375 3.9625C9.17786 4.00145 9.12328 4.04763 9.075 4.1L8.9375 4.3C8.9375 4.375 8.8625 4.4375 8.825 4.525C8.7875 4.6125 8.825 4.525 8.825 4.6L3.825 23.35C3.81869 23.404 3.81869 23.4585 3.825 23.5125C3.78715 23.587 3.76181 23.6673 3.75 23.75V35C3.75 35.3315 3.8817 35.6495 4.11612 35.8839C4.35054 36.1183 4.66848 36.25 5 36.25H35C35.3315 36.25 35.6495 36.1183 35.8839 35.8839C36.1183 35.6495 36.25 35.3315 36.25 35V23.75C36.2558 23.696 36.2558 23.6415 36.25 23.5875ZM10.9625 6.25H29.0375L33.375 22.5H25C24.6685 22.5 24.3505 22.6317 24.1161 22.8661C23.8817 23.1005 23.75 23.4185 23.75 23.75C23.75 24.7446 23.3549 25.6984 22.6517 26.4017C21.9484 27.1049 20.9946 27.5 20 27.5C19.0054 27.5 18.0516 27.1049 17.3483 26.4017C16.6451 25.6984 16.25 24.7446 16.25 23.75C16.25 23.4185 16.1183 23.1005 15.8839 22.8661C15.6495 22.6317 15.3315 22.5 15 22.5H6.625L10.9625 6.25ZM33.75 33.75H6.25V25H13.875C14.1619 26.4129 14.9284 27.6831 16.0447 28.5955C17.1609 29.5079 18.5583 30.0063 20 30.0063C21.4417 30.0063 22.8391 29.5079 23.9553 28.5955C25.0716 27.6831 25.8381 26.4129 26.125 25H33.75V33.75Z"
      fill="#BCC4CC"
    />
  </svg>
);

export default EmptyInboxIcon;
