export const balanceDueBonusRowTranslations = {
  "en-US": {
    unit_vesting_bonus: "Unit Vesting Bonus",
    time_vesting_bonus: "Time Vesting Bonus",
    performance_challenge_bonus: "Performance Challenge",
    bonus_accepted_date: "Bonus accepted on: ",
    days_left: "Days Left",
    total_vested: "Total Vested Amount",
    unvested_amount: "Unvested/Amount Due",
  },
};

export default balanceDueBonusRowTranslations;
