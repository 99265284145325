import * as yup from "yup";

import { MAX_INPUT_LENGTH } from "constants/form";

export const manualBankAccountAdditionalInfoValidationSchema = yup.object({
  bankName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  bankHolderName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
});

export type ManualBankAccountAdditionalInfoFormType = yup.InferType<
  typeof manualBankAccountAdditionalInfoValidationSchema
>;
