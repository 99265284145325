import { FC } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import routes from "routes/routes";

import { getFormattedSum } from "helpers";

import { Container, PointsAmount, PointsLabel } from "./styles";

interface IProps {
  className?: string;
  amount?: number;
  onClick?: () => void;
}

const RewardPointsDisplay: FC<IProps> = ({ className, amount, onClick }) => {
  const translationPrefix = `components.reward_points_display`;
  const navigate = useNavigate();

  const redirectToRewardsPage = () => {
    navigate(routes.EMPLOYEE_BANKING_REWARDS);
  };

  return (
    <Container
      className={className}
      onClick={onClick || redirectToRewardsPage}
      data-testid="reward-points-display"
    >
      <PointsAmount>{getFormattedSum(amount || 0, { symbol: "", precision: 0 })}</PointsAmount>
      <PointsLabel>
        <Trans i18nKey={`${translationPrefix}.label`} count={amount || 0} />
      </PointsLabel>
    </Container>
  );
};

export default RewardPointsDisplay;
