import styled from "styled-components";

import { breakpoint } from "helpers";

import { UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 460px !important;
    height: 470px;
  `}
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 280px;
`;
