import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { useBankAccountLink } from "hooks";

export const useAdminBankAccountsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);

  const [isLoading, setLoading] = useState<boolean>(true);

  const bankAccountLink = useBankAccountLink({ loadingCallback: setLoading });

  const handleBackBtnClick = () => navigate(routes.ADMIN_SETTINGS);

  return {
    metadata: {
      isLoading,
      currentUser,
    },
    pageData: {
      ...bankAccountLink.data,
    },
    actions: {
      ...bankAccountLink.actions,
      handleBackBtnClick,
    },
  };
};
