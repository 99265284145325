import styled from "styled-components";

import { SecondaryButton, UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  width: 460px !important;
  height: 470px;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 280px;
`;

export const CloseBtn = styled(SecondaryButton)`
  width: 252px;
  margin: 0 auto;
`;
