import { FC } from "react";

import IconProps from "./IconProps";

interface IEyeIcon extends IconProps {
  isCrossedOut?: boolean;
}

const EyeIcon: FC<IEyeIcon> = ({ className, isCrossedOut = true }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {isCrossedOut && (
      <path
        d="M17.3406 14.7929L4.12013 1.57343L4.65938 1.03418L17.8799 14.2537L17.3406 14.7929Z"
        stroke="#17AACF"
      />
    )}
    <path
      d="M21.5014 7.42048C21.3102 7.19941 16.7697 2 10.9998 2C5.2299 2 0.689454 7.19941 0.498432 7.42048L0 8.00009L0.498432 8.57953C0.689632 8.80059 5.23007 14 11 14C16.7699 14 21.3103 8.80059 21.5015 8.57953L22 8.00009L21.5014 7.42048ZM8.01384 11.6865C5.42892 10.7739 3.33905 8.93809 2.38786 7.99991C3.33905 7.06174 5.42892 5.22592 8.01384 4.31329C6.94821 5.18611 6.26728 6.51426 6.26728 7.99991C6.26728 9.48556 6.94821 10.8137 8.01384 11.6865ZM10.9998 10.9841C9.36253 10.9841 8.03019 9.64556 8.03019 7.99991C8.03019 6.35428 9.36271 5.01576 10.9998 5.01576C12.6369 5.01576 13.9695 6.35428 13.9695 7.99991C13.9695 9.64556 12.6369 10.9841 10.9998 10.9841ZM13.9858 11.6865C15.0515 10.8137 15.7324 9.48556 15.7324 7.99991C15.7324 6.51426 15.0515 5.18611 13.9858 4.31329C16.5707 5.22575 18.6605 7.06174 19.6117 7.99991C18.6605 8.93791 16.5707 10.7739 13.9858 11.6865Z"
      fill="#17AACF"
    />
    <path
      d="M10.9999 6.35547C10.1074 6.35547 9.38379 7.07905 9.38379 7.97161C9.38379 8.86418 10.1074 9.58776 10.9999 9.58776C11.8925 9.58776 12.6163 8.86436 12.6163 7.97161C12.6163 7.07887 11.8925 6.35547 10.9999 6.35547Z"
      fill="#17AACF"
    />
  </svg>
);

export default EyeIcon;
