import { Trans } from "react-i18next";

import { MFAStatus } from "constants/mfa/mfaStatus";

export const getMfaStatus = (isVerified: boolean) => {
  const status = isVerified ? MFAStatus.ACTIVE : MFAStatus.INACTIVE;

  return status;
};

export const getMfaStatusTranslation = (isVerified: boolean) => {
  const status = getMfaStatus(isVerified);

  const translation = <Trans i18nKey={`components.mfa_status.${status}`} />;

  return translation;
};
