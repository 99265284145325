import { FC } from "react";
import Tooltip from "react-tooltip";
import copy from "copy-to-clipboard";

import ErrorLayout from "layouts/ErrorLayout";

import { PageDescription } from "uikit";
import { Title } from "uikit/TextWithTooltip/styles";

import { iconsList } from "./constants";
import { Container, ICIcon, IconCard, IconCardsContainer, ICText, TitleContainer } from "./styles";

const IconsPage: FC = () => {
  const icons = Object.keys(iconsList)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => ({
      key,
      component: (iconsList as any)[key],
    }));

  return (
    <>
      <ErrorLayout>
        <Container>
          <TitleContainer>
            <Title>Icons Page</Title>
            <PageDescription>
              <p>Below is the list of all svg icons from assets/svg.</p>
              <p>Click on a card to copy icon name to clipboard</p>
              <p>* Actual icon sizes may differ</p>
            </PageDescription>
          </TitleContainer>
          <IconCardsContainer>
            {icons.map((item) => {
              const Component = item.component;
              return (
                <IconCard
                  key={item.key}
                  data-for={item.key}
                  data-html={false}
                  data-tip={"Copied to clipboard"}
                >
                  <ICIcon>
                    <Component />
                  </ICIcon>
                  <ICText>{item.key}</ICText>
                  <Tooltip
                    id={item.key}
                    place="top"
                    type="dark"
                    effect="float"
                    event="click"
                    afterShow={() => {
                      copy(item.key);
                      setTimeout(Tooltip.hide, 2000);
                    }}
                  />
                </IconCard>
              );
            })}
          </IconCardsContainer>
        </Container>
      </ErrorLayout>
    </>
  );
};

export default IconsPage;
