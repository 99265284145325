import { FC } from "react";

import IconProps from "./IconProps";

const PaymentTransactionIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.519903 12.2725L0.000681898 19.6409C-0.00268171 19.6722 0.00101091 19.7039 0.0114889 19.7337C0.0219669 19.7634 0.0389652 19.7905 0.0612392 19.8128C0.0835133 19.8352 0.110499 19.8522 0.140224 19.8628C0.169949 19.8734 0.20166 19.8771 0.233034 19.8739L7.60142 19.3546C7.67014 19.3481 7.73445 19.3179 7.78326 19.2691C7.83207 19.2203 7.86232 19.156 7.86882 19.0872L5.60761 17.1499L11.4404 11.31C11.6195 11.1308 11.6351 10.8602 11.4832 10.7051L9.16882 8.38611C9.01305 8.23099 8.74241 8.25046 8.56392 8.42894L2.72659 14.2663L0.78925 12.0051C0.720224 12.0113 0.655525 12.0414 0.606344 12.0902C0.557162 12.139 0.526587 12.2035 0.519903 12.2725Z"
      fill="#F29128"
    />
    <path
      d="M4.08164 1.35898L11.45 0.839763C11.4814 0.83638 11.5131 0.840062 11.5428 0.850543C11.5725 0.861024 11.5995 0.878034 11.6218 0.900323C11.6441 0.922613 11.6611 0.949615 11.6716 0.979344C11.6821 1.00907 11.6858 1.04077 11.6824 1.07211L11.1632 8.4405C11.1565 8.50928 11.1261 8.57359 11.0772 8.62239C11.0283 8.67119 10.9639 8.70143 10.8951 8.7079L8.95582 6.44475L3.11849 12.2821C2.93936 12.4606 2.66871 12.48 2.51359 12.3249L0.195275 10.0059C0.0401575 9.85083 0.0589812 9.58019 0.237463 9.4017L6.07869 3.56372L3.81684 1.62638C3.82347 1.55815 3.85343 1.49428 3.90167 1.44557C3.94991 1.39686 4.01347 1.36628 4.08164 1.35898Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default PaymentTransactionIcon;
