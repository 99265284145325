import { useState } from "react";

import { COMPANY_TECHNOLOGY_PLATFORM_AGREEMENT } from "constants/assets";
import { openDownloadLink } from "helpers/index";

import {
  queryDocumentsControllerDownloadDocument,
  queryDocumentsControllerGetDocumentRecipientView,
  ViewUrlResponseDto,
} from "utils/swagger_react_query";

export const useServiceAgreementPreviewDocument = (documentId: string) => {
  const [documentPreviewIsLoading, setDocumentPreviewLoading] = useState<boolean>(false);

  const [docuSignPreviewUrl, setDocuSignPreviewUrl] = useState<string>("");
  const [docuSignPreviewModalState, setDocuSignPreviewModalState] = useState<boolean>(false);

  const technologyPlatformAgreementPdfPath = `${window.location.origin}${COMPANY_TECHNOLOGY_PLATFORM_AGREEMENT}`;

  const fetchPreviewUrl = async () => {
    try {
      setDocumentPreviewLoading(true);
      if (documentId) {
        const previewUrlRes: ViewUrlResponseDto =
          await queryDocumentsControllerGetDocumentRecipientView(
            { documentId: documentId },
            {
              returnUrl: window.location.href,
            },
          );
        setDocuSignPreviewUrl(previewUrlRes.url || "");
        return setDocuSignPreviewModalState(true);
      }
      return window.open(technologyPlatformAgreementPdfPath, "_blank");
    } catch (error) {
      console.log(error);
    } finally {
      setDocumentPreviewLoading(false);
    }
  };

  const onDocumentDownload = async () => {
    try {
      setDocumentPreviewLoading(true);
      const res = await queryDocumentsControllerDownloadDocument({ documentId: documentId || "" });
      const url = URL.createObjectURL(res as unknown as Blob | MediaSource);
      openDownloadLink(url, "Keep-Service-Agreement.pdf");
    } catch (error) {
      console.log(error);
    } finally {
      setDocumentPreviewLoading(false);
    }
  };

  return {
    documentPreviewIsLoading,
    onDocumentDownload,
    fetchPreviewUrl,
    docuSignPreviewModalState,
    setDocuSignPreviewModalState,
    docuSignPreviewUrl,
  };
};
