import { FC } from "react";

import IconProps from "./IconProps";

const ExitWithBgCircleIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_4)">
      <path
        d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
        fill="#BC0000"
      />
      <g clipPath="url(#clip1_1_4)">
        <path
          d="M23.1707 8.57013H18.5365V6.61642C18.5365 6.19784 18.1311 5.90109 17.736 6.03073L10.4631 8.58759C10.1858 8.68499 10 8.94866 10 9.24456V23.7576C10 24.0536 10.1858 24.3173 10.4632 24.4147L17.7242 26.9653C18.1215 27.1048 18.5365 26.8077 18.5365 26.3836V24.2519H23.1707C23.6288 24.2519 24 23.8778 24 23.4162V9.40585C24 8.94426 23.6288 8.57013 23.1707 8.57013ZM17 17.2714C16.7665 17.2714 16.5772 16.9045 16.5772 16.452C16.5772 15.9995 16.7665 15.6327 17 15.6327C17.2335 15.6327 17.4228 15.9996 17.4228 16.452C17.4228 16.9044 17.2336 17.2714 17 17.2714ZM22.4878 22.7773H18.5365V10.1923H22.4878V22.7773Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_4">
        <rect width="34" height="34" fill="white" />
      </clipPath>
      <clipPath id="clip1_1_4">
        <rect width="14" height="21" fill="white" transform="translate(10 6)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExitWithBgCircleIcon;
