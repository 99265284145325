import React, { FC } from "react";

import IconProps from "./IconProps";

const WarningRound: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8.5" fill="#F29128" stroke="white" />
    <path
      d="M9.16438 11.3682C8.82147 11.3682 8.53199 11.2338 8.51703 11.0609L8.00351 5.11596C7.9522 4.52174 8.47 4 9.16438 4C9.85877 4 10.3766 4.52174 10.3253 5.11596L9.81174 11.0609C9.79677 11.2338 9.5073 11.3682 9.16438 11.3682Z"
      fill="white"
    />
    <path
      d="M10.1632 13.0375C10.1632 13.5692 9.71484 14.0003 9.16169 14.0003C8.60854 14.0003 8.16016 13.5692 8.16016 13.0375C8.16016 12.5057 8.60854 12.0747 9.16169 12.0747C9.71484 12.0747 10.1632 12.5057 10.1632 13.0375Z"
      fill="white"
    />
  </svg>
);

export default WarningRound;
