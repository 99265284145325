import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { useEmployeeSetup } from "./useEmployeeSetup";

interface IProps {}

const EmployeeSetupPage: FC<IProps> = () => {
  const { renderContent } = useEmployeeSetup();

  return (
    <DashboardLayout showBreadcrumbs={false}>
      <>{renderContent()}</>
    </DashboardLayout>
  );
};

export default EmployeeSetupPage;
