import { FC } from "react";

import IconProps from "./IconProps";

const CheckmarkRoundFilledDark: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14.5" cy="14.5" r="14.5" fill="#007D7B" />
    <rect
      x="7.12109"
      y="11.5352"
      width="11"
      height="3"
      transform="rotate(45 7.12109 11.5352)"
      fill="white"
    />
    <rect
      x="24.0938"
      y="10.1211"
      width="16"
      height="3"
      transform="rotate(135 24.0938 10.1211)"
      fill="white"
    />
  </svg>
);

export default CheckmarkRoundFilledDark;
