import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";
import ListEmployeeInfo from "components/ListEmployeeInfo";

import { PrimaryButton, UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  overflow: auto;
  outline: none;
  padding: 18px !important;
  width: 466px !important;

  ${breakpoint("xs", "md")`
    width: 350px !important;
    padding: 15px !important;
  `}

  & > div:nth-child(1) {
    padding: 30px 14px;
    text-align: center;

    & > svg {
      margin-bottom: 36px;
    }
  }
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.title};
  color: ${({ theme }) => theme.colors.textAlt};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  margin-bottom: 24px;
`;

export const Text = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 20px;
  line-height: 19px;

  span {
    color: ${({ theme }) => theme.colors.bcbBorderVoided};
  }
`;

export const InfoSection = styled.div`
  margin-bottom: 32px;
  background-color: ${({ theme }) => theme.colors.contactInfoBg};
  padding: 19px 16px 12px 26px;
  border-radius: 6px;
`;

export const ISTop = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.separatorLine};
  margin-bottom: 12px;
`;

export const StyledUserInfo = styled(ListEmployeeInfo)`
  margin-bottom: 20px;
  cursor: text;

  & > div:nth-child(2) {
    width: min-content;
    text-align: left;

    & > div {
      margin-bottom: 4px;
    }
    span {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: ${({ theme }) => theme.size.body2};
      color: ${({ theme }) => theme.colors.textAlt};
    }
  }
`;

export const ISTEmail = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 310px;
`;

export const ISBottom = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
`;

export const ISBTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.danger};
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const ISBText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 4px;
`;

export const ISBDate = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 4px;
`;

export const PrimaryBtn = styled(PrimaryButton)`
  width: 272px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0 auto;
`;
