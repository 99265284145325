import { FC } from "react";

import IconProps from "./IconProps";

const LogoOrangeRound: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Layer_1" clipPath="url(#clip0_6037_10878)">
      <path
        id="Vector"
        d="M12.1953 23.2656C18.5466 23.2656 23.6953 18.1169 23.6953 11.7656C23.6953 5.41435 18.5466 0.265625 12.1953 0.265625C5.84404 0.265625 0.695312 5.41435 0.695312 11.7656C0.695312 18.1169 5.84404 23.2656 12.1953 23.2656Z"
        fill="#F96331"
      />
      <path
        id="Vector_2"
        d="M10.8991 5.14831C11.2511 4.89532 11.6416 4.70283 12.0595 4.55433C12.483 4.41134 12.89 4.33984 13.297 4.33984C13.9075 4.33984 14.4739 4.47734 14.9964 4.75233C15.5189 5.02731 16.0964 5.4453 16.4704 5.92927C17.2513 6.93023 18.4338 10.2356 16.1514 12.859C15.5849 13.5134 15.0624 13.8544 14.4244 14.1569C13.7865 14.4594 13.099 14.6079 12.3675 14.6079C11.7956 14.6079 11.3061 14.5199 10.9046 14.3439V18.3642L8.01172 19.1892V4.56533H10.9046V5.14831H10.8991ZM10.8991 12.232C10.8991 12.5455 10.9486 12.8315 11.0476 13.0955C11.1466 13.3595 11.2731 13.5849 11.4326 13.7719C11.5921 13.9589 11.7736 14.1074 11.9715 14.2119C12.175 14.3164 12.373 14.3714 12.5655 14.3714C12.835 14.3714 13.088 14.2834 13.3245 14.1074C13.561 13.9314 13.77 13.6894 13.946 13.376C14.122 13.0625 14.2649 12.694 14.3694 12.2705C14.4739 11.847 14.5234 10.4171 14.5234 9.9166C14.5234 9.36662 14.4629 7.87619 14.3419 7.38121C14.2209 6.88623 14.056 6.45175 13.858 6.07777C13.6545 5.70378 13.418 5.4013 13.143 5.17581C12.868 4.95032 12.571 4.84032 12.2575 4.84032C12.0375 4.84032 11.8011 4.90632 11.5426 5.03831C11.2841 5.17031 11.0751 5.3408 10.9046 5.55529V12.2375L10.8991 12.232Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M13.8242 17.5034C13.8242 17.0359 14.0002 16.6344 14.3577 16.2989C14.7152 15.9635 15.1387 15.793 15.6391 15.793C16.1396 15.793 16.5851 15.9635 16.9426 16.2989C17.3001 16.6344 17.4761 17.0359 17.4761 17.5034C17.4761 17.9709 17.3001 18.3724 16.9426 18.6968C16.5851 19.0268 16.1506 19.1918 15.6391 19.1918C15.1277 19.1918 14.7152 19.0268 14.3577 18.6968C14.0002 18.3669 13.8242 17.9709 13.8242 17.5034Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6037_10878">
        <rect width="23" height="23" fill="white" transform="translate(0.695312 0.265625)" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoOrangeRound;
