import { FC } from "react";

import IconProps from "./IconProps";

const GmailIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8799 12.2576C22.8799 11.454 22.8078 10.6813 22.6738 9.93945H11.9999V14.3234H18.0993C17.8366 15.7401 17.0381 16.9404 15.8378 17.744V20.5877H19.5005C21.6435 18.6146 22.8799 15.7092 22.8799 12.2576Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.3333C15.06 23.3333 17.6254 22.3185 19.5006 20.5876L15.8379 17.7439C14.823 18.4239 13.5248 18.8258 12 18.8258C9.04814 18.8258 6.54965 16.8321 5.65844 14.1533H1.87207V17.0897C3.73692 20.7936 7.56966 23.3333 12 23.3333Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.65845 14.1532C5.43179 13.4732 5.303 12.7468 5.303 11.9999C5.303 11.2529 5.43179 10.5265 5.65845 9.84652V6.91016H1.87208C1.10451 8.44016 0.666626 10.1711 0.666626 11.9999C0.666626 13.8287 1.10451 15.5596 1.87208 17.0896L5.65845 14.1532Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.17409C13.6639 5.17409 15.1578 5.74591 16.3324 6.86894L19.583 3.61833C17.6203 1.78954 15.0548 0.666504 12 0.666504C7.56966 0.666504 3.73692 3.20621 1.87207 6.91015L5.65844 9.84652C6.54965 7.16773 9.04814 5.17409 12 5.17409Z"
      fill="#EA4335"
    />
  </svg>
);

export default GmailIcon;
