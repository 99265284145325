import styled from "styled-components";

import { breakpoint } from "helpers";

import { ButtonsContainer, NoBorderPrimaryButton, SectionAlt, StepIndicator, UIModal } from "uikit";
import { PrimaryButtonIconed, SecondaryButton } from "uikit";
import { Container as ModalContentContainer } from "uikit/Modal/styles";

import { ResponseStatusContainer } from "../components/styles";

const circleBorderSize = 37;
const circleBorderWidth = 2;
const circleSize = 27;

export const StyledUIModal = styled(UIModal)`
  width: 1100px !important;
  text-align: left;
  overflow: hidden !important;

  &.small {
    width: 862px !important;
  }

  ${ModalContentContainer} {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MFAWidgetContainer = styled(SectionAlt)`
  ${ModalContentContainer} {
    background: transparent;
    text-align: center;
    padding-bottom: 0;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 10px;
`;

export const StyleStepIndicator = styled(StepIndicator)`
  margin-bottom: 32px;
`;

export const PromoSection = styled.div`
  width: 100%;
`;

export const PromoSectionInnerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PromoSectionTextContainer = styled.div``;

export const StepsList = styled.div`
  margin-bottom: 33px;
`;

export const StepsListItem = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 14px;
  position: relative;
  height: auto;

  &::after {
    content: "";
    display: inline-block;
    border-left: 2px dotted ${({ theme }) => theme.colors.accentMain};
    position: absolute;
    top: ${circleBorderSize}px;
    left: ${circleBorderSize / 2}px;
    transform: translateX(-50%);
    height: 80%;
    z-index: 0;
  }

  &:last-of-type {
    margin-bottom: 0;

    &::after {
      display: none;
    }

    div:nth-child(1)::after {
      display: none;
    }
  }
`;

export const StepsListItemNumberContainer = styled.div``;

export const StepsListItemNumberContainerInner = styled.div`
  display: block;
  border: ${circleBorderWidth}px solid ${({ theme }) => theme.colors.accentMain};
  background-color: ${({ theme }) => theme.colors.contentBg};
  border-radius: 50%;
  width: ${circleBorderSize}px;
  height: ${circleBorderSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
`;

export const StepsListItemNumber = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.accentMain};
  border-radius: 50%;
  width: ${circleSize}px;
  height: ${circleSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
`;

export const StepsListItemTextContainer = styled.div`
  margin-left: 12px;
`;

export const StepsListItemTitle = styled.p`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20.8px;
`;

export const StepsListItemDescription = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 18.2px;
`;

export const PromoImg = styled.img`
  width: 392px;
  height: 336px;
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const BtnsWrapper = styled(ButtonsContainer)`
  margin-top: 35px;

  &.center {
    justify-content: center;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column-reverse;
		justify-content: flex-start;
  `}
`;

export const ButtonsGroup = styled.div`
  display: flex;
`;

export const SubmitBtn = styled(PrimaryButtonIconed)`
  width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 100%;
  `}
`;

export const SkipBtn = styled(NoBorderPrimaryButton)``;

export const BackBtn = styled(SecondaryButton)`
  width: 90px;
  margin-right: 24px;

  ${breakpoint("xs", "md")`
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
  `}
`;

export const BankAccountSetupCompletedSection = styled.div`
  ${ResponseStatusContainer} {
    background-color: ${({ theme }) => theme.colors.contentBg};
  }
`;
