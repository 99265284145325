import { FC } from "react";

import IconProps from "./IconProps";

const BalanceReduction: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12111_64554)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4504 15.1407C13.5977 14.9935 13.839 14.9935 13.9863 15.1407L17.2021 18.3542C17.3495 18.5014 17.3495 18.7425 17.2021 18.8898C17.0547 19.037 16.8135 19.037 16.6661 18.8898L13.4504 15.6763C13.303 15.529 13.303 15.288 13.4504 15.1407Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2344 10.4854C15.4429 10.4854 15.6133 10.6558 15.6133 10.864V15.4086C15.6133 15.6169 15.4427 15.7872 15.2344 15.7872C15.0259 15.7872 14.8555 15.6168 14.8555 15.4086V10.864C14.8555 10.6556 15.026 10.4854 15.2344 10.4854Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6328 10.4854C18.8413 10.4854 19.0117 10.6558 19.0117 10.864V15.4086C19.0117 15.6169 18.8412 15.7872 18.6328 15.7872C18.4243 15.7872 18.2539 15.6168 18.2539 15.4086V10.864C18.2539 10.6556 18.4245 10.4854 18.6328 10.4854Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4169 15.1407C20.2696 14.9935 20.0283 14.9935 19.881 15.1407L16.6652 18.3542C16.5178 18.5014 16.5178 18.7425 16.6652 18.8898C16.8126 19.037 17.0538 19.037 17.2012 18.8898L20.4169 15.6763C20.5643 15.529 20.5643 15.288 20.4169 15.1407Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4509 15.1407C13.5989 14.9928 13.8388 14.9928 13.9868 15.1407C14.1349 15.2886 14.1349 15.5284 13.9868 15.6763C13.8388 15.8242 13.5989 15.8242 13.4509 15.6763C13.3028 15.5284 13.3028 15.2886 13.4509 15.1407Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6657 18.3536C16.8137 18.2057 17.0536 18.2057 17.2017 18.3536C17.3497 18.5015 17.3497 18.7413 17.2017 18.8892C17.0536 19.0371 16.8137 19.0371 16.6657 18.8892C16.5177 18.7413 16.5177 18.5015 16.6657 18.3536Z"
        fill="#339900"
      />
      <path
        d="M15.2344 15.7871C15.4437 15.7871 15.6133 15.6175 15.6133 15.4084C15.6133 15.1993 15.4437 15.0298 15.2344 15.0298C15.0251 15.0298 14.8555 15.1993 14.8555 15.4084C14.8555 15.6175 15.0251 15.7871 15.2344 15.7871Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9665 10.5958C15.1145 10.4479 15.3544 10.4479 15.5025 10.5958C15.6505 10.7437 15.6505 10.9835 15.5025 11.1314C15.3544 11.2793 15.1145 11.2793 14.9665 11.1314C14.8185 10.9835 14.8185 10.7437 14.9665 10.5958Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3649 10.5958C18.5129 10.4479 18.7529 10.4479 18.9009 10.5958C19.0489 10.7437 19.0489 10.9835 18.9009 11.1314C18.7529 11.2793 18.5129 11.2793 18.3649 11.1314C18.2169 10.9835 18.2169 10.7437 18.3649 10.5958Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3649 15.1407C18.5129 14.9928 18.7529 14.9928 18.9009 15.1407C19.0489 15.2886 19.0489 15.5284 18.9009 15.6763C18.7529 15.8242 18.5129 15.8242 18.3649 15.6763C18.2169 15.5284 18.2169 15.2886 18.3649 15.1407Z"
        fill="#339900"
      />
      <path
        d="M20.1524 15.7871C20.3616 15.7871 20.5313 15.6175 20.5313 15.4084C20.5313 15.1993 20.3616 15.0298 20.1524 15.0298C19.9431 15.0298 19.7734 15.1993 19.7734 15.4084C19.7734 15.6175 19.9431 15.7871 20.1524 15.7871Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7188 15.0298H15.2348C15.4433 15.0298 15.6137 15.2002 15.6137 15.4084C15.6137 15.6168 15.4431 15.7871 15.2348 15.7871H13.7188C13.5103 15.7871 13.3398 15.6166 13.3398 15.4084C13.3398 15.2001 13.5104 15.0298 13.7188 15.0298Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6328 15.0298H20.1488C20.3573 15.0298 20.5277 15.2002 20.5277 15.4084C20.5277 15.6168 20.3572 15.7871 20.1488 15.7871H18.6328C18.4243 15.7871 18.2539 15.6166 18.2539 15.4084C18.2539 15.2001 18.4245 15.0298 18.6328 15.0298Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2344 10.4854H18.6339C18.8424 10.4854 19.0129 10.6558 19.0129 10.864C19.0129 11.0724 18.8423 11.2426 18.6339 11.2426H15.2344C15.0259 11.2426 14.8555 11.0722 14.8555 10.864C14.8555 10.6556 15.026 10.4854 15.2344 10.4854Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9345 18.6213L20.1503 15.4083H18.6343V10.8638H15.2348V15.4083H13.7188L16.9345 18.6213Z"
        fill="#339900"
      />
      <path
        d="M10.1232 14.0088C10.6172 13.9039 11.0365 13.7244 11.3852 13.47C11.7317 13.2154 11.997 12.9034 12.1811 12.5336C12.3651 12.1638 12.4571 11.7533 12.4591 11.3022C12.4613 10.8703 12.3863 10.4898 12.2346 10.1649C12.0827 9.84 11.8452 9.54717 11.5199 9.2884C11.1947 9.02762 10.7711 8.7797 10.2513 8.54446C9.92618 8.38402 9.65669 8.23227 9.44267 8.09336C9.22665 7.95446 9.06626 7.81756 8.95917 7.68083C8.85442 7.5461 8.80096 7.40069 8.80096 7.24893C8.80096 7.09717 8.84372 6.96027 8.92943 6.82771C9.01497 6.69732 9.14545 6.5883 9.31871 6.50483C9.49196 6.42152 9.70798 6.37878 9.96911 6.37661C10.2172 6.37878 10.4504 6.40215 10.6643 6.44706C10.8803 6.49214 11.0771 6.54974 11.2547 6.61819C11.4323 6.68664 11.5905 6.75492 11.7274 6.82554L12.138 5.18375C11.9157 5.07039 11.6482 4.97205 11.3401 4.88657C11.032 4.8011 10.6726 4.74984 10.264 4.73482V3.40088H8.81365V4.8632C8.34736 4.98507 7.9514 5.17323 7.62628 5.42532C7.30116 5.67542 7.05506 5.97693 6.88616 6.32753C6.71708 6.67812 6.63355 7.06294 6.63154 7.47982C6.63371 7.90738 6.72142 8.27935 6.8905 8.59154C7.06158 8.90358 7.30116 9.17955 7.60924 9.41678C7.91732 9.65619 8.28103 9.87857 8.69821 10.0838C9.08548 10.2482 9.39339 10.4065 9.62027 10.5561C9.84698 10.7056 10.0095 10.8576 10.1079 11.0135C10.2064 11.1696 10.2535 11.3384 10.2513 11.52C10.2513 11.6932 10.2085 11.8493 10.121 11.9905C10.0333 12.1316 9.89844 12.2449 9.71884 12.3282C9.53907 12.4117 9.31019 12.4545 9.03201 12.4566C8.75818 12.4545 8.49505 12.4289 8.24478 12.3755C7.9945 12.3241 7.76127 12.2579 7.54308 12.1745C7.32472 12.0912 7.12791 12.0013 6.95265 11.9052L6.55469 13.6113C6.71524 13.6967 6.90771 13.778 7.13025 13.8528C7.35278 13.9275 7.59654 13.9896 7.85767 14.0408C8.12081 14.0901 8.39247 14.1243 8.67264 14.137V15.5993H10.1232V14.0089V14.0088Z"
        fill="#339900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50683 0C14.7564 0 19.0137 4.25424 19.0137 9.5H16.8235C16.8235 5.46263 13.5469 2.18839 9.50666 2.18839C5.46639 2.18839 2.1898 5.46263 2.1898 9.5C2.1898 13.5374 5.46639 16.8116 9.50666 16.8116C10.5878 16.8116 11.6141 16.5772 12.5375 16.1565L14.1648 17.7826C12.7881 18.5577 11.1991 19.0002 9.50666 19.0002C4.2573 19 0 14.7459 0 9.5C0 4.25407 4.2573 0 9.50683 0Z"
        fill="#339900"
      />
    </g>
    <defs>
      <clipPath id="clip0_12111_64554">
        <rect width="20.5297" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BalanceReduction;
