export const insufficientPermissionsPage = {
  "en-US": {
    title: "Sorry",
    text: "You do not have permission set to view this page.",
    dashboard_link: "return to your dashboard",
    text2:
      "You can <1>{{dashboardLink}}</1>, or reach out to our support team at <2>{{supportLink}}</2> <br /> if you can’t find what you are looking for.",
  },
};

export default insufficientPermissionsPage;
