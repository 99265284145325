import { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { CreditCard, LogoFull } from "assets/svg";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { PayDistributionType } from "types/BETypes";

import { BankItemType, FormType } from "./components/BankAccountsForm/validationSchema";
import { EDistributionOptions, EWidgetType, IProps } from "./types";
import useBankAccountsInitialValues from "./useBankAccountsInitialValues";
import { useCommonModals } from "./useCommonModals";
import {
  CACButtonsContainer,
  CACContentContainer,
  CACCreateAccountButton,
  CACLogoContainer,
  CACText,
  CACTextLink,
  CACTitle,
  CompanyAccountCardContainer,
  MethodSelectionItem,
  MSIActiveLabel,
  MSIIcon,
  MSIText,
} from "./styles";

const usePayDistribution = (props: IProps) => {
  //Constants and external hooks
  const {
    initialStep,
    disabledSteps,
    widgetType,
    onNoAccountsModalCancel,
    onNoAccountsModalConfirm,
  } = props;

  const currentUser = useAppSelector(userMetadataSelector);
  const firstPayDistributionMethod = currentUser?.defaultPayDistribution?.[0];
  const navigate = useNavigate();
  const { showNoAccountsModal, showNavigationWarningModal } = useCommonModals();
  const distributionOptions = [EDistributionOptions.BANK_ACCOUNT, EDistributionOptions.CHECK];
  const translationPrefix = `components.pay_distribution`;

  //States
  const [currentStep, setCurrentStep] = useState<EDistributionOptions | undefined>(undefined);
  const [currentFormData, setCurrentFormData] = useState<FormType | undefined>(undefined);
  const bankAccountFormRef = useRef<any>(null);

  //Methods
  const redirectToBankAccounts = () => {
    navigate(routes.EMPLOYEE_BANK_ACCOUNTS);
  };

  const handleNoBankAccountsModalClose = () => {
    onNoAccountsModalCancel ? onNoAccountsModalCancel() : switchStep(EDistributionOptions.CHECK);
  };
  const handleNoBankAccountsModalConfirm = () => {
    onNoAccountsModalConfirm ? onNoAccountsModalConfirm() : redirectToBankAccounts();
  };

  const { isLoading, initialFormData, combinedAccounts, getInitialFormData } =
    useBankAccountsInitialValues({
      widgetType,
      onNoAccountsModalCancel: handleNoBankAccountsModalClose,
      onNoAccountsModalConfirm: handleNoBankAccountsModalConfirm,
    });

  const initializeStep = () => {
    if (initialStep) {
      setCurrentStep(initialStep);
    } else if (
      firstPayDistributionMethod?.type === PayDistributionType.CHECK ||
      (widgetType === EWidgetType.ONBOARDING && !combinedAccounts?.length)
    ) {
      setCurrentStep(EDistributionOptions.CHECK);
    } else {
      setCurrentStep(EDistributionOptions.BANK_ACCOUNT);
    }
  };

  const handleBankAccountFormConfirmPayDistribution = (data: FormType) => {
    setCurrentFormData(data);
  };

  const shouldShowRedirectModal = () => {
    return bankAccountFormRef.current?.isDataUnsaved?.();
  };

  const switchStep = (item: EDistributionOptions) => {
    if (disabledSteps?.includes(item)) return;
    if (item === EDistributionOptions.CHECK) {
      if (shouldShowRedirectModal()) {
        showNavigationWarningModal(() => {
          setCurrentStep(item);
        });
      } else {
        setCurrentStep(item);
      }
    } else if (!combinedAccounts?.length) {
      showNoAccountsModal(handleNoBankAccountsModalConfirm, handleNoBankAccountsModalClose);
    } else {
      if (item === EDistributionOptions.BANK_ACCOUNT) {
        const formData = getInitialFormData();
        if (formData) {
          setCurrentFormData(formData);
        }
      }

      setCurrentStep(item);
    }
  };

  const renderMethodSelectionItem = (item: EDistributionOptions) => {
    const isDisabledBankAccountOptionsWithNoData =
      item === EDistributionOptions.BANK_ACCOUNT &&
      widgetType === EWidgetType.ONBOARDING &&
      !combinedAccounts?.length;
    const isDisabled = disabledSteps?.includes(item) || isDisabledBankAccountOptionsWithNoData;
    const className = `${currentStep === item ? "active" : ""} ${isDisabled ? "disabled" : ""}`;
    return (
      <MethodSelectionItem
        className={className}
        onClick={() => {
          if (isDisabled) return;
          switchStep(item);
        }}
        data-testid={`pay-method-selection-item_${item}`}
      >
        <MSIIcon>
          <CreditCard />
        </MSIIcon>
        <MSIText>
          <Trans i18nKey={`${translationPrefix}.pay_distribution_options.${item}`} />
        </MSIText>
        {currentStep === item && (
          <MSIActiveLabel>
            <Trans i18nKey={`${translationPrefix}.pay_distribution_option_selected`} />
          </MSIActiveLabel>
        )}
      </MethodSelectionItem>
    );
  };

  const renderCompanyCard = () => {
    // Not displayed on the page right now, mb will be used later
    return (
      <CompanyAccountCardContainer>
        <CACLogoContainer>
          <LogoFull />
        </CACLogoContainer>
        <CACContentContainer>
          <CACTitle>
            <Trans i18nKey={`${translationPrefix}.company_account_card.title`} />
          </CACTitle>
          <CACText>
            <Trans
              i18nKey={`${translationPrefix}.company_account_card.text`}
              components={{ 1: <CACTextLink to={routes.EMPLOYEE_BANKING} /> }}
            />
          </CACText>
        </CACContentContainer>
        <CACButtonsContainer>
          {/* TODO::: add modal */}
          <CACCreateAccountButton to={""}>
            <Trans i18nKey={`${translationPrefix}.company_account_card.create_account_button`} />
          </CACCreateAccountButton>
        </CACButtonsContainer>
      </CompanyAccountCardContainer>
    );
  };

  useEffect(() => {
    if (initialFormData && combinedAccounts && !currentStep) {
      initializeStep();
    }
  }, [initialFormData, combinedAccounts]);

  return {
    currentStep,
    distributionOptions,
    bankAccountFormRef,
    isLoading,
    initialFormData: currentFormData || initialFormData,
    combinedAccounts,
    redirectToBankAccounts,
    renderMethodSelectionItem,
    renderCompanyCard,
    shouldShowRedirectModal,
    handleNoBankAccountsModalConfirm,
    handleNoBankAccountsModalClose,
    handleBankAccountFormConfirmPayDistribution,
  };
};

export default usePayDistribution;
