import { FC, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import OutsideClickHandler from "react-outside-click-handler";

import { getName } from "helpers/index";

import { Input } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { Props as IInputProps } from "./Input";
import {
  InputWithSearchOption,
  InputWithSearchOptionsList,
  InputWithSearchWrapper,
  IWSOptionText,
  IWSUIAvatar,
  IWSUIDepartment,
  IWSUIEmail,
  IWSUILeftText,
  IWSUIName,
  IWSUIRightText,
  IWSUserInfoWrapper,
  StyledLoader,
} from "./styles";

interface IProps {
  inputProps: IInputProps;
  options?: string[] | UserResponseDto[];
  type: "text" | "userShortInfo";
  onSelect?: (option: string | UserResponseDto) => void;
  fetchMore?: () => void;
  hasMore?: boolean;
  className?: string;
  isLoading?: boolean;
}

const InputWithSearch: FC<IProps> = ({
  inputProps,
  options,
  type,
  onSelect,
  fetchMore,
  hasMore = false,
  className,
  isLoading,
}) => {
  const [isListOpen, setListOpen] = useState<boolean>(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setListOpen(false)}>
      <InputWithSearchWrapper className={className}>
        {isLoading && <StyledLoader />}
        <Input {...inputProps} onFocus={() => setListOpen(true)} />
        {isListOpen && options?.[0] && onSelect && (
          <InputWithSearchOptionsList>
            <InfiniteScroll
              dataLength={options.length}
              next={fetchMore!}
              hasMore={hasMore}
              height={type === "text" ? "min-content" : 210}
              loader={<></>}
            >
              {options.map((option, index) => {
                return (
                  <InputWithSearchOption
                    key={index.toString()}
                    onClick={() => {
                      onSelect(option);
                      setListOpen(false);
                    }}
                  >
                    {type === "text" && typeof option === "string" && (
                      <IWSOptionText>{option}</IWSOptionText>
                    )}
                    {type === "userShortInfo" && typeof option !== "string" && (
                      <>
                        <IWSUserInfoWrapper>
                          <IWSUIAvatar
                            src={option.avatarUrl}
                            firstName={option.firstName}
                            lastName={option.lastName}
                          />
                          <IWSUILeftText>
                            <IWSUIName>{getName(option)}</IWSUIName>
                            <IWSUIDepartment>{option.department}</IWSUIDepartment>
                          </IWSUILeftText>
                          <IWSUIRightText>
                            <IWSUIEmail>{option.email || "-"}</IWSUIEmail>
                          </IWSUIRightText>
                        </IWSUserInfoWrapper>
                      </>
                    )}
                  </InputWithSearchOption>
                );
              })}
            </InfiniteScroll>
          </InputWithSearchOptionsList>
        )}
      </InputWithSearchWrapper>
    </OutsideClickHandler>
  );
};

export default InputWithSearch;
