import { FC } from "react";

import { IProps } from "./types";
import useMFASMS from "./useMFASMS";
import { Container } from "./styles";

const MFASMS: FC<IProps> = (props) => {
  const { className } = props;
  const { renderCurrentStep } = useMFASMS(props);
  return <Container className={className}>{renderCurrentStep()}</Container>;
};

export default MFASMS;
