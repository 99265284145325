import * as Sentry from "@sentry/react";
import { stop } from "ky/distribution/core/constants";
import moment from "moment";
import routes from "routes/routes";
import { v4 as uuidv4 } from "uuid";

import { signInCompletedAnalytics } from "services/analytics";
import { clearSalsaData, saveSalsaData } from "services/salsa";
import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { CURRENT_ROLE_KEY, LOCALHOST_AUTH_TOKEN_KEY, SESSION_ID_KEY } from "constants/auth";
import { LOCAL_STORAGE_KEY as ACTIVITY_TRACKER_TIME_KEY } from "components/ActivityTracker/constants";
import { removeFailedKycUserDataFromLocalStorage } from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";

import {
  mutationAuthControllerAuthorize,
  mutationAuthControllerAuthPasswordless,
  mutationAuthControllerGetGoogleOAuthUrl,
  mutationAuthControllerLogout,
  queryCompaniesControllerGetCompany,
  queryUsersControllerGetProfile,
} from "../utils/swagger_react_query";

export const setLocalhostAuthToken = (token: string) => {
  const isLocalhost = (window.location.origin || "").includes("http://localhost");
  if (isLocalhost) localStorage.setItem(LOCALHOST_AUTH_TOKEN_KEY, token);
};
export const getLocalhostAuthToken = () => localStorage.getItem(LOCALHOST_AUTH_TOKEN_KEY);
export const removeLocalhostAuthToken = () => localStorage.removeItem(LOCALHOST_AUTH_TOKEN_KEY);

export const setSessionId = () => localStorage.setItem(SESSION_ID_KEY, uuidv4());
export const getSessionId = () => localStorage.getItem(SESSION_ID_KEY);
export const removeSessionId = () => localStorage.removeItem(SESSION_ID_KEY);
export const removeActivityTracker = () => localStorage.removeItem(ACTIVITY_TRACKER_TIME_KEY);

export const sendMagicLink = async (email: string, mode: EMagicLinkFlow) => {
  try {
    const res = await mutationAuthControllerAuthPasswordless()({ email, mode });
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: (err as any).message,
      code: (err as any).code,
      errorMessage: (err as any)?.data?.error,
    };
  }
};

export const login = async (
  code: string,
  mode: EMagicLinkFlow,
  invitationId?: string,
  fetchCompany: boolean = true,
) => {
  try {
    const response = await mutationAuthControllerAuthorize()({ code, invitationId, mode });
    setLocalhostAuthToken(response.token);

    const _companyId = response.companyUsers[0]?.companyId;
    setSessionId();
    saveSalsaData({
      salsaEmployerToken: response.salsaEmployerToken,
      salsaWorkerToken: response.salsaWorkerToken,
    });

    if (_companyId && fetchCompany) {
      const userInfo = response.companyUsers[0];
      const companyInfoRes = await queryCompaniesControllerGetCompany({
        companyId: _companyId || "",
      });
      if (userInfo.firstName && userInfo.lastName) {
        const analyticsData = {
          userId: userInfo.userId,
          role: userInfo.lastActiveRole,
          companyId: userInfo.companyId,
          companyName: companyInfoRes.name,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email || "",
          phone: userInfo.phone || "",
          state: userInfo.state || "",
          address: `${userInfo.zip || ""}, ${userInfo.address || ""}`,
          timestamp: moment().toString(),
        };
        signInCompletedAnalytics(analyticsData);
      }
    }
    return { success: true, data: response };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: (err as any)?.data?.debugError || (err as any)?.data?.error || (err as any).message,
      code: (err as any).code,
    };
  }
};

export const googleAuth = async (mode: EMagicLinkFlow) => {
  try {
    const response = await mutationAuthControllerGetGoogleOAuthUrl()({ mode });
    return { success: true, url: response.oAuthUrl };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export const cleanUp = () => {
  removeLocalhostAuthToken();
  removeSessionId();
  removeActivityTracker();
  localStorage.removeItem(CURRENT_ROLE_KEY);
  removeFailedKycUserDataFromLocalStorage();
};

export const logout = async (
  withRequest: boolean = true,
  withNavigate: boolean = true,
  params?: string,
): Promise<void | typeof stop> => {
  try {
    if (withRequest) {
      await mutationAuthControllerLogout()();
    }

    cleanUp();
    clearSalsaData();

    Sentry.setUser(null);

    if (withNavigate) {
      window.location.href = routes.SIGN_IN;
    }
    if (params) window.location.href = routes.SIGN_IN + `?${params}`;
    return;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getUser = async () => {
  try {
    const response = await queryUsersControllerGetProfile();
    return { success: true, user: response };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};
