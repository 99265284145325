import { FC, ReactNode } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";

import { TooltipContainer, TooltipContent } from "./styles";

interface Props extends TooltipProps {
  className?: string;
  id: string;
  children?: string | ReactNode;
  tooltipContent?: ReactNode;
}

const Tooltip: FC<Props> = ({ className, id, children, tooltipContent, ...rest }) => (
  <TooltipContainer data-tip data-for={id} className={className} style={{ position: "relative" }}>
    {children}
    {tooltipContent && (
      <ReactTooltip id={id} place="top" type="dark" effect="solid" delayUpdate={500} {...rest}>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </ReactTooltip>
    )}
  </TooltipContainer>
);

export default Tooltip;
