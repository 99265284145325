import { isEmail } from "class-validator";
import * as yup from "yup";

import { MAX_INPUT_LENGTH, MAX_INPUT_LENGTH_LONG } from "constants/form";
import { userNameRegExp } from "constants/shared";
import { validatePhoneNumber } from "helpers";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .test("invalid first name", "validationErrors.common.wrong_format", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),

  lastName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .test("invalid last name", "validationErrors.common.wrong_format", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),
  email: yup
    .string()
    .max(MAX_INPUT_LENGTH_LONG, "validationErrors.common.too_long")
    .test(
      "invalid email",
      "validationErrors.email.wrong_format",
      (value) => !value || isEmail(value),
    )
    .required("validationErrors.email.is_required"),
  phone: yup
    .string()
    .nullable()
    .test(
      "invalid phone",
      "validationErrors.phone.phone_wrong_format",
      (value) => !value || validatePhoneNumber(value),
    ),
  overtimeEligible: yup.boolean().required("validationErrors.common.is_required"),

  employmentType: yup.string().nullable().required("validationErrors.employment_type.is_required"),
});

export type FormType = yup.InferType<typeof validationSchema>;
export default validationSchema;
