import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { generatePath } from "react-router";
import routes from "routes/routes";

import { UserResponseDto } from "utils/swagger_react_query";

import {
  EIABCBox,
  EIAEmailText,
  EIAJobTitle,
  EIALabelText,
  EIALeftSection,
  EIARightSection,
  EIARSBottomContent,
  EIARSTopContent,
  EIAValueText,
  EmployeeInfoAltContainer,
  StyledLink,
  StyledListEmployeeInfo,
  TopRightContainer,
} from "./styles";

interface Props {
  className?: string;
  user?: Partial<UserResponseDto> | null;
  showEditPageLink?: boolean;
  showViewDetailsPageLink?: boolean;
  viewDetailsAltLink?: string;
  bottomRightContent?: ReactNode;
  topRightContent?: ReactNode;
}

const EmployeeInfo: FC<Props> = ({
  className,
  user,
  showEditPageLink,
  showViewDetailsPageLink,
  viewDetailsAltLink,
  bottomRightContent,
  topRightContent,
}) => {
  const translationPrefix = `components.employee_info`;

  const employeeDetailsPageLink =
    viewDetailsAltLink ||
    generatePath(routes.EMPLOYEE_BONUSES_LIST, {
      employeeId: user?.userId || "",
    });
  const employeeEditPageLink = generatePath(routes.EDIT_EMPLOYEE, {
    employeeId: user?.userId || "",
  });

  return (
    <EmployeeInfoAltContainer className={className}>
      <EIALeftSection>
        <StyledListEmployeeInfo
          firstName={user?.firstName}
          lastName={user?.lastName}
          avatar={user?.avatarUrl}
          contentAfterName={user?.jobTitle ? <EIAJobTitle>{user?.jobTitle}</EIAJobTitle> : <></>}
        />
      </EIALeftSection>

      <EIARightSection>
        <EIARSTopContent>
          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.work_email`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-email">{user?.email || "-"}</EIAValueText>
          </EIABCBox>
          <TopRightContainer>{topRightContent}</TopRightContainer>
        </EIARSTopContent>

        <EIARSBottomContent>
          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.employment_type`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-employment-type">
              <Trans i18nKey={`common.worker_employment_type.${user?.employmentType}`} />
            </EIAValueText>
          </EIABCBox>

          <EIABCBox>
            <EIALabelText>
              <Trans i18nKey={`${translationPrefix}.phone_number`} />
            </EIALabelText>
            <EIAValueText data-testid="worker-phone-number">{user?.phone || "-"}</EIAValueText>
          </EIABCBox>

          <EIABCBox>
            {showViewDetailsPageLink && (
              <StyledLink to={employeeDetailsPageLink}>
                <Trans i18nKey={`${translationPrefix}.view_link`} />
              </StyledLink>
            )}
            {showEditPageLink && (
              <StyledLink to={employeeEditPageLink}>
                <Trans i18nKey={`${translationPrefix}.edit_link`} />
              </StyledLink>
            )}
            {!!bottomRightContent && bottomRightContent}
          </EIABCBox>
        </EIARSBottomContent>
      </EIARightSection>
    </EmployeeInfoAltContainer>
  );
};

export default EmployeeInfo;
