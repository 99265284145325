export type EventsData = Record<string, Record<string, any>>;

export class Events<T extends EventsData> {
  private observers: any = [];

  on<K extends keyof T>(event: K, fn: (data: T[K]) => void) {
    this.observers.push([event, fn]);
  }
  off<K extends keyof T>(event: K, fn: (data: T[K]) => void) {
    this.observers = this.observers.filter((subscriber: any) => {
      return subscriber[0] !== event || subscriber[1] !== fn;
    });
  }
  emit<K extends keyof T>(event: K, data: T[K]) {
    this.observers.forEach((subscriber: any) => {
      if (subscriber[0] === event) subscriber[1](data);
    });
  }
}
