import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, useFormikContext } from "formik";

import { SalsaWorkerCategoryEnum } from "types/BETypes";
import SelectOption from "types/SelectOption";
import { MAX_INPUT_LENGTH, MAX_INPUT_LENGTH_LONG } from "constants/form";
import { getFieldError } from "helpers";
import {
  EComprehensibleUserStatuses,
  getComprehensibleStatus,
} from "helpers/employee/userStatusMap";

import { FormSectionTitleSmall, Input, InputContainer, PhoneInput, Select } from "uikit";

import { initialValues } from "./constants";
import { useAddWorkerForm, useFormValues } from "./hooks";
import { IProps, IPropsExtended } from "./types";
import validationSchema, { FormType } from "./validationSchema";
import {
  AddEmployeeButton,
  ButtonsContainer,
  CancelButton,
  Container,
  ContainerInner,
  EmploymentTypeContainer,
  ETContainerInner,
  ETRadioButton,
  FormContainer,
  OvertimeEligibleContainer,
  StyledFormSectionTitle,
} from "./styles";

const AddWorkerForm: FC<IPropsExtended> = (props) => {
  const { className, user } = props;
  const translationPrefix = `people_page.index.add_worker_form`;
  const { overtimeEligibleOptions, handleSubmit, handleCancel } = useAddWorkerForm(props);
  const formikContext = useFormikContext<FormType>();
  const { values, handleChange, setFieldValue } = formikContext;
  const { t } = useTranslation();

  const isEdited = !!user;
  const status = user ? getComprehensibleStatus(user) : undefined;
  const emailIsDisabled = status && status !== EComprehensibleUserStatuses.PROSPECT;
  const employeeTypeIsDisabled = status && status !== EComprehensibleUserStatuses.PROSPECT;

  return (
    <Container className={className}>
      <ContainerInner>
        <FormContainer>
          <StyledFormSectionTitle>
            <Trans
              i18nKey={
                isEdited ? `${translationPrefix}.title_edit` : `${translationPrefix}.title_new`
              }
            />
          </StyledFormSectionTitle>

          <InputContainer>
            <Input
              name="firstName"
              label={<Trans i18nKey={`${translationPrefix}.labels.first_name`} />}
              value={values.firstName}
              onChange={handleChange}
              error={getFieldError("firstName", formikContext, {
                field: t(`${translationPrefix}.labels.first_name`),
                maxInputLength: MAX_INPUT_LENGTH,
              })}
              autoComplete={"first-name"}
              errorDataTestId="error-firstName"
              alwaysShowErrorBlock={true}
            />
          </InputContainer>

          <InputContainer>
            <Input
              name="lastName"
              label={<Trans i18nKey={`${translationPrefix}.labels.last_name`} />}
              value={values.lastName}
              onChange={handleChange}
              error={getFieldError("lastName", formikContext, {
                field: t(`${translationPrefix}.labels.last_name`),
                maxInputLength: MAX_INPUT_LENGTH,
              })}
              autoComplete={"last-name"}
              errorDataTestId="error-lastName"
              alwaysShowErrorBlock={true}
            />
          </InputContainer>

          <InputContainer>
            <Input
              name="email"
              label={<Trans i18nKey={`${translationPrefix}.labels.email`} />}
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.currentTarget.value.trim());
              }}
              disabled={emailIsDisabled}
              error={getFieldError("email", formikContext, {
                field: t(`${translationPrefix}.labels.email`),
                maxInputLength: MAX_INPUT_LENGTH_LONG,
              })}
              errorDataTestId="error-email"
              alwaysShowErrorBlock={true}
            />
          </InputContainer>
          <InputContainer>
            <PhoneInput
              name="phone"
              label={<Trans i18nKey={`${translationPrefix}.labels.phone`} />}
              value={values.phone || ""}
              onChange={(value) => setFieldValue("phone", value)}
              error={getFieldError("phone", formikContext, {
                field: t(`${translationPrefix}.labels.phone`),
                maxInputLength: MAX_INPUT_LENGTH,
              })}
              errorDataTestId="error-phone"
              alwaysShowErrorBlock={true}
              optional
            />
          </InputContainer>

          <EmploymentTypeContainer>
            <FormSectionTitleSmall>
              <Trans i18nKey={`${translationPrefix}.labels.employee_type`} />
            </FormSectionTitleSmall>

            <ETContainerInner>
              <ETRadioButton
                name="employmentType"
                id={SalsaWorkerCategoryEnum.EMPLOYEE}
                value={values.employmentType}
                onChange={() => setFieldValue("employmentType", SalsaWorkerCategoryEnum.EMPLOYEE)}
                label={
                  <Trans
                    i18nKey={`${translationPrefix}.employee_types.${SalsaWorkerCategoryEnum.EMPLOYEE}`}
                  />
                }
                isDisabled={employeeTypeIsDisabled}
              />

              <ETRadioButton
                name="employmentType"
                id={SalsaWorkerCategoryEnum.CONTRACTOR}
                value={values.employmentType}
                onChange={() => setFieldValue("employmentType", SalsaWorkerCategoryEnum.CONTRACTOR)}
                label={
                  <Trans
                    i18nKey={`${translationPrefix}.employee_types.${SalsaWorkerCategoryEnum.CONTRACTOR}`}
                  />
                }
                isDisabled={employeeTypeIsDisabled}
              />
            </ETContainerInner>
          </EmploymentTypeContainer>

          <OvertimeEligibleContainer>
            <FormSectionTitleSmall>
              <Trans i18nKey={`${translationPrefix}.labels.overtime_elligible`} />
            </FormSectionTitleSmall>
            <Select<SelectOption<boolean>>
              name="overtimeEligible"
              //label={<Trans i18nKey={`${translationPrefix}.labels.overtime_elligible`} />}
              options={overtimeEligibleOptions}
              onChange={(option) => {
                setFieldValue("overtimeEligible", (option as SelectOption<boolean>)?.value);
              }}
              value={overtimeEligibleOptions.find(
                (value: SelectOption<boolean>) => value.value === values?.overtimeEligible,
              )}
              alwaysShowErrorBlock={true}
              error={getFieldError("overtimeEligible", formikContext)}
            />
          </OvertimeEligibleContainer>
        </FormContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCancel}>
            <Trans i18nKey={`common.buttons.cancel`} />
          </CancelButton>
          {!!values.employmentType && (
            <AddEmployeeButton onClick={handleSubmit}>
              <Trans
                i18nKey={
                  isEdited
                    ? `${translationPrefix}.edit_worker_btn.${values.employmentType}`
                    : `${translationPrefix}.add_worker_btn.${values.employmentType}`
                }
              />
            </AddEmployeeButton>
          )}
        </ButtonsContainer>
      </ContainerInner>
    </Container>
  );
};

const AddWorkerFormContainer: FC<IProps> = (props) => {
  const [svoc, setSvoc] = useState<boolean>(false);
  const { user } = props;

  const { userToFormValues } = useFormValues();

  const toggleSvoc = () => {
    setSvoc(true);
  };

  const iv = user ? userToFormValues(user) : initialValues;

  return (
    <Formik<FormType>
      onSubmit={() => {}}
      validateOnChange={svoc}
      initialValues={iv}
      validationSchema={validationSchema}
    >
      <AddWorkerForm {...props} _onSubmitAttempt={toggleSvoc} />
    </Formik>
  );
};

export default AddWorkerFormContainer;
