import { useEffect } from "react";

import { AppEvents, TAppEvents } from "services/events/index";

export const useEventsListener = <T extends keyof TAppEvents>(
  name: T,
  action: (data: TAppEvents[T]) => void,
  dependencies: unknown[] = [],
) => {
  useEffect(() => {
    const fn = (data: TAppEvents[T]) => action(data);
    AppEvents.on(name, action);

    return () => AppEvents.off(name, fn);
  }, dependencies);
};
