import { useNavigate } from "react-router";
import routes from "routes/routes";

const useEmployeePayDistributionPage = () => {
  //Constants and external hooks
  const navigate = useNavigate();

  //Methods
  const redirectToBankAccounts = () => {
    navigate(routes.EMPLOYEE_BANK_ACCOUNTS);
  };

  return {
    redirectToBankAccounts,
  };
};

export default useEmployeePayDistributionPage;
