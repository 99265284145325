import { FC } from "react";
import { Formik, FormikProps } from "formik";
import routes from "routes/routes";

import AuthLayout from "layouts/AuthLayout";
import TooManyAttemptsErrorModal from "components/TooManyAttemptsErrorModal";

import { FullScreenLoader } from "uikit";

import { SignInForm, SignInFormType, signInValidationSchema } from "../_shared";
import { authDefaultLeftSectionParams } from "../_shared/constants";
import { useSignInPage } from "./useSignInPage";
import { Container } from "./styles";

const SignInPage: FC = () => {
  const translationPrefix = `auth_pages.shared.sign_in_page`;
  const { metadata, actions, modals, pageLoader } = useSignInPage({
    errorModalsTranslationsPrefix: `auth_pages.shared.error_modals`,
  });

  if (pageLoader) return <FullScreenLoader />;

  return (
    <AuthLayout isLoading={metadata.isLoading} leftSectionParams={authDefaultLeftSectionParams()}>
      <Formik<SignInFormType>
        initialValues={metadata.formInitialValues}
        onSubmit={(values) => actions.handleSubmit(values)}
        validationSchema={signInValidationSchema}
        validateOnChange={metadata.svoc}
      >
        {(formikProps: FormikProps<SignInFormType>) => (
          <Container onSubmit={formikProps.handleSubmit}>
            <SignInForm
              isLoading={metadata.isLoading}
              handleGoogleAuth={actions.handleGoogleAuth}
              translationPrefix={`${translationPrefix}`}
              values={formikProps.values}
              errors={formikProps.errors}
              handleChange={formikProps.handleChange}
              navigationLink={routes.SIGN_UP}
            />
          </Container>
        )}
      </Formik>

      <TooManyAttemptsErrorModal
        isOpen={modals.tooManyAttemptsErrorModalIsOpen}
        onClose={() => modals.setOpenTooManyAttemptsErrorModal(false)}
      />
    </AuthLayout>
  );
};

export default SignInPage;
