export const repaymentLoanRowTranslations = {
  "en-US": {
    one_year: "1 Year Repayment Loan",
    three_year: "3 Year Repayment Loan",
    loan_start_date: "Start Date {{loanStartDate}}",
    interest_rate: "{{interestRate}}% Interest",
    days_remaining: "Days Remaining {{remainingDays}}",
    min_monthly_payment: "Minimum Monthly Payment<br/>",
    next_payment_due_date: "Next Payment Due<br/>",
    remaining_balance: "REMAINING BALANCE<br/>",
    make_payment_btn: "Make a Payment",
    fully_paid_message: "Paid IN FULL",
  },
};

export default repaymentLoanRowTranslations;
