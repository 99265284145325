import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AttentionCircleBorderedIcon, PendingCircledBorderedIcon } from "assets/svg";
import actions from "store/actions";
import { useAppDispatch } from "store/hooks";

import { AppEvents } from "services/events";
import { RewardPointsTransactionStatus, RewardPointsTransactionType } from "types/BETypes";
import { getFormattedSum, showErrorModal } from "helpers";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";
import { EColumnType } from "uikit/Table/types";

import {
  ListRewardPointsTransactionsResDto,
  mutationRewardPointsControllerRedeemRewardPoints,
  queryRewardPointsControllerListTransactions,
  queryUsersControllerGetRewardPoints,
  RewardPointsTransactionDto,
  UserRewardPointsResDto,
} from "utils/swagger_react_query";

import { MINIMUM_REDEEMABLE_POINTS } from "./constants";
import {
  RedeemPointsModalMessage,
  RedeemPointsModalMessageContainer,
  RPMMRow,
  RPMRLabel,
  RPMRowsContainer,
  RPMRValue,
  TablePointsValue,
  TPVTooltip,
  TPVTooltipIconContainer,
} from "./styles";

const useRewardsPage = () => {
  const translationPrefix = `banking_pages.employee.rewards_page`;
  const historyPrefix = `banking_pages.employee.rewards_page.history`;

  const [areTermsAndConditionsOpen, setTermsAndConditionsOpen] = useState<boolean>(false);
  const [rewardsData, setRewardsData] = useState<UserRewardPointsResDto | undefined>(undefined);

  const [isDataLoading, setDataLoading] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isTransactionListLoading, setTransactionListLoading] = useState<boolean>(false);
  const [transactionsData, setTransactionsData] = useState<
    ListRewardPointsTransactionsResDto | undefined
  >(undefined);
  const transactionsPerPage = 10;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const renderTransactionTooltip = (data: RewardPointsTransactionDto) => {
    return (
      <TPVTooltip
        id={`history_item_tooltip_${data.rewardPointsTransactionId}`}
        tooltipContent={<Trans i18nKey={`${historyPrefix}.type_tooltips.${data.status}`} />}
        scrollHide={false}
        resizeHide={false}
      >
        <TPVTooltipIconContainer className={`${data.type} ${data.status}`}>
          {data.status === RewardPointsTransactionStatus.PENDING && <PendingCircledBorderedIcon />}
          {data.status === RewardPointsTransactionStatus.CANCELED && (
            <AttentionCircleBorderedIcon />
          )}
        </TPVTooltipIconContainer>
      </TPVTooltip>
    );
  };

  const transactionsColumns = [
    {
      key: "createdAt",
      className: "createdAt",
      title: t(`${historyPrefix}.columns.date`),
      type: EColumnType.Date,
    },
    {
      key: "type",
      className: "type",
      title: t(`${historyPrefix}.columns.type`),
      type: EColumnType.Component,
      component: (row: RewardPointsTransactionDto) => {
        return <Trans i18nKey={`${historyPrefix}.type_labels.${row.type}`} />;
      },
    },
    {
      key: "amount",
      className: "amount",
      title: t(`${historyPrefix}.columns.amount`),
      type: EColumnType.Component,
      component: (row: RewardPointsTransactionDto) => {
        return <>{getFormattedSum(row.amount / 100)}</>;
      },
    },
    {
      key: "points",
      className: "points",
      title: t(`${historyPrefix}.columns.points`),
      type: EColumnType.Component,
      component: (row: RewardPointsTransactionDto) => {
        return (
          <TablePointsValue className={`${row.type} ${row.status}`}>
            {row.type === RewardPointsTransactionType.REDEMPTION ? "-" : "+"}
            {row.points}
            {(row.status === RewardPointsTransactionStatus.PENDING ||
              row.status === RewardPointsTransactionStatus.CANCELED) && (
              <>{renderTransactionTooltip(row)}</>
            )}
          </TablePointsValue>
        );
      },
    },
  ];

  //Hooks

  useEffect(() => {
    fetchData();
  }, []);

  //Methods
  const fetchRewardPoints = async () => {
    try {
      setLoading(true);
      const result = await queryUsersControllerGetRewardPoints();
      dispatch(actions.userMetaData.setUserRewardPoints(result || null));
      setRewardsData(result);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransactions = async (page: number) => {
    try {
      setTransactionListLoading(true);
      const result = await queryRewardPointsControllerListTransactions({
        page: (page || transactionsData?.page || 1).toString(),
        perPage: transactionsPerPage.toString(),
      });
      setTransactionsData(result);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setTransactionListLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      await fetchRewardPoints();
      await fetchTransactions(transactionsData?.page || 1);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleTransactionsPageChange = async (page: number) => {
    fetchTransactions(page);
  };

  const handleRedeemPoints = () => {
    if ((rewardsData?.pointsAmount || 0) < MINIMUM_REDEEMABLE_POINTS) {
      showInsufficientPointsModal();
    } else {
      showRedeemPointsModal();
    }
  };

  //Modals
  const handleRedeemPointsModalConfirm = async () => {
    try {
      setLoading(true);
      await mutationRewardPointsControllerRedeemRewardPoints()();
      showRedeemSuccessModal();
    } catch (error) {
      console.log("Error", error);
      if ((error as any)?.data?.error === "NOT_ENOUGH_REWARD_POINTS") {
        showInsufficientPointsModal();
        return;
      }
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRedeemPointsSuccessModalClose = () => {
    fetchData();
  };

  const showRedeemPointsModal = () => {
    const _translationPrefix = `${translationPrefix}.modals.redeem_points`;

    AppEvents.emit("SetGlobalModal", {
      className: "rewards-redeem-points-modal",
      isOpen: true,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: (
        <>
          <RedeemPointsModalMessageContainer>
            <RedeemPointsModalMessage>
              <Trans i18nKey={`${_translationPrefix}.message`} />
            </RedeemPointsModalMessage>

            <RPMRowsContainer>
              <RPMMRow>
                <RPMRLabel>
                  <Trans i18nKey={`${_translationPrefix}.points_redeemed_label`} />
                </RPMRLabel>
                <RPMRValue>
                  {getFormattedSum((rewardsData?.dollarsAmount || 0) * 10, { symbol: "", precision: 0 })}
                </RPMRValue>
              </RPMMRow>
              <RPMMRow>
                <RPMRLabel>
                  <Trans i18nKey={`${_translationPrefix}.amount_label`} />
                </RPMRLabel>
                <RPMRValue>{getFormattedSum((rewardsData?.dollarsAmount || 0) / 100)}</RPMRValue>
              </RPMMRow>
            </RPMRowsContainer>
          </RedeemPointsModalMessageContainer>
        </>
      ),
      mainButton: {
        text: <Trans i18nKey={`buttons.confirm`} />,
        onClick: handleRedeemPointsModalConfirm,
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
    });
  };

  const showRedeemSuccessModal = () => {
    const _translationPrefix = `${translationPrefix}.modals.redeem_points_success`;

    AppEvents.emit("SetGlobalModal", {
      type: EModalTypes.SUCCESS,
      className: "rewards-redeem-success-modal",
      isOpen: true,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`buttons.done`} />,
        autoWidth: true,
      },
      onClose: handleRedeemPointsSuccessModalClose,
    });
  };

  const showInsufficientPointsModal = () => {
    const _translationPrefix = `${translationPrefix}.modals.redeem_points_insufficient_amount`;

    AppEvents.emit("SetGlobalModal", {
      type: EModalTypes.WARNING,
      className: "rewards-insufficient-points-modal",
      isOpen: true,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`buttons.done`} />,
        autoWidth: true,
      },
    });
  };

  return {
    isLoading,
    isDataLoading,
    isTransactionListLoading,
    rewardsData,
    transactionsData,
    transactionsPerPage,
    transactionsColumns,
    areTermsAndConditionsOpen,
    setTermsAndConditionsOpen,
    handleRedeemPoints,
    handleTransactionsPageChange,
  };
};

export default useRewardsPage;
