import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import Select, { Props as SelectProps, StylesConfig as SelectStylesConfig } from "react-select";
import { ChevronBottom } from "assets/svg";
import { theme } from "styles/theme";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import {
  Container,
  ErrorContainer,
  InputBase,
  InputDescription,
  InputWrapper,
  NumberDropdownGroupContainer,
  SelectWrapper,
} from "./styles";

export type InputProps = {
  onChange?: (value: string) => void;
  value?: string;
} & NumberFormatProps;

export type DropdownProps<OptionType> = SelectProps<OptionType>;

export type Props<OptionType> = {
  className?: string;
  name?: string;
  label?: ReactNode | string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  optional?: boolean;

  inputProps: InputProps;
  dropdownProps: DropdownProps<OptionType>;
} & BaseInputProps;

function NumberDropdownGroup<OptionType>({
  className,
  id,
  label,
  error,
  alwaysShowErrorBlock = true,
  optional = false,
  disabled,
  description,
  descriptionPosition,
  errorDataTestId,
  inputProps,
  dropdownProps,
}: React.HTMLAttributes<HTMLInputElement> & Props<OptionType>) {
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isDropdownFocused, setIsDropdownFocused] = useState(false);

  const renderInput = () => {
    const { value, onChange, placeholder = "#", ...rest } = inputProps;

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(true);
      inputProps.onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(false);
      inputProps.onBlur?.(e);
    };

    return (
      <InputWrapper>
        <NumberFormat
          thousandSeparator={false}
          allowLeadingZeros={false}
          allowNegative={false}
          allowEmptyFormatting={true}
          customInput={InputBase}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onValueChange={({ value }) => onChange?.(value)}
          placeholder={placeholder}
          value={value}
          {...rest}
        />
      </InputWrapper>
    );
  };

  const renderDropdown = () => {
    const { value, styles, placeholder, isSearchable = false, ...rest } = dropdownProps;

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsDropdownFocused(true);
      dropdownProps.onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsDropdownFocused(false);
      dropdownProps.onBlur?.(e);
    };

    const colorStyles: SelectStylesConfig<any> = {
      option: (styles, { isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? theme.colors.activeOptionBg : theme.colors.optionBg,
          color: "inherit",
        };
      },
    };

    return (
      <SelectWrapper disabled={disabled}>
        <Select
          isSearchable={isSearchable}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          styles={colorStyles}
          placeholder={placeholder}
          inputId={id || name || undefined}
          components={{
            DropdownIndicator: () => <ChevronBottom />,
          }}
          {...rest}
        />
        {renderLabel(label, optional)}
      </SelectWrapper>
    );
  };

  return (
    <Container className={`number-input ${className} ${optional ? "optional" : ""}`}>
      <NumberDropdownGroupContainer>
        {renderInput()}
        {renderDropdown()}
      </NumberDropdownGroupContainer>
      {!!description && (
        <InputDescription className={`${descriptionPosition}`}>{description}</InputDescription>
      )}
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={errorDataTestId}>{error} </ErrorContainer>
      )}
    </Container>
  );
}

export default NumberDropdownGroup;
