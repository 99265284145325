import { FC, useEffect, useState } from "react";
import { Trans } from "react-i18next";

import WorkersList from "components/WorkersWidgets/WorkersList";
import { ETableVariants } from "components/WorkersWidgets/WorkersList/types";

import { PageTitle } from "uikit";

import { UserShortResponseDto } from "utils/swagger_react_query";

import { BCLeft, BCRight, ButtonsContainer, ContinueButton } from "../styles";
import { Container, WorkersListContainer } from "./styles";

interface Props {
  className?: string;
  onContinue: () => void;
  onContinueDisabledChange: (isDisabled: boolean) => void;
}

const SetupPayroll: FC<Props> = ({ className, onContinue, onContinueDisabledChange }) => {
  const translationPrefix = `admin_setup_page.steps.add_workers`;

  const [isContinueDisabled, setContinueDisabled] = useState<boolean>(true);

  const handleListChange = (workers: UserShortResponseDto[]) => {
    const _isContinueDisabled = !workers.length;
    setContinueDisabled(_isContinueDisabled);
    onContinueDisabledChange(_isContinueDisabled);
  };

  return (
    <Container className={className}>
      <PageTitle>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </PageTitle>

      <WorkersListContainer>
        <WorkersList
          perPage={10}
          onListChange={handleListChange}
          type={ETableVariants.ONBOARDING_LIST}
        />
      </WorkersListContainer>

      <ButtonsContainer>
        <BCLeft></BCLeft>
        <BCRight>
          <ContinueButton disabled={!!isContinueDisabled} onClick={onContinue}>
            <Trans i18nKey={`${translationPrefix}.continue_button`} />
          </ContinueButton>
        </BCRight>
      </ButtonsContainer>
    </Container>
  );
};

export default SetupPayroll;
