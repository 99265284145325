import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { GmailIcon, OutlookIcon, YahooIcon } from "assets/svg";

import { MailAppButton, Wrapper } from "./styles";

interface IMailAppsConfig {
  title: string;
  icon: ReactNode;
  url: string;
}

interface IProps {
  className?: string;
}

const MailAppButtons: FC<IProps> = ({ className }) => {
  const translationPrefix = "auth_pages.shared.check_email_page";

  const mailAppsConfig: IMailAppsConfig[] = [
    {
      title: `${translationPrefix}.open_gmail`,
      icon: <GmailIcon />,
      url: "https://mail.google.com/",
    },
    {
      title: `${translationPrefix}.open_yahoo`,
      icon: <YahooIcon />,
      url: "https://mail.yahoo.com",
    },
    {
      title: `${translationPrefix}.open_outlook`,
      icon: <OutlookIcon />,
      url: "https://outlook.office365.com ",
    },
  ];

  return (
    <Wrapper className={className}>
      {mailAppsConfig.map((it) => (
        <MailAppButton key={it.title} onClick={() => window.open(it.url)}>
          {it.icon}
          <span>
            <Trans i18nKey={it.title} />
          </span>
        </MailAppButton>
      ))}
    </Wrapper>
  );
};

export default MailAppButtons;
