import { useTranslation } from "react-i18next";
import { generatePath, useMatches, useParams } from "react-router-dom";
import { StringMap, TOptions } from "i18next";
import useRouterConfig from "routes/router";
import { IRouterConfigItem } from "routes/types";

export interface IBreadcrumb {
  id: string;
  title: string;
  link?: string;
}

const useRouteProperties = () => {
  const { routerConfig } = useRouterConfig();
  const matches = useMatches();
  const urlParams = useParams();
  const { t } = useTranslation();

  const getRouteConfigByMatch = (pathname: string) => {
    if (!pathname) return undefined;
    return routerConfig.find((item) => {
      const pathWithParams = Object.keys(urlParams)?.length
        ? generatePath(item.path, urlParams as any)
        : item.path;
      return pathWithParams === pathname;
    });
  };

  const getCurrentRouteConfig = () => {
    return getRouteConfigByMatch(matches?.[0]?.pathname);
  };

  const getRouteBreadcrumbs = (route: IRouterConfigItem, values?: TOptions<StringMap>) => {
    const routeBreadcrumbs = route?.breadcrumbs || [];

    const result: IBreadcrumb[] = (routeBreadcrumbs || []).map((bcPath) => {
      const _route = routerConfig.find((item) => item.path === bcPath);
      return {
        id: _route?.path || "",
        title: _route?.title ? t(_route?.title, values) : "",
        link: _route?.path,
      };
    });

    return result;
  };

  const getBreadcrumbsChainForCurrentRoute = (values?: TOptions<StringMap>) => {
    const currentRoute = getCurrentRouteConfig();
    if (!currentRoute) {
      return [];
    }

    return getRouteBreadcrumbs(currentRoute, values);
  };

  return {
    routerConfig,
    matches,
    getRouteConfigByMatch,
    getCurrentRouteConfig,
    getRouteBreadcrumbs,
    getBreadcrumbsChainForCurrentRoute,
  };
};

export default useRouteProperties;
