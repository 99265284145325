import { FC } from "react";
import { Trans } from "react-i18next";

import { HELP_CENTER } from "constants/shared";
import {
  Description,
  Footer,
  FooterLinkExternal,
  FormItem,
  StyledPrimaryBtn,
  Title,
} from "pages/Auth/_shared/components/styles";
import DefaultMFAInput from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/components/DefaultInput/DefaultInput";

import { MfaFactorDto } from "utils/swagger_react_query";

import { Container } from "./styles";

interface IProps {
  factor?: MfaFactorDto;
  onResend?: (resetTimerCallback: () => void) => void;
}

const SignInMfaForm: FC<IProps> = ({ factor, onResend }) => {
  const translationPrefix = `auth_pages.shared.sign_in_mfa_page`;

  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>
      <Description>
        <Trans
          i18nKey={`${translationPrefix}.description`}
          values={{
            phoneLast4Digits: factor?.phoneNumber?.slice(-4) || "",
          }}
        />
      </Description>

      <FormItem>
        <DefaultMFAInput onResend={onResend} showInputLabel={false} />
      </FormItem>

      <StyledPrimaryBtn type={"submit"} data-testid={"sign-in-mfa-page-submit-button"}>
        <Trans i18nKey={`${translationPrefix}.verify_button`} />
      </StyledPrimaryBtn>

      <Footer>
        <FooterLinkExternal href={HELP_CENTER} target="_blank">
          <Trans i18nKey={`buttons.need_help_btn`} />
        </FooterLinkExternal>
      </Footer>
    </Container>
  );
};

export default SignInMfaForm;
