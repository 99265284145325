import styled from "styled-components";

import { EModalTypes } from "uikit/Modal/types";

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainerBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 50%;
`;

export const IconContainerOuter = styled(IconContainerBase)`
  width: 88px;
  height: 88px;

  &.${EModalTypes.SUCCESS} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSuccess}14`};
  }
  &.${EModalTypes.WARNING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgWarning}14`};
  }
  &.${EModalTypes.ERROR} {
    background: ${({ theme }) => `${theme.colors.modalIconBgError}14`};
  }
  &.${EModalTypes.CONFIRM} {
    background: ${({ theme }) => `${theme.colors.modalIconBgConfirm}14`};
  }
  &.${EModalTypes.CARD} {
    background: ${({ theme }) => `${theme.colors.modalIconBgCard}14`};
  }
  &.${EModalTypes.DELETE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgDelete}14`};
  }
  &.${EModalTypes.SHARE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgShare}14`};
  }
  &.${EModalTypes.REFRESH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgRefresh}14`};
  }
  &.${EModalTypes.SWITCH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSwitch}14`};
  }
  &.${EModalTypes.USER} {
    background: ${({ theme }) => `${theme.colors.modalIconBgUser}14`};
  }
`;

export const IconContainerMiddle = styled(IconContainerBase)`
  width: 72px;
  height: 72px;

  &.${EModalTypes.SUCCESS} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSuccess}29`};
  }
  &.${EModalTypes.WARNING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgWarning}29`};
  }
  &.${EModalTypes.ERROR} {
    background: ${({ theme }) => `${theme.colors.modalIconBgError}29`};
  }
  &.${EModalTypes.CONFIRM} {
    background: ${({ theme }) => `${theme.colors.modalIconBgConfirm}29`};
  }
  &.${EModalTypes.CARD} {
    background: ${({ theme }) => `${theme.colors.modalIconBgCard}29`};
  }
  &.${EModalTypes.DELETE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgDelete}29`};
  }
  &.${EModalTypes.SHARE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgShare}29`};
  }
  &.${EModalTypes.REFRESH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgRefresh}29`};
  }
  &.${EModalTypes.SWITCH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSwitch}29`};
  }
  &.${EModalTypes.PENDING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgPending}29`};
  }
  &.${EModalTypes.USER} {
    background: ${({ theme }) => `${theme.colors.modalIconBgUser}29`};
  }
`;
export const IconContainerInner = styled(IconContainerBase)`
  width: 56px;
  height: 56px;
  padding: 16px;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.modalIconFill};
    }
  }

  &.${EModalTypes.SUCCESS} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSuccess}`};
  }
  &.${EModalTypes.WARNING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgWarning}`};
  }
  &.${EModalTypes.ERROR} {
    background: ${({ theme }) => `${theme.colors.modalIconBgError}`};
  }
  &.${EModalTypes.CONFIRM} {
    background: ${({ theme }) => `${theme.colors.modalIconBgConfirm}`};
  }
  &.${EModalTypes.CARD} {
    background: ${({ theme }) => `${theme.colors.modalIconBgCard}`};
  }
  &.${EModalTypes.DELETE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgDelete}`};
  }
  &.${EModalTypes.SHARE} {
    background: ${({ theme }) => `${theme.colors.modalIconBgShare}`};
  }
  &.${EModalTypes.REFRESH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgRefresh}`};
  }
  &.${EModalTypes.SWITCH} {
    background: ${({ theme }) => `${theme.colors.modalIconBgSwitch}`};
  }
  &.${EModalTypes.PENDING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgPending}`};
  }
  &.${EModalTypes.USER} {
    background: ${({ theme }) => `${theme.colors.modalIconBgUser}`};
  }
`;

//Modal Status Title
export const TitleContainer = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};

  &.${EModalTypes.SUCCESS} {
    color: ${({ theme }) => `${theme.colors.modalIconBgSuccess}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgSuccess}14`};
  }
  &.${EModalTypes.WARNING} {
    color: ${({ theme }) => `${theme.colors.modalIconBgWarning}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgWarning}14`};
  }
  &.${EModalTypes.ERROR} {
    color: ${({ theme }) => `${theme.colors.modalIconBgError}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgError}14`};
  }
  &.${EModalTypes.CONFIRM} {
    color: ${({ theme }) => `${theme.colors.modalIconBgConfirm}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgConfirm}14`};
  }
  &.${EModalTypes.CARD} {
    color: ${({ theme }) => `${theme.colors.modalIconBgCard}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgCard}14`};
  }
  &.${EModalTypes.DELETE} {
    color: ${({ theme }) => `${theme.colors.modalIconBgDelete}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgDelete}14`};
  }
  &.${EModalTypes.SHARE} {
    color: ${({ theme }) => `${theme.colors.modalIconBgShare}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgShare}14`};
  }
  &.${EModalTypes.REFRESH} {
    color: ${({ theme }) => `${theme.colors.modalIconBgRefresh}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgRefresh}14`};
  }
  &.${EModalTypes.SWITCH} {
    color: ${({ theme }) => `${theme.colors.modalIconBgSwitch}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgSwitch}29`};
  }
  &.${EModalTypes.PENDING} {
    background: ${({ theme }) => `${theme.colors.modalIconBgPending}14`};
  }
  &.${EModalTypes.USER} {
    color: ${({ theme }) => `${theme.colors.modalIconBgUser}`};
    background: ${({ theme }) => `${theme.colors.modalIconBgUser}14`};
  }
`;
