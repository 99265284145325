export const employeeInfo = {
  "en-US": {
    work_email: "Email Address",
    id: "Employee ID",
    department: "Department",
    employment_type: "Employment Type",
    phone_number: "Phone Number",
    started_at: "Employee Start Date",
    mobile_start_date: "Start Date: {{date}}",
    edit_link: "Edit Worker",
    view_link: "View details",
  },
};

export default employeeInfo;
