import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  /* height: 46px; */

  ${breakpoint("xs", "md")`
    height: 36px;
  `}
`;

export const TILabel = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  user-select: none;
  line-height: 44px;

  &.inactive {
    z-index: 0;
    cursor: pointer;
    border-bottom: 2px solid ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabled};

    ${TILabel} {
      color: ${({ theme }) => theme.colors.disabled};
    }
  }
`;
