import React, { FC } from "react";

import IconProps from "./IconProps";

const CloseRoundIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#C8C8C8" />
    <line x1="6.90336" y1="6.90279" x2="16.0957" y2="16.0952" stroke="#C8C8C8" />
    <line x1="16.0957" y1="6.90287" x2="6.90335" y2="16.0953" stroke="#C8C8C8" />
  </svg>
);

export default CloseRoundIcon;
