import { FC, ReactNode } from "react";
import UserIcon from "assets/svg/UserIcon";

import Avatar from "components/Avatar";

import {
  AvatarPlaceholder,
  AvatarWrapper,
  ContentAfterNameContainer,
  ContentWrapper,
  LeftSection,
  NameWrapper,
  Wrapper,
} from "./styles";

interface IProps {
  id?: string;
  avatar?: any;
  firstName?: string;
  lastName?: string;
  onClick?: () => void;
  className?: string;
  contentAfterName?: ReactNode;
  contentAfterAvatar?: ReactNode;
}

export const ListEmployeeInfo: FC<IProps> = (props) => {
  const needShowAvatarPlaceholder = !props.avatar && !props.firstName && !props.lastName;

  return (
    <Wrapper className={props.className} onClick={props.onClick} clickable={!!props.onClick}>
      <LeftSection>
        <AvatarWrapper data-testid="worker-avatar">
          {needShowAvatarPlaceholder ? (
            <AvatarPlaceholder>
              <UserIcon />
            </AvatarPlaceholder>
          ) : (
            <Avatar firstName={props.firstName} lastName={props.lastName} src={props?.avatar} />
          )}
        </AvatarWrapper>
        {props.contentAfterAvatar}
      </LeftSection>
      <ContentWrapper>
        {props.firstName && props.lastName ? (
          <NameWrapper>
            <span data-testid="worker-first-name">{props.firstName}</span>{" "}
            <span data-testid="worker-last-name">{props.lastName}</span>
          </NameWrapper>
        ) : (
          <p>-</p>
        )}
        {!!props.contentAfterName && (
          <ContentAfterNameContainer>{props.contentAfterName}</ContentAfterNameContainer>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ListEmployeeInfo;
