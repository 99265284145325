import { FC } from "react";

import IconProps from "./IconProps";

const LogoFullAlt: FC<IconProps> = ({ className }) => (
  <svg
    width="120"
    height="53"
    viewBox="0 0 120 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect y="0.899902" width="120" height="51.2" rx="25.6" fill="white" />
    <g clipPath="url(#clip0_5057_9427)">
      <path
        d="M86.6982 34.4539C86.0505 34.908 85.3423 35.2678 84.5651 35.5333C83.7965 35.7989 83.0366 35.9274 82.3025 35.9274C81.1799 35.9274 80.1436 35.679 79.185 35.1735C78.2264 34.6767 77.1729 33.9057 76.482 33.0319C75.0485 31.2072 72.8809 25.1935 77.0692 20.4219C78.1142 19.2312 79.0641 18.6058 80.2386 18.0576C81.413 17.5093 82.6739 17.2352 84.0124 17.2352C85.066 17.2352 85.9555 17.3979 86.6982 17.7149V10.3991L92.0006 8.8999V35.5076H86.6982V34.4454V34.4539ZM86.6982 21.5613C86.6982 20.9959 86.6118 20.4648 86.4305 19.9936C86.2491 19.5139 86.0159 19.1027 85.7223 18.76C85.4287 18.4173 85.1005 18.1518 84.7292 17.9548C84.3579 17.7577 83.9952 17.6635 83.6411 17.6635C83.1488 17.6635 82.6825 17.8263 82.2507 18.1432C81.8103 18.4602 81.4303 18.9056 81.1108 19.4796C80.7826 20.045 80.5322 20.7218 80.3422 21.4927C80.1522 22.2637 80.0572 24.868 80.0572 25.776C80.0572 26.7783 80.1695 29.4853 80.394 30.3848C80.6185 31.2843 80.9122 32.0724 81.2835 32.7578C81.6548 33.4431 82.0866 33.9914 82.5962 34.394C83.097 34.8052 83.6411 35.0108 84.2283 35.0108C84.6342 35.0108 85.066 34.8908 85.541 34.651C86.0073 34.4111 86.4045 34.1027 86.7154 33.7087V21.5527L86.6982 21.5613Z"
        fill="#0A252E"
      />
      <path
        d="M33.3024 18.5545C33.9501 18.1005 34.6582 17.7407 35.4354 17.4751C36.204 17.2096 36.964 17.0811 37.698 17.0811C38.8207 17.0811 39.857 17.3295 40.8155 17.8349C41.7741 18.3318 42.8277 19.1028 43.5186 19.9766C44.9521 21.8012 47.1197 27.815 42.9313 32.5865C41.8864 33.7773 40.9364 34.4026 39.762 34.9509C38.5875 35.4992 37.3267 35.7733 35.9881 35.7733C34.9346 35.7733 34.0451 35.6105 33.3024 35.2936V42.6008L28 44.1V17.4922H33.3024V18.5545ZM33.3024 31.4472C33.3024 32.0126 33.3887 32.5437 33.5701 33.0149C33.7515 33.4946 33.9846 33.9058 34.2782 34.2484C34.5719 34.5911 34.9 34.8567 35.2714 35.0537C35.6427 35.2507 36.0054 35.345 36.3595 35.345C36.8517 35.345 37.318 35.1822 37.7498 34.8652C38.1903 34.5483 38.5702 34.1028 38.8898 33.5289C39.2179 32.9635 39.4684 32.2867 39.6583 31.5157C39.8483 30.7447 39.9433 28.1405 39.9433 27.2324C39.9433 26.2301 39.8311 23.5231 39.6065 22.6236C39.382 21.7241 39.0884 20.936 38.717 20.2507C38.3457 19.5654 37.9139 19.0171 37.4044 18.6145C36.9035 18.2033 36.3595 17.9977 35.7722 17.9977C35.3663 17.9977 34.9346 18.1176 34.4596 18.3575C33.9933 18.5973 33.596 18.9057 33.2851 19.2998V31.4557L33.3024 31.4472Z"
        fill="#0A252E"
      />
      <path
        d="M66.4126 12.2843C66.4126 11.5562 66.6889 10.9308 67.2503 10.4082C67.8116 9.88568 68.4766 9.62012 69.2624 9.62012C70.0483 9.62012 70.7478 9.88568 71.3091 10.4082C71.8704 10.9308 72.1468 11.5562 72.1468 12.2843C72.1468 13.0125 71.8704 13.6378 71.3091 14.1433C70.7478 14.6573 70.0655 14.9143 69.2624 14.9143C68.4593 14.9143 67.8116 14.6573 67.2503 14.1433C66.6889 13.6293 66.4126 13.0125 66.4126 12.2843Z"
        fill="#EE3824"
      />
      <path d="M66.6533 18.9391L71.9557 17.4399V35.8837H66.6533V18.9391Z" fill="#5ABBBB" />
      <path
        d="M56.8002 24.834C56.8002 24.834 53.199 24.8683 50.0038 26.6159C49.097 27.1127 48.3975 27.7895 47.8362 28.5262C47.2749 29.2629 46.9985 30.1453 46.9985 31.1733C46.9985 31.8329 47.1194 32.4497 47.3699 33.0151C47.6117 33.5805 47.9657 34.0773 48.4062 34.48C48.8552 34.8912 49.4079 35.2167 50.0642 35.4566C50.7206 35.6964 51.4546 35.8164 52.2664 35.8164C52.8709 35.8164 53.389 35.7735 53.8295 35.6793C54.2613 35.5851 54.6499 35.4651 54.9867 35.3024C55.3235 35.1396 55.6343 34.9511 55.928 34.737C56.2216 34.5228 56.5066 34.2829 56.8002 34.0345C56.8002 34.0345 52.4391 34.8912 52.37 30.3081C52.3182 26.8215 56.8002 24.8169 56.8002 24.8169V24.834Z"
        fill="#5ABBBB"
      />
      <path
        d="M51.2823 23.2921C52.799 23.2921 54.0285 22.0724 54.0285 20.5679C54.0285 19.0634 52.799 17.8438 51.2823 17.8438C49.7656 17.8438 48.5361 19.0634 48.5361 20.5679C48.5361 22.0724 49.7656 23.2921 51.2823 23.2921Z"
        fill="#5ABBBB"
      />
      <path
        d="M61.4465 18.4855C60.3065 17.6117 58.4671 17.1748 55.9455 17.1748C54.9178 17.1748 53.9333 17.2862 53.0093 17.5003C56.8004 17.5003 57.6899 20.5415 57.8108 21.9207C57.8108 22.0235 57.8281 22.1177 57.8281 22.2291V35.4815H63.165V22.0577C63.165 20.5586 62.595 19.3678 61.4551 18.4855H61.4465Z"
        fill="#5ABBBB"
      />
    </g>
    <defs>
      <clipPath id="clip0_5057_9427">
        <rect width="64" height="35.2" fill="white" transform="translate(28 8.8999)" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoFullAlt;
