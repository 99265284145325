import { FC, useCallback, useEffect } from "react";

import { AppEvents } from "services/events";
import { signInErrors } from "constants/auth";
import useAuth from "hooks/useAuth";

import { FAST_LOGOUT_KEY, LOCAL_STORAGE_KEY } from "./constants";

interface Props {
  logoutTimer?: number;
}

const ActivityTracker: FC<Props> = ({
  logoutTimer = localStorage.getItem(FAST_LOGOUT_KEY)
    ? 10000
    : Number(process.env.REACT_APP_INACTIVITY_TIMER_MINUTES || 5000) * 60 * 1000,
}) => {
  let logoutInterval: ReturnType<typeof setInterval> | undefined = undefined;
  const { logout } = useAuth();

  const forceLogout = (logoutParams?: string) => {
    AppEvents.emit("PromptsWereDisabled", { promptsWereDisabled: true });
    logout(true, true, logoutParams);
    AppEvents.emit("PromptsWereDisabled", { promptsWereDisabled: false });
  };

  const logoutOnStorageChange = useCallback((event: StorageEvent) => {
    const { key, newValue, oldValue } = event;
    if (key === LOCAL_STORAGE_KEY && !newValue && !oldValue) {
      cleanUp();
      forceLogout(signInErrors.SESSION_HAS_EXPIRED);
    }
  }, []);

  const checkIfShouldLogout = (initialCheck: boolean = false): boolean => {
    const logoutTime = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY) || "0", 10);
    const currentTime = Date.now();
    if (logoutTime && logoutTime < Date.now()) {
      logoutInterval && window.clearInterval(logoutInterval);
      cleanUp();
      forceLogout(initialCheck ? undefined : signInErrors.SESSION_HAS_EXPIRED);
      return true;
    }
    return false;
  };

  const resetTimeout = useCallback(() => {
    logoutInterval && window.clearInterval(logoutInterval);
    localStorage.setItem(LOCAL_STORAGE_KEY, (Date.now() + logoutTimer).toString());
    logoutInterval = setInterval(checkIfShouldLogout, 1000);
  }, [logoutInterval]);

  const setListeners = () => {
    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("scroll", resetTimeout);
    window.addEventListener("keydown", resetTimeout);
    window.addEventListener("click", resetTimeout);

    window.addEventListener("storage", logoutOnStorageChange);
  };

  const cleanUp = () => {
    logoutInterval && window.clearInterval(logoutInterval);
    window.removeEventListener("mousemove", resetTimeout);
    window.removeEventListener("scroll", resetTimeout);
    window.removeEventListener("keydown", resetTimeout);
    window.removeEventListener("click", resetTimeout);

    window.removeEventListener("storage", logoutOnStorageChange);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  useEffect(() => {
    const shouldInitiallyLogout = checkIfShouldLogout(true);
    if (shouldInitiallyLogout) return;
    resetTimeout();
    setListeners();
    return () => {
      cleanUp();
    };
  }, []);

  return <></>;
};

export default ActivityTracker;
