import { FC } from "react";

import IconProps from "./IconProps";

const LogoFull: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="80"
    height="44"
    viewBox="0 0 80 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3427_9105)">
      <path
        d="M73.372 31.3182C72.5624 31.8746 71.6772 32.3155 70.7057 32.641C69.7449 32.9664 68.795 33.1239 67.8774 33.1239C66.4741 33.1239 65.1787 32.8194 63.9805 32.2C62.7823 31.5911 61.4653 30.6463 60.6018 29.5755C58.8098 27.3394 56.1003 19.9696 61.3358 14.1221C62.642 12.6628 63.8294 11.8965 65.2975 11.2246C66.7656 10.5527 68.3416 10.2167 70.0148 10.2167C71.3318 10.2167 72.4436 10.4162 73.372 10.8046V1.83914L80 0.00195312V32.6095H73.372V31.3077V31.3182ZM73.372 15.5183C73.372 14.8255 73.264 14.1746 73.0373 13.5972C72.8106 13.0093 72.5192 12.5054 72.1522 12.0854C71.7851 11.6655 71.3749 11.34 70.9108 11.0986C70.4466 10.8571 69.9932 10.7416 69.5506 10.7416C68.9353 10.7416 68.3524 10.9411 67.8127 11.3295C67.2621 11.718 66.7872 12.2639 66.3878 12.9673C65.9776 13.6602 65.6645 14.4895 65.427 15.4344C65.1895 16.3792 65.0708 19.5707 65.0708 20.6835C65.0708 21.9118 65.2111 25.2292 65.4918 26.3315C65.7725 27.4338 66.1395 28.3997 66.6037 29.2395C67.0678 30.0794 67.6076 30.7513 68.2445 31.2447C68.8706 31.7486 69.5506 32.0006 70.2847 32.0006C70.792 32.0006 71.3318 31.8536 71.9255 31.5596C72.5084 31.2657 73.0049 30.8878 73.3936 30.4048V15.5078L73.372 15.5183Z"
        fill="#0A252E"
      />
      <path
        d="M6.62799 11.8351C7.43759 11.2786 8.32276 10.8377 9.29429 10.5123C10.255 10.1868 11.205 10.0294 12.1225 10.0294C13.5258 10.0294 14.8212 10.3338 16.0194 10.9532C17.2176 11.5621 18.5346 12.5069 19.3982 13.5778C21.1901 15.8139 23.8996 23.1836 18.6641 29.0312C17.358 30.4904 16.1706 31.2568 14.7025 31.9287C13.2344 32.6006 11.6583 32.9365 9.98516 32.9365C8.6682 32.9365 7.55633 32.737 6.62799 32.3486V41.3036L0 43.1408V10.5333H6.62799V11.8351ZM6.62799 27.6349C6.62799 28.3278 6.73593 28.9787 6.96262 29.5561C7.18931 30.144 7.48077 30.6479 7.84779 31.0678C8.21482 31.4877 8.62502 31.8132 9.08919 32.0547C9.55337 32.2961 10.0067 32.4116 10.4493 32.4116C11.0646 32.4116 11.6476 32.2121 12.1873 31.8237C12.7378 31.4353 13.2128 30.8893 13.6122 30.186C14.0224 29.4931 14.3354 28.6637 14.5729 27.7189C14.8104 26.774 14.9292 23.5826 14.9292 22.4698C14.9292 21.2415 14.7888 17.924 14.5082 16.8217C14.2275 15.7194 13.8605 14.7536 13.3963 13.9137C12.9321 13.0738 12.3924 12.402 11.7555 11.9085C11.1294 11.4046 10.4493 11.1527 9.71529 11.1527C9.20793 11.1527 8.6682 11.2996 8.07448 11.5936C7.49157 11.8875 6.99501 12.2655 6.6064 12.7484V27.6454L6.62799 27.6349Z"
        fill="#0A252E"
      />
      <path
        d="M48.0166 4.15079C48.0166 3.25844 48.362 2.49206 49.0637 1.85167C49.7654 1.21128 50.5965 0.885834 51.5789 0.885834C52.5612 0.885834 53.4356 1.21128 54.1372 1.85167C54.8389 2.49206 55.1843 3.25844 55.1843 4.15079C55.1843 5.04314 54.8389 5.80951 54.1372 6.4289C53.4356 7.0588 52.5828 7.37374 51.5789 7.37374C50.575 7.37374 49.7654 7.0588 49.0637 6.4289C48.362 5.79901 48.0166 5.04314 48.0166 4.15079Z"
        fill="#EE3824"
      />
      <path d="M48.3169 12.3059L54.9449 10.4688V33.0715H48.3169V12.3059Z" fill="#5ABBBB" />
      <path
        d="M36.0001 19.5288C36.0001 19.5288 31.4987 19.5708 27.5046 21.7124C26.3712 22.3213 25.4968 23.1507 24.7951 24.0535C24.0935 24.9564 23.748 26.0377 23.748 27.2975C23.748 28.1059 23.8992 28.8617 24.2122 29.5546C24.5145 30.2475 24.9571 30.8564 25.5076 31.3498C26.0689 31.8537 26.7598 32.2527 27.5802 32.5466C28.4006 32.8406 29.3181 32.9875 30.3329 32.9875C31.0885 32.9875 31.7362 32.9351 32.2867 32.8196C32.8264 32.7041 33.3122 32.5571 33.7332 32.3577C34.1542 32.1582 34.5428 31.9272 34.9098 31.6648C35.2769 31.4023 35.6331 31.1084 36.0001 30.8039C36.0001 30.8039 30.5487 31.8537 30.4624 26.2372C30.3976 21.9644 36.0001 19.5078 36.0001 19.5078V19.5288Z"
        fill="#5ABBBB"
      />
      <path
        d="M29.1036 17.6409C30.9995 17.6409 32.5364 16.1462 32.5364 14.3024C32.5364 12.4586 30.9995 10.964 29.1036 10.964C27.2078 10.964 25.6709 12.4586 25.6709 14.3024C25.6709 16.1462 27.2078 17.6409 29.1036 17.6409Z"
        fill="#5ABBBB"
      />
      <path
        d="M41.8087 11.7498C40.3838 10.679 38.0845 10.1436 34.9324 10.1436C33.6479 10.1436 32.4172 10.28 31.2622 10.5425C36.0011 10.5425 37.113 14.2694 37.2641 15.9596C37.2641 16.0856 37.2857 16.201 37.2857 16.3375V32.5783H43.9569V16.1275C43.9569 14.2904 43.2444 12.8311 41.8195 11.7498H41.8087Z"
        fill="#5ABBBB"
      />
    </g>
    <defs>
      <clipPath id="clip0_3427_9105">
        <rect width="80" height="43.1373" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoFull;
