import { FC, ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import moment from "moment";

import { defaultDateFormat } from "constants/shared";
import { getAddress, getName } from "helpers/";

import {
  KYCInputDataResponseDto,
  UserAddressResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import {
  ACBlock,
  ACBMaskContainer,
  ACBText,
  ACBTitle,
  ACBVisibilityIconContainer,
  ACRow,
  AddressContainer,
  Container,
  NameContainer,
  StyledEyeIcon,
} from "./styles";

interface Props {
  className?: string;
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  user?: Partial<UserResponseDto | KYCInputDataResponseDto> | null;
  address?: Partial<UserAddressResponseDto> | null;
}

const ContactInfo: FC<Props> = ({ className, topContent, bottomContent, user, address }) => {
  const [ssnIsHidden, setSSNHidden] = useState<boolean>(true);

  const getMaskedSSN = (ssn: string) => {
    if (!ssn) return "";
    const maskedValue = ssn.slice(0, ssn.length - 4).replace(/[0-9]/g, "*");
    return maskedValue + ssn.slice(ssn.length - 4);
  };

  const toggleSSNHidden = () => {
    setSSNHidden(!ssnIsHidden);
  };

  return (
    <Container className={className}>
      {topContent}

      <NameContainer>{getName(user)}</NameContainer>

      <AddressContainer>
        <ACRow>
          <ACBlock>
            <ACBTitle>
              <Trans i18nKey={"components.contact_info.address"} />
            </ACBTitle>
            <ACBText>{getAddress(address)}</ACBText>
          </ACBlock>
          <ACBlock>
            <ACBTitle>
              <Trans i18nKey={"components.contact_info.ssn"} />
            </ACBTitle>
            <ACBMaskContainer>
              <ACBText>
                {ssnIsHidden
                  ? getMaskedSSN(user?.socialSecurityNumber!)
                  : user?.socialSecurityNumber}
              </ACBText>
              <ACBVisibilityIconContainer onClick={toggleSSNHidden}>
                <StyledEyeIcon isCrossedOut={ssnIsHidden} />
              </ACBVisibilityIconContainer>
            </ACBMaskContainer>
          </ACBlock>
        </ACRow>

        <ACRow>
          <ACBlock>
            <ACBTitle>
              <Trans i18nKey={"components.contact_info.phone"} />
            </ACBTitle>
            <ACBText>{user?.phone}</ACBText>
          </ACBlock>
          <ACBlock>
            <ACBTitle>
              <Trans i18nKey={"components.contact_info.date_of_birth"} />
            </ACBTitle>
            <ACBText>{moment(new Date(user?.birthDate || "")).format(defaultDateFormat)}</ACBText>
          </ACBlock>
        </ACRow>
      </AddressContainer>
      {bottomContent}
    </Container>
  );
};

export default ContactInfo;
