import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import Tooltip from "uikit/Tooltip";

export const Container = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  min-height: 24px;

  ${breakpoint("xl")`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Text = styled.span`
  display: inline-block;
`;

export const TooltipContainer = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  max-height: 24px;
  text-align: left;

  .__react_component_tooltip {
    max-width: 150px;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: 8px;
  cursor: pointer;
`;

export const tooltipContentContainer = styled.span`
  .__react_component_tooltip {
    &:after {
      display: none;
    }
    max-width: 275px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.contentBorder};
    box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.colors.contentBorder};
  }
`;
