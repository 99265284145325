import { FC } from "react";

import IconProps from "./IconProps";

const ChevronBottom: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L7.28267 6.7168C7.24557 6.75395 7.2015 6.78343 7.153 6.80354C7.1045 6.82365 7.05251 6.834 7 6.834C6.94749 6.834 6.8955 6.82365 6.847 6.80354C6.7985 6.78343 6.75444 6.75395 6.71733 6.7168L1 1"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronBottom;
