import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";
import ListEmployeeInfo from "components/ListEmployeeInfo";
import { AvatarWrapper, NameWrapper } from "components/ListEmployeeInfo/styles";

import { LinkTextMixin } from "uikit";

const avatarSize = 55;

//employee info alt

export const EmployeeInfoAltContainer = styled.div`
  display: flex;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    width: 100%;
  `}
`;

export const EIALeftSection = styled.div`
  display: flex;
  width: 305px;
  background-color: ${({ theme }) => theme.colors.employeeInfoTopBg};
  border-radius: 12px 0 0 12px;
  padding: 33px 0 33px 36px;

  ${breakpoint("md", "lg")`
    width: 260px;
    padding-left: 24px
  `}
  ${breakpoint("xs", "md")`
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    border-radius: 12px 12px 0 0;
  `}
`;

export const EIARightSection = styled.div`
  background-color: ${({ theme }) => theme.colors.employeeInfoBg};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 12px 12px 0;
  padding: 20px 23px 20px 40px;

  ${breakpoint("xs", "md")`
    box-sizing: border-box;
    width: 100%;
    padding: 16px 16px 6px 16px;
    border-radius: 0 0 12px 12px;
  `}
`;

export const StyledListEmployeeInfo = styled(ListEmployeeInfo)`
  cursor: default;
  ${AvatarWrapper} {
    cursor: default;
    margin-right: 14px;
    width: ${avatarSize}px;
    height: ${avatarSize}px;
    div {
      width: ${avatarSize}px;
      height: ${avatarSize}px;
      font-size: ${({ theme }) => theme.size.title} !important;
    }

    ${breakpoint("md", "lg")`
      margin-right: 8px;
    `}
  }

  ${NameWrapper} {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.size.subtitle};
    line-height: 1.3;
    padding: 0;
    cursor: default;
    white-space: normal;
  }
`;

export const EIARSTopContent = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  ${breakpoint("xs", "md")`
    margin-bottom: 0;
  `}
`;

export const TopRightContainer = styled.div``;

export const EIARSBottomContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${breakpoint("xs", "md")`
    flex-direction: column;
  `}
`;

export const EIABCBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:last-of-type {
    flex-direction: row;
    align-items: center;
  }

  ${breakpoint("xs", "md")`
    margin-bottom: 10px;
    justify-content: flex-start;
  `}
`;

export const EIALabelText = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 4px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.small};
  `}
`;

export const EIAValueText = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;

export const EIAEmailText = styled.span`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};

  ${breakpoint("xs", "md")`
    margin-bottom: 10px;
  `}
`;

export const EIAJobTitle = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const StyledLink = styled(NavLink)`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text} !important;
  text-decoration: underline;

  ${breakpoint("xs", "md")`
    margin-right: 10px;
  `}
`;
