import styled, { css } from "styled-components";

const TextAltMixin = css`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 13px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyrightText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 14px;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.accentMain};

  &:first-of-type {
    margin-bottom: 4px;
  }

  &:last-of-type {
    border-right: none;
  }
`;

//Copyright alt

export const ContainerAlt = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const CopyrightTextAlt = styled.p`
  ${TextAltMixin};

  &.separator {
    margin: 0 3px;
  }
`;

export const LinksContainerAlt = styled.div`
  display: flex;
`;

export const LinkAlt = styled.a`
  ${TextAltMixin};
  display: inline-block;
  text-decoration: underline;

  &:last-of-type {
    margin-left: 16px;
  }
`;
