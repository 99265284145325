import styled from "styled-components";

export const Container = styled.div`
  padding-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.contentBorder};
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.8;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
