import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: min-content;
  align-items: center;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__prev svg {
      transform: rotate(180deg);
    }

    &__control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.paginationButtonBg};
      border: none;
      cursor: pointer;
      margin: 0 5px;

      svg > path {
        stroke: ${({ theme }) => theme.colors.paginationButtonText};
        fill: ${({ theme }) => theme.colors.paginationButtonText};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.paginationButtonBgHover};
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.paginationButtonBg};
      cursor: pointer;
      margin: 0 5px;
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.paginationButtonText};

      &:hover {
        background-color: ${({ theme }) => theme.colors.paginationButtonBgHover};
      }
    }

    &__break {
      a {
        margin-bottom: 5px;
      }
    }

    & .selected {
      font-weight: bold;
      display: flex;
      border-radius: 50%;

      a {
        background: ${({ theme }) => theme.colors.paginationButtonBgActive};
        color: ${({ theme }) => theme.colors.paginationButtonTextActive};
      }
    }
  }
`;
