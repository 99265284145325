import { CloseIcon } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.tagBg};
  min-width: 148px;
  max-width: 248px;
  min-height: 33px;
  height: min-content;
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 9px 24px 9px 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  ${breakpoint("xs", "md")`
    min-width: 118px;
    max-width: 218px;
  `}
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textAlt};

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.small};
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.contentBg};
  padding: 3px;
  border-radius: 2px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

export const Icon = styled(CloseIcon)`
  width: 7px;
  height: 7px;
`;
