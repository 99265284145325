import styled from "styled-components";

import { Body1 } from "uikit";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const OptionsList = styled.div`
  width: 100%;
`;

export const OptionsListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  &.between {
    justify-content: space-between;
  }
`;

export const BankAccountInfoText = styled.span``;

export const AddBankBtn = styled(Body1)`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.accentMain};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 26px;

  & > svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    stroke: ${({ theme }) => theme.colors.accentMain};
  }
`;
