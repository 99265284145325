import { FC } from "react";

import IconProps from "./IconProps";

const ClockSmall: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4855_32321)">
      <circle cx="15" cy="15" r="14" fill="white" />
      <path
        d="M14.4991 0C6.50513 0 0 6.50513 0 14.4991C0 22.493 6.50513 28.9982 14.4991 28.9982C22.493 28.9982 28.9982 22.493 28.9982 14.4991C28.9982 6.50513 22.4949 0 14.4991 0ZM14.4991 26.0991C8.10356 26.0991 2.89909 20.8946 2.89909 14.4991C2.89909 8.10356 8.10356 2.89909 14.4991 2.89909C20.8946 2.89909 26.0991 8.10356 26.0991 14.4991C26.0991 20.8946 20.8946 26.0991 14.4991 26.0991Z"
        fill="#007D7B"
      />
      <path
        d="M14.4982 5.7998C13.6962 5.7998 13.0477 6.44831 13.0477 7.25026V13.8997L9.12199 17.8254C8.55569 18.3917 8.55569 19.3088 9.12199 19.8751C9.40514 20.1582 9.77597 20.3007 10.1468 20.3007C10.5176 20.3007 10.8885 20.1582 11.1716 19.8751L15.5212 15.5255C15.7934 15.2533 15.9468 14.8843 15.9468 14.5007V7.25026C15.9468 6.45014 15.2983 5.7998 14.4964 5.7998H14.4982Z"
        fill="#5FAEAC"
      />
    </g>
    <defs>
      <clipPath id="clip0_4855_32321">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ClockSmall;
