import styled from "styled-components";

import { CancelButton as UikitCancelButton, PrimaryButton } from "uikit";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const BCLeft = styled.div``;
export const BCRight = styled.div``;

export const CancelButton = styled(UikitCancelButton)`
  width: auto;
`;

export const ContinueButton = styled(PrimaryButton)`
  width: auto;
`;
