import { UserShortResponseDto } from "utils/swagger_react_query";

export enum ETableVariants {
  ONBOARDING_LIST = "ONBOARDING_LIST",
  PEOPLE_LIST = "PEOPLE_LIST",
}

export interface IProps {
  onListChange?: (workers: UserShortResponseDto[]) => void;
  perPage?: number;
  type: ETableVariants;
}

export interface IResendDisableRecord {
  id: string;
  datetime: number;
}
