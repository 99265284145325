import { FC } from "react";

import IconProps from "./IconProps";

const HelpCenterAlt: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="53"
    height="44"
    viewBox="0 0 53 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5 11.8256C33.2321 11.8256 32.2518 12.7492 32.2518 14.085C32.2518 14.71 31.7477 15.2166 31.1259 15.2166C30.5041 15.2166 30 14.71 30 14.085C30 11.4513 32.0368 9.5625 34.5 9.5625C36.9632 9.5625 39 11.4513 39 14.085C39 15.1807 38.7378 16.0342 38.3092 16.7435C37.9125 17.4 37.3945 17.8887 36.9925 18.2681C36.9809 18.2791 36.9694 18.2899 36.958 18.3007C36.521 18.7133 36.214 19.0119 35.9895 19.3833C35.7855 19.7209 35.6259 20.1646 35.6259 20.867V21.431C35.6259 22.0559 35.1218 22.5625 34.5 22.5625C33.8782 22.5625 33.3741 22.0559 33.3741 21.431V20.867C33.3741 19.7714 33.6362 18.9178 34.0649 18.2085C34.4616 17.5521 34.9795 17.0633 35.3816 16.684C35.3932 16.673 35.4047 16.6621 35.4161 16.6513C35.8531 16.2387 36.1601 15.9401 36.3846 15.5687C36.5886 15.2311 36.7482 14.7874 36.7482 14.085C36.7482 12.7492 35.7679 11.8256 34.5 11.8256Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 23.5625C34.5523 23.5625 35 24.1001 35 24.7633V25.3617C35 26.0249 34.5523 26.5625 34 26.5625C33.4477 26.5625 33 26.0249 33 25.3617V24.7633C33 24.1001 33.4477 23.5625 34 23.5625Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7034 6.45978C16.8178 7.05764 16.42 7.63392 15.8149 7.74693C7.94412 9.21682 2.23019 15.2425 2.23019 22.2665C2.23019 26.924 4.73409 31.1073 8.71951 33.8642C9.04658 34.0905 9.22625 34.4722 9.19057 34.8651C9.00033 36.9601 8.57283 38.9669 8.07825 40.5695C10.4757 39.5565 12.5978 38.2629 14.3863 36.8798C14.6478 36.6776 14.987 36.601 15.3114 36.671C16.6636 36.9626 18.0632 37.1305 19.5052 37.1305C22.3637 37.1305 25.0593 36.5292 27.4285 35.4799C27.9905 35.231 28.6502 35.4793 28.9022 36.0345C29.1541 36.5897 28.9027 37.2416 28.3408 37.4905C25.6866 38.666 22.6804 39.3339 19.5052 39.3339C18.0713 39.3339 16.6835 39.1859 15.3483 38.9284C12.906 40.7511 9.91924 42.4074 6.49824 43.5083C6.06958 43.6462 5.59903 43.5156 5.30583 43.1773C5.01263 42.8389 4.95448 42.3595 5.15846 41.9623C5.7783 40.7553 6.57035 38.1935 6.90563 35.2857C2.71944 32.1756 0 27.528 0 22.2665C0 13.9736 6.70009 7.20673 15.4006 5.58187C16.0058 5.46886 16.5891 5.86191 16.7034 6.45978Z"
      fill="#F29127"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 17.9131C14 8.20373 22.8655 0.5625 33.4964 0.5625C44.1263 0.5625 53 8.19565 53 17.9131C53 22.8369 50.6798 27.246 47.0347 30.3753C47.3099 33.6393 48.1747 36.599 48.8516 37.9343C49.0563 38.3381 48.9986 38.826 48.7054 39.1704C48.4122 39.5147 47.9413 39.6477 47.5124 39.5073C43.9934 38.3551 40.9399 36.6074 38.4611 34.6884C36.8792 35.061 35.2157 35.2638 33.5036 35.2638C22.8733 35.2638 14 27.6231 14 17.9131ZM33.4964 2.80246C23.819 2.80246 16.23 9.70285 16.23 17.9131C16.23 26.1229 23.8256 33.0238 33.5036 33.0238C35.2173 33.0238 36.8761 32.7975 38.4388 32.3862C38.7798 32.2964 39.143 32.374 39.4183 32.5953C41.2496 34.0679 43.4414 35.448 45.9339 36.5196C45.3831 34.697 44.9175 32.3588 44.7677 29.9512C44.7451 29.5875 44.9 29.2356 45.183 29.0076C48.6374 26.2249 50.77 22.2685 50.77 17.9131C50.77 9.69643 43.1748 2.80246 33.4964 2.80246Z"
      fill="#007D7B"
    />
  </svg>
);

export default HelpCenterAlt;
