import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 80px;
  border-top: 1px solid ${({ theme }) => theme.colors.contentBorder};
  height: 29px;
  margin-top: 12px;
  flex-shrink: 0;
`;
