export enum ExternalBankAccountInfoRowColumn {
  NAME = "NAME",
  BANK_ACCOUNT_NAME = "BANK_ACCOUNT_NAME",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  TYPE = "TYPE",
  STATUS = "STATUS",
}

export enum PaidBankAccountInfoRowColumn {
  NAME = "NAME",
  BANK_ACCOUNT_NAME = "BANK_ACCOUNT_NAME",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
}
