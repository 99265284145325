export const APPLICATION_VERSION = "1.0.0";

export const APPLICATION_NAME = "Paid";
export const APPLICATION_NAME_UPPERCASE = "Paid";
export const APPLICATION_PARENT_NAME = "Keep";
export const APPLICATION_PARENT_NAME_FULL = "Keep Financial";
export const APPLICATION_PARENT_NAME_UPPERCASE = "KEEP";
export const APPLICATION_PARENT_NAME_FULL_UPPERCASE = "KEEP Financial";
export const APPLICATION_PARENT_NAME_FULL_ALT = "Keep Financial Technologies";
export const APPLICATION_PARENT_NAME_FULL_ALT_UPPERCASE = "KEEP Financial Technologies";

export const REMOVE_ACTIONS_TIMEOUT_LOCAL_STORAGE_KEY = "removeActionsTimeout";

export enum Environment {
  DEV = "dev",
  STG = "stg",
  PROD = "prd",
  TST = "tst",
}

export const AllowedEnvForTestingContent = [Environment.TST, Environment.DEV, Environment.STG];
