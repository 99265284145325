import { FC } from "react";

import IconProps from "./IconProps";

const ChevronRight: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14887 5.12932L2.12307 0.103516L0.75239 1.4742L5.77819 6.5L0.751953 11.5262L2.12263 12.8969L7.14887 7.87068L7.14931 7.87112L8.51999 6.50044L8.51956 6.5L7.14887 5.12932Z"
      fill="white"
    />
  </svg>
);

export default ChevronRight;
