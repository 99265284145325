import React, { FC } from "react";

import IconProps from "./IconProps";

const FilterIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.917 0.4701C16.7803 0.156778 16.5432 7.82326e-05 16.2056 0H0.783676C0.446494 0 0.209449 0.1567 0.0725418 0.4701C-0.0640524 0.799341 -0.00774422 1.08045 0.241466 1.31343L6.18155 7.25345V13.1092C6.18155 13.3178 6.25783 13.4986 6.41038 13.6515L9.49507 16.7355C9.6398 16.8885 9.8206 16.9651 10.0375 16.9651C10.1334 16.9651 10.2338 16.9449 10.3386 16.9045C10.652 16.7681 10.8086 16.5312 10.8087 16.194V7.25345L16.7488 1.31343C16.9977 1.08029 17.0538 0.799185 16.917 0.4701Z"
      fill="#5B5B5B"
    />
  </svg>
);

export default FilterIcon;
