import styled, { css } from "styled-components";

export const ModalFormMixin = css`
  padding: 31px 86px 38px 86px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`;

export const ModalForm = styled.form`
  ${ModalFormMixin}
`;

export const ModalFormContent = styled.div`
  width: 100%;
  text-align: center;
`;

export const ModalFormButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

export const FormContainer = styled.div`
  position: relative;
  padding: 24px 28px 24px 28px;
  border: none;
`;

export const ShadowContainer = styled(FormContainer)`
  border-radius: 11px;
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.accordionCollapseContainerShadow};
`;
