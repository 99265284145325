import SelectOption from "types/SelectOption";

export const getFormattedYearsSelectOptions = (years: number[]): SelectOption<string>[] => {
  const result = years.map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));

  return result.sort((a, b) => parseInt(b.value) - parseInt(a.value));
};
