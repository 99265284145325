import { FC } from "react";

import IconProps from "./IconProps";

const AttentionCircleSmallAltIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM9.2 13.2C9.2 13.6418 9.55817 14 10 14C10.4418 14 10.8 13.6418 10.8 13.2V10C10.8 9.55817 10.4418 9.2 10 9.2C9.55817 9.2 9.2 9.55817 9.2 10V13.2ZM10 6C10.4418 6 10.8 6.35817 10.8 6.8V6.808C10.8 7.24983 10.4418 7.608 10 7.608C9.55817 7.608 9.2 7.24983 9.2 6.808V6.8C9.2 6.35817 9.55817 6 10 6Z"
      fill="#F96331"
    />
  </svg>
);

export default AttentionCircleSmallAltIcon;
