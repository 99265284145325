import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CreditCard,
  CurrencyDollarIcon,
  HelpCenter,
  OnboardingIcon,
  People,
  Settings,
} from "assets/svg";
import { find } from "lodash";
import useRouterConfig from "routes/router";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";
import { HELP_CENTER, MAIN_MENU_COLLAPSED } from "constants/shared";

interface IMenuOption {
  id: string;
  icon: React.ReactNode;
  label: string;
  hide?: boolean;
  url?: routes;
  href?: string;
}

export const useMainMenu = () => {
  const { t } = useTranslation();
  const translationPrefix = `components.main_menu`;

  const { routerConfig } = useRouterConfig();
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const existingState = window.localStorage.getItem(MAIN_MENU_COLLAPSED) === "true" ? true : false;

  const [isCollapsed, setCollapsed] = useState<boolean>(existingState);

  const handleCollapse = (state?: boolean | undefined) => {
    window.localStorage.setItem(MAIN_MENU_COLLAPSED, `${state || !isCollapsed}`);
    setCollapsed(state || !isCollapsed);
  };

  const pageIsHidden = (option?: IMenuOption) => {
    if (!option) return false;

    const routeFromConfig = find(routerConfig, (item) => item.path === option.url);
    if (!routeFromConfig) return false;

    return routeFromConfig?.disableCondition?.(currentUser, currentCompany) || !!option?.hide;
  };

  const adminOptions: IMenuOption[] = [
    {
      id: "setup",
      icon: <OnboardingIcon />,
      label: t(`${translationPrefix}.setup`),
      url: routes.ADMIN_SETUP,
      // hide: currentCompany?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED,
    },

    {
      id: "pay",
      icon: <CurrencyDollarIcon />,
      label: t(`${translationPrefix}.pay_admin`),
      url: routes.ADMIN_PAY,
    },
    {
      id: "people",
      icon: <People />,
      label: t(`${translationPrefix}.people`),
      url: routes.PEOPLE,
    },
    {
      id: "settings",
      icon: <Settings />,
      label: t(`${translationPrefix}.settings_admin`),
      url: routes.ADMIN_SETTINGS,
    },
    {
      id: "help_center",
      icon: <HelpCenter />,
      label: t(`${translationPrefix}.help_center`),
      href: HELP_CENTER,
    },
  ];

  const employeeOptions = [
    {
      id: "setup",
      icon: <OnboardingIcon />,
      label: t(`${translationPrefix}.setup`),
      url: routes.EMPLOYEE_SETUP,
      // hide: currentCompany?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED,
    },
    {
      id: "pay",
      icon: <CurrencyDollarIcon />,
      label: t(`${translationPrefix}.pay_employee`),
      url: routes.EMPLOYEE_PAY,
    },
    {
      id: "banking",
      icon: <CreditCard />,
      label: t(`${translationPrefix}.banking_employee`),
      url: routes.EMPLOYEE_BANKING,
    },
    {
      id: "settings",
      icon: <Settings />,
      label: t(`${translationPrefix}.settings_employee`),
      url: routes.EMPLOYEE_SETTINGS,
    },
    {
      id: "help_center",
      icon: <HelpCenter />,
      label: t(`${translationPrefix}.help_center`),
      href: HELP_CENTER,
    },
  ];

  const getModifiedMenuOptions = (options: IMenuOption[] = []) => {
    return options.map((item) => ({
      ...item,
      hide: pageIsHidden(item),
    }));
  };

  const getMenu = () => {
    const options = {
      [UserRole.SUPERADMIN]: adminOptions,
      [UserRole.ADMIN]: adminOptions,
      [UserRole.OWNER]: adminOptions,
      [UserRole.EMPLOYEE]: employeeOptions,
    };
    return getModifiedMenuOptions(options[currentUser?.lastActiveRole as UserRole] || []);
  };

  return {
    metadata: {
      getMenu,
      currentUser,
    },
    state: {
      isCollapsed,
      handleCollapse,
    },
  };
};
