import React, { FC, ReactNode } from "react";
import SwitchComponent, { ReactSwitchProps } from "react-switch";
import { theme } from "styles/theme";

import { Container, SwitchContainer, TextContainer } from "./styles";

export interface Props extends ReactSwitchProps {
  className?: string;
  children?: ReactNode | string;
  childrenAfterSwitch?: ReactNode | string;
}

const Switch: FC<Props> = ({
  className,
  children,
  checked,
  childrenAfterSwitch = true,
  ...rest
}) => {
  const defaultOptions: Partial<ReactSwitchProps> = {
    height: 28,
    width: 48,
    checkedIcon: false,
    uncheckedIcon: false,
    onColor: theme.colors.switchBackground,
    offColor: theme.colors.switchOffBackground,
    handleDiameter: 24,
  };

  return (
    <Container className={className}>
      {!!children && !childrenAfterSwitch && <TextContainer>{children}</TextContainer>}
      <SwitchContainer {...defaultOptions} {...rest} onChange={() => undefined}>
        <SwitchComponent
          className={checked ? "checked" : ""}
          {...defaultOptions}
          checked={checked}
          {...rest}
        />
      </SwitchContainer>
      {!!children && !!childrenAfterSwitch && <TextContainer>{children}</TextContainer>}
    </Container>
  );
};

export default Switch;
