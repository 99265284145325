import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import { MFASMSSetup } from "components/MultiFactorAuthorization";
import { EWidgetType as MFAWidgetType } from "components/MultiFactorAuthorization/types";
import { NavigationCardWithDetailsAlt } from "components/NavigationCardWithDetails";

import { FullScreenLoader, Loader, Modal } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

import useEmployeeSettingsAuthenticationPage from "./useEmployeeSettingsAuthenticationPage";
import { OptionsSection } from "./styles";

interface IProps {}

const EmployeeSettingsAuthenticationPage: FC<IProps> = () => {
  const { metadata, pageData, actions, render } = useEmployeeSettingsAuthenticationPage();
  const { cardsList } = pageData;
  const { isAddSMSFactorModalOpen, isRemoveSMSFactorModalOpen, isLoading, isDataLoading } =
    metadata;
  const { renderRemoveSMSFactorModalContent } = render;
  const {
    handleAddSMSModalEnterPhoneCancel,
    handleAddSMSModalVerifyCodeCancel,
    handleAddSMSModalSubmitSuccess,
  } = actions;

  return (
    <DashboardLayout>
      {isLoading && <FullScreenLoader />}

      <OptionsSection>
        {isDataLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          cardsList.map((option) => {
            return <NavigationCardWithDetailsAlt {...option} key={option.id} />;
          })
        )}
      </OptionsSection>

      {/* Add SMS Factor Modal */}
      <Modal isOpen={isAddSMSFactorModalOpen} showCloseButton={false}>
        <MFASMSSetup
          widgetType={MFAWidgetType.SETTINGS}
          onEnterPhoneCancel={handleAddSMSModalEnterPhoneCancel}
          onVerifyCodeCancel={handleAddSMSModalVerifyCodeCancel}
          onSubmitSuccess={handleAddSMSModalSubmitSuccess}
        />
      </Modal>

      {/* Remove SMS Factor Modal */}
      <Modal isOpen={isRemoveSMSFactorModalOpen} showCloseButton={false}>
        {renderRemoveSMSFactorModalContent()}
      </Modal>
    </DashboardLayout>
  );
};

export default EmployeeSettingsAuthenticationPage;
