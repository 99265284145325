import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  AlertSquareRounded,
  BalanceReduction,
  DepositTransactionIcon,
  PaymentTransactionIcon,
} from "assets/svg";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import { RepaymentTransactionType, SyncteraAccountTransactionDcSignType } from "types/BETypes";
import { getUserFriendlyId } from "helpers/index";
import CurrencySumComponent from "components/CurrencySumComponent";
import EmptyList from "components/EmptyList";
import { TransactionType } from "components/TransactionStatus/types";
import TransactionStatusBanking from "components/TransactionStatusBanking";

import { TableList } from "uikit";
import { EColumnType, IColumn } from "uikit/Table/types";

import { SyncteraTransactionDto } from "utils/swagger_react_query";

import { ITransactionTypes, TTransaction } from "./types";
import {
  Container,
  TableContainer,
  TIAmount,
  TIBankAccount,
  TIBankInfo,
  TIBankName,
  TransactionInfo,
  TransactionTypeText,
} from "./styles";

interface Props {
  isLoading: boolean;
  className?: string;
  pendingTransactions: TTransaction[];
  postedTransactions: TTransaction[];
}

const TransactionsListBanking: FC<Props> = ({
  isLoading,
  className,
  postedTransactions = [],
  pendingTransactions = [],
}) => {
  const { t } = useTranslation();
  const translationPrefix = "components.transactions_list";

  const currentCompany = useAppSelector(companyMetadataSelector);

  const tableData: TTransaction[] = [
    ...pendingTransactions.map((item) => ({
      ...item,
    })),
    ...postedTransactions.map((item) => ({
      ...item,
    })),
    //@ts-ignore
  ].sort((a, b) => moment(b.createdAt).format("YYYYMMDD") - moment(a.createdAt).format("YYYYMMDD"));

  const renderEmptyListComponent = () => (
    <EmptyList
      icon={<AlertSquareRounded />}
      title={<Trans i18nKey={`${translationPrefix}.empty_list_title`} />}
      message={<Trans i18nKey={`${translationPrefix}.empty_list_description`} />}
    />
  );

  const getIcon = (item: TTransaction) => {
    if (!item.dcSign) return <PaymentTransactionIcon />;
    switch (item.dcSign) {
      case SyncteraAccountTransactionDcSignType.DEBIT:
        return <DepositTransactionIcon />;
      case SyncteraAccountTransactionDcSignType.CREDIT:
        return <BalanceReduction />;
      default:
        return <PaymentTransactionIcon />;
    }
  };

  const getBankName = (transaction: TTransaction) => {
    // if (!transaction?.receivingAccountInfo?.bankName) {
    //   if (transaction.type === RepaymentTransactionType.BALANCE_DUE)
    //     return t(`${translationPrefix}.balance_due`);
    //   if (transaction.type === RepaymentTransactionType.LOAN)
    //     return getUserFriendlyId(transaction?._loanId || "");
    //   return t(`${translationPrefix}.bank_name_placeholder`);
    // }

    return (
      transaction?.receivingAccountInfo?.bankName || t(`${translationPrefix}.bank_name_placeholder`)
    );
  };

  const columns: IColumn[] = [
    {
      key: "icon",
      className: "icon",
      title: "",
      type: EColumnType.Component,
      component: (item: TTransaction) => <>{getIcon(item)}</>,
    },
    {
      key: "transaction",
      className: "transaction",
      title: t(`${translationPrefix}.transaction`),
      type: EColumnType.Component,
      component: (item: TTransaction) => {
        const bankName = getBankName(item);
        const hideDescription =
          item.dcSign === SyncteraAccountTransactionDcSignType.DEBIT &&
          !bankName &&
          !item?.receivingAccountInfo?.last4Digits;
        return (
          <TransactionInfo>
            <TIAmount>
              <Trans
                i18nKey={`${translationPrefix}.type_descriptions_banking.${item.dcSign}.description_1`}
                components={{ 1: <CurrencySumComponent sum={Number(item?.amount) / 100} /> }}
              />
            </TIAmount>
            {!hideDescription && (
              <TIBankInfo>
                <Trans
                  i18nKey={`${translationPrefix}.type_descriptions_banking.${item.dcSign}.description_2`}
                  values={{
                    companyName: currentCompany?.name || "",
                    bankName: bankName,
                    last4Digits: item?.receivingAccountInfo?.last4Digits
                      ? `*********${item?.receivingAccountInfo?.last4Digits}`
                      : "",
                    description: item.description,
                  }}
                  components={{
                    1: <TIBankName />,
                    2: <TIBankAccount />,
                  }}
                />
              </TIBankInfo>
            )}
          </TransactionInfo>
        );
      },
    },
    {
      key: "type",
      className: "type",
      title: t(`${translationPrefix}.type`),
      type: EColumnType.Component,
      component: (item: TTransaction) => {
        const _translationPrefix = `components.transaction_type_banking`;
        return (
          <TransactionTypeText>
            <Trans i18nKey={`${_translationPrefix}.${item.type}`} />
          </TransactionTypeText>
        );
      },
    },
    {
      key: "createdAt",
      className: "date",
      title: t(`${translationPrefix}.date`),
      type: EColumnType.Date,
    },
    {
      key: "status",
      className: "status",
      title: t(`${translationPrefix}.status`),
      type: EColumnType.Component,
      component: (item: TTransaction) => {
        return <TransactionStatusBanking item={item} />;
      },
    },
  ];

  return (
    <Container className={className}>
      <TableContainer>
        <TableList<any>
          data={tableData}
          isLoading={!!isLoading}
          columns={columns}
          emptyListComponent={renderEmptyListComponent()}
          scrollProps={{
            maxHeight: 305,
          }}
          rowClass={(item: TTransaction) => item._transactionType || ""}
          //infiniteScrollProps={infScrollProps}
        />
      </TableContainer>
    </Container>
  );
};

export default TransactionsListBanking;
