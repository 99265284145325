import { FC, ReactNode } from "react";
import { AlertSquareRounded } from "assets/svg";

import {
  ActionButton,
  ChildrenContainer,
  Container,
  IconContainer,
  MessageContainer,
  TitleContainer,
} from "./styles";

interface ButtonProps {
  text?: string | ReactNode;
  onClick?: () => void;
}

interface Props {
  className?: string;
  icon?: ReactNode | null;
  title?: ReactNode;
  message?: ReactNode;
  button?: ButtonProps;
  children?: ReactNode;
  listName?: string;
}

const EmptyList: FC<Props> = ({
  className,
  icon = <AlertSquareRounded />,
  title,
  message,
  button,
  children,
  listName,
}) => {
  const dataTestIdPrefix = listName ? `${listName}_` : "";

  return (
    <Container className={className} data-testid={`${dataTestIdPrefix}empty_state_container`}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {title && (
        <TitleContainer data-testid={`${dataTestIdPrefix}empty_state_title`}>
          {title}
        </TitleContainer>
      )}
      {message && (
        <MessageContainer data-testid={`${dataTestIdPrefix}empty_state_message`}>
          {message}
        </MessageContainer>
      )}
      <ChildrenContainer>{children}</ChildrenContainer>
      {button && (
        <ActionButton
          onClick={button.onClick}
          data-testid={`${dataTestIdPrefix}empty_state_action_button`}
        >
          {button.text || ""}
        </ActionButton>
      )}
    </Container>
  );
};

export default EmptyList;
