import moment from "moment";

import { BonusStatus, BonusType } from "types/BETypes";

import { BonusResponseDto, EmployeeBonusResponseDto } from "utils/swagger_react_query";

export enum ExtensionBonusStatuses {
  PAST_DUE = "PAST_DUE",
  BLANK = "BLANK",
}

export type BonusStatusExtended = BonusStatus | ExtensionBonusStatuses;

export const isBonusPastDue = (
  data: Partial<BonusResponseDto | EmployeeBonusResponseDto>,
): boolean => {
  if (data?.type === BonusType.TIME || data?.status !== BonusStatus.ACTIVE) {
    return false;
  }
  const completeDate =
    data?.unitGoals?.[0]?.completeBy || (data as BonusResponseDto)?.challengeGoals?.[0]?.completeBy;
  return !!moment(new Date()).startOf("day").isAfter(moment(completeDate).startOf("day"));
};

export const getFormattedBonusStatus = (
  data?: Partial<BonusResponseDto | EmployeeBonusResponseDto | any>,
): BonusStatus | ExtensionBonusStatuses => {
  if (!data) return ExtensionBonusStatuses.BLANK;
  const _isPastDue = isBonusPastDue(data);
  return _isPastDue ? ExtensionBonusStatuses.PAST_DUE : (data?.status as BonusStatus);
};
