import { CardForm, CardReissueReason } from "types/CardShipping";
import { PaidCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/PaidCardShippingDetailsForm";

import { IssueBankCardReqDto } from "utils/swagger_react_query";

export const reissueCardFormValuesToRequestPayload = (
  cardId: string,
  cardForm: CardForm,
  reason: CardReissueReason,
  shippingDetails?: PaidCardShippingDetailsFormType,
): IssueBankCardReqDto => {
  const result: IssueBankCardReqDto = {
    form: cardForm,
    reissuedFromId: cardId,
    reissueReason: reason,
    shipping: shippingDetails
      ? {
          address: {
            addressLine1: shippingDetails.address,
            addressLine2: shippingDetails.suite || undefined,
            city: shippingDetails.city,
            state: shippingDetails.state.value,
            postalCode: shippingDetails.zip,
            countryCode: "US",
          },
          recipientFirstName: shippingDetails.firstName,
          recipientLastName: shippingDetails.lastName,
          phoneNumber: shippingDetails.phone,
        }
      : undefined,
  };

  return result;
};
