import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik";
import { useRouteHelpers } from "routes/helpers";

import { logout } from "services/auth";
import { ErrorConst, MFAFactorType } from "types/BETypes";
import { HELP_EMAIL } from "constants/shared";
import { showErrorModal } from "helpers";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { EWidgetType as MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { mutationAuthControllerMfaLogin } from "utils/swagger_react_query";

import { MfaSignInFormType } from "./components";
import { ISignInMfaPageNavigationParams } from "./types";

export const useSignInMfaPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationState = useLocation().state as unknown as ISignInMfaPageNavigationParams;

  const [isPageDataLoading, setPageDataLoading] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [svoc, setSvoc] = useState<boolean>(false);

  const {
    currentFactor,
    currentFactorChallenge,
    createFactorChallenge,
    resendVerificationCode,
    fetchData,
    factorsData,
    selectFactor,
    errorsPrefix: mfaErrorsPrefix,
  } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
    fetchDataOnMount: false,
  });

  const { getDefaultRoute } = useRouteHelpers();

  const initialValues: MfaSignInFormType = {
    code: "",
  };

  useEffect(() => {
    if (!locationState?.code) {
      logout();
    }
    initFetch();
  }, []);

  const initFetch = async () => {
    try {
      setPageDataLoading(true);
      const factorsRes = await fetchData();
      const smsFactor = factorsRes?.find((factor) => factor.type === MFAFactorType.SMS);
      if (!smsFactor) throw new Error(ErrorConst.MFA_FACTOR_NOT_FOUND);
      selectFactor(smsFactor);
      await createFactorChallenge(smsFactor?.factorId || "");
    } catch (error) {
      showErrorModal(error, logout);
    } finally {
      setPageDataLoading(false);
    }
  };

  const handleSubmit = async (
    values: MfaSignInFormType,
    formikHelpers: FormikHelpers<MfaSignInFormType>,
  ) => {
    const { setFieldError } = formikHelpers;
    try {
      setSubmitting(true);
      await mutationAuthControllerMfaLogin()({
        challengeId: currentFactorChallenge?.challengeId || "",
        code: values.code,
      });

      return navigate(getDefaultRoute(), {
        replace: true,
      });
    } catch (error) {
      setFieldError(
        "code",
        t(`${mfaErrorsPrefix}.${(error as any).data.error}`, { helpEmail: HELP_EMAIL }),
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleVerifyCodeResend = async (callback: () => void) => {
    try {
      setSubmitting(true);
      await resendVerificationCode(callback);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    metadata: {
      isLoading: isPageDataLoading,
      isSubmitting,
      svoc,
      setSvoc,
      initialValues,
    },
    pageData: {
      currentFactor,
    },
    actions: {
      handleSubmit,
      handleVerifyCodeResend,
    },
  };
};
