import { VestingScheduleType } from "types/BETypes";
import {
  BONUS_MAX_GOAL_QUANTITY,
  BONUS_MAX_MILESTONES_QUANTITY,
  MAX_INPUT_LENGTH_LONG,
  MAX_TEXTAREA_LENGTH,
} from "constants/form";

export const editBonusFormTranslations = {
  "en-US": {
    form_label_main: "Vesting Options",
    form_label_main_tooltip_time:
      "This setting lets you choose from four vesting options. Vesting with equally spaced milestones, vesting with 1 different milestone (a cliff) and multiple equally spaced milestones thereafter, vesting 100% Bonus amount on the end date or a completely custom vesting schedule that lets you pick the dates and bonus amounts per milestone.",
    form_label_main_tooltip_unit: `<1>Vesting Options</1><br/>
        A vesting milestone is a “number of units completed” target. When each milestone is completed by the recipient, that portion of their loan is paid down. You have four vesting milestone options.
      `,
    bonus_amount: "Amount",
    bonus_amount_tooltip:
      "Provide the <1>Net Amount</1>, which is calculated by taking the full amount, minus the anticipated estimate for payroll taxes. <br /><br />When the employee accepts the bonus, Keep will transfer the <1>Net Amount</1> to the employee. At each milestone, the portion that vests is taxable income and payment to the IRS is the responsiblity of the employer.",
    vesting_max_length: "Vesting Max Length",
    first_vesting_milestone: "First Vesting Milestone",
    thereafter_vest_every: "Thereafter Vest Every...",
    start_date: "Start Vesting Date",
    start_date_tooltip:
      "Each bonus has a starting vesting date. You can configure milestones for when the bonus is paid towards an employee loan. At the final vesting date, 100% of the bonus will have been paid down by the employer.<br /><br />This setting lets you configure the bonus start date.",
    end_date: "End Date: {{endDate}}",
    end_date_tooltip:
      "Each bonus has a starting and ending vesting date. Between those dates are vesting milestones where when reached a portion of the total amount are paid off. At the end vesting date 100% of the bonus will have been paid down by the employer. Here you may enter when you wish for the vesting to begin and the final date when it should be paid in full by the employer.",
    end_date_placeholder: "Date",
    total_vesting_period: "Total Vesting Period",

    time_form: {},
    unit_form: {},

    vesting_units: {
      month: "Month",
      quarter: "Quarter",
      year: "Year",
    },

    vesting_units_plural: {
      month: "Months",
      quarter: "Quarters",
      year: "Years",
    },

    vesting_type_options_placeholder: "Please Choose",
    vesting_type_description_custom: "Configure your custom vesting schedule below.",

    vesting_type_options_tooltips: {
      [VestingScheduleType?.UNIT_VEST_100_PERCENTS_ON_END_DATE]: `<1>Vest 100% upon completion of goal</1><br/>
          Recipient must complete 100% off the units by the complete all date. If they do not complete 100% of the units, the full repayment of the entire balance is due.
        `,
      [VestingScheduleType?.UNIT_EQUALLY]: `<1>Equal vesting milestones</1><br/>
          You may set number of completed unit milestones that absolve the user of repayment of that block of units upon completion.
        `,
      [VestingScheduleType?.UNIT_ON_EACH_UNIT]: `<1>Vest upon completed units</1><br/>
          Upon completion of each unit, the value of that unit is no longer owed by the recipient.
        `,
      [VestingScheduleType?.UNIT_CUSTOM]: `<1>Custom milestones</1><br/>
          You may set custom number of unit milestones that add up to the total to be completed before the end date.
        `,
    },

    time_vesting_type_options: {
      vesting_equally: "Vest equally every",
      vesting_equally_after_cliff: "Vest equally after a cliff",
      vesting_100_percent_on_end_date: "Vest 100% on End Date",
      custom_vesting_schedule: "Custom Vesting Schedule",
    },

    unit_vesting_type_options: {
      vesting_100_percent_on_completion: "Vest 100% upon completion of the Goal",
      vesting_equally: "Vest Equally Over",
      vesting_on_each_unit: "Vest on each unit completed",
      custom_vesting: "Custom Vesting",
    },

    forms: {
      vesting_equally: {
        period_label: "Vesting Interval",
      },

      vesting_equally_after_cliff: {
        first_milestone_date: "Set Cliff at",
        first_milestone_date_tooltip:
          "This setting lets you configure the 1st vesting milestone or “cliff” schedule to be different from the milestones that come after it.<br /><br /> This is helpful if you wish to have the employee stay for a while before the first payment. ",
        first_milestone_date_placeholder: "Date",
        first_vest_amount: "Vest",
        therefore_period_label: "Thereafter vest equally every",
      },

      vesting_schedule: {
        title: "Vesting Milestones",
        title_sample: "Vesting Schedule Sample",
        title_custom: "Custom Vesting Milestones",
        calculation_is_impossible: "Please complete the form above to view the Vesting Schedule.",
      },

      goal_form: {
        complete_label: "Complete",
        unit_name: "Unit Name",
        unit_name_tooltip: `<1>Unit Name</1><br/>A “Unit” is a “Unit of Work” completed. An example of this may be a nurse working a shift, a sales person closing a deal, a driver completing a delivery. You may give each of these “units” a name such as “Shift”, “closed deal” or “delivery”. When creating a goal, that may look like” Complete 50 closed deals by 2/15/2025.`,
        unit_name_example: "Example: Deliveries, Night Shits, etc.",
        complete_by: "Complete all by",
        complete_by_tooltip:
          "<1>Complete all units by</1><br />Every goal has an end date. All the units must be accomplished by the bonus recipient by the date you define here to become fully vested. You may create vesting milestones under vesting options drop down below.  ",
        by: "By",
      },

      unit_vest_equally_form: {
        quantity_description: "Equal Milestones",
      },

      unit_vest_on_each_unit: {
        description_placeholder: "Please provide your bonus details above",
        description: "Bonus vests <1>{{amount}}</1> for each “<1>{{goalName}}</1>” completed.",
      },
    },
    errors: {
      common: "Field is invalid",
      title_required: "Title is required",
      title_exceeds_max: `Title must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
      vesting_type_required: "Please choose bonus type",
      vesting_schedule_type_required: "Please choose a vesting option",
      amount_required: "Amount is required",
      amount_too_large: "Amount is too large",
      amount_cannot_be_zero: "Amount cannot be zero",
      amount_per_goal_is_too_small:
        "The Milestone Vesting Amount should be higher than {{minGoalAmount}}. Please increase your Bonus Amount or reduce your Goals number.",
      number_of_days_required: "Number of days is required",
      start_date_required: "Start date is required",
      end_date_is_smaller_or_equal_to_start_date:
        "End date can not be earlier or equal to the start date",
      end_date_must_be_2_days_apart_from_start_date:
        "End date must be at least 2 days apart from start date",
      stipulations_too_long: `Special stipulations must not exceed ${MAX_TEXTAREA_LENGTH} characters`,
      first_vesting_bigger_than_max:
        "First Vesting Milestone should not be bigger than Vesting Max Length.",
      vesting_length_exceeds_loan:
        "Vesting length exceeds the max length of the loan set above. Please modify your settings.",
      payback_terms_days_cannot_exceed_max: "Number of days cannot exceed {{maxValue}} days",

      vesting_period_required: "Vesting period is required",
      vesting_period_cannot_be_zero: "Value cannot be zero",
      vesting_period_cannot_exceed_max: "Total vesting periods cannot exceed {{maxYears}} years",
      vesting_interval_cannot_exceed_period: "Vesting Interval can not exceed Total Vesting Period",
      choose_end_date_option: "Vesting length can not be equal to Vesting Interval",

      vesting_equally_after_cliff: {
        first_milestone_required: "Please select period length",
        first_milestone_cannot_be_zero: "Value cannot be zero",
        first_milestone_cannot_exceed_max:
          "First milestone must be within {{maxYears}} years from start date",
        first_milestone_cannot_exceed_total_period: "Cliff can not exceed Total Vesting Period",
        first_milestone_cannot_be_equal_to_total_period:
          "Cliff can not be equal to Total Vesting Period",
        first_vesting_amount_required: "Amount is required",
        first_vesting_amount_too_large: "Amount is too large",
        first_vesting_amount_cannot_be_zero: "Amount cannot be zero",
        first_vesting_amount_larger_than_bonus_amount: "Amount can not exceed Bonus amount",
        first_vesting_amount_and_bonus_amount_should_be_same:
          "If there is no vesting after 1st Milestone then 1st Vesting Milestone Amount should be same as Bonus Amount",
      },

      unit_vesting_equally: {
        milestones_quantity_exceeded_max: `Milestones quantity must not exceed ${BONUS_MAX_MILESTONES_QUANTITY}`,
        milestones_quantity_exceeded_goal_quantity: `Milestones quantity must not exceed unit goal quantity`,
        milestones_quantity_required: `Milestones quantity is required`,
        milestones_quantity_cannot_be_zero: `Milestones quantity cannot be zero`,
      },

      goal_quantity_exceeded_max: `Goal quantity must not exceed ${BONUS_MAX_GOAL_QUANTITY}`,
      goal_quantity_cannot_be_zero: `Quantity cannot be zero`,
      goal_quantity_required: `Goal quantity is required`,
      goal_name_exceeded_max: `Goal name must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
      goal_name_required: `Goal name is required`,
      goal_complete_by_required: `Goal completion date is required`,
      goal_complete_by_date_cannot_exceed_max: "End date cannot exceed {{maxYears}} years",
      goal_date_cannot_be_before_start_date: "Goal End date must be after Start Date",
      goal_date_is_too_close_too_start_date:
        "Goal End date and Start Date must be at least 2 days apart",
    },
  },
};

export default editBonusFormTranslations;
