import { FC } from "react";
import { Trans } from "react-i18next";
import { SuccessCircledCheckMarkLarge } from "assets/svg";

import { getSeparatedNumber } from "helpers";
import CurrencySumComponent from "components/CurrencySumComponent";

import { CILabel, CIValue, ContentItem, StyledUIModal } from "./styles";

interface IProps {
  originTranslationPrefix?: string;
  fromAccountNumber: string;
  toAccountNumber: string;
  amount: string;
  onClose: () => void;
  isOpen: boolean;
}

export const TransferSuccessModal: FC<IProps> = ({
  originTranslationPrefix,
  fromAccountNumber,
  toAccountNumber,
  amount,
  onClose,
  isOpen,
}) => {
  const translationPrefix = `${originTranslationPrefix}.modals`;

  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.transfer_success.title`} />}
      message={<Trans i18nKey={`${translationPrefix}.transfer_success.message`} />}
      icon={<SuccessCircledCheckMarkLarge />}
      secondaryButton={{
        text: <Trans i18nKey={`buttons.ok`} />,
      }}
    >
      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.from`} />
        </CILabel>
        <CIValue>{getSeparatedNumber(fromAccountNumber)}</CIValue>
      </ContentItem>

      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.to`} />
        </CILabel>
        <CIValue>**** **** **** {toAccountNumber}</CIValue>
      </ContentItem>

      <ContentItem>
        <CILabel>
          <Trans i18nKey={`${translationPrefix}.labels.amount`} />
        </CILabel>
        <CIValue>
          <CurrencySumComponent sum={Number(amount)} />
        </CIValue>
      </ContentItem>
    </StyledUIModal>
  );
};
