import { FC } from "react";

import IconProps from "./IconProps";

const CreditCard: FC<IconProps> = ({ className }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_7222_26496)">
      <path
        d="M3.25 8.5C3.25 7.70435 3.56607 6.94129 4.12868 6.37868C4.69129 5.81607 5.45435 5.5 6.25 5.5H18.25C19.0456 5.5 19.8087 5.81607 20.3713 6.37868C20.9339 6.94129 21.25 7.70435 21.25 8.5V16.5C21.25 17.2956 20.9339 18.0587 20.3713 18.6213C19.8087 19.1839 19.0456 19.5 18.25 19.5H6.25C5.45435 19.5 4.69129 19.1839 4.12868 18.6213C3.56607 18.0587 3.25 17.2956 3.25 16.5V8.5Z"
        stroke="#F96331"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 10.5H21.25"
        stroke="#F96331"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 15.5H7.26"
        stroke="#F96331"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 15.5H13.25"
        stroke="#F96331"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7222_26496">
        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CreditCard;
