import {
  APPLICATION_NAME,
  APPLICATION_PARENT_NAME,
  APPLICATION_PARENT_NAME_FULL_ALT_UPPERCASE,
} from "constants/systemConstants";

export const bankAccountSectionTranslation = {
  "en-US": {
    point_1: `${APPLICATION_NAME} is a d/b/a of ${APPLICATION_PARENT_NAME_FULL_ALT_UPPERCASE}, Inc. ("${APPLICATION_PARENT_NAME}"). ${APPLICATION_PARENT_NAME} is a financial technology company and is not a bank. Banking services are provided by Thread Bank, Member FDIC. The ${APPLICATION_NAME} Mastercard Debit Card is issued by Thread Bank, Member FDIC, pursuant to a license from Mastercard U.S.A. Inc. and may be used anywhere Mastercard cards are accepted. FDIC insurance is available for funds on deposit through Thread Bank, Member FDIC. Pass-through insurance coverage is subject to conditions.`,
    point_2: `** The interest rate on your account is 2.47% with an Annual Percentage Yield (APY) of 2.50%, effective as of 10/01/2024. Rate is variable and is subject to change at any time three (3) months after account opening. Fees may reduce earnings.`,
    point_3: `*** Employees are eligible to receive 25% of their net pay 2 days before payday - with the use of a ${APPLICATION_NAME} checking account. Terms and conditions apply.`,
  },
};

export default bankAccountSectionTranslation;
