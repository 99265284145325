import { FC } from "react";
import { Trans } from "react-i18next";

import { COMPANY_ESIGN_CONSENT_DISCLOSURE } from "constants/assets";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/shared";

import { Container, CopyrightText, Link, LinksContainer, Text } from "./styles";

interface IProps {
  className?: string;
}

export const Copyright: FC<IProps> = ({ className }) => {
  const translationPrefix = `copyright`;

  return (
    <Container className={className}>
      <LinksContainer>
        <Link href={TERMS_AND_CONDITIONS} target="_blank">
          <Trans i18nKey={`${translationPrefix}.terms_conditions`} />
        </Link>
        <Text>,</Text>
        <Link href={PRIVACY_POLICY} target="_blank">
          <Trans i18nKey={`${translationPrefix}.privacy_policy`} />
        </Link>
        <Text>
          <Trans i18nKey={`common.and`} />
        </Text>
        <Link href={`${COMPANY_ESIGN_CONSENT_DISCLOSURE}?t=${Date.now()}`} target="_blank">
          <Trans i18nKey={`${translationPrefix}.e_sign_agreement`} />
        </Link>
      </LinksContainer>

      <CopyrightText>
        <Trans i18nKey={`${translationPrefix}.copyright`} />
      </CopyrightText>
    </Container>
  );
};
