import { SyncteraAccountTransactionDcSignType } from "types/BETypes";
import { ITransactionTypes } from "components/TransactionsList/types";

export const transactionsListTranslations = {
  "en-US": {
    transaction: "Transaction",
    date: "Date",
    status: "Status",
    type: "Type",
    bank_name_placeholder: "No Bank Name",
    type_descriptions: {
      [ITransactionTypes.BONUS]: {
        description_1: "<1 /> Bonus",
        description_2: "To: <1>{{bankName}}</1><2>{{last4Digits}}</2>",
      },
      [ITransactionTypes.FORGOING]: {
        description_1: "<1 /> Balance Reduction",
        description_2: "Balance Due reduced by {{companyName}}",
      },
      [ITransactionTypes.REPAYMENT]: {
        description_1: "<1 /> Payment",
        description_2: "To: <1>{{bankName}}</1><2>{{last4Digits}}</2>",
      },
    },

    type_descriptions_banking: {
      [SyncteraAccountTransactionDcSignType.DEBIT]: {
        description_1: "-<1 />",
        description_2: "To: <1>{{bankName}}</1> <2>{{last4Digits}}</2>",
      },
      [SyncteraAccountTransactionDcSignType.CREDIT]: {
        description_1: "<1 />",
        description_2: "{{description}}",
      },
    },

    empty_list_title: "Not Found",
    empty_list_description: "We were unable to find any record of your most recent transaction",
    balance_due: "Balance Due",
    loan: "Paid {{loanId}}",
  },
};

export default transactionsListTranslations;
