import { FC, ReactNode } from "react";

import { Label, LabelWrapper, Radio, RadioInput, Wrapper } from "./styles";

interface IProps {
  name: string;
  value: string;
  id: string;
  onChange: (id: string) => void;
  label?: string | ReactNode;
  className?: string;
  isDisabled?: boolean;
}

const RadioButton: FC<IProps> = ({ name, value, id, onChange, label, className, isDisabled }) => {
  const isChecked = id === value;

  return (
    <Wrapper className={className} disabled={isDisabled}>
      <LabelWrapper onChange={() => onChange(id)}>
        <RadioInput type="radio" checked={isChecked} name={name} />
        <Radio className={isChecked ? "checked" : ""}>{isChecked && <div />}</Radio>
        {label && <Label>{label}</Label>}
      </LabelWrapper>
    </Wrapper>
  );
};

export default RadioButton;
