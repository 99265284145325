export const openDownloadLink = (url: string = "", name: string = "") => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  link.setAttribute("target", "_blank");
  link.click();
};

export const openBlobAsLink = (file: Blob) => {
  const url = URL.createObjectURL(file);
  window.open(url, "_blank");
};
