import { FC } from "react";

import IconProps from "./IconProps";

const HelpCenter: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11075_3421)">
      <path
        d="M21 14.1992L18 11.1992H11C10.7348 11.1992 10.4804 11.0939 10.2929 10.9063C10.1054 10.7188 10 10.4644 10 10.1992V4.19922C10 3.934 10.1054 3.67965 10.2929 3.49211C10.4804 3.30458 10.7348 3.19922 11 3.19922H20C20.2652 3.19922 20.5196 3.30458 20.7071 3.49211C20.8946 3.67965 21 3.934 21 4.19922V14.1992Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 15.1992V17.1992C14 17.4644 13.8946 17.7188 13.7071 17.9063C13.5196 18.0939 13.2652 18.1992 13 18.1992H6L3 21.1992V11.1992C3 10.934 3.10536 10.6796 3.29289 10.4921C3.48043 10.3046 3.73478 10.1992 4 10.1992H6"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11075_3421">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199219)" />
      </clipPath>
    </defs>
  </svg>
);

export default HelpCenter;
