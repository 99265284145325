import { FC } from "react";

import { getName } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";

import { FrameContainer, FullScreenLoader } from "uikit";

import useAdminEditWorker from "./useAdminEditWorker";

interface Props {
  className?: string;
}

const AdminEditWorker: FC<Props> = ({ className }) => {
  const { employeeData, isLoading, frameId, handleBack } = useAdminEditWorker();
  const translationData = { fullName: getName(employeeData) || "" };

  return (
    <DashboardLayout
      onBackBtnClick={handleBack}
      className={className}
      metaDataLoading={!employeeData}
      translationValues={translationData}
    >
      {!!isLoading && <FullScreenLoader />}
      {!!employeeData && (
        <>
          <FrameContainer id={frameId} />
        </>
      )}
    </DashboardLayout>
  );
};

export default AdminEditWorker;
