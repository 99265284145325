export const employeeRepaymentCardsTranslations = {
  "en-US": {
    make_payment: "Make a payment",
    make_payment_description: "Make a payment or pay off your entire balance.",
    make_payment_btn: "Make a payment",
    or: "OR",
    configure_special_plan: "Special Payment Plan",
    configure_special_plan_description: "Select a 1 or 3 year low interest repayment plan.",
    configure_special_btn: "Select a Plan",

    make_payment_tooltip:
      "Here you may make a payment towards your balance due from your connected bank account. Failure to pay the balance in full or configure a Special Payment Plan by the end of the repayment period will result in the loan balance going into default.",
    configure_special_plan_tooltip:
      "Don’t have all the money right now to pay off your balance due? Not a problem. You may select a 1 or 3 year low interest Special Payment Plan to pay off your remaining balance over time.  Failure to pay the balance in full or configure a Special Payment Plan by the end of the repayment period will result in the loan balance going into default.",
  },
};

export default employeeRepaymentCardsTranslations;
