import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import WorkersList from "components/WorkersWidgets/WorkersList";
import { ETableVariants } from "components/WorkersWidgets/WorkersList/types";

import { WorkersTableContainer } from "./styles";

interface IProps {}

const AdminPeople: FC<IProps> = () => {
  return (
    <DashboardLayout>
      <>
        <WorkersTableContainer>
          <WorkersList type={ETableVariants.PEOPLE_LIST} />
        </WorkersTableContainer>
      </>
    </DashboardLayout>
  );
};

export default AdminPeople;
