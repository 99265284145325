import ReactSlidingPane from "react-sliding-pane";
import styled from "styled-components";

export const Container = styled(ReactSlidingPane)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.slidePane};

  .slide-pane__header {
    display: none;
  }

  .slide-pane__content {
    padding: 24px 40px 24px 32px;
  }
`;
