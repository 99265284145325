import { FC, useState } from "react";
import { Trans } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { ChevronBottom } from "assets/svg";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import BankAccountInfo from "components/BankAccountInfo";
import MicroDepositInfoModal from "components/MicroDepositInfoModal";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import {
  AddBankBtn,
  Container,
  DDWrapper,
  DDWrapperInner,
  DropDownArrowWrapper,
  OptionsList,
  OptionsListItem,
  SelectedBankAccountWrapper,
  Wrapper,
} from "./styles";

export interface IBankAccountsSelectProps {
  bankAccountsList: GetPlaidBankAccountsResponseDto[];
  selectedBankAccount?: GetPlaidBankAccountsResponseDto;
  onChange: (account: GetPlaidBankAccountsResponseDto) => void | Promise<void>;
  onAddAccount: () => void | Promise<void>;
  onVerify: (bankAccountId: string, verificationToken: string) => void | Promise<void>;
  className?: string;
}

const BankAccountsSelect: FC<IBankAccountsSelectProps> = ({
  bankAccountsList,
  selectedBankAccount,
  onChange,
  onAddAccount,
  onVerify,
  className,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const [isMicroDepositInfoModalOpen, setMicroDepositInfoModal] = useState<boolean>(false);
  const [microDepositInfoModalType, setMicroDepositInfoModalType] = useState<
    | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
    | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION
  >();

  const needVerify = [
    BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
  ].includes(selectedBankAccount?.verificationStatus as BankAccountVerifyStatus);

  const onPendingVerificationClick = (
    verificationStatus:
      | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
      | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
  ) => {
    setMicroDepositInfoModalType(verificationStatus);
    setMicroDepositInfoModal(true);
  };

  const onSelect = (bankAccount: GetPlaidBankAccountsResponseDto) => {
    onChange(bankAccount);
    setOpen(false);
  };

  return (
    <Container className={className}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <Wrapper>
          <DDWrapper>
            <DDWrapperInner isOpen={isOpen}>
              <SelectedBankAccountWrapper className={isOpen ? "isOpen" : ""}>
                <BankAccountInfo
                  bankAccountInfo={selectedBankAccount}
                  onVerify={onVerify}
                  onPendingVerificationClick={needVerify ? onPendingVerificationClick : undefined}
                />
                <DropDownArrowWrapper
                  onClick={() => setOpen((currentValue) => !currentValue)}
                  data-testid="bank_accounts_dropdown_handler_button"
                >
                  <ChevronBottom />
                </DropDownArrowWrapper>
              </SelectedBankAccountWrapper>
              {isOpen && (
                <OptionsList>
                  {bankAccountsList.map((item) => {
                    const isSelectedOption =
                      selectedBankAccount?.bankAccountId === item.bankAccountId;
                    if (isSelectedOption) return;
                    return (
                      <OptionsListItem onClick={() => onSelect(item)} key={item.bankAccountId}>
                        <BankAccountInfo
                          bankAccountInfo={item}
                          onPendingVerificationClick={
                            needVerify ? onPendingVerificationClick : undefined
                          }
                        />
                      </OptionsListItem>
                    );
                  })}
                  <AddBankBtn onClick={() => onAddAccount()} data-testid="add_new_bank_button">
                    <Trans i18nKey={"components.bank_account_select.add_new_bank"} />
                  </AddBankBtn>
                </OptionsList>
              )}
            </DDWrapperInner>
          </DDWrapper>
        </Wrapper>
      </OutsideClickHandler>

      <MicroDepositInfoModal
        isOpen={isMicroDepositInfoModalOpen}
        onClose={() => setMicroDepositInfoModal(false)}
        onBtnClick={() => setMicroDepositInfoModal(false)}
        verificationStatus={
          microDepositInfoModalType || BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION
        }
      />
    </Container>
  );
};

export default BankAccountsSelect;
