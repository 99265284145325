import { ReactNode } from "react";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

export const enum EOptionIds {
  DEFAULT = "DEFAULT",
  COMPANY_BANK_ACCOUNT = "COMPANY_BANK_ACCOUNT",
  DIRECT_DEPOSIT = "DIRECT_DEPOSIT",
  PAPER_CHECK = "PAPER_CHECK",
}

export const enum EWidgetType {
  ONBOARDING = "ONBOARDING",
  SETTINGS = "SETTINGS",
}

export interface IProps {
  className?: string;
  widgetType?: EWidgetType;
}

export interface IRootStateProps extends IProps {
  showTitle?: boolean;
  onCompanyBankAccountClick?: () => void;
  onCompanyBankAccountSelect?: () => void;
  onDirectDepositClick?: (bankAccounts: GetPlaidBankAccountsResponseDto[]) => void;
  onPaperCheckSelect?: () => void;
}

export interface ICompanyBankAccountProps extends IProps {
  onBack?: () => void;
  widgetType?: EWidgetType;
  onKycVerificationSuccess?: () => void;
  onShippingDetailsSuccess?: () => void;
}

export interface IDirectDepositProps extends IProps {
  onBack?: () => void;
  widgetType?: EWidgetType;
}

export interface IBenefit {
  id: string;
  icon?: ReactNode;
  text: string | ReactNode;
  traditionalBank: boolean;
  companyAccount: boolean;
}
