import { ReactNode, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { ArrowIconSmall, ClockAltIcon, UploadIconSmall } from "assets/svg";
import { find } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { rewardPointsSelector, userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { SyncteraAccountBalanceType, UserRole } from "types/BETypes";
import { CardForm, CardStatus } from "types/CardShipping";
import { getFormattedSum, getHiddenNumber, showErrorModal } from "helpers";
import { EComprehensibleCardStatus, getComprehensibleCardStatus } from "helpers/assets/bankCards";
import { getSortedBankCards } from "helpers/bankCards";
import useAuth from "hooks/useAuth";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { EWidgetType } from "components/MultiFactorAuthorization/types";
import PermissionContent from "components/PermissionContent";

import { SectionAlt, SectionTitle } from "uikit";
import { EModalTypes } from "uikit/Modal";

import {
  BankCardResDto,
  ListSyncteraTransactionsResDto,
  queryBankCardsControllerGetSyncteraAccount,
  queryBankCardsControllerListBankCards,
  queryBankCardsControllerListSyncteraTransactions,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

import BankAccountInfo from "../../shared/components/BankAccountInfo";
import { CARD_TABS } from "../EmployeeCardsDetailsPage/constants";
import { showNoMFAModal } from "../shared/modals";
import { IPathParams } from "./types";
import {
  BankAccountInfoTitleAdditionalContent,
  ButtonsContainer,
  CardButton,
  CardButtonsContainer,
  CardInfo,
  CardItem,
  CardNumber,
  CardsContainer,
  CardTitle,
  CIBottom,
  MonthlyStatementsLinkButton,
  RewardPointsContainer,
  RPAmount,
  RPLabel,
  StyledBankCardStatus,
  StyledTransactionsList,
  TransactionsContainer,
  TransferFundsButton,
} from "./styles";

interface IBankAccountNumber {
  id: string;
  title: string | ReactNode;
  number: string;
  isHidden: boolean;
  onShowToggle?: () => void;
}

interface IBankNumbersState {
  accountNumberHidden: boolean;
  routingNumberHidden: boolean;
}

type IEmptyBankCard = Omit<
  BankCardResDto,
  | "cardBrand"
  | "cardStatus"
  | "type"
  | "statusReason"
  | "creationTime"
  | "embossNameLine1"
  | "expirationMonth"
  | "expirationYear"
  | "isPinSet"
> & {
  isEmptyCard: boolean;
};

const useEmployeeBankingPage = () => {
  const { getCurrentUser } = useAuth();

  const [cardsList, setCardsList] = useState<BankCardResDto[] | undefined>(undefined);
  const [bankAccountInfo, setBankAccountInfo] = useState<SyncteraAccountDto | undefined>(undefined);
  const [transactionsList, setTransactionsList] = useState<
    ListSyncteraTransactionsResDto | undefined
  >(undefined);
  const navigationParams = useLocation().state as IPathParams;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [requestsLoading, setRequestsLoading] = useState<boolean>(true);

  const [bankAccountNumbersState, setBankAccountNumbersState] = useState<IBankNumbersState>({
    accountNumberHidden: true,
    routingNumberHidden: true,
  });

  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);

  const currentUser = useAppSelector(userMetadataSelector);
  const rewardPoints = useAppSelector(rewardPointsSelector);
  const translationPrefix = `banking_pages.employee.index`;
  const sharedTranslationPrefix = `banking_pages.shared`;

  const navigate = useNavigate();

  const { fetchData: fetchFactors, factorsData } = useMFASMS({
    widgetType: EWidgetType.MISC,
  });

  const requestCardsList = async () => {
    try {
      const bankCardsListRes = await queryBankCardsControllerListBankCards();

      const sortedBankCards = getSortedBankCards(bankCardsListRes.cards);
      setCardsList(sortedBankCards);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        setCardsList([]);
        setRequestsLoading(false);
      } else {
        showErrorModal(error);
      }
    }
  };

  const requestBankAccountInfo = async () => {
    try {
      const result = await queryBankCardsControllerGetSyncteraAccount();
      setBankAccountInfo(result);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        //Not sure if action is needed
      } else {
        showErrorModal(error);
      }
    }
  };

  const requestTransactionsInfo = async () => {
    try {
      const result = await queryBankCardsControllerListSyncteraTransactions();
      setTransactionsList(result);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        //Not sure if action is needed
      } else {
        showErrorModal(error);
      }
    }
  };

  const handleAddAccount = () => {
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const handleTransferFunds = () => {
    const hasVerifiedFactor = !!factorsData?.some((factor) => factor.isVerified);
    if (!hasVerifiedFactor) {
      showNoMFAModal(() => navigate(routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION));
    } else {
      navigate(routes.EMPLOYEE_TRANSFER_FUNDS);
    }
  };

  //Render Account Info
  const renderBankAccountSection = () => {
    const _translationPrefix = `${translationPrefix}.account_section`;
    const componentTranslationPrefix = `banking_pages.shared.components.bank_account_info`;

    const amount =
      (bankAccountInfo?.balances || []).find(
        (item) => item.type === SyncteraAccountBalanceType.AVAILABLE_BALANCE,
      )?.balance || 0;

    const BANumbers: IBankAccountNumber[] = [
      {
        id: "ba_routing_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.routing_number_label`} />,
        number: bankAccountInfo?.bankRouting || "",
        isHidden: !!bankAccountNumbersState.routingNumberHidden,
        onShowToggle: () => {
          setBankAccountNumbersState({
            ...bankAccountNumbersState,
            routingNumberHidden: !bankAccountNumbersState.routingNumberHidden,
          });
        },
      },
      {
        id: "ba_account_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.account_number_label`} />,
        number: bankAccountInfo?.accountNumber || "",
        isHidden: !!bankAccountNumbersState.accountNumberHidden,
        onShowToggle: () => {
          setBankAccountNumbersState({
            ...bankAccountNumbersState,
            accountNumberHidden: !bankAccountNumbersState.accountNumberHidden,
          });
        },
      },
    ];

    return (
      <BankAccountInfo
        amount={amount}
        items={BANumbers}
        additionalContent={
          <ButtonsContainer>
            <TransferFundsButton onClick={handleTransferFunds} disabled={!amount}>
              <UploadIconSmall />
              <Trans i18nKey={`${_translationPrefix}.transfer_funds_button`} />
            </TransferFundsButton>
          </ButtonsContainer>
        }
        sectionTitleAdditionalContent={
          <BankAccountInfoTitleAdditionalContent>
            <MonthlyStatementsLinkButton
              onClick={() => navigate(routes.EMPLOYEE_BANKING_MONTHLY_STATEMENTS)}
              data-testid="monthly_statements_button"
            >
              <ClockAltIcon />
              <Trans i18nKey={`${_translationPrefix}.monthly_statements_button`} />
            </MonthlyStatementsLinkButton>
            <PermissionContent roles={[UserRole.EMPLOYEE]}>
              <RewardPointsContainer to={routes.EMPLOYEE_BANKING_REWARDS}>
                <RPAmount>
                  {getFormattedSum(rewardPoints?.pointsAmount || 0, { symbol: "", precision: 0 })}
                </RPAmount>
                <RPLabel>
                  <Trans
                    i18nKey={`${_translationPrefix}.reward_points_button`}
                    count={rewardPoints?.pointsAmount || 0}
                  />
                </RPLabel>
              </RewardPointsContainer>
            </PermissionContent>
          </BankAccountInfoTitleAdditionalContent>
        }
      />
    );
  };

  const redirectToPhysicalCardDetails = () => {
    navigate(routes.EMPLOYEE_BANKING_CARDS, { state: { selectedTab: CARD_TABS.PHYSICAL } });
  };

  const redirectToVirtualCardDetails = () => {
    navigate(routes.EMPLOYEE_BANKING_CARDS, { state: { selectedTab: CARD_TABS.VIRTUAL } });
  };

  const getCardAction = (card: Partial<BankCardResDto> | IEmptyBankCard) => {
    const cardActions = {
      [CardForm.PHYSICAL]: redirectToPhysicalCardDetails,
      [CardForm.VIRTUAL]: redirectToVirtualCardDetails,
    };

    return cardActions[card?.form || CardForm.PHYSICAL];
  };

  const renderCard = (data: Partial<BankCardResDto> | IEmptyBankCard) => {
    const buttonsTranslationPrefix = `${sharedTranslationPrefix}.components.bank_cards.card_list_buttons`;
    const commonTranslationPrefix = `common.bank_cards`;
    const { id, form, lastFour } = data;
    const cardStatuses = getComprehensibleCardStatus(data);
    const showNumber = lastFour && cardStatuses.status !== EComprehensibleCardStatus.EMPTY;
    const showStatus = cardStatuses.status !== EComprehensibleCardStatus.EMPTY;
    const hiddenNumberMask = getHiddenNumber("*".repeat(12), 4);
    const onClick = getCardAction(data);

    return (
      <CardItem key={id}>
        <CardInfo>
          <CardTitle>
            <Trans i18nKey={`${commonTranslationPrefix}.card_titles.${form}`} />
          </CardTitle>
          <CIBottom>
            {!!showNumber && (
              <CardNumber>
                {hiddenNumberMask} {lastFour}
              </CardNumber>
            )}
            {!!showStatus && <StyledBankCardStatus data={data} shouldShowAdditionalStatus />}
          </CIBottom>
        </CardInfo>
        <CardButtonsContainer>
          <CardButton
            onClick={form ? onClick : undefined}
            data-testId={`${CardForm?.[data?.form as CardForm]}_card_action_button`}
          >
            <Trans i18nKey={`${buttonsTranslationPrefix}.${form}.${cardStatuses.status}`} />
            <ArrowIconSmall />
          </CardButton>
        </CardButtonsContainer>
      </CardItem>
    );
  };

  const renderCardsSection = () => {
    const _translationPrefix = `${translationPrefix}.cards_section`;

    const cardsListModified: any = cardsList ? [...cardsList] : [];

    const shouldShowVirtualCardPlaceholder = !find(
      cardsListModified,
      (item: BankCardResDto) =>
        item.form === CardForm.VIRTUAL &&
        [CardStatus.UNACTIVATED, CardStatus.ACTIVE].includes(item.cardStatus as CardStatus),
    );

    const shouldShowPhysicalCardPlaceholder = !find(
      cardsListModified,
      (item: BankCardResDto) =>
        item.form === CardForm.PHYSICAL &&
        [CardStatus.UNACTIVATED, CardStatus.ACTIVE].includes(item.cardStatus as CardStatus),
    );

    if (shouldShowVirtualCardPlaceholder) {
      cardsListModified?.unshift({
        id: "virtual_card_placeholder",
        form: CardForm.VIRTUAL,
        lastFour: "",
        isEmpty: true,
      });
    }

    if (shouldShowPhysicalCardPlaceholder) {
      cardsListModified?.unshift({
        id: "physical_card_placeholder",
        form: CardForm.PHYSICAL,
        lastFour: "",
        isEmpty: true,
      });
    }

    return (
      <SectionAlt>
        <SectionTitle>
          <Trans i18nKey={`${_translationPrefix}.title`} />
        </SectionTitle>
        <CardsContainer>
          {cardsListModified?.map((item: any) => <>{renderCard(item)}</>)}
        </CardsContainer>
      </SectionAlt>
    );
  };

  const renderTransactionsSection = () => {
    const _translationPrefix = `${translationPrefix}.transactions_section`;

    return (
      <SectionAlt>
        <SectionTitle>
          <Trans i18nKey={`${_translationPrefix}.title`} />
        </SectionTitle>
        <TransactionsContainer>
          <StyledTransactionsList
            pendingTransactions={transactionsList?.pendingTransactions || []}
            postedTransactions={transactionsList?.postedTransactions || []}
            isLoading={false}
          />
        </TransactionsContainer>
      </SectionAlt>
    );
  };

  const showRequestVirtualCardModal = () => {
    const _translationPrefix = `${translationPrefix}.modals.request_virtual_card`;
    AppEvents.emit("SetGlobalModal", {
      type: EModalTypes.SUCCESS,
      isOpen: true,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.main_button`} />,
        onClick: redirectToVirtualCardDetails,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
      },
    });
  };

  const refetchData = async () => {
    try {
      setLoading(true);
      await getCurrentUser();
      await requestCardsList();
      await requestBankAccountInfo();
      await requestTransactionsInfo();
      await fetchFactors();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    requestCardsList();
    requestBankAccountInfo();
    requestTransactionsInfo();
  }, []);

  useEffect(() => {
    if (!!bankAccountInfo && !!cardsList && !!transactionsList) {
      setRequestsLoading(false);
      if (navigationParams?.showRequestVirtualModal) {
        const hasVirtualCard = find(
          cardsList,
          (item: BankCardResDto) => item.form === CardForm.VIRTUAL,
        );
        if (!hasVirtualCard) {
          showRequestVirtualCardModal();
        }
      }
    }
  }, [bankAccountInfo, cardsList, transactionsList]);

  return {
    isLoading,
    requestsLoading,
    translationPrefix,
    cardsList,
    bankAccountInfo,
    currentUser,
    handleAddAccount,
    renderBankAccountSection,
    renderCardsSection,
    renderTransactionsSection,
    isCreationCompanyBankAccountModalOpen,
    setIsCreationCompanyBankAccountModalOpen,
    refetchData,
    factorsData,
  };
};

export default useEmployeeBankingPage;
