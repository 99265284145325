import { FC } from "react";

import IconProps from "./IconProps";

const Pencil: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon Left" clip-path="url(#clip0_11629_17056)">
      <path
        id="Vector"
        d="M2.66602 13.3342H5.33268L12.3327 6.33419C12.5078 6.15909 12.6467 5.95122 12.7414 5.72245C12.8362 5.49367 12.885 5.24848 12.885 5.00085C12.885 4.75323 12.8362 4.50803 12.7414 4.27926C12.6467 4.05048 12.5078 3.84262 12.3327 3.66752C12.1576 3.49242 11.9497 3.35353 11.7209 3.25877C11.4922 3.16401 11.247 3.11523 10.9993 3.11523C10.7517 3.11523 10.5065 3.16401 10.2778 3.25877C10.049 3.35353 9.84111 3.49242 9.66602 3.66752L2.66602 10.6675V13.3342Z"
        stroke="#627293"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M9 4.33398L11.6667 7.00065"
        stroke="#627293"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11629_17056">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Pencil;
