import { HELP_EMAIL } from "constants/shared";
import { APPLICATION_NAME } from "constants/systemConstants";

export const employeeDashboardTranslation = {
  "en-US": {
    steps_state: {
      title: "Congrats on the vesting bonus {{firstName}}",
      status_section_title: "To activate your vesting bonus, follow these 2 simple steps",
      bonus_step_card: {
        title: "View and accept bonus terms",
        title_declined: "Bonus Terms Declined",
        description:
          "View the terms of your vesting bonus plan. View your vesting schedule and <br/> accept your bonus invitation.",
        view_bonuses_btn: "View My Bonuses",
        view_bonus_btn: "View Bonus",
        bonus_contract: "Bonus contract ",
        accepted_status: "signed and accepted",
        declined_status: "DECLINED",
        voided_status: "VOIDED",
        pending_status: "is <1>PENDING<1>",
      },
      payment_method_step_card: {
        title: "Set up bonus payment method",
        description:
          "After you have accepted the bonus invitation, you can set up your desired payment method for deposit of your money. You can choose to take the all, part, or none of the money at any point.",
        view_bonuses_btn: "View My Bonuses",
        set_up_dep_account_btn: "Set Up Deposit Account",
        dep_account_connected: "Deposit Account Connected",
        view_balance_btn: "View Available Balance",
      },
      funds_withdrawn_card: {
        title: "Transfer Funds",
        description:
          "Once you have connected your bank account and Keep has approved your bonus you may transfer the funds into your account.",
        transfer_funds_btn: "Transfer My Funds",

        pending_status_label: `<1>Awaiting confirmation</1> <br/>from ${APPLICATION_NAME}`,
        confirmed_status_label: "<1>Bonus Confirmed</1> <br/>You may transfer your funds",
        request_submitted_status_label:
          "<1>Transfer Request Submitted</1> <br/>Funds may take 2-4 business days to appear in your account.",
        transferred_status_label:
          "<1>The funds have been transferred</1> <br/>The funds have sent to your bank account.",
        problem_confirming_status_label: `<1>Problem Confirming Your Bonus</1> <br/>There was a problem confirming your bonus with ${APPLICATION_NAME}.`,
        problem_transferring_status_label: `<1>Problem Transferring Funds</1> <br/>There was a problem transferring your funds.Please try again. If the problem persists, please contact <2>${HELP_EMAIL}</2>`,
      },
    },
    existing: {
      title: "Welcome back {{firstName}}",
      my_bonuses_section_title: "My Bonuses",
    },
    termination: {
      title: "Welcome back {{firstName}}",

      no_payment_due_title: "It appears you have a balance due on your account.",
      no_payment_due_text: "Not a problem. Please choose from the following options.",
      no_payment_due_disclaimer:
        "<1>Disclaimer:</1> Failure to pay the balance in full or configure Special Payment Plan by the end of your Repayment Period ending <2>{{repaymentDate}}</2> will result in the loan balance going into default.",
    },
    empty_state: {
      zero_interest_payment_due_text:
        "You have <1>no active bonuses</1> and <2>{{sum}}</2> balance due.",
      view_bonuses_btn: "View My Bonuses",
    },
    repayment_loan: {
      text_main_one:
        "You have <1>{{quantity}} repayment loan</1> with a <2>{{amount}}</2> balance remaining.",
      text_main_other:
        "You have <1>{{quantity}} repayment loan</1> with a <2>{{amount}}</2> balance remaining.",
      text_additional: "Your next payment is due: <1>{{dueDate}}</1>",
      make_payment_btn: "Make a payment",
    },
  },
};

export default employeeDashboardTranslation;
