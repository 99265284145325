import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

const progressBarHeight = 4;
const progressBarBorder = 3;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  justify-content: center;
  gap: 20px;
`;

export const StepLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 ${progressBarBorder}px;
`;

export const StepCompletedIcon = styled.div`
  height: 16px;
  margin-right: 12px;
  display: none;

  svg {
    height: 100%;
    width: auto;

    circle {
      fill: ${({ theme }) => theme.colors.stepIndicatorCompletedIconBg};
    }
  }
`;

export const StepLabel = styled.label`
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.stepIndicatorText};
  line-height: 1.3;
`;

export const StepProgressContainer = styled.div`
  border-width: ${progressBarBorder}px;
  border-style: solid;
  border-color: transparent;
  border-radius: 8px;
`;

export const StepProgress = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.stepIndicatorDefaultBg};
  height: ${progressBarHeight}px;
  border-radius: 8px;
`;

export const SubstepProgress = styled.div`
  flex: 1;
  height: ${progressBarHeight}px;
  border: none;
  background: transparent;
  border-radius: 0;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type,
  &.last-active {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.active {
    background: ${({ theme }) => theme.colors.stepIndicatorActiveBg};
  }
`;

export const StepContainer = styled.div`
  flex: 1;

  &.active {
    ${StepLabel} {
      color: ${({ theme }) => theme.colors.stepIndicatorActiveText};
    }
    ${StepProgress} {
      background: ${({ theme }) => theme.colors.stepIndicatorActiveBg};
    }
  }

  &.clickable {
    cursor: pointer;
    ${StepLabel} {
      cursor: pointer;
    }
  }

  &.completed {
    ${StepCompletedIcon} {
      display: block;
    }
    ${StepProgress} {
      background: ${({ theme }) => theme.colors.stepIndicatorActiveBg};
    }
  }

  &.with-active-substeps {
    ${StepProgressContainer} {
      border-color: ${({ theme }) => theme.colors.stepIndicatorSubstepsBorder};
    }
    ${StepProgress} {
      background: ${({ theme }) => theme.colors.stepIndicatorSubstepsBg};
    }
  }
`;
