import { FC } from "react";

import IconProps from "./IconProps";

const ExclamationRoundedRed: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" fill="#D20000" stroke="white" />
    <path
      d="M7.12829 8.84168C6.86157 8.84168 6.63643 8.73714 6.62479 8.60265L6.22538 3.97881C6.18548 3.51664 6.58821 3.11084 7.12829 3.11084C7.66837 3.11084 8.0711 3.51664 8.03119 3.97881L7.63179 8.60265C7.62015 8.73714 7.395 8.84168 7.12829 8.84168Z"
      fill="white"
    />
    <path
      d="M7.9056 10.1399C7.9056 10.5535 7.55685 10.8888 7.12663 10.8888C6.6964 10.8888 6.34766 10.5535 6.34766 10.1399C6.34766 9.72637 6.6964 9.39111 7.12663 9.39111C7.55685 9.39111 7.9056 9.72637 7.9056 10.1399Z"
      fill="white"
    />
  </svg>
);

export default ExclamationRoundedRed;
