import { FC } from "react";

import IconProps from "./IconProps";

const SmallBonusIconUnit: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="#9B9B9B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" />
    <g clipPath="url(#clip0_3111_38093)">
      <path
        d="M10.3218 9.53123L11.2821 9.2739C11.4588 9.22657 11.6426 9.33604 11.6912 9.5175L15.922 25.3068C15.9706 25.4883 15.8661 25.675 15.6895 25.7223L14.7291 25.9796C14.5525 26.027 14.3686 25.9175 14.32 25.736L10.0893 9.94674C10.0407 9.76528 10.1451 9.57856 10.3218 9.53123Z"
        fill="white"
      />
      <path
        d="M12.9738 10.457L15.0305 18.1326C17.5383 12.9628 23.0103 18.847 25.5201 13.6766L23.4634 6.00098C20.9536 11.1714 15.4817 5.28716 12.9738 10.457Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3111_38093">
        <rect
          width="13.9361"
          height="17.033"
          fill="white"
          transform="translate(10 9.60938) rotate(-15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SmallBonusIconUnit;
