import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { TableContainer } from "../components/Table/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &.without_border_radius {
    ${TableContainer} {
      border-top-left-radius: 0;
    }
  }

  ${TableContainer} {
    z-index: 10;
    border-radius: 8px 8px 8px 8px;

    table {
      z-index: 10;
    }
  }
`;

export const TabsSection = styled.div`
  display: flex;
  height: 46px;

  ${breakpoint("xs", "md")`
    height: 36px;
  `}
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  border-radius: 8px 8px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  background-color: ${({ theme }) => theme.colors.emptyListBg};
  border-bottom: 0;
  margin-right: 9px;
  user-select: none;
  position: relative;
  height: 46px;

  &.inactive {
    z-index: 0;
    cursor: pointer;
    padding-bottom: 5px;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.contentBg};
    z-index: 15;
  }

  ${breakpoint("xs", "md")`
    width: 135px;
    height: 36px;

  `}
`;

export const TILabel = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
