import styled from "styled-components";

import { PhoneInput, PrimaryButton, SecondaryButton } from "uikit";
import { ButtonsContainer, LargeButtonMixin, PrimaryButtonIconed } from "uikit/Buttons/Buttons";
import { inputHeight } from "uikit/Input/styles";

import { EWidgetType } from "../types";

export const Container = styled.div``;

export const TitleContainer = styled.div`
  margin-bottom: 24px;
`;

export const TitleLarge = styled.div`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  line-height: 1.3;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.3;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.3;
`;

export const FormContainer = styled.div``;

export const MainInputContainer = styled.div`
  &.${EWidgetType.SETUP} {
    display: flex;
    width: 760px;
  }
`;

export const MainInputContainerInner = styled.div`
  flex: 1;
`;

export const InputBesideButtonsContainer = styled.div`
  margin-left: 24px;
`;

export const InputBesideSubmitButton = styled(PrimaryButton)`
  height: ${inputHeight}px;
  border-radius: 32px;
  white-space: nowrap;
`;

export const BottomButtonsContainer = styled(ButtonsContainer)`
  margin-top: 32px;
`;

export const CancelButton = styled(SecondaryButton)`
  ${LargeButtonMixin}
  width: auto;
`;

export const SubmitButton = styled(PrimaryButtonIconed)`
  ${LargeButtonMixin}
  width: auto;

  &.${EWidgetType.MISC} {
    svg {
      display: none;
    }
  }
`;
