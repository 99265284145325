import styled from "styled-components";

import { Loader, PrimaryButton } from "uikit";

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 80px;
  background: ${({ theme }) => theme.colors.adminSetupWelcomeContainerBg};
  position: relative;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hidden {
    visibility: hidden;
  }
`;

export const ContainerInnerLeft = styled.div`
  max-width: 480px;
  box-sizing: border-box;
  margin-right: 10px;
`;

export const ContainerInnerRight = styled.div``;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.titleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.25;
  margin-bottom: 12px;
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.adminSetupWelcomeMessageText};
  line-height: 1.5;
  margin-bottom: 32px;
`;

export const StepsListContainer = styled.div`
  margin-bottom: 32px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StepContainerLeft = styled.div`
  margin-right: 24px;
`;

export const StepContainerRight = styled.div``;

export const StepIndexContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.adminSetupWelcomeListItemIndexBorder};
  border-radius: 50%;

  text-align: center;
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.adminSetupWelcomeListItemIndexBorder};
`;

export const StepTitle = styled.div`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.adminSetupWelcomeListItemTitleText};
  margin-bottom: 4px;
`;

export const StepMessage = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.adminSetupWelcomeListItemMessageText};
`;

export const CIRImageContainer = styled.div``;

export const CIRImage = styled.img`
  height: 423px;
  width: auto;
`;

export const ContinueButton = styled(PrimaryButton)``;
