import { ErrorCircledCrossLarge } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { LinkTextMixin, PrimaryButton, UIModal } from "uikit";
import { Container as ModalContainer, Title as ModalTitle } from "uikit/Modal/styles";

export const StyledModal = styled(UIModal)`
  overflow: auto;
  outline: none;
  padding: 20px !important;
  width: 490px !important;

  ${breakpoint("xs", "md")`
    width: 350px!important;
    padding: 15px!important;
  `}

  ${ModalContainer} {
    padding: 18px;

    ${breakpoint("xs", "md")`
      padding: 18px 12px;
    `}

    ${ModalTitle} {
      font-size: ${({ theme }) => theme.size.title};
    }
  }
`;

export const StyledErrorIcon = styled(ErrorCircledCrossLarge)`
  margin-bottom: 16px;
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  max-width: 380px;
  margin: 0 auto 44px;
  line-height: 24px;

  ${breakpoint("xs", "md")`
    margin-bottom: 22px;
    max-width: 100%;
  `}
`;

export const EmailLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  display: inline-block;
`;

export const ConfirmBtn = styled(PrimaryButton)`
  width: 180px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0 auto;

  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;
