import { FC } from "react";

import IconProps from "./IconProps";

const LowerAttrition: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="42"
    viewBox="0 0 40 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_230_720)">
      <path
        d="M15.8945 41.1023C15.8355 41.1014 15.7768 41.0933 15.7199 41.0781L0.516637 37.0313C0.370474 36.9913 0.241407 36.9055 0.149088 36.7869C0.0567689 36.6682 0.0061825 36.5231 0.00488281 36.3736V4.83269C0.0061825 4.68313 0.0567689 4.53805 0.149088 4.41937C0.241407 4.3007 0.370474 4.2149 0.516637 4.1749L15.7199 0.126389C15.8223 0.10029 15.9295 0.0974766 16.0333 0.118191C16.137 0.138906 16.2346 0.182599 16.3187 0.245957C16.4028 0.309314 16.4712 0.390671 16.5187 0.483914C16.5663 0.577157 16.5917 0.679825 16.5931 0.784177V40.4203C16.5931 40.603 16.5195 40.7782 16.3885 40.9074C16.2575 41.0366 16.0798 41.1092 15.8945 41.1092V41.1023ZM1.3811 35.8518L15.2046 39.5317V1.67278L1.3811 5.35275V35.8518Z"
        fill="#C2C2C2"
      />
      <path
        d="M15.895 38.4094C15.7127 38.4072 15.5385 38.3348 15.4097 38.2077C15.2808 38.0806 15.2073 37.9089 15.2051 37.7292V3.47788C15.2051 3.29667 15.2775 3.12274 15.4066 2.99381C15.5357 2.86487 15.7112 2.79133 15.895 2.78906H22.881C23.0647 2.79133 23.2401 2.86487 23.3693 2.99381C23.4984 3.12274 23.5709 3.29667 23.5709 3.47788V6.73941C23.5859 6.83746 23.5792 6.93756 23.5514 7.03286C23.5236 7.12816 23.4753 7.2164 23.4097 7.29159C23.3441 7.36677 23.2628 7.42714 23.1714 7.4685C23.08 7.50986 22.9807 7.53127 22.8801 7.53127C22.7795 7.53127 22.6801 7.50986 22.5887 7.4685C22.4973 7.42714 22.416 7.36677 22.3504 7.29159C22.2849 7.2164 22.2365 7.12816 22.2087 7.03286C22.1809 6.93756 22.1743 6.83746 22.1894 6.73941V4.15635H16.5866V37.7275C16.5848 37.9078 16.5113 38.0802 16.382 38.2077C16.2527 38.3352 16.0778 38.4076 15.895 38.4094Z"
        fill="#C2C2C2"
      />
      <path
        d="M12.3882 23.0126C12.2059 23.0104 12.0317 22.938 11.9028 22.811C11.774 22.6839 11.7005 22.5121 11.6983 22.3324V18.8797C11.6832 18.7817 11.6899 18.6816 11.7177 18.5863C11.7455 18.491 11.7939 18.4028 11.8594 18.3276C11.925 18.2524 12.0063 18.192 12.0977 18.1507C12.1891 18.1093 12.2884 18.0879 12.389 18.0879C12.4896 18.0879 12.589 18.1093 12.6804 18.1507C12.7718 18.192 12.8531 18.2524 12.9187 18.3276C12.9843 18.4028 13.0326 18.491 13.0604 18.5863C13.0882 18.6816 13.0948 18.7817 13.0798 18.8797V22.3376C13.0761 22.5167 13.0019 22.6874 12.8728 22.8134C12.7437 22.9394 12.5698 23.0109 12.3882 23.0126Z"
        fill="#C2C2C2"
      />
      <path
        d="M39.3102 41.1024H15.895C15.7112 41.1001 15.5357 41.0265 15.4066 40.8976C15.2775 40.7687 15.2051 40.5948 15.2051 40.4136V37.722C15.2051 37.5408 15.2775 37.3669 15.4066 37.238C15.5357 37.1091 15.7112 37.0355 15.895 37.0332H39.3102C39.4955 37.0332 39.6732 37.1058 39.8043 37.235C39.9353 37.3641 40.0088 37.5393 40.0088 37.722V40.4136C40.0088 40.5963 39.9353 40.7714 39.8043 40.9006C39.6732 41.0298 39.4955 41.1024 39.3102 41.1024ZM16.5866 39.7402H38.6186V38.4091H16.5866V39.7402Z"
        fill="#C2C2C2"
      />
      <path
        d="M22.9805 12.0415C22.38 12.0418 21.7928 11.8665 21.2934 11.5378C20.794 11.209 20.4047 10.7416 20.1747 10.1947C19.9448 9.64774 19.8845 9.04581 20.0016 8.46508C20.1187 7.88436 20.4078 7.35094 20.8324 6.93228C21.257 6.51361 21.798 6.22851 22.387 6.11309C22.976 5.99766 23.5865 6.05708 24.1412 6.28382C24.6959 6.51056 25.1699 6.89443 25.5033 7.38687C25.8367 7.87932 26.0145 8.45818 26.0141 9.05026C26.0132 9.84329 25.6933 10.6036 25.1246 11.1643C24.5559 11.7251 23.7847 12.0406 22.9805 12.0415ZM22.9805 7.42122C22.6537 7.42122 22.3343 7.51675 22.0626 7.69576C21.7909 7.87476 21.5791 8.1292 21.4541 8.42687C21.329 8.72454 21.2963 9.0521 21.36 9.3681C21.4238 9.68411 21.5812 9.97434 21.8122 10.2022C22.0433 10.43 22.3377 10.5852 22.6582 10.648C22.9787 10.7109 23.3108 10.6786 23.6127 10.5553C23.9146 10.432 24.1727 10.2232 24.3542 9.95534C24.5357 9.68745 24.6326 9.37246 24.6326 9.05026C24.6322 8.61835 24.458 8.20427 24.1483 7.89886C23.8385 7.59345 23.4185 7.42168 22.9805 7.42122Z"
        fill="#FFC808"
      />
      <path
        d="M21.3455 38.4088C20.9892 38.4091 20.6377 38.3279 20.3185 38.1716C19.9994 38.0152 19.7214 37.7879 19.5064 37.5077C19.2915 37.2275 19.1454 36.9019 19.0796 36.5566C19.0139 36.2112 19.0302 35.8556 19.1275 35.5176L22.6083 23.4116C22.6408 23.2968 22.6473 23.1763 22.6274 23.0586L21.3822 15.9277C21.3186 15.5665 21.3362 15.1959 21.4336 14.8421C21.5311 14.4883 21.7061 14.16 21.9463 13.8801C22.2829 13.4943 22.7288 13.2165 23.2268 13.0824C23.7247 12.9483 24.2519 12.964 24.7407 13.1276C25.0366 13.2284 25.305 13.3948 25.5254 13.6139C25.7459 13.833 25.9124 14.0991 26.0122 14.3916L26.6514 16.9196C26.6676 17.084 26.6233 17.2487 26.5266 17.3837C26.4299 17.5186 26.2873 17.6148 26.1247 17.6547C25.9621 17.6945 25.7904 17.6755 25.6408 17.6009C25.4913 17.5263 25.3739 17.4012 25.3101 17.2485L24.6866 14.7721C24.6499 14.6884 24.5956 14.6133 24.5273 14.5518C24.4589 14.4903 24.3781 14.444 24.2901 14.4157C24.0978 14.3483 23.8909 14.3316 23.69 14.3673C23.4891 14.4031 23.3013 14.4901 23.145 14.6196C22.9887 14.7491 22.8695 14.9167 22.7993 15.1057C22.729 15.2947 22.7101 15.4985 22.7444 15.6969L23.988 22.8278C24.0438 23.1456 24.0265 23.4716 23.9373 23.7819L20.4566 35.8878C20.393 36.115 20.4211 36.3578 20.5352 36.565C20.6493 36.7721 20.8404 36.9276 21.0684 36.9988C21.2963 37.0699 21.5434 37.0511 21.7576 36.9464C21.9718 36.8416 22.1365 36.6591 22.2171 36.4371L26.7911 23.4478C26.8684 23.301 26.9966 23.1865 27.1525 23.1252C27.3083 23.0639 27.4812 23.06 27.6397 23.1142C27.7983 23.1683 27.9318 23.2768 28.0158 23.42C28.0999 23.5631 28.1289 23.7313 28.0975 23.8938L23.5217 36.8849C23.3638 37.3299 23.0696 37.7156 22.6798 37.9886C22.29 38.2616 21.8237 38.4085 21.3455 38.4088Z"
        fill="#FFC808"
      />
      <path
        d="M31.4827 38.4088C31.1973 38.4084 30.915 38.3499 30.6536 38.2371C30.3921 38.1242 30.1571 37.9593 29.9633 37.7528L24.8687 32.299C24.5653 31.9917 24.3417 31.6166 24.2173 31.2056C24.1711 31.0675 24.1711 30.9184 24.2173 30.7802L26.0948 25.4884C26.1487 25.3514 26.2456 25.2351 26.3713 25.1563C26.4971 25.0775 26.645 25.0404 26.7936 25.0504C26.9423 25.0604 27.0837 25.117 27.1974 25.2119C27.3111 25.3068 27.3911 25.435 27.4256 25.5779L28.2989 29.8314C28.33 29.9281 28.3805 30.0177 28.4474 30.0948L33.0406 35.0388C33.2997 35.3341 33.4673 35.6966 33.5234 36.0831C33.5795 36.4696 33.5218 36.8639 33.3571 37.219C33.1924 37.574 32.9277 37.875 32.5944 38.0859C32.2612 38.2969 31.8735 38.409 31.4775 38.4088H31.4827ZM25.6128 31.0041C25.6739 31.138 25.7591 31.26 25.8643 31.364L30.9798 36.8384C31.044 36.9066 31.1218 36.9611 31.2083 36.9985C31.2949 37.0358 31.3883 37.0551 31.4827 37.0553C31.6122 37.0555 31.739 37.0196 31.8486 36.9515C31.9581 36.8835 32.0459 36.7863 32.1017 36.6711C32.1575 36.5559 32.179 36.4274 32.1637 36.3007C32.1484 36.1739 32.097 36.054 32.0154 35.9549L27.4134 31.0023C27.1983 30.7525 27.0424 30.4583 26.9575 30.1413L26.5769 28.2816L25.6128 31.0041Z"
        fill="#FFC808"
      />
      <path
        d="M27.097 24.418C26.9874 24.418 26.878 24.4076 26.7704 24.387C26.4024 24.3153 26.0697 24.1233 25.826 23.842C25.5823 23.5608 25.4419 23.2068 25.4274 22.8372L25.3366 20.5176L24.61 19.8288C24.507 19.6984 24.4551 19.5358 24.4636 19.3707C24.4721 19.2057 24.5405 19.0491 24.6564 18.9297C24.7722 18.8102 24.9279 18.7358 25.0947 18.72C25.2616 18.7043 25.4286 18.7482 25.5654 18.8438L26.4928 19.7186C26.6219 19.8414 26.6982 20.0085 26.7058 20.1853L26.8088 22.7786C26.8111 22.8422 26.8352 22.9032 26.877 22.9516C26.9189 23.0001 26.9761 23.0332 27.0394 23.0456C27.081 23.0544 27.1241 23.0535 27.1654 23.0431C27.2066 23.0326 27.2448 23.0129 27.2769 22.9853C27.3098 22.9585 27.3363 22.9249 27.3544 22.8867C27.3725 22.8486 27.3819 22.807 27.3817 22.7649L27.331 19.3535C27.33 19.2743 27.3116 19.1963 27.2772 19.1247C27.2429 19.0531 27.1933 18.9896 27.1319 18.9385L23.8014 16.1454C23.6955 16.0206 23.6379 15.8628 23.6387 15.7001C23.6395 15.5373 23.6987 15.3801 23.8058 15.2563C23.913 15.1325 24.0611 15.0502 24.2239 15.0238C24.3868 14.9974 24.5539 15.0287 24.6956 15.1122L28.0279 17.9053C28.2387 18.0828 28.4089 18.3025 28.5269 18.5498C28.6449 18.7972 28.7083 19.0664 28.7125 19.3397L28.7632 22.7494C28.7664 22.9672 28.7257 23.1835 28.6435 23.3857C28.5612 23.5879 28.439 23.772 28.284 23.9273C28.129 24.0825 27.9442 24.2058 27.7405 24.2901C27.5367 24.3743 27.318 24.4178 27.097 24.418Z"
        fill="#FFC808"
      />
    </g>
    <defs>
      <clipPath id="clip0_230_720">
        <rect width="40" height="41" fill="white" transform="translate(0 0.102539)" />
      </clipPath>
    </defs>
  </svg>
);

export default LowerAttrition;
