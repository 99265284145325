import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { FrameContainer } from "uikit";

import useAdminDocumentsPage from "./useAdminDocumentsPage";

interface Props {
  className?: string;
}

const AdminDocumentsPage: FC<Props> = () => {
  const { frameId } = useAdminDocumentsPage();

  return (
    <DashboardLayout>
      <>
        <FrameContainer id={frameId} />
      </>
    </DashboardLayout>
  );
};

export default AdminDocumentsPage;
