import { FC, useState } from "react";
import { Formik } from "formik";

import { EWidgetType } from "../../types";
import DefaultForm from "./components/DefaultForm/DefaultForm";
import { IVerifyCodeStepProps, IVerifyCodeStepPropsInner } from "./types";
import { validationSchema } from "./validationSchema";
import { Container } from "./styles";

const VerifyCode: FC<IVerifyCodeStepPropsInner> = (props) => {
  const { className } = props;

  return <Container className={className}>{<DefaultForm {...props} />}</Container>;
};

const VerifyCodeContainer: FC<IVerifyCodeStepProps> = (props) => {
  const initialFormData = {};

  const [svoc, setSvoc] = useState(false);

  const handleSubmitAttempt = () => {
    setSvoc(true);
  };

  return (
    <>
      <Formik
        initialValues={initialFormData}
        onSubmit={() => undefined}
        validationSchema={validationSchema}
        validateOnChange={svoc}
        validateOnBlur={false}
      >
        <VerifyCode {...props} formSubmitCallback={handleSubmitAttempt} />
      </Formik>
    </>
  );
};

export default VerifyCodeContainer;
