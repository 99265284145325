import styled from "styled-components";

import { H2, PrimaryButton, SecondaryButton } from "uikit";

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const StyledH2 = styled(H2)`
  margin-bottom: 24px;
`;

export const BankAccountsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BankAccountListRow = styled.div``;

export const MoreInfoBtn = styled(SecondaryButton)`
  width: auto;
`;

export const ContinueSetupBtn = styled(PrimaryButton)`
  width: auto;
`;
