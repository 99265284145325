import { FC } from "react";
import { Trans } from "react-i18next";

import { EModalTypes } from "uikit/Modal";

import { ESMSWidgetSteps } from "../VerifyCode/types";
import { Container, StyledModalContent } from "./styles";

interface IProps {
  className?: string;
}

const SuccessState: FC<IProps> = ({ className }) => {
  const commonPrefix = `components.multi_factor_authorization.sms`;
  const translationPrefix = `${commonPrefix}.steps.${ESMSWidgetSteps.SUCCESS}`;

  return (
    <Container className={className}>
      <StyledModalContent
        type={EModalTypes.SUCCESS}
        title={<Trans i18nKey={`${translationPrefix}.title`} />}
        message={<Trans i18nKey={`${translationPrefix}.description`} />}
      />
    </Container>
  );
};

export default SuccessState;
