import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";
import { isEmpty } from "lodash";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import MicroDepositInfoModal from "components/MicroDepositInfoModal";

import { GetPlaidBankAccountsResponseDto, UserResponseDto } from "utils/swagger_react_query";

import { BankAccountInfoRow } from "../BankAccountInfoRows";
import {
  renderBankInUseLink,
  renderRemoveBankAccountBtn,
  renderVerifyBtn,
} from "../BankAccountInfoRows/renderElements";
import {
  BankAccountListRow,
  BankAccountsListWrapper,
  ConnectBankBtn,
  EmptyStateContainer,
  ESIcon,
  ESMessage,
  ESTitle,
  StyledH2,
  Wrapper,
} from "./styles";

export interface IBankAccountSectionProps {
  userDetails?: UserResponseDto;
  bankAccounts: GetPlaidBankAccountsResponseDto[];
  onConnectBankAccount?: () => void;
  onRemoveBankAccount: (bankAccountId: string) => void;
  onVerifyBankAccount: (bankAccountId: string, accessToken: string) => void;
  onBankInUseClick?: (bankAccountId: string) => void;
}

const ExternalBankAccountsSection: FC<IBankAccountSectionProps> = ({
  userDetails,
  bankAccounts,
  onConnectBankAccount,
  onRemoveBankAccount,
  onVerifyBankAccount,
  onBankInUseClick,
}) => {
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.external_accounts_list_section`;

  const [isMicroDepositInfoModalOpen, setMicroDepositInfoModal] = useState<boolean>(false);
  const [microDepositInfoModalType, setMicroDepositInfoModalType] =
    useState<BankAccountVerifyStatus>();

  const onPendingVerificationClick = (verificationStatus: BankAccountVerifyStatus) => {
    setMicroDepositInfoModalType(verificationStatus);
    setMicroDepositInfoModal(true);
  };

  const getActionsColumn = (item: GetPlaidBankAccountsResponseDto) => {
    const canRemoveBankAccount = true;

    const shouldShowVerifyBtn =
      !!item?.accessToken &&
      (item?.verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION ||
        !!item?.renewLoginRequired);

    const shouldShowBankInUseBtn =
      !!onBankInUseClick &&
      userDetails?.defaultPayDistribution?.some((it) => it.id === item.bankAccountId);

    return (
      <>
        {shouldShowBankInUseBtn && renderBankInUseLink(() => onBankInUseClick(item.bankAccountId))}
        {shouldShowVerifyBtn &&
          renderVerifyBtn(
            () => onVerifyBankAccount(item.bankAccountId, item?.accessToken || ""),
            item?.renewLoginRequired ?? false,
          )}
        {canRemoveBankAccount &&
          renderRemoveBankAccountBtn(() => onRemoveBankAccount(item.bankAccountId), item)}
      </>
    );
  };

  const renderBankAccountsList = () => {
    return bankAccounts?.map((item) => {
      return (
        <BankAccountListRow key={item.bankAccountId}>
          <BankAccountInfoRow
            bankAccountInfo={item}
            onPendingVerificationClick={onPendingVerificationClick}
            actionsColumn={getActionsColumn(item)}
          />
        </BankAccountListRow>
      );
    });
  };

  return (
    <Wrapper>
      <StyledH2>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </StyledH2>

      {isEmpty(bankAccounts) ? (
        <>
          <EmptyStateContainer data-testid={"external_bank_account_empty_state_text"}>
            <ESIcon />
            <Trans
              i18nKey={`${translationPrefix}.empty_state.message`}
              components={{
                1: <ESTitle />,
                2: <ESMessage />,
              }}
            />
            {onConnectBankAccount && (
              <ConnectBankBtn
                onClick={onConnectBankAccount}
                data-testId={`connect_bank_account_button`}
              >
                <PlusThin />
                <Trans i18nKey={`${translationPrefix}.empty_state.button`} />
              </ConnectBankBtn>
            )}
          </EmptyStateContainer>
        </>
      ) : (
        <BankAccountsListWrapper>{renderBankAccountsList()}</BankAccountsListWrapper>
      )}

      {microDepositInfoModalType && (
        <MicroDepositInfoModal
          isOpen={isMicroDepositInfoModalOpen}
          onClose={() => setMicroDepositInfoModal(false)}
          onBtnClick={() => setMicroDepositInfoModal(false)}
          verificationStatus={microDepositInfoModalType}
        />
      )}
    </Wrapper>
  );
};

export default ExternalBankAccountsSection;
