import styled from "styled-components";

import { TooltipContainer } from "uikit/Tooltip/styles";

export const Container = styled.span`
  display: inline-block;
`;

export const StyledTooltipContainer = styled(TooltipContainer)`
  display: inline-block;

  .__react_component_tooltip {
    width: auto;
    min-width: 70px;
    text-align: center;

    &:before {
      display: none;
    }
  }
`;
