import { UserRole } from "types/BETypes";

import { UserResponseDto } from "utils/swagger_react_query";

export const getShouldEmployeeCompleteSignUp = (user: UserResponseDto | null | undefined) => {
  const res = user?.lastActiveRole === UserRole.EMPLOYEE && !user?.termsAcceptedAt;
  return res;
};

export const getShouldAdminCompleteSignUp = (user: UserResponseDto | null | undefined) => {
  const res =
    !user?.termsAcceptedAt &&
    [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.lastActiveRole as UserRole) &&
    !user?.firstName &&
    !user?.lastName;
  return res;
};

export const getShouldOwnerCompleteSignUp = (
  user: UserResponseDto | null | undefined,
  isCompanyExist: boolean,
) => {
  const res = !isCompanyExist || (!isCompanyExist && user?.lastActiveRole === UserRole.OWNER);

  return res;
};
