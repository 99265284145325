import { FC } from "react";

import IconProps from "../IconProps";

const ModalAlert: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="alert-triangle" clipPath="url(#clip0_5594_22536)">
      <path
        id="Vector"
        d="M10.24 3.95647L1.81802 18.0165C1.64449 18.3172 1.5527 18.6581 1.55177 19.0052C1.55083 19.3524 1.64078 19.6938 1.81268 19.9954C1.98458 20.2971 2.23244 20.5484 2.53162 20.7246C2.83079 20.9007 3.17086 20.9955 3.51802 20.9995H20.363C20.7102 20.9955 21.0502 20.9007 21.3494 20.7246C21.6486 20.5484 21.8965 20.2971 22.0684 19.9954C22.2403 19.6938 22.3302 19.3524 22.3293 19.0052C22.3283 18.6581 22.2365 18.3172 22.063 18.0165L13.64 3.95647C13.4627 3.66446 13.2131 3.42306 12.9153 3.25553C12.6176 3.088 12.2817 3 11.94 3C11.5984 3 11.2625 3.088 10.9647 3.25553C10.667 3.42306 10.4174 3.66446 10.24 3.95647Z"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12 9V13"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12 17H12.01"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5594_22536">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ModalAlert;
