import { orderBy } from "lodash";

import { BonusStatus } from "types/BETypes";

import { BonusResponseDto } from "utils/swagger_react_query";

const StatusesOrder = [
  BonusStatus.DRAFT,
  BonusStatus.PENDING,
  BonusStatus.DECLINED,
  BonusStatus.ACCEPTED,
  BonusStatus.ACTIVE,
  BonusStatus.COMPLETED,
  BonusStatus.VESTING_STOPPED,
  BonusStatus.NOT_COMPLETED,
];

export const sortBonusesByStatus = (data: BonusResponseDto[]) => {
  const result = orderBy(
    data,
    [
      (item) => StatusesOrder.indexOf(item.status as BonusStatus),
      (item) => new Date(item?.challengeGoals?.[0]?.completeBy || item.vestingEndDate),
    ],
    ["asc", "desc"],
  );
  return result;
};
