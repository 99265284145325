import { createGlobalStyle } from "styled-components";

import fontsCSS from "./fonts";
import resetCSS from "./reset";

export default createGlobalStyle`
${resetCSS}
${fontsCSS}
body {
    font-weight: 400;
    width: 100%;
    position: relative;
}
a {
    text-decoration: none;
}

html,
body,
#root {
    height: 100%;
}
`;
