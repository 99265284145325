import { FC } from "react";

import IconProps from "./IconProps";

const CheckmarkRoundFilled: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.53848" cy="10.4799" r="9.53848" fill="#339900" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17579 14.9978L15.4164 7.75724L14.1386 6.47949L8.1759 12.4422L4.93888 9.20522L3.66113 10.483L6.89815 13.72L6.89804 13.7201L8.17579 14.9978Z"
      fill="white"
    />
  </svg>
);

export default CheckmarkRoundFilled;
