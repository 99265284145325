import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { ErrorConst, KycStatus, MFAFactorType, PayDistributionType } from "types/BETypes";
import { showErrorModal } from "helpers";
import { useBankAccountLink } from "hooks";
import { isPhysicalCardActiveOrUnactivated } from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal/helpers";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { EWidgetType as MFAWidgetType } from "components/MultiFactorAuthorization/types";

import {
  BankCardResDto,
  queryBankCardsControllerGetSyncteraAccount,
  queryBankCardsControllerListBankCards,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

export interface IEmployeeBankAccountsPageLocationState {
  shouldOpenPlaidModalOnMount?: boolean;
  shouldOpenCompanyAccountModalOnMount?: boolean;
}

export const useEmployeeBankAccountsPage = () => {
  const translationPrefix = "settings_pages.employee.bank_accounts_page";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()?.state as unknown as IEmployeeBankAccountsPageLocationState;
  const currentUser = useAppSelector(userMetadataSelector);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [companyBankAccount, setCompanyBankAccount] = useState<SyncteraAccountDto>();
  const [isBankAccountConnectionMethodModalOpen, setBankAccountConnectionMethodModalOpen] =
    useState<boolean>(false);
  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);
  const [bankCards, setBankCards] = useState<BankCardResDto[]>([]);
  const hasBankCards = !!bankCards.length;
  const shouldUserOrderPhysicalCard = !bankCards.some(isPhysicalCardActiveOrUnactivated);

  const bankAccountLink = useBankAccountLink({
    loadingCallback: setLoading,
    shouldFetchBankAccountsListOnMount: false,
  });

  const { factorsData, fetchData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });
  const isMFAEnabled = !!factorsData?.some(
    (factor) => factor.type === MFAFactorType.SMS && factor.isVerified,
  );

  useEffect(() => {
    if (
      currentUser &&
      (isEmpty(bankAccountLink?.data?.bankAccounts) || isEmpty(companyBankAccount))
    ) {
      initFetch();
    }

    if (location?.shouldOpenPlaidModalOnMount) {
      bankAccountLink.actions.openModal();
      window.history.replaceState({}, ""); //Clearing route state
    } else if (location?.shouldOpenCompanyAccountModalOnMount) {
      setIsCreationCompanyBankAccountModalOpen(true);
      window.history.replaceState({}, ""); //Clearing route state
    }
  }, []);

  const initFetch = async () => {
    try {
      setLoading(true);
      await bankAccountLink.actions.refetchBankAccountsList(false);

      if (currentUser?.kycStatus === KycStatus.ACCEPTED) {
        const bankAccountRes = await queryBankCardsControllerGetSyncteraAccount();
        setCompanyBankAccount(bankAccountRes);
        const bankCardsRes = await queryBankCardsControllerListBankCards();
        setBankCards(bankCardsRes.cards);
        await fetchData();
      }
    } catch (error: any) {
      if (error?.data?.error !== ErrorConst.USER_HAS_NO_PARTNER_BANK_ACCOUNT) {
        showErrorModal(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onBankInUseClick = () => {
    navigate(routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION);
  };

  const onPaidBankBannerBtnClick = () => {
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const handleBackBtnClick = () => navigate(routes.EMPLOYEE_SETTINGS);

  const handleChooseMethod = (method: PayDistributionType) => {
    if (method === PayDistributionType.PARTNER_ACCOUNT) {
      setBankAccountConnectionMethodModalOpen(false);
      setIsCreationCompanyBankAccountModalOpen(true);
    }

    if (method === PayDistributionType.EXTERNAL_ACCOUNT) {
      bankAccountLink.actions.openModal();
    }
  };

  const handleOpenChooseMethodModal = () => {
    if (!companyBankAccount?.id) {
      setBankAccountConnectionMethodModalOpen(true);
      return;
    }
    bankAccountLink.actions.openModal();
  };

  return {
    metadata: {
      isLoading,
      currentUser,
      translationPrefix,
      hasBankCards,
      shouldUserOrderPhysicalCard,
      isMFAEnabled,
    },
    pageData: {
      externalBankAccounts: bankAccountLink.data,
      companyBankAccount,
      bankCards,
    },
    actions: {
      externalBankAccounts: { ...bankAccountLink.actions },
      onBankInUseClick,
      handleBackBtnClick,
      handleChooseMethod,
      handleOpenChooseMethodModal,
      onPaidBankBannerBtnClick,
      initFetch,
    },
    modals: {
      bankAccountConnectionMethodModal: {
        isBankAccountConnectionMethodModalOpen,
        setBankAccountConnectionMethodModalOpen,
      },
      companyBankAccountCreation: {
        isCreationCompanyBankAccountModalOpen,
        setIsCreationCompanyBankAccountModalOpen,
      },
    },
  };
};
