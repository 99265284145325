import { FC } from "react";

import IconProps from "./IconProps";

const ArrowSplit: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7549_14180)">
      <path
        d="M17.5 14.1842H13.0025C12.3326 14.1842 11.6725 14.0226 11.0783 13.7132C10.4841 13.4037 9.97331 12.9556 9.58917 12.4067L9.16083 11.7951C8.77669 11.2462 8.26587 10.7981 7.67168 10.4886C7.07748 10.1792 6.41743 10.0176 5.7475 10.0176H2.5"
        stroke="#17AACF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 5.85107H13.0042C12.3338 5.85104 11.6733 6.01276 11.0788 6.3225C10.4843 6.63223 9.97328 7.08084 9.58917 7.63024L9.16417 8.23857C8.78013 8.78786 8.26925 9.2364 7.67489 9.54613C7.08053 9.85586 6.42022 10.0176 5.75 10.0177H2.5"
        stroke="#17AACF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.35107L17.5 5.85107L15 3.35107"
        stroke="#17AACF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.6841L17.5 14.1841L15 11.6841"
        stroke="#17AACF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7549_14180">
        <rect width="20" height="20" fill="white" transform="translate(0 0.0175781)" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowSplit;
