import { FC } from "react";
import currency from "currency.js";

import {
  defaultOptions,
  getCents,
  getDollars,
  getSymbol,
  separator as separatorValue,
} from "helpers/shared/currency";

import { Container } from "./styles";

interface Props {
  className?: string;
  sum: number | string;
  separator?: string;
  options?: currency.Options;
  showCents?: boolean;
  separateCurrencyPrefix?: boolean;
}

const CurrencySumComponent: FC<Props> = ({
  className,
  sum,
  separator = separatorValue,
  options,
  showCents = true,
  separateCurrencyPrefix,
}) => {
  const additionalOptions = separateCurrencyPrefix
    ? {
        symbol: "",
      }
    : {};

  const _options = { ...defaultOptions, ...options, ...additionalOptions };

  return (
    <Container className={className}>
      {!!separateCurrencyPrefix && <span className="csc-currency">{getSymbol()}</span>}
      <span className="csc-dollars">{getDollars(sum, _options)}</span>
      {showCents && (
        <>
          <span className="csc-separator">{separator}</span>
          <span className="csc-cents">{getCents(currency(Number(sum)).cents())}</span>
        </>
      )}
    </Container>
  );
};

export default CurrencySumComponent;
