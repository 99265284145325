import { LogoFull } from "assets/svg";
import styled from "styled-components";

import { RewardPointsTransactionStatus, RewardPointsTransactionType } from "types/BETypes";

import {
  PrimaryButton,
  RoundBorderedContainer,
  Section,
  SectionAlt,
  SectionInner,
  SectionInnerTitleSmall,
  Tooltip,
} from "uikit";
import { TableContainer, THead } from "uikit/Table/components/Table/styles";

import { IHistoryDataType } from "./types";

const infoPointIconHShift = 6;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const MCLeft = styled.div`
  flex: 1;
`;

export const MCRight = styled.div`
  width: 432px;
`;

//Reward Points Container
export const RewardPointsContainer = styled(Section)`
  padding: 32px;
  background: ${({ theme }) => theme.colors.sectionContainerWarning};
  border: 1px solid ${({ theme }) => theme.colors.sectionBorderWarning};
`;

export const RPTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 42px;
`;

export const StyledLogo = styled(LogoFull)`
  width: auto;
  height: 60px;
`;

export const RPTitle = styled.div`
  margin-top: 16px;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.55;
`;

export const RPBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const RPBLeft = styled.div``;

export const RPBTitle = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.55;
`;

export const RPBPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const RPBPValue = styled.div`
  font-size: ${({ theme }) => theme.size.titleLarger};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.3;
`;

export const RPBPLabel = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.3;
  letter-spacing: -0.28px;
`;

export const RPBValue = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.accentMain};
  line-height: 1.3;
`;

export const RPBValueCurrency = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body1};
  margin-left: 4px;
`;

export const RPBPendingPointsContainer = styled.div`
  color: ${({ theme }) => theme.colors.rewardsPendingPoints};
  display: flex;
  gap: 4px;
`;

export const RPBPendingValue = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RPBPendingLabel = styled.span`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.3;
`;

export const RPBRight = styled.div``;

export const RedeemPointsButton = styled(PrimaryButton)``;

//History Container
export const HistoryContainer = styled(SectionAlt)``;

export const HistoryTableContainer = styled.div`
  ${TableContainer} {
    border: none;
  }

  ${THead} td {
    &.points {
      text-align: right;
    }
  }

  td {
    &.amount {
      text-align: right;
    }
  }
`;

export const TablePointsValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;

  &.${RewardPointsTransactionType.DIRECT_DEPOSIT}, &.${RewardPointsTransactionType.CARD_SPEND} {
    color: ${({ theme }) => theme.colors.successAlt};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.successAlt};
      }
    }
  }

  &.${RewardPointsTransactionStatus.PENDING} {
    color: ${({ theme }) => theme.colors.warning};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.warning};
      }
    }
  }

  &.${RewardPointsTransactionStatus.CANCELED} {
    color: ${({ theme }) => theme.colors.danger};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.danger};
      }
    }
  }

  .__react_component_tooltip {
    width: 100px;
    text-align: center;
  }
`;

export const TPVTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const TPVTooltipIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  svg {
    width: 16px;
    height: 16px;
  }

  &.${IHistoryDataType.DEBIT_PENDING} {
    background: ${({ theme }) => theme.colors.warningAlt3};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.warning};
      }
    }
  }
  &.${IHistoryDataType.DEBIT_CANCELLED} {
    background: ${({ theme }) => theme.colors.dangerAlt3};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.danger};
      }
    }
  }
`;

export const InfoContainer = styled(SectionAlt)``;

export const InfoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InfoItem = styled(SectionInner)`
  padding: 16px;
`;

export const InfoItemInner = styled.div`
  display: flex;
  align-items: center;
`;

export const IITitle = styled(SectionInnerTitleSmall)`
  font-size: ${({ theme }) => theme.size.caption};
`;

export const IIContent = styled.div`
  flex: 1;
`;

export const IICPointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IICPointsIconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IICPointIconBlock = styled.div`
  display: flex;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.rewardsInfoRewardPointBorder};
  background: ${({ theme }) => theme.colors.rewardsInfoRewardPointBg};

  &:nth-of-type(2) {
    left: -${infoPointIconHShift}px;
  }

  &:nth-of-type(3) {
    left: -${infoPointIconHShift * 2}px;
  }

  svg {
    margin-left: 1px;
    margin-top: 1px;
  }
`;

export const IICPointsTitle = styled.div`
  position: relative;
  margin-left: 4px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  .count-2 & {
    left: -${infoPointIconHShift}px;
  }

  .count-3 & {
    left: -${infoPointIconHShift * 2}px;
  }
`;

export const IICPCTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.4;
  margin-top: 8px;
`;

export const IICPCParagraph = styled.div``;

export const IICPCParagraphAlt = styled.div`
  color: ${({ theme }) => theme.colors.textAlt};
  font-style: italic;
`;

export const IISeparator = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0 8px;
  background-color: ${({ theme }) => theme.colors.sectionContainerAlt4};
  padding: 8px 4px;
  border-radius: 5px;
  height: 24px;
`;

export const TermsAndConditionsContainer = styled(SectionAlt)`
  margin-top: 16px;
`;

export const TermsAndConditionsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TermsParagraph = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.4;
`;

//Modals
export const RedeemPointsModalMessageContainer = styled.div``;

export const RedeemPointsModalMessage = styled.div``;

export const RPMRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`;

export const RPMMRow = styled(RoundBorderedContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
`;

export const RPMRLabel = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 2;
`;

export const RPMRValue = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.77;
`;
