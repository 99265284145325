import { IMappedBankAccount } from "types/BankAccounts";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { PayDistributionType } from "types/BETypes";
import { CardForm, CardFulfillmentStatus, CardStatus, CardStatusReason } from "types/CardShipping";

import {
  BankCardResDto,
  GetPlaidBankAccountsResponseDto,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

export const getSortedBankCards = (cards: BankCardResDto[]) => {
  const _physicalCards = cards?.filter((it) => it.form === CardForm.PHYSICAL) || [];
  const _virtualCards = cards?.filter((it) => it.form === CardForm.VIRTUAL) || [];

  // Physical cards
  const modifiedPhysicalCards: BankCardResDto[] = [];
  const inactivePhysicalCards = _physicalCards.filter(
    (it) => ![CardStatus.ACTIVE, CardStatus.UNACTIVATED].includes(it.cardStatus as CardStatus),
  );

  const unActivatedPhysicalCard = _physicalCards.find(
    (it) => it.cardStatus === CardStatus.UNACTIVATED,
  );

  const activePhysicalCard = _physicalCards.find((card) => {
    if (card.cardStatus === CardStatus.ACTIVE) return true;

    if (unActivatedPhysicalCard?.id && card.reissuedToId === unActivatedPhysicalCard.id)
      return true;

    return false;
  });

  if (activePhysicalCard) {
    modifiedPhysicalCards.push(activePhysicalCard);
  }
  if (unActivatedPhysicalCard) {
    modifiedPhysicalCards.push(unActivatedPhysicalCard);
  }

  if (inactivePhysicalCards.length && !activePhysicalCard) {
    //If there is at least one inactive card, and no active card, then the last inactive card should be displayed.
    let lastInactiveCard = inactivePhysicalCards?.slice(-1)[0];

    //User has active reissued card (expiration reason of reissue) and new inactive card => active card became expired and inactive one become canceled => show expired card
    const lastExpiredCardRelevantToLastInactiveCard = _physicalCards.find(
      (it) =>
        it.reissuedToId === lastInactiveCard.id &&
        it.cardStatus === CardStatus.TERMINATED &&
        it.cardFulfillmentStatus === CardFulfillmentStatus.ISSUED &&
        it.statusReason === CardStatusReason.EXP,
    );

    if (lastExpiredCardRelevantToLastInactiveCard) {
      lastInactiveCard = lastExpiredCardRelevantToLastInactiveCard;
    }

    modifiedPhysicalCards.unshift(lastInactiveCard);
  }

  //virtual cards
  const activeVirtualCard = _virtualCards?.find((it) => it.cardStatus === CardStatus.ACTIVE);
  const inactiveVirtualCards = _virtualCards?.filter((it) => it.cardStatus !== CardStatus.ACTIVE);
  const modifiedVirtualCards: BankCardResDto[] = [];
  if (activeVirtualCard) {
    modifiedVirtualCards.push(activeVirtualCard);
  }

  if (inactiveVirtualCards.length && !activeVirtualCard) {
    const lastInactiveCardCard = inactiveVirtualCards?.slice(-1)[0] || [];
    modifiedVirtualCards.push(lastInactiveCardCard);
  }

  return [...modifiedPhysicalCards, ...modifiedVirtualCards];
};

export const isSyncteraAccountDto = (
  card: SyncteraAccountDto | GetPlaidBankAccountsResponseDto,
): card is SyncteraAccountDto => {
  return (
    (card as SyncteraAccountDto)?.accountNumber !== undefined ||
    (card as SyncteraAccountDto)?.bankRouting !== undefined
  );
};

export const mapSyncteraAccountsToPlaidAccount = (
  accounts: SyncteraAccountDto[],
): IMappedBankAccount[] => {
  if (!accounts?.length) return [];

  const serializedData = accounts.map((account) => {
    return {
      bankAccountId: account.id,
      isDefault: undefined,
      plaidAccountId: undefined,
      accountName: undefined,
      type: undefined,
      subType: undefined,
      verificationStatus: undefined,
      verifiedAt: account.createdAt,
      last4Digits: account.accountNumber.slice(-3),
      institution: undefined,
      userId: undefined,
      companyId: undefined,
      currentBalance: undefined,
      availableBalance: undefined,
      accessToken: undefined,
      renewLoginRequired: undefined,
      manualVerificationRequired: undefined,
      manualBankName: undefined,
      manualOwnerName: undefined,
      _accountType: PayDistributionType.PARTNER_ACCOUNT,
    } as IMappedBankAccount;
  });

  return serializedData;
};

export const getSerializedPlaidBankAccounts = (
  bankCards: GetPlaidBankAccountsResponseDto[],
): IMappedBankAccount[] => {
  return bankCards?.map?.((bankCard) => {
    return {
      ...bankCard,
      _accountType: PayDistributionType.EXTERNAL_ACCOUNT,
    } as IMappedBankAccount;
  });
};

export const isBankAccountVerified = (data?: GetPlaidBankAccountsResponseDto) => {
  return [
    BankAccountVerifyStatus.AUTOMATICALLY_VERIFIED,
    BankAccountVerifyStatus.MANUALLY_VERIFIED,
    BankAccountVerifyStatus.INSTANTLY_VERIFIED,
  ].includes(data?.verificationStatus as BankAccountVerifyStatus);
};
