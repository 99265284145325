import { FC } from "react";

import IconProps from "./IconProps";

const OrangeRocket: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1392_2046)">
      <path
        d="M34.8174 0.180833C34.6292 -0.00580262 34.3821 -0.0174673 34.1527 0.0116945C32.8292 0.175001 29.788 0.554104 27.5645 1.19566L33.788 7.3663C34.435 5.16167 34.8174 2.15217 34.9821 0.83989C35.0115 0.61826 34.9997 0.367469 34.8115 0.180833H34.8174Z"
        fill="#F29127"
      />
      <path
        d="M26.7354 1.49306C26.6471 1.48723 26.5589 1.49306 26.4766 1.52805C19.8942 3.861 15.1883 9.02264 11.6177 14.1843C11.0648 14.2543 9.94127 14.3359 8.87656 14.4059C7.69421 14.4759 6.64126 14.5342 6.64126 14.5342C6.47656 14.5401 6.31774 14.6159 6.21185 14.7384L0.747146 20.874C0.541264 21.1015 0.558911 21.4864 0.776558 21.7022L1.97656 22.8862C2.17068 23.0728 2.49421 23.1136 2.72362 22.9736L7.17656 20.2966L7.68244 20.4949C7.50009 20.8157 7.31185 21.1364 7.1295 21.4514C7.00009 21.6789 7.04126 21.988 7.2295 22.1746L12.6236 27.5229C12.8119 27.7095 13.1295 27.7503 13.3589 27.622C13.6707 27.4471 13.9942 27.2604 14.3177 27.0738L14.5177 27.5754L11.8177 31.9846C11.6766 32.2121 11.7177 32.5329 11.906 32.7254L13.106 33.9093C13.3236 34.1251 13.7119 34.1368 13.9413 33.9385L20.1413 28.5202C20.2648 28.4152 20.3413 28.2578 20.3471 28.0945C20.3471 28.0945 20.4001 27.0505 20.4766 25.8782C20.5413 24.8225 20.6295 23.7144 20.7001 23.1603C25.906 19.6259 31.1236 14.96 33.4707 8.4394C33.5001 8.35775 33.5119 8.27026 33.506 8.18278L26.7354 1.49306ZM26.1413 8.77768C27.1413 9.76918 27.1413 11.3731 26.1413 12.3587C25.1413 13.3502 23.5236 13.3502 22.5236 12.3587C21.5236 11.3672 21.5295 9.76335 22.5236 8.77768C23.5236 7.78618 25.1413 7.78618 26.1354 8.77768H26.1413Z"
        fill="#F29127"
      />
      <path
        d="M7.82351 32.4804C8.48821 32.2354 9.05292 31.868 9.49409 31.4306C11.2059 29.7334 11.2059 26.9746 9.49409 25.2774C7.78233 23.5802 4.99998 23.5802 3.28821 25.2774C2.66468 26.0006 2.24115 26.8055 1.95292 27.6979C1.62351 28.7069 0.905859 29.4184 0.00585938 29.9258C0.458801 29.7509 0.917624 29.6809 1.28821 29.64C2.31174 29.5059 2.84704 29.2434 3.44115 28.2753C3.81174 27.6629 4.37645 27.6745 4.65292 27.9486C5.34115 28.6602 3.88233 29.6167 2.82939 30.025C0.911741 30.7715 0.0705653 32.5387 0.235271 35C0.605859 34.3584 1.19998 33.7402 2.22351 33.7169C3.9588 33.6644 5.03527 33.087 5.45292 31.4772C5.67645 30.6024 6.52939 30.2174 6.92939 30.614C7.28821 30.9698 6.9588 31.3256 6.4588 31.623C5.75292 32.1829 5.81174 33.0286 5.85292 33.8102C6.25292 33.0053 7.05292 32.7837 7.82351 32.4862V32.4804Z"
        fill="#F29127"
      />
    </g>
    <defs>
      <clipPath id="clip0_1392_2046">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OrangeRocket;
