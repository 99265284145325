import { FC } from "react";
import { ReactSlidingPaneProps } from "react-sliding-pane";

import { Container } from "./styles";

import "./styles.scss";

interface Props extends ReactSlidingPaneProps {
  className?: string;
}

const SlidingPane: FC<Props> = (props) => {
  return <Container {...props} />;
};

export default SlidingPane;
