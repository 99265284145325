import styled from "styled-components";

import UIModal from "uikit/Modal/Modal";
import { Message } from "uikit/Modal/styles";

export const StyledUIModal = styled(UIModal)`
  width: 560px !important;
`;

export const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 24px;
`;

export const StyledMessage = styled(Message)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 20.8px;
`;
