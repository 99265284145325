export async function s3UploadFromBlob(
  file: Blob,
  presignedPostUrl: string,
  presignedPostFields: Record<string, string>,
  requestOptions?: RequestInit,
): Promise<{ s3AssetUrl: string; s3Key: string }> {
  const form = new FormData();
  Object.keys(presignedPostFields).forEach((key) => form.append(key, presignedPostFields[key]));
  form.append("file", file);
  await fetch(presignedPostUrl, { method: "POST", body: form, ...requestOptions });
  return {
    s3AssetUrl: `${process.env.REACT_APP_REMOTE_ASSETS_HOST}/${presignedPostFields.key}`,
    s3Key: presignedPostFields.key,
  };
}
