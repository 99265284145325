import { isEmail } from "class-validator";
import * as yup from "yup";

export const signInValidationSchema = yup.object({
  email: yup
    .string()
    .test("invalid email", "validationErrors.email.wrong_format", function (value) {
      return !value || isEmail(value);
    })
    .required("validationErrors.common.is_required"),
});

export type SignInFormType = yup.InferType<typeof signInValidationSchema>;
