import { PaidBankCardTemplate } from "assets/svg";
import styled from "styled-components";

import { H3, H4, PrimaryButton, Section } from "uikit";

export const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 40px 20px;
`;

export const Title = styled(H3)`
  margin-bottom: 32px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const CardImage = styled(PaidBankCardTemplate)`
  margin-bottom: 16px;
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.size.body1};
  margin-bottom: 32px;
  text-align: center;
`;

export const PrimaryBtn = styled(PrimaryButton)`
  width: 100%;
  max-width: 400px;
`;
