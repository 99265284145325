import { FC } from "react";

import IconProps from "./IconProps";

const BonusTypeTime: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="91"
    height="88"
    viewBox="0 0 91 88"
    fill="none"
  >
    <g clipPath="url(#clip0_2238_56458)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2203 7.71185V12.6771H27.9524V7.71185H37.8282V12.6771H40.5603V7.71185H50.4383V12.6771H53.1704V7.71185H63.0485V12.6771H65.7806V7.71185H75.6586V12.6771H78.3907V7.71185H83.3295C86.0638 7.71185 88.2683 9.92819 88.2683 12.677V19.7551H15.3422V12.677C15.3422 9.92819 17.5468 7.71185 20.281 7.71185H25.2203ZM78.3907 4.96515H83.3295H85.4312C88.4974 4.96515 91.0005 7.48158 91.0005 10.5642V12.677V22.5018V65.4982C91.0005 69.764 87.5727 73.2101 83.3295 73.2101H51.8053V70.4634H83.3295C86.0638 70.4634 88.2683 68.247 88.2683 65.4982V22.5018H15.3422V35.9183H12.6101V22.5018V12.677V10.5642C12.6101 7.48158 15.1132 4.96515 18.1794 4.96515H20.281H25.2203V0H27.9524V4.96515H37.8282V0H40.5603V4.96515H50.4383V0H53.1704V4.96515H63.0485V0H65.7806V4.96515H75.6586V0H78.3907V4.96515ZM24.4838 41.517C12.4707 41.517 2.7321 51.3076 2.7321 63.3849C2.7321 75.4622 12.4707 85.2528 24.4838 85.2528C36.497 85.2528 46.2356 75.4622 46.2356 63.3849C46.2356 51.3076 36.497 41.517 24.4838 41.517ZM0 63.3849C0 49.7906 10.9618 38.7703 24.4838 38.7703C38.0059 38.7703 48.9677 49.7906 48.9677 63.3849C48.9677 76.9792 38.0059 87.9995 24.4838 87.9995C10.9618 87.9995 0 76.9792 0 63.3849ZM25.8488 48.5954H23.1167V64.7587H37.0925V62.012H25.8488V48.5954Z"
        fill="url(#paint0_linear_2238_56458)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2238_56458"
        x1="9.47921"
        y1="1.79901e-08"
        x2="10.0515"
        y2="54.3704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <clipPath id="clip0_2238_56458">
        <rect width="91" height="88" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BonusTypeTime;
