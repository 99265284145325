import { FC } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import routes from "routes/routes";

import { IMappedBankAccount } from "types/BankAccounts";
import { mapSyncteraAccountsToPlaidAccount } from "helpers/bankCards";
import { isKycPending, isKycRejected } from "helpers/bonus/kyc";
import { CBACard1, CBACard2 } from "components/CompanyBankAccountPromoCards";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";
import { EOptionIds, EWidgetType } from "components/PaymentMethods/types";

import { SyncteraAccountDto, UserResponseDto } from "utils/swagger_react_query";

import { BankAccountInfoRow } from "../BankAccountInfoRows";
import { renderBankInUseLink } from "../BankAccountInfoRows/renderElements";
import { ExternalBankAccountInfoRowColumn } from "../BankAccountInfoRows/types";
import {
  BankAccountListRow,
  BankAccountsListWrapper,
  ContinueSetupBtn,
  MoreInfoBtn,
  StyledH2,
  Wrapper,
} from "./styles";

export interface IPaidBankAccountSectionProps {
  bankAccounts?: SyncteraAccountDto[];
  currentUser?: UserResponseDto;
  widgetType?: EWidgetType;
  isMFAEnabled: boolean;
  onBankInUseClick?: (bankAccountId: string) => void;
  onBannerBtnClick: () => void;
  onContinueSetupClick?: () => void;
}

const PaidBankAccountSection: FC<IPaidBankAccountSectionProps> = ({
  bankAccounts = [],
  currentUser,
  widgetType = EWidgetType.SETTINGS,
  isMFAEnabled,
  onBankInUseClick,
  onBannerBtnClick,
  onContinueSetupClick,
}) => {
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.paid_accounts_list_section`;
  const navigate = useNavigate();
  const mappedBankAccounts = mapSyncteraAccountsToPlaidAccount(bankAccounts);

  const getEmptyStateBannerBtn = () => {
    const _buttonTranslationPrefix = `components.payment_methods`;

    const config: NavigationCardProps = {
      buttonText: (
        <Trans
          i18nKey={`${_buttonTranslationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.create`}
        />
      ),
      onButtonClick: onBannerBtnClick,
    };

    return config;
  };

  const renderBanner = (props: NavigationCardProps, type: EWidgetType) => {
    const isKycPendingOrRejected = isKycPending(currentUser) || isKycRejected(currentUser);

    const component = {
      [EWidgetType.ONBOARDING]:
        !!currentUser?.kycStatus && isKycPendingOrRejected ? (
          <CBACard2 {...props} />
        ) : (
          <CBACard1 {...props} />
        ),
      [EWidgetType.SETTINGS]: <CBACard2 {...props} />,
    };

    return component[type];
  };

  const _actionsColumn = (bankAccount: IMappedBankAccount) => {
    const shouldShowBankInUseBtn =
      !!onBankInUseClick &&
      !!bankAccount.bankAccountId &&
      currentUser?.defaultPayDistribution?.some((it) => it.id === bankAccount.bankAccountId);
    const shouldShowContinueSetupBtn = onContinueSetupClick && !isMFAEnabled;
    const shouldShowInfoButton =
      widgetType !== EWidgetType.ONBOARDING && !shouldShowContinueSetupBtn;
    return (
      <>
        {shouldShowBankInUseBtn &&
          renderBankInUseLink(() => onBankInUseClick(bankAccount?.bankAccountId || ""))}
        {shouldShowInfoButton && (
          <MoreInfoBtn onClick={() => navigate(routes.EMPLOYEE_BANKING)}>
            <Trans i18nKey={`${translationPrefix}.more_info_btn`} />
          </MoreInfoBtn>
        )}
        {shouldShowContinueSetupBtn && (
          <ContinueSetupBtn onClick={onContinueSetupClick}>
            <Trans i18nKey={`${translationPrefix}.continue_setup_btn`} />
          </ContinueSetupBtn>
        )}
      </>
    );
  };

  const renderList = () => {
    return mappedBankAccounts?.map((item) => {
      return (
        <BankAccountListRow key={item.bankAccountId}>
          <BankAccountInfoRow
            bankAccountInfo={item}
            actionsColumn={_actionsColumn(item)}
            displayedColumns={[
              ExternalBankAccountInfoRowColumn.NAME,
              ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME,
              ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
            ]}
          />
        </BankAccountListRow>
      );
    });
  };

  return (
    <Wrapper>
      <StyledH2>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </StyledH2>

      {isEmpty(mappedBankAccounts) ? (
        <>{renderBanner(getEmptyStateBannerBtn(), widgetType)}</>
      ) : (
        <BankAccountsListWrapper>{renderList()}</BankAccountsListWrapper>
      )}
    </Wrapper>
  );
};

export default PaidBankAccountSection;
