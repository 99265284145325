import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { LockIcon } from "assets/svg";
import { Formik, FormikProps } from "formik";

import { COMPANY_CONSUMER_ACCOUNT_AGREEMENT } from "constants/assets";

import { Checkbox } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { ScrollableContainer } from "../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage, renderKycResponseSection } from "../components/renderElements";
import {
  CheckboxText,
  CheckboxTextLink,
  CheckboxWrapper,
  HDescription,
  Header,
  HTitle,
} from "../components/styles";
import {
  KYCProfileForm,
  KYCProfileFormType,
  KYCProfileFormValidationSchema,
} from "./KYCProfileForm";
import { IKYCProfileFormRef } from "./types";
import { EKycResponseType } from "./useKycStatusModals";
import { Container, Content, FormSection } from "./styles";

export interface IKYCVerificationWidgetProps {
  className?: string;
  userDetails: UserResponseDto | null | undefined;
  profileData: {
    formData: KYCProfileFormType;
    onSubmit: (values: KYCProfileFormType) => void;
    setFormData: (data: KYCProfileFormType) => void;
  };
  termAndConditionsCheckboxProps: {
    isChecked: boolean;
    onChange: () => void;
  };
  isLoading?: boolean;
  kycResponseType?: EKycResponseType;
  errorMessage?: string;
}

const KYCVerificationWidget = forwardRef<IKYCProfileFormRef, IKYCVerificationWidgetProps>(
  (
    {
      className,
      profileData,
      userDetails,
      termAndConditionsCheckboxProps,
      kycResponseType,
      isLoading,
      errorMessage,
    },
    ref,
  ) => {
    const translationPrefixOrigin = `components.company_bank_account_components`;
    const translationPrefix = `${translationPrefixOrigin}.kyc_verification_widget`;
    const verificationStepTranslationPrefix = `${translationPrefix}.verification_step`;
    const canVerifyKyc = !userDetails?.kycStatus || !kycResponseType;
    const [svoc, setSvoc] = useState<boolean>(false);
    const { isChecked, onChange } = termAndConditionsCheckboxProps;
    const formikRef = useRef<FormikProps<KYCProfileFormType>>(null);

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        setSvoc(true);
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Container className={className}>
        <Header>
          <HTitle>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </HTitle>
          <HDescription>
            <Trans i18nKey={`${translationPrefix}.protection_description`} />
            <LockIcon />
          </HDescription>
        </Header>

        {renderErrorMessage(errorMessage)}

        <Formik<KYCProfileFormType>
          innerRef={formikRef}
          onSubmit={(values) => profileData.onSubmit(values)}
          initialValues={profileData.formData}
          validationSchema={KYCProfileFormValidationSchema}
          validateOnChange={svoc}
        >
          <>
            {canVerifyKyc ? (
              <>
                <ScrollableContainer>
                  <FormSection>
                    <KYCProfileForm
                      originTranslationPrefix={translationPrefixOrigin}
                      isLoading={isLoading}
                      setFormData={profileData.setFormData}
                    />
                  </FormSection>
                </ScrollableContainer>

                <CheckboxWrapper>
                  <Checkbox
                    checked={isChecked}
                    disabled={isLoading}
                    onCheck={onChange}
                    onUncheck={onChange}
                  >
                    <CheckboxText>
                      <Trans
                        i18nKey={`${translationPrefix}.verification_step.terms_and_conditions`}
                        components={{
                          1: (
                            <CheckboxTextLink
                              href={COMPANY_CONSUMER_ACCOUNT_AGREEMENT}
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    </CheckboxText>
                  </Checkbox>
                </CheckboxWrapper>
              </>
            ) : (
              !!kycResponseType && (
                <Content>
                  <>
                    {renderKycResponseSection(kycResponseType, verificationStepTranslationPrefix)}
                  </>
                </Content>
              )
            )}
          </>
        </Formik>
      </Container>
    );
  },
);

KYCVerificationWidget.displayName = "VerificationStep";

export default KYCVerificationWidget;
