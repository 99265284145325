export const personalEmailModalTranslations = {
  "en-US": {
    title: "Personal Email Address",
    subTitle:
      "Please provide a personal email address that is different<br />than the primary email address you use to sign into your<br />account. This may be used for recovery purposes.",
    personalEmailTitle: "Please enter your new personal email address",
    personalEmailLabel: "Personal Email Address",
    verifyPersonalEmailTitle: "Please re-enter your new personal email address",
    verifyPersonalEmailLabel: "Re-enter your Personal Email Address",
    submit: "Submit",
    cancel: "Cancel",
    successModal: {
      title: "Personal email saved",
      message: "Your profile personal email was successfully saved.",
      ok: "OK",
    },
  },
};

export default personalEmailModalTranslations;
