import styled from "styled-components";

import { Body2, Body3, SectionAlt } from "uikit";

export const Container = styled.div`
  display: flex;
  gap: 32px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
`;

export const StyledSectionAlt = styled(SectionAlt)`
  position: relative;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SwitchTextWrapper = styled.div``;

export const SwitchTextTitle = styled(Body2)``;

export const SwitchTextDescription = styled(Body3)`
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const RightSection = styled.div``;

export const PromoImage = styled.img`
  width: 316px;
  height: 317px;
`;
