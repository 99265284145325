export const employeeGroupsTableTranslations = {
  "en-US": {
    search_placeholder: "Search by Name, Role, Department or Group",
    create_group_btn: "Create Group",
    archived_groups_count_text: "Archived Groups: {{count}}",
    empty_state_message: "No Groups",
    columns: {
      group_name: "Group Name",
      members: "Members",
    },
    context_menu: {
      edit_group_btn: "Edit Group",
      // delete_group_btn: "Delete Group",
      // archive_group_btn: "Archive Group",
    },
    archived_groups_modal: {
      title: "Archived Groups",
      restore_btn: "Restore",
    },
  },
};

export default employeeGroupsTableTranslations;
