import { FC } from "react";
import { Trans } from "react-i18next";

import { ButtonsContainer, FullScreenLoader, ModalContent, Section } from "uikit";
import { EModalTypes } from "uikit/Modal";

import { BackButton, ConfirmPayDistributionButton } from "../../styles";
import { IWidgetProps } from "./types";
import useCheckForm from "./useCheckForm";
import { Container } from "./styles";

const EmptyComponent: FC<IWidgetProps> = (props) => {
  const { className } = props;
  const translationPrefix = `components.pay_distribution.paper_check_form`;
  const { isLoading, renderBackButton, renderSubmitButton } = useCheckForm(props);

  return (
    <Container className={className}>
      {!!isLoading && <FullScreenLoader />}
      <Section>
        <ModalContent
          type={EModalTypes.CARD}
          title={<Trans i18nKey={`${translationPrefix}.title`} />}
          message={<Trans i18nKey={`${translationPrefix}.message`} />}
        />
      </Section>

      <ButtonsContainer>
        {renderBackButton()}
        {renderSubmitButton()}
      </ButtonsContainer>
    </Container>
  );
};

export default EmptyComponent;
