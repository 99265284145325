import { FC } from "react";

import IconProps from "./IconProps";

const DoubleChevrons: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5" clip-path="url(#clip0_10961_595)">
      <path
        d="M13 7L18 12L13 17"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 7L12 12L7 17"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10961_595">
        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
);

export default DoubleChevrons;
