export const LOCAL_STORAGE_KEY = "__redirectionLink__";

export const clearRedirectionLink = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const saveRedirectionLink = (link: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, link);
};

export const getRedirectionLink = (clearStorage?: boolean) => {
  const link = localStorage.getItem(LOCAL_STORAGE_KEY);
  clearStorage && clearRedirectionLink();
  return link;
};
