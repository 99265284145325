import { APPLICATION_PARENT_NAME_FULL } from "constants/systemConstants";

export const keepFooterTranslations = {
  "en-US": {
    footer_title: "Keep employers and employees together longer",
    footer_subtitle: "Attracting and keeping top talent has never been easier.",
    footer_1: "Flexible Vesting <br /> Bonus Plans",
    footer_2: "Starting <br /> Bonuses",
    footer_3: "Retention <br /> Bonuses",
    footer_4: "Increase <br /> Productivity",
    footer_5: "Lower <br /> Attrition",
    copyright: `© 2024 ${APPLICATION_PARENT_NAME_FULL} Technologies Inc. All rights reserved.`,
    privacy_policy: "Privacy Policy",
    terms_of_service: "Terms of Service",
    terms_block: "{{one}} | {{two}}",
  },
};

export default keepFooterTranslations;
