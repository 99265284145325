import styled, { css } from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton } from "uikit";

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  background: ${({ theme }) => theme.colors.paymentMethodsLargeContentBg};
  text-align: center;

  ${breakpoint("xs", "xlm")`
    padding: 16px 16px 32px 16px;
  `}
`;

export const LogoContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.titleLarger};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.4;
  margin-bottom: 24px;

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.title};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  `}
`;

export const TitleAlt = styled(Title)`
  ${breakpoint("xs", "md")`
    margin-bottom: 12px;
  `}

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.subtitle};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1.77;
  `}
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.33;
  margin-bottom: 24px;
  max-width: 672px;

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.subtitle};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1.55;
  `}
`;

export const PromoCardsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 16px auto 56px auto;
  max-width: 1000px;
  box-sizing: border-box;

  ${breakpoint("xs", "md")`
    margin: 0 auto 24px auto;
  `}

  ${breakpoint("xs", "xlm")`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

export const PromoCard = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBg};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardText};
  line-height: 1.71;
  box-sizing: border-box;

  ${breakpoint("xs", "xlm")`
    width: 100%;
    margin-bottom: 12px;
    text-align: initial;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${breakpoint("xl", "md")`
    font-size: ${({ theme }) => theme.size.caption};
    line-height: 1.5;
  `}
`;

export const PromoCardIcon = styled.div`
  margin-right: 12px;

  ${breakpoint("xs", "xlm")`
    margin-right: 16px;

    img, svg {
      width: auto;
      height: 24px;
    }
  `}
`;

export const PromoCardText = styled.div``;

export const benefitTitleMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.34;
`;

export const BenefitsTableContainer = styled.div`
  width: 100%;
  max-width: 792px;
  box-sizing: border-box;
  margin: 16px 0 24px 0;
  background: ${({ theme }) => theme.colors.contentBg};
  padding: 20px;

  ${breakpoint("xs", "md")`
    display: none;
  `}
`;

export const BenefitsTable = styled.table``;

export const BTHeaderRow = styled.tr``;

export const BTHeaderCell = styled.th`
  ${benefitTitleMixin}
  box-sizing: border-box;
  padding: 20px 16px;

  &:nth-of-type(1) {
    text-align: left;
  }
`;

export const BTHeaderCellAdditionalText = styled.div``;

export const BTRow = styled.tr``;

export const BTCell = styled.td`
  box-sizing: border-box;
  padding: 10px 16px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.66;

  &:nth-of-type(1) {
    width: 46%;
    text-align: left;
  }
  &:nth-of-type(2) {
    width: 27%;
  }
  &:nth-of-type(3) {
    width: 27%;
  }
`;

export const BenefitIconContainer = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }

  &.checked {
    svg {
      circle {
        fill: ${({ theme }) => theme.colors.paymentMethodsBenefitTrueIconBg};
      }
      line {
        fill: ${({ theme }) => theme.colors.paymentMethodsBenefitTrueIconLine};
      }
    }
  }
  &.unchecked {
    circle {
      fill: ${({ theme }) => theme.colors.paymentMethodsBenefitFalseIconBg};
    }
    rect {
      fill: ${({ theme }) => theme.colors.paymentMethodsBenefitFalseIconLine};
    }
  }
`;

//Mobile benefits

export const MobileBenefitsContainer = styled.div`
  margin-bottom: 12px;
  width: 100%;

  ${breakpoint("md")`
    display: none;
  `}
`;

export const MobileBenefitCard = styled.div`
  padding: 20px 16px;
  margin-bottom: 12px;
  background: ${({ theme }) => theme.colors.contentBg};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const MBCTitle = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: initial;
`;

export const MBCList = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

export const MBCListItem = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const MBCContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  background: ${({ theme }) => theme.colors.paymentMethodsLargeContentBg};
`;

export const MBCBenefit = styled.div``;

export const MBCBTitle = styled.div`
  ${benefitTitleMixin}
  font-size: ${({ theme }) => theme.size.caption};
  margin-bottom: 12px;
`;

export const MBCBIcon = styled.div``;

export const CreateAccountButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 400px;

  ${breakpoint("xs", "md")`
    margin-top: 12px;
  `}
`;
