import { Trans } from "react-i18next";

import { COMPANY_ACCOUNT_PROMOTION_POINTS } from "../../constants";
import { IBenefit } from "../../types";

export const useNoAccountState = () => {
  const translationPrefix = `components.payment_methods`;
  const benefitsTranslationPrefix = `${translationPrefix}.promotion_points`;

  const benefitsList: IBenefit[] = [
    ...COMPANY_ACCOUNT_PROMOTION_POINTS,
    {
      id: "benefit_4",
      text: <Trans i18nKey={`${benefitsTranslationPrefix}.point_4`} />,
      traditionalBank: true,
      companyAccount: true,
    },
    {
      id: "benefit_5",
      text: <Trans i18nKey={`${benefitsTranslationPrefix}.point_5`} />,
      traditionalBank: true,
      companyAccount: true,
    },
    {
      id: "benefit_6",
      text: <Trans i18nKey={`${benefitsTranslationPrefix}.point_6`} />,
      traditionalBank: true,
      companyAccount: true,
    },
    {
      id: "benefit_7",
      text: <Trans i18nKey={`${benefitsTranslationPrefix}.point_7`} />,
      traditionalBank: true,
      companyAccount: true,
    },
  ];

  const benefitsListWithDifference = benefitsList.filter(
    (item) => item.companyAccount !== item.traditionalBank,
  );
  const benefitsListSame = benefitsList.filter(
    (item) => !!item.companyAccount && item.companyAccount === item.traditionalBank,
  );

  return {
    benefitsList,
    benefitsListWithDifference,
    benefitsListSame,
  };
};
