export const bonusTemplatesListTable = {
  "en-US": {
    columns: {
      status: "Status",
      type: "Type",
      title: "Title",
      bonus_amount: "Maximum Bonus Amount",
      assigned_to: "Assigned to",
      tracking_type: "Tracking Type",
      record_progress_btn: "Record Progress",
      remaining_days: "Days Remaining",
      footer: {
        total_bonus_amount: "Maximum Bonus Amount",
      },
      context_menu: {
        view_btn: "View Bonus Template",
        edit_btn: "Edit Bonus Template",
        delete_btn: "Delete Bonus Template",
      },
    },
  },
};

export default bonusTemplatesListTable;
