import { APPLICATION_NAME } from "constants/systemConstants";
import { EKycResponseType } from "components/CompanyBankAccountComponents/KYCVerificationWidget";

const companyBankAccountComponents = {
  "en-US": {
    company_bank_account_form: {
      page_title: "Bank Accounts",

      title: `Order ${APPLICATION_NAME} Debit Card`,
      description: `Where would you like your new ${APPLICATION_NAME} Debit Card to be shipped?`,
      shipping_details: {
        title: "Shipping Details",
        cardholder_agreement:
          "I have read and understood the terms of the <1>cardholder agreement</1>",
      },
      card_template_section: {
        title: `${APPLICATION_NAME} Debit Card`,
        description: "Use at ATMs and anywhere MasterCard is accepted.",
      },
    },
    mfa_widget: {
      title: "Enable Two-Factor Authentication",
      description: "Enhance your account security by adding an extra layer of protection.",
    },
    kyc_verification_widget: {
      title: `Create ${APPLICATION_NAME} Bank Account`,
      protection_description: "Provide you personal information and create Paid Bank Account",

      verification_step: {
        title: "Provide your personal information",
        terms_and_conditions: `I agree to the <1>terms and conditions</1> for setting up a ${APPLICATION_NAME} Bank Account`,
        restart_verification_btn: "Restart Verification",

        verification_statuses: {
          [EKycResponseType.SUCCESS]: {
            title: `${APPLICATION_NAME} Bank Account Created!`,
            message: `You successfully verified your personal information and created ${APPLICATION_NAME} Bank Account`,
          },
          [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: {
            title: "Pending Verification",
            message:
              "Verifying the information is taking longer that expected. You will be notified via email when the process is complete. It may take up to a few hours. Sorry for the wait.",
          },
          [EKycResponseType.ERROR]: {
            title: "Trouble verifying your identity",
            message:
              "Please review your information to ensure it is correct. If you continue face problems, please contact:<br/><1></1>",
          },
          [EKycResponseType.INCORRECT_INFO]: {
            title: "Please update your information.",
            message: `
                  You've already submitted this information. Please update your information to avoid errors.
                  <br/>
                  If you continue to have problems, please contact:
                  <br/>
                  <1></1>
                `,
          },
        },
        modals: {
          [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: {
            title: "What’s taking so long?",
            message: `Verifying the information is taking longer that expected. You will be notified via email when the process is complete. It may take up to a few hours. Sorry for the wait.`,
          },
        },
      },
    },
    early_pay_form: {
      title: "Enroll in Early Pay",
      description: "Get 25% of your net pay 2 days early at ZERO cost.",
      switch: {
        title: `Enroll in Early Pay`,
        description: `Yes, I would like to enroll in Early Pay and get 25% of my Net Pay 2 days before payday at ZERO cost.`,
      },
    },
    bank_account_setup_completed_section: {
      title: `${APPLICATION_NAME} Bank Account Set Up Finished!`,
      message: `Great job! You got everything to use your ${APPLICATION_NAME} Bank Account!`,
    },
    physical_card_order_success_modal: {
      title: "Physical Card has been successfully ordered",
      message: `Great job! You got everything to use your ${APPLICATION_NAME} Bank Account!`,
    },
    finish_setup_banner: {
      title: "Finish setting up your Paid Bank Account",
      message: "Continue with ordering your card to unlock all features.",
      continue_setup_btn: "Continue Setup",
    },
    shared: {
      promo_section: {
        title: "Setup Paid Bank Account",
        description: "To enjoy exclusive benefits, follow these",
        sub_title: "{{stepsNumber}} simple steps:",
        steps_descriptions_list: {
          personal_information: {
            title: "Create Paid Bank Account",
            description: "Provide your personal information and create Paid Bank Account",
          },
          early_pay: {
            title: "Enroll in Early Pay",
            description: "Decide if you want to get 25% of your net pay 2 days early at ZERO cost",
          },
          order_physical_card: {
            title: `Order your physical ${APPLICATION_NAME} card`,
            description: `To start using your ${APPLICATION_NAME} Bank Account you need to request a Physical Card`,
          },
          mfa_configuration: {
            title: `Enable Two-Factor Authentication`,
            description: `To proceed with transferring funds enable Two-Factor Authentication (2FA)`,
          },
        },
      },
      steps: {
        steps_current_total_text: "Step {{currentStep}} of {{totalSteps}}",
        skip_button: "Skip for now",
        list: {
          kyc_verification: {
            title: `Create ${APPLICATION_NAME} Bank Account`,
          },
          early_pay: {
            title: "Enroll in Early Pay",
          },
          shipping_details: {
            title: `Order Physical ${APPLICATION_NAME} card`,
          },
          mfa_configuration: {
            title: `Enable 2FA`,
          },
        },
      },
      info_protected_tooltip:
        "This form uses advanced security measures to protect your personal information",
      profile_form: {
        form_labels: {
          first_name: "First Name",
          last_name: "Last Name",
          phone: "Phone Number",
          social_security_number: "Social Security Number",
          birth_date: "Birth Date",
          address: "Address",
          suite: "Apartment",
          city: "City",
          state: "State",
          zip: "Zip Code",
        },
      },
      skip_step_confirmation: {
        title: "Are you sure you want to skip this step?",
        message: "Your physical card won't be shipped",
      },
      close_form_confirmation: {
        title: "Are you sure you want to cancel?",
        common: "If you cancel now, your Paid Bank Account setup won’t be completed",
        shipping: "If you cancel now, Physical Card won't be shipped",
        continue_btn: "Continue Setup",
      },
    },
  },
};

export default companyBankAccountComponents;
