import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .infinite-scroll-component {
    overflow: initial !important;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 34px;
`;
