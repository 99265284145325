import { FC } from "react";

import IconProps from "./IconProps";

const EmployeesCircledIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="39.5"
      cy="39.5"
      r="38.5"
      fill="white"
      stroke="url(#paint0_linear_1_208)"
      strokeWidth="2"
    />
    <g clipPath="url(#clip0_1_208)">
      <path
        d="M31.431 34.9084C31.431 37.6977 32.6754 40.2227 34.708 41.851C30.7425 44.2251 29.7305 48.8995 29.3338 52.476C29.2499 53.2272 29.7742 53.9047 30.5048 53.991C30.5555 53.9964 30.6061 54 30.6568 54C31.3244 54 31.8994 53.4842 31.9781 52.7869C32.6125 47.0718 34.472 44.2538 38.1964 43.3642C38.7836 43.224 39.2048 42.6956 39.2258 42.0756C39.245 41.4556 38.8588 40.9003 38.282 40.7205C35.7758 39.9388 34.0928 37.6024 34.0928 34.9084C34.0928 31.5585 36.7423 28.8339 40 28.8339C43.2577 28.8339 45.9072 31.5585 45.9072 34.9084C45.9072 37.6042 44.2242 39.9388 41.718 40.7205C41.1412 40.9003 40.7533 41.4556 40.7742 42.0756C40.7935 42.6956 41.2164 43.224 41.8036 43.3642C45.528 44.2538 47.3893 47.0736 48.0219 52.7869C48.1058 53.5381 48.7647 54.079 49.4952 53.991C50.2258 53.9047 50.7501 53.2272 50.6662 52.476C50.2695 48.8977 49.2575 44.2251 45.292 41.851C47.3246 40.2209 48.569 37.6977 48.569 34.9084C48.569 30.0488 44.724 26.0968 40 26.0968C35.276 26.0968 31.431 30.0506 31.431 34.9084Z"
        fill="url(#paint1_linear_1_208)"
      />
      <path
        d="M46.9576 25.7212C48.0744 24.4596 49.6665 23.7371 51.3251 23.7371C54.5828 23.7371 57.2323 26.4617 57.2323 29.8116C57.2323 32.5074 55.5493 34.842 53.0431 35.6237C52.4663 35.8035 52.0783 36.3588 52.0993 36.9788C52.1185 37.5988 52.5415 38.1272 53.1287 38.2674C56.853 39.157 58.7126 41.9768 59.347 47.6901C59.4239 48.3892 60.0006 48.9032 60.6683 48.9032C60.7189 48.9032 60.7696 48.8996 60.8203 48.8942C61.5508 48.8079 62.0752 48.1304 61.9913 47.3792C61.5945 43.8009 60.5826 39.1283 56.6171 36.7542C58.6497 35.1241 59.894 32.6009 59.894 29.8116C59.894 24.952 56.0491 21 51.3251 21C48.9202 21 46.6115 22.0496 44.9897 23.8773C44.4951 24.4362 44.5335 25.3025 45.0771 25.8111C45.6206 26.3197 46.463 26.2801 46.9576 25.7212Z"
        fill="url(#paint2_linear_1_208)"
      />
      <path
        d="M19.3332 48.905C20.0009 48.905 20.5776 48.3892 20.6545 47.6919C21.2889 41.9768 23.1485 39.1588 26.8728 38.2692C27.46 38.129 27.8812 37.6006 27.9022 36.9806C27.9214 36.3606 27.5352 35.8052 26.9584 35.6255C24.4522 34.8438 22.7692 32.5074 22.7692 29.8134C22.7692 26.4635 25.4187 23.7389 28.6764 23.7389C30.335 23.7389 31.9254 24.4614 33.0439 25.723C33.5385 26.2819 34.3809 26.3215 34.9244 25.8129C35.468 25.3043 35.5064 24.438 35.0118 23.8791C33.3882 22.0496 31.0795 21 28.6747 21C23.9489 21 20.1057 24.9538 20.1057 29.8116C20.1057 32.6009 21.3501 35.1259 23.3827 36.7542C19.4171 39.1283 18.4052 43.8027 18.0085 47.3809C17.9246 48.1322 18.4507 48.8097 19.1794 48.896C19.2301 48.9014 19.2808 48.905 19.3315 48.905H19.3332Z"
        fill="url(#paint3_linear_1_208)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_208"
        x1="8.22917"
        y1="1.61503e-08"
        x2="8.76042"
        y2="48.8096"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_208"
        x1="31.549"
        y1="26.0968"
        x2="31.7942"
        y2="43.3354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_208"
        x1="46.4507"
        y1="21"
        x2="46.7523"
        y2="38.2366"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_208"
        x1="19.8079"
        y1="21"
        x2="20.1095"
        y2="38.2377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <clipPath id="clip0_1_208">
        <rect width="44" height="33" fill="white" transform="translate(18 21)" />
      </clipPath>
    </defs>
  </svg>
);

export default EmployeesCircledIcon;
