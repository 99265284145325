export const loanVestingScheduleTranslations = {
  "en-US": {
    accordion_header: {
      total_principal: "Principal ",
      interest_rate: "Interest Rate ",
      start_date: "Start Date ",
    },
    accordion: {
      title_one: "{{count}} Year Special Payment Plan Schedule",
      title_other: "{{count}} Years Special Payment Plan Schedule",
      mobile_title_one: "{{count}} Year Repayment Loan",
      mobile_title_other: "{{count}} Years Repayment Loan",
    },
    columns: {
      period: "Period",
      date: "Date",
      monthly_payment: "Monthly Payment",
      interest_payment: "Interest Payment",
      principal_payment: "Principal Payment",
      loan_balance: "Loan Balance",
    },
    additional_final_payment: "Additional Final Payment",
    remaining_balance: "Remaining Balance",
  },
};

export default loanVestingScheduleTranslations;
