import { FC } from "react";
import { CheckmarkRoundFilled } from "assets/svg";

import { Step } from "./types";
import {
  Container,
  StepCompletedIcon,
  StepContainer,
  StepLabel,
  StepLabelContainer,
  StepProgress,
  StepProgressContainer,
  SubstepProgress,
} from "./styles";

interface Props {
  className?: string;
  steps: Step[];
  currentStep: number;
  currentSubstep?: number;
}

const StepIndicator: FC<Props> = ({ className, steps, currentStep, currentSubstep }) => {
  const renderSubStep = (item: Step, parentItem: Step) => {
    const isActive = parentItem.index === currentStep && item.index <= (currentSubstep || 0);
    const isLastActive = parentItem.index === currentStep && item.index === (currentSubstep || 0);

    const className = `${isActive ? "active" : ""} ${isLastActive ? "last-active" : ""}`;
    return <SubstepProgress className={className} />;
  };

  const renderStep = (item: Step) => {
    const { isCompleted = currentStep > item.index } = item;
    const isActive = item.index === currentStep;
    const showSubstepProgress = !!item.substeps?.length && item.index === currentStep;

    const className = `
      ${isActive ? "active" : ""} 
      ${isCompleted ? "completed" : ""} 
      ${showSubstepProgress ? "with-active-substeps" : ""}
      ${item.onClick ? "clickable" : ""}`;

    return (
      <StepContainer
        data-testid={`step-indicator-step_${item.index}`}
        key={item.index.toString()}
        className={className}
        onClick={item.onClick}
      >
        <StepLabelContainer>
          <StepCompletedIcon data-testid={`step-indicator-compelete-step-icon_${item.index}`}>
            <CheckmarkRoundFilled />
          </StepCompletedIcon>
          <StepLabel>{item.label}</StepLabel>
        </StepLabelContainer>
        <StepProgressContainer>
          <StepProgress>
            {!!item.substeps?.length && (
              <>
                {item.substeps.map((substep: Step) => (
                  <>{renderSubStep(substep, item)}</>
                ))}
              </>
            )}
          </StepProgress>
        </StepProgressContainer>
      </StepContainer>
    );
  };

  return (
    <Container className={className}>
      {steps.map((item: Step) => (
        <>{renderStep(item)}</>
      ))}
    </Container>
  );
};

export default StepIndicator;
