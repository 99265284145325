import { FC } from "react";

import IconProps from "./IconProps";

const ChevronLeftThin: FC<IconProps> = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <path
      d="M7 0.999999L1.2832 6.71733C1.24605 6.75443 1.21657 6.7985 1.19646 6.847C1.17635 6.8955 1.166 6.94749 1.166 7C1.166 7.05251 1.17635 7.1045 1.19646 7.153C1.21657 7.2015 1.24605 7.24557 1.2832 7.28267L7 13"
      stroke="#0F1826"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronLeftThin;
