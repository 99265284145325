import { FC } from "react";

import { Container, StyledIcon, Text } from "./styles";

interface Props {
  className?: string;
  email?: string;
}

const EmailWithIcon: FC<Props> = ({ className, email }) => {
  return (
    <Container className={className}>
      <StyledIcon />
      <Text>{email}</Text>
    </Container>
  );
};

export default EmailWithIcon;
