import { FC } from "react";
import { ChevronRight } from "assets/svg";

import { IProps } from "./types";
import {
  ChainContainer,
  ChainLink,
  ChainLinkContainer,
  ChainSeparator,
  ChainText,
  Container,
} from "./styles";

const Breadcrumbs: FC<IProps> = (props) => {
  const { className, breadcrumbs = [] } = props;

  return (
    <Container className={className}>
      <ChainContainer>
        {breadcrumbs.map((item, index) => (
          <ChainLinkContainer key={item.id}>
            {index !== breadcrumbs.length - 1 ? (
              <ChainLink to={item.link || ""}>{item.title}</ChainLink>
            ) : (
              <ChainText>{item.title}</ChainText>
            )}
            <ChainSeparator>
              <ChevronRight />
            </ChainSeparator>
          </ChainLinkContainer>
        ))}
      </ChainContainer>
    </Container>
  );
};

export default Breadcrumbs;
