import { FC } from "react";

import IconProps from "./IconProps";

const PlusLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default PlusLarge;
