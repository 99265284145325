import { FC, ReactNode, useRef, useState } from "react";
import DatePickerComponent, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Calendar } from "assets/svg";
import { range } from "lodash";

import { defaultDateFormat } from "constants/shared";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import {
  Container,
  DateFormat,
  DateLabel,
  DateWrapper,
  ErrorContainer,
  MonthsYearsHeaderContainer,
  MYHMonthSelect,
  MYHYearSelect,
} from "./styles";

import "react-datepicker/dist/react-datepicker.css";

export type Props = {
  type?: string;
  label?: ReactNode | string;
  name?: string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  useMothsYearsHeader?: boolean;
  showDateFormat?: boolean;
  disableManualInput?: boolean;
  errorDataTestId?: string;
} & React.ComponentProps<typeof DatePickerComponent> &
  BaseInputProps;

const DatePicker: FC<Props> = (props) => {
  const {
    className,
    label,
    error,
    onFocus,
    onBlur,
    optional,
    readOnly,
    disabled,
    value,
    alwaysShowErrorBlock = true,
    useMothsYearsHeader = false,
    showDateFormat = false,
    disableManualInput = false,
    minDate,
    maxDate,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const pickerRef = useRef<DatePickerComponent<string, boolean> | null>(null);

  const years = range((minDate || new Date()).getFullYear(), new Date().getFullYear() + 11, 1);
  const months = [
    t("common.months.january"),
    t("common.months.february"),
    t("common.months.march"),
    t("common.months.april"),
    t("common.months.may"),
    t("common.months.june"),
    t("common.months.july"),
    t("common.months.august"),
    t("common.months.september"),
    t("common.months.october"),
    t("common.months.november"),
    t("common.months.december"),
  ];

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleCalendarOpen = () => {
    const component = pickerRef?.current;
    component?.setOpen?.(true);
  };

  const isFloatingLabel = !props.readOnly && label && (isFocused || props.selected);

  const renderMonthsYearsHeader = ({
    date,
    changeYear,
    changeMonth,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <MonthsYearsHeaderContainer>
        <MYHMonthSelect
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </MYHMonthSelect>

        <MYHYearSelect
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(Number(value))}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </MYHYearSelect>
      </MonthsYearsHeaderContainer>
    );
  };

  return (
    <Container
      className={`custom-date-picker ${className} ${isFloatingLabel ? "with-floating-label" : ""}`}
    >
      <DateWrapper>
        <DatePickerComponent
          ref={pickerRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          renderCustomHeader={
            useMothsYearsHeader ? (params) => renderMonthsYearsHeader(params) : undefined
          }
          onChangeRaw={
            disableManualInput
              ? (e) => {
                  e.preventDefault();
                }
              : undefined
          }
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          {...rest}
        />
        {renderLabel(label, optional)}
        <DateLabel className={disabled ? "disabled" : ""} onClick={handleCalendarOpen}>
          <Calendar />
        </DateLabel>
      </DateWrapper>
      {showDateFormat && <DateFormat>{defaultDateFormat.toLocaleLowerCase()}</DateFormat>}
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={props.errorDataTestId}>{error} </ErrorContainer>
      )}
    </Container>
  );
};

export default DatePicker;
