import { RepaymentPeriodDaysNumber } from "types/BETypes";

export const RepaymentPeriodOptions = [
  {
    value: RepaymentPeriodDaysNumber[60],
    label: "60",
  },
  {
    value: RepaymentPeriodDaysNumber[90],
    label: "90",
  },
  {
    value: RepaymentPeriodDaysNumber[120],
    label: "120",
  },
];
