import { FC } from "react";

import IconProps from "./IconProps";

const ClockAltIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 16.0002H1.16667C0.989856 16.0002 0.820286 15.9299 0.695262 15.8049C0.570238 15.6799 0.5 15.5103 0.5 15.3335C0.5 15.1567 0.570238 14.9871 0.695262 14.8621C0.820286 14.7371 0.989856 14.6668 1.16667 14.6668H6.5C6.67681 14.6668 6.84638 14.7371 6.9714 14.8621C7.09643 14.9871 7.16667 15.1567 7.16667 15.3335C7.16667 15.5103 7.09643 15.6799 6.9714 15.8049C6.84638 15.9299 6.67681 16.0002 6.5 16.0002ZM5.16667 13.3335H1.16667C0.989856 13.3335 0.820286 13.2633 0.695262 13.1382C0.570238 13.0132 0.5 12.8436 0.5 12.6668C0.5 12.49 0.570238 12.3204 0.695262 12.1954C0.820286 12.0704 0.989856 12.0002 1.16667 12.0002H5.16667C5.34348 12.0002 5.51305 12.0704 5.63807 12.1954C5.76309 12.3204 5.83333 12.49 5.83333 12.6668C5.83333 12.8436 5.76309 13.0132 5.63807 13.1382C5.51305 13.2633 5.34348 13.3335 5.16667 13.3335ZM3.83333 10.6668H1.16667C0.989856 10.6668 0.820286 10.5966 0.695262 10.4716C0.570238 10.3465 0.5 10.177 0.5 10.0002C0.5 9.82335 0.570238 9.65378 0.695262 9.52876C0.820286 9.40373 0.989856 9.3335 1.16667 9.3335H3.83333C4.01014 9.3335 4.17971 9.40373 4.30474 9.52876C4.42976 9.65378 4.5 9.82335 4.5 10.0002C4.5 10.177 4.42976 10.3465 4.30474 10.4716C4.17971 10.5966 4.01014 10.6668 3.83333 10.6668Z"
      fill="#17AACF"
    />
    <path
      d="M9.16667 15.9701C8.98986 15.9779 8.81717 15.9152 8.68658 15.7958C8.55599 15.6763 8.47821 15.5099 8.47034 15.3331C8.46247 15.1563 8.52516 14.9836 8.64462 14.853C8.76409 14.7224 8.93053 14.6446 9.10734 14.6367C10.369 14.5213 11.5716 14.0488 12.5744 13.2745C13.5771 12.5002 14.3385 11.4562 14.7693 10.2648C15.2001 9.07334 15.2825 7.78384 15.0068 6.54728C14.7312 5.31072 14.1089 4.17831 13.2128 3.28268C12.3168 2.38704 11.1841 1.76527 9.94742 1.49019C8.71073 1.21511 7.42127 1.2981 6.23005 1.72945C5.03884 2.1608 3.99519 2.92265 3.22136 3.92576C2.44752 4.92887 1.97553 6.13172 1.86067 7.39341C1.85279 7.48061 1.82782 7.5654 1.78717 7.64294C1.74652 7.72049 1.691 7.78927 1.62377 7.84535C1.55654 7.90144 1.47892 7.94374 1.39534 7.96982C1.31177 7.99591 1.22387 8.00529 1.13667 7.99741C1.04948 7.98953 0.964684 7.96455 0.887139 7.9239C0.809594 7.88325 0.740815 7.82773 0.684728 7.7605C0.628642 7.69327 0.586346 7.61565 0.560257 7.53208C0.534167 7.4485 0.524794 7.36061 0.532674 7.27341C0.7186 5.22228 1.68841 3.32188 3.24023 1.96779C4.79204 0.613697 6.8063 -0.0897472 8.86371 0.00388451C10.9211 0.0975163 12.8632 0.98101 14.2856 2.47045C15.708 3.9599 16.5012 5.94054 16.5 8.00007C16.5102 9.99897 15.7676 11.9285 14.4199 13.4047C13.0722 14.881 11.2182 15.7959 9.22667 15.9674C9.20673 15.9693 9.18671 15.9702 9.16667 15.9701Z"
      fill="#17AACF"
    />
    <path
      d="M8.50001 4C8.3232 4 8.15363 4.07024 8.02861 4.19526C7.90358 4.32029 7.83334 4.48986 7.83334 4.66667V8C7.83338 8.1768 7.90364 8.34634 8.02868 8.47133L10.0287 10.4713C10.1544 10.5928 10.3228 10.66 10.4976 10.6584C10.6724 10.6569 10.8396 10.5868 10.9632 10.4632C11.0868 10.3396 11.1569 10.1724 11.1585 9.9976C11.16 9.8228 11.0928 9.6544 10.9713 9.52867L9.16668 7.724V4.66667C9.16668 4.48986 9.09644 4.32029 8.97141 4.19526C8.84639 4.07024 8.67682 4 8.50001 4Z"
      fill="#17AACF"
    />
  </svg>
);

export default ClockAltIcon;
