import styled from "styled-components";

import ContactInfo from "components/ContactInfo";
import CopyText from "components/CopyText";

import { LinkTextMixin } from "uikit";

export const StyledTitle = styled.div`
  margin-top: 32px;
`;

export const ImageIcon = styled.img`
  height: 130px;
`;

export const StyledContactInfo = styled(ContactInfo)`
  position: relative;
`;

export const LinkText = styled.span`
  ${LinkTextMixin}
`;

export const StyledCopyText = styled(CopyText)`
  margin-top: 12px;
`;
