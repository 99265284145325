import { FC, ReactNode } from "react";

import { Loader } from "uikit";

import { ChecklistItem } from "./components";
import {
  Container,
  CopyrightContainerMobile,
  Futures,
  FuturesContainer,
  FuturesSecondLine,
  LeftSection,
  LoaderWrapper,
  LSDescription,
  LSFooter,
  LSHeader,
  LSHeaderBottom,
  LSHTop,
  LSMain,
  LSTitle,
  RightSection,
  StyledCopyright,
  StyledLogoWithBg,
  Wrapper,
} from "./styles";

export interface IAuthLayoutProps {
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  leftSectionParams?: {
    title: string;
    description: string;
    checkListItems: string[];
  };
  leftSectionCustomContent?: ReactNode;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  leftSectionParams,
  leftSectionCustomContent,
  children,
  isLoading,
  className,
}) => {
  const leftSectionClassName = leftSectionParams?.checkListItems?.length ? "with-checklist" : "";

  return (
    <Container className={className}>
      <Wrapper>
        {leftSectionParams && !leftSectionCustomContent && (
          <LeftSection className={leftSectionClassName}>
            <LSHeader>
              <LSHTop>
                <StyledLogoWithBg />
              </LSHTop>

              <LSHeaderBottom>
                <LSTitle>{leftSectionParams.title}</LSTitle>
                <LSDescription>{leftSectionParams.description}</LSDescription>
              </LSHeaderBottom>
            </LSHeader>

            {leftSectionParams?.checkListItems && (
              <LSMain>
                <FuturesContainer>
                  <Futures key={"first-line"}>
                    {leftSectionParams.checkListItems.map((item, index) => (
                      <ChecklistItem key={index} label={item} />
                    ))}
                  </Futures>
                </FuturesContainer>

                <FuturesContainer>
                  <FuturesSecondLine key={"second-line"}>
                    {leftSectionParams.checkListItems.map((item, index) => (
                      <ChecklistItem key={index} label={item} />
                    ))}
                  </FuturesSecondLine>
                </FuturesContainer>
              </LSMain>
            )}

            <LSFooter>
              <StyledCopyright />
            </LSFooter>
          </LeftSection>
        )}

        {!leftSectionParams && leftSectionCustomContent && (
          <LeftSection>{leftSectionCustomContent}</LeftSection>
        )}

        <RightSection>
          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}

          {children}
        </RightSection>
      </Wrapper>

      <CopyrightContainerMobile>
        <StyledCopyright />
      </CopyrightContainerMobile>
    </Container>
  );
};

export default AuthLayout;
