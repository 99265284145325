import styled from "styled-components";

import { breakpoint } from "helpers";

import { CloseModalButton, NoBorderButton, PrimaryButton, SearchInput, SlidingPane } from "uikit";
import { InputWrapper, Units } from "uikit/Input/styles";
import { AdditionalTableHeader } from "uikit/Table/components/Table/styles";

import { ETableVariants } from "./types";

import "react-sliding-pane/dist/react-sliding-pane.css";

//Table
export const Container = styled.div``;
export const TableContainer = styled.div`
  td {
    text-align: left;

    &.userInfo {
      width: 35%;
      padding-left: 20px;
      text-align: left;

      ${breakpoint("xs", "xl")`
        width: auto;
      `}
    }

    &.email {
      width: 30%;
    }

    &.department {
      width: 30%;
    }

    &.employment_type {
      width: 20%;
    }

    &.status {
      width: 9%;
    }

    &.contextMenu {
      width: 6%;
      text-align: right;
      padding-right: 24px;
      box-sizing: border-box;
      overflow: visible;
    }
  }

  &.${ETableVariants.PEOPLE_LIST} {
    td {
      &.employment_type {
        width: 39%;
      }
      &.status {
        width: 20%;
      }
    }
  }
`;

export const THeaderSection = styled(AdditionalTableHeader)`
  display: flex;
  flex: 1;

  ${breakpoint("xs", "md")`
    flex-direction: column;
  `}
`;

export const HLeftContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${breakpoint("xs", "md")`
    margin-bottom: 10px;
  `}
`;

export const HRightContent = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: flex-end;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  max-width: 340px;
  margin-right: 20px;

  ${InputWrapper} {
    height: 40px;

    ${Units} {
      min-width: 40px;
      width: 40px;
      height: 100%;
    }

    input {
      height: 100%;
      font-size: ${({ theme }) => theme.size.caption};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }

  ${breakpoint("md", "xl")`
    width: 100%;
    margin-right: 10px;
    flex: 1;
  `}
  ${breakpoint("xs", "md")`
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0px;
    order: -2;
  `}
`;

export const AddEmployeeBtn = styled(PrimaryButton)`
  width: 180px;
  height: 40px;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 24px;

  ${breakpoint("md", "xl")`
    width: 150px;
  `};

  ${breakpoint("xs", "md")`
    order: -1;
    margin-left: 0;
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
  `}
`;

export const OpenCsvImportModalBtn = styled(NoBorderButton)`
  width: auto;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const AddWorkerPane = styled(SlidingPane)`
  width: 680px !important;
`;

export const AddWorkerCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
