import styled from "styled-components";

import { breakpoint } from "helpers/";
import ListAdminInfo from "components/ListEmployeeInfo";
import { AvatarWrapper, ContentWrapper, LeftSection } from "components/ListEmployeeInfo/styles";

import { PrimaryButton, TableList, TextOverflowMixin, Tooltip, TooltipMessage } from "uikit";
import { TBody, Tr } from "uikit/Table/components/Table/styles";

export const Container = styled.div`
  flex: none;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  margin-bottom: 47px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.87;
`;

export const InviteTeamMemberBtn = styled(PrimaryButton)`
  margin: 0 0 0 auto;
  padding: 0;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 180px;

  ${breakpoint("md", "xl")`
    width: 240px;
  `}
  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 430px;
  `}
`;

export const StyledTableList = styled(TableList)`
  margin-bottom: 140px;

  ${TBody} {
    ${Tr} {
      ${breakpoint("xs", "md")`
        height: 60px;
      `}
    }
  }

  & .userInfo {
    width: 190px;
    padding: 0 15px 0 20px;

    ${breakpoint("md", "xl")`
        width: 150px;
      `}

    ${breakpoint("xs", "md")`
        width: 210px;
        box-sizing: border-box;
      `}
  }
  & .ownUser {
    width: 50px;

    ${breakpoint("xs", "md")`
        display: none;
      `}
  }

  & .email {
    width: 190px;
    padding: 0 20px;

    ${breakpoint("md", "xl")`
        width: 150px;
      `}
    ${breakpoint("xs", "md")`
        display: none;
      `}
  }

  & .createdAt,
  .lastSignedIn {
    width: 80px;

    ${breakpoint("md", "xl")`
        width: 90px;
      `}
  }

  & .createdAt {
    ${breakpoint("xs", "md")`
        display: none;
      `}
  }

  & .companyRole {
    padding-left: 15px;
    width: 90px;

    ${breakpoint("md", "xl")`
        padding-left: 10px;
        width: 70px;
      `}

    ${breakpoint("xs", "md")`
        display: none;
      `}
  }

  & .status {
    padding-left: 15px;
    width: 60px;

    ${breakpoint("xs", "xl")`
        display: none;
      `}
  }

  & .contextMenu {
    width: 50px;
    text-align: right;
    padding-right: 27px;
    overflow: visible;

    ${breakpoint("md", "xl")`
        width: 30px;
      `}
  }

  & .email {
    color: ${({ theme }) => theme.colors.accentMain};
    font-size: ${({ theme }) => theme.size.caption};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const StyledListAdminInfo = styled(ListAdminInfo)`
  ${breakpoint("xs", "md")`
    ${ContentWrapper} {
      p {
        font-weight: ${({ theme }) => theme.fontWeight.regular}!important;
        font-size: ${({ theme }) => theme.size.small}!important;
        line-height: 1.33;
      }
    }
    ${LeftSection} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 33px;

      ${AvatarWrapper} {
        margin: 0;
      }
    }
  `}
`;

export const StyledTooltip = styled(Tooltip)`
  & div {
    opacity: 1;
    ${TooltipMessage} {
      color: ${({ theme }) => theme.colors.textAlt};
    }
  }
`;

export const CreatedAtColumn = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const LastSignedInRow = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const CompanyRoleColumn = styled.p`
  color: ${({ theme }) => theme.colors.accentMain};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StatusColumn = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;

  &.ACTIVE {
    color: ${({ theme }) => theme.colors.titleAlt};
  }

  &.INVITED {
    color: ${({ theme }) => theme.colors.titleDot};
    font-style: italic;
  }

  &.DISABLED {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const OwnUserLabel = styled.span`
  background: ${({ theme }) => theme.colors.titleDot};
  padding: 4px 10px;
  border-radius: 13px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ResponsiveAdminInfoWrapper = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
`;

export const RAIRole = styled.p`
  padding-top: 2px;
`;

export const RAIEmail = styled.p`
  ${TextOverflowMixin}
  color: ${({ theme }) => theme.colors.accentMain};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RAIStartDate = styled.p`
  color: ${({ theme }) => theme.colors.disabled};
`;

export const ResponsiveOwnLabel = styled(OwnUserLabel)`
  display: none;

  ${breakpoint("xs", "md")`
    display: inline-block;
    padding: 2px 8px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.size.small};
    margin-top: 4px;
  `}
`;
