import { NavLink } from "react-router-dom";
import { LogoFullAlt } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { CustomLink, LinkTextMixin, SecondaryButton } from "uikit";

const headerInnerWidth = 600;
const maxContainerWidth = 1024;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.errorLayoutBg};
`;

export const Header = styled.div`
  width: 100%;
  height: 98px;
  padding: 16px 16px 0 16px;
  box-sizing: border-box;
`;

export const HeaderInner = styled.div`
  position: relative;
  max-width: ${headerInnerWidth}px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLogo = styled(LogoFullAlt)`
  width: auto;
  height: 48px;
`;

export const LogoutButton = styled(SecondaryButton)`
  width: 120px;
  height: 40px;
  padding: 0 10px;
  background: ${({ theme }) => theme.colors.contentBg};
  color: ${({ theme }) => theme.colors.text};
  border: none;
  font-size: ${({ theme }) => theme.size.caption};

  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.cancelButtonBgAlt};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 580px;
  z-index: 1;
  padding: 100px 16px 16px 16px;

  ${breakpoint("xs", "md")`
    padding-top: 70px;
  `}
`;

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: ${maxContainerWidth}px;
`;

export const Footer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.footerBg};
  height: 538px;
`;

export const StyledIcon = styled.img`
  margin-bottom: 40px;

  ${breakpoint("xs", "md")`
    width: 176px;
  `}
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 72px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.errorLayoutText};
  margin-bottom: 23px;

  ${breakpoint("xs", "md")`
    font-size: 48px;
  `}
`;

export const Text = styled.p`
  margin-bottom: 32px;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.errorLayoutText};
  line-height: 1.3;
`;

export const Text2 = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.errorLayoutText};
  line-height: 1.3;
`;

export const StyledLink = styled(NavLink)`
  ${LinkTextMixin}
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
`;

export const StyledExternalLink = styled(CustomLink)`
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
`;
