export const bonusesListTableTranslations = {
  "en-US": {
    context_menu: {
      view_btn: "View Bonus",
      edit_btn: "Edit Bonus",
      delete_btn: "Delete Bonus",
      void_btn: "Void Bonus",
      resend_invitation_btn: "Resend Bonus Invitation",
    },
    columns: {
      status: "Status",
      bonus_name: "Bonus Name",
      potential: "Potential",
      earned: "Earned",
      issued_date: "Issued",
      days_left: "Days Left",
      footer: {
        bonuses_count: "Bonuses",
        total_potential: "Total Potential",
        total_earned: "Total Earned",
      },
    },
    empty_list: {
      description_employee: "You don't have any active bonuses yet",
      description_admin: "This employee does not have any <br />existing or pending bonuses.",
      create_new_bonus_button: "Create New Bonus",
    },
  },
};

export default bonusesListTableTranslations;
