import styled from "styled-components";

import { breakpoint } from "helpers";

import { ErrorContainer as BaseInputError } from "uikit/Input/styles";

export const Content = styled.div`
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  margin-bottom: 53px;

  & > div:nth-child(1) {
    margin-bottom: 53px;
  }

  ${breakpoint("xs", "md")`
    padding: 0;
  `}
`;

export const StepSection = styled.div`
  display: flex;
  align-items: flex-start;

  div.loader {
    width: 59px;
    height: 59px;
  }

  & > img {
    width: 59px;
    height: 59px;

    ${breakpoint("xs", "md")`
      width: 39px;
      height: 39px;
    `}
  }

  svg {
    ${breakpoint("xs", "md")`
      width: 39px;
      height: 39px;
    `}
  }

  & > div {
    position: relative;
    top: -4px;
    margin-left: 20px;
    width: 325px;

    ${breakpoint("xs", "md")`
      margin-left: 10px;
    `}

    & p {
      text-align: left;
      line-height: 20px;
      font-size: ${({ theme }) => theme.size.body2};

      ${breakpoint("xs", "md")`
        font-size: ${({ theme }) => theme.size.caption};
      `}

      &:nth-child(1) {
        font-weight: ${({ theme }) => theme.fontWeight.medium};
      }

      &:nth-child(2) {
        color: ${({ theme }) => theme.colors.text};
        margin-bottom: 30px;

        ${breakpoint("xs", "md")`
          margin-bottom: 15px;
        `}
      }
    }

    button {
      width: 100%;
      border-radius: 6px;
      border: 2px solid ${({ theme }) => theme.colors.secondaryButtonText};
      font-weight: ${({ theme }) => theme.fontWeight.regular};

      ${breakpoint("xs", "xl")`
        padding: 0 6px;
      `}
    }
  }
`;

export const BtnPairsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 25px 20px 0;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    justify-content: center;
    margin: 0 0 20px 0;
  `}

  & > button {
    font-size: ${({ theme }) => theme.size.body2};
    font-weight: ${({ theme }) => theme.fontWeight.regular};

    ${breakpoint("xs", "md")`
      width: 100%;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    `}
  }

  & > button:nth-child(2) {
    width: 180px;
    margin-left: 47px;

    ${breakpoint("xs", "md")`
      order: -1;
      margin: 0 0 10px 0;
      width: 100%;
    `}
  }
`;

export const UploadedFileList = styled.div`
  & > div {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};

    ${breakpoint("xs", "md")`
      padding: 6px;
    `}

    p {
      font-size: ${({ theme }) => theme.size.body2};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;

      ${breakpoint("xs", "md")`
        font-size: ${({ theme }) => theme.size.caption};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
      `}
    }

    img {
      width: 25px;
      height: 25px;
      margin-right: 14px;

      ${breakpoint("xs", "md")`
        width: 20px;
        height: 20px;
        margin-right: 5px;
      `}
    }
  }
`;

export const RemoveFileButton = styled.span`
  margin-left: auto;

  svg {
    background: #f58686;
    padding: 8px;
    border-radius: 50%;
    width: auto;
    height: auto;
    cursor: pointer;

    ${breakpoint("xs", "md")`
      width: 10px;
      height: 10px;
      padding: 5px;
    `}

    path {
      fill: white;
    }
  }
`;

export const InputError = styled(BaseInputError)`
  margin-top: 8px;
  text-align: center;
`;
