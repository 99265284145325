import { SearchIcon } from "assets/svg";
import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared";
import Avatar from "components/Avatar";

import { Loader } from "uikit/Loader";

import { ErrorTextMixin } from "../Typography/Typography";
import Input from "./Input";

export const inputHeight = 48;
export const numberGroupWidth = 48;
export const inputRadius = 8;
export const inputBorderWidth = 1;
export const floatLabelPadding = `16px 16px 2px 16px`;
export const floatLabelPaddingLarge = `17px 16px 2px 16px`;
export const floatLabelClass = `with-floating-label`;

export const DefaultTextInputMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const LargeTextInputMixin = css`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const NumberTextInputMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const PlaceholderMixin = css`
  &::placeholder {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: ${({ theme }) => theme.colors.inputPlaceholder};
    font-size: ${({ theme }) => theme.size.body2};
  }
  &.disabled::placeholder {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: ${({ theme }) => theme.colors.inputText};
  }
`;

export const NumberPlaceholderMixin = css`
  ${PlaceholderMixin}
`;

export const InputContainer = styled.div``;

export const InputContainerMargined = styled(InputContainer)`
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InputGroupContainer = styled.div`
  display: flex;
`;

export const InputGroupContainerHalved = styled(InputGroupContainer)`
  justify-content: space-between;
  ${InputContainer} {
    width: 49%;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column;

    ${InputContainer} {
      width: 100%;
    }
  `}
`;

export const Label = styled.label`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 24px);
  padding-bottom: 8px;
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  white-space: nowrap;
  text-overflow: ellipsis;

  transform-origin: top left;
  transition: transform 200ms linear;
  transform: translate(15px, 14px) scale(1);

  pointer-events: none;
`;

export const InputWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  overflow: hidden;
  border: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
  border-radius: ${inputRadius}px;
  display: flex;
  flex-direction: row;
  ${DefaultTextInputMixin};
  transition: box-shadow 0.13s ease-in;

  &:focus-within {
    box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.inputActiveShadow};
  }

  .react-international-phone-input-container {
    width: 100%;
    height: ${inputHeight}px;

    .react-international-phone-country-selector-button {
      border: 0;
      border-right: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
      height: ${inputHeight - inputBorderWidth * 2}px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    border: 0;
    height: ${inputHeight - inputBorderWidth * 2}px;
  }
`;

export const OptionalLabel = styled.label`
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: ${({ theme }) => theme.size.caption};
  line-height: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  font-style: italic;
`;

export const OptionalText = styled.span`
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0 2px 0 2px;
`;

export const InputBaseMixin = css`
  ${DefaultTextInputMixin}
  ${PlaceholderMixin}
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${inputHeight - inputBorderWidth * 2}px;
  padding: 4px 16px;
  border: none;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  outline: none;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.inputText};

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.inputDisabledBg};
    color: ${({ theme }) => theme.colors.disabled};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }
`;

export const DDBaseMixin = css`
  ${DefaultTextInputMixin}
  line-height: 26px;
  color: ${({ theme }) => theme.colors.inputText};
`;

export const InputBase = styled.input`
  ${InputBaseMixin}
  ${PlaceholderMixin}
`;

export const ErrorContainer = styled.div`
  ${ErrorTextMixin};
  margin: 5px 0;
  text-align: left;
  display: block;
  padding: 0 0 0 6px;
  min-height: 16px;
  line-height: 16px;
  box-sizing: border-box;
`;

export const InputDescription = styled.p`
  text-align: left;
  margin: 10px 7px 0 7px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.textAlt};

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  & + ${ErrorContainer} {
    margin: 0;
  }
`;

export const TopLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #585858;
`;

export const DDArrow = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 1;

  svg {
    width: 27px;
    height: 27px;
    transform: rotate(180deg);

    path {
      color: ${({ theme }) => theme.colors.DDArrow};
    }
  }
`;

export const SelectWrapper = styled(InputWrapper)<{ isDisabled?: boolean }>`
  &,
  & * {
    text-align: left;
    box-sizing: border-box;
    ${({ isDisabled, theme }) => (isDisabled ? `color: ${theme.colors.disabled} !important` : "")};
    ${({ isDisabled, theme }) =>
      isDisabled ? `background: ${theme.colors.inputDisabledBg} !important` : ""};
  }

  overflow: visible;

  & > div[class*="-container"] {
    width: 100%;
    & > div[class*="-control"] {
      background: ${({ disabled, theme }) => disabled && theme.colors.inputDisabledBg};
      cursor: pointer;
      border: none;
      box-shadow: none;
      border-radius: ${inputRadius}px;

      & > div:nth-of-type(1) {
        ${InputBaseMixin}
        ${DDBaseMixin}

        padding: 4px 0 4px 16px;

        & > div[class*="-singleValue"] {
          margin-left: -1px;
          display: flex;
          align-items: center;
          height: 100%;
          margin-right: 0;
        }
        & > div[class*="-placeholder"] {
          font-size: ${({ theme }) => theme.size.caption};
        }
        & > div[data-value] {
          position: absolute;
          margin-left: -1px;

          & > input:-webkit-autofill {
            display: flex;
            position: absolute;
            width: 100px !important;
            transition:
              background-color 600000s 0s,
              color 600000s 0s !important;
          }
        }
      }

      & > div:nth-of-type(2) {
        padding: 8px 15px 8px 8px;

        & span[class*="-indicatorSeparator"] {
          display: none;
        }
        & div[class*="-indicatorContainer"] {
          padding: 0;
          color: ${({ theme }) => theme.colors.DDArrow};
        }
      }
    }
    & div[class*="-menu"] {
      z-index: 9;
      border: 1px solid ${({ theme }) => theme.colors.inputDropdownBorder};
      box-shadow: 0px 1px 8px 0px ${({ theme }) => theme.colors.inputDropdownShadow};

      & > div {
        max-height: 200px;
        border-radius: 4px;
      }

      & div[class*="-option"] {
        ${DDBaseMixin}
        padding: 10px 16px;
        background-color: ${({ theme }) => theme.colors.inputBg};

        cursor: pointer;
      }
    }
  }
`;

export const DateWrapper = styled(InputWrapper)`
  overflow: visible;
  height: ${inputHeight}px;

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      border-radius: ${inputRadius}px;
      height: 100%;
    }

    input {
      ${PlaceholderMixin};
      width: 100%;
      border: none;
      border-radius: inherit;
      padding: 4px 2px 4px 14px;
      height: 100%;
      box-sizing: border-box;

      &[disabled] {
        background-color: ${({ theme }) => theme.colors.inputDisabledBg};
      }
    }
  }
`;

export const DateLabel = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.inputBg};
  border-radius: 0 ${inputRadius}px ${inputRadius}px 0;

  &.disabled {
    background-color: ${({ theme }) => theme.colors.inputDisabledBg};
  }
`;

export const MonthsYearsHeaderContainer = styled.div`
  display: flex;
  flex-dirextion: row;
  justify-content: center;
  padding: 0 15px;
`;

export const MYHSelect = styled.select`
  height: 22px;
  border: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.datePickerHeaderDDBorder};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0 6px;
`;

export const MYHMonthSelect = styled(MYHSelect)`
  width: 117px;
`;

export const MYHYearSelect = styled(MYHSelect)`
  width: 62px;
`;

export const DateFormat = styled(InputDescription)``;

export const Units = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${inputHeight}px;
  min-width: ${inputHeight}px;
  height: ${inputHeight - inputBorderWidth * 2}px;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.inputUnitsText};
  background-color: ${({ theme }) => theme.colors.inputCurrencyBg};
  border-right: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};

  &.units-right {
    border-right: 0;
    border-left: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
  }
`;

export const Currency = styled(Units)``;

export const Percentage = styled(Units)``;

export const Textarea = styled.textarea`
  width: 100%;
  height: 265px;
  resize: none;
  border: none;
  padding: 12px 12px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceholder};
  }
`;

export const Container = styled.div`
  &,
  & * {
    box-sizing: border-box;
  }

  &.${floatLabelClass} ${Label} {
    transform: translate(15px, 4px) scale(0.86);
    top: 0;
  }

  &.${floatLabelClass} ${InputBase} {
    padding: ${floatLabelPadding};
  }

  &.${floatLabelClass} div[class*="-control"] > div:first-of-type {
    padding: ${floatLabelPadding} !important;
  }

  &.phone-input {
    ${Label} {
      left: 25px;
    }

    &.${floatLabelClass} input {
      padding: ${floatLabelPadding};
      padding-left: 8px;
    }
  }

  &.custom-date-picker .react-datepicker-wrapper {
    input {
      border: none;
      box-sizing: border-box;
    }
  }

  &.custom-date-picker.${floatLabelClass} .react-datepicker-wrapper {
    input {
      padding: ${floatLabelPaddingLarge};
      border-radius: ${inputRadius}px 0 0 ${inputRadius}px;
    }
  }
  &.number-input {
    input {
      flex: 1;
      ${NumberTextInputMixin}
      ${NumberPlaceholderMixin}
    }
  }

  &.money-input.${floatLabelClass} {
    ${Label} {
      left: 40px;
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const NoBorderInput = styled(Input)`
  border: none;
`;

export const SearchIconBtn = styled(SearchIcon)`
  //z-index: 100; //Just why?
`;

export const SsnInputWrapper = styled.div`
  position: relative;
`;

export const SsnInputIconWrapper = styled.div`
  position: absolute;
  right: 0;
  height: ${inputHeight}px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
`;

export const InputWithSearchWrapper = styled.div`
  position: relative;

  ${Container} {
    position: relative;

    &${floatLabelClass} ${InputBase} {
      padding-right: 40px;
    }
  }
`;

export const InputWithSearchOptionsList = styled.div`
  position: absolute;
  top: 45px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 98%;
  border: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
  max-height: 210px;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.widgetBg};
`;

export const InputWithSearchOption = styled.div`
  padding: 12px 0px 12px 14px;
  border-top: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.separatorLine};
  background: ${({ theme }) => theme.colors.contentBg};
  cursor: pointer;

  &:nth-child(1) {
    border: none;
  }
`;

export const IWSOptionText = styled.p`
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const IWSUserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;

export const IWSUIAvatar = styled(Avatar)`
  div {
    width: 36px;
    height: 36px;
  }
  margin-right: 16px;
`;

export const IWSUILeftText = styled.div`
  margin-right: 42px;
  width: 150px;
`;

export const IWSUIRightText = styled.div``;

export const IWSUIName = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const IWSUIDepartment = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const IWSUIEmail = styled.p`
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 48%;
  right: 3%;
  transform: translate(0, -55%);
  width: 18px;
  height: 18px;
  z-index: 100;

  div {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
`;

export const NumberArrows = styled.div`
  height: ${inputHeight}px;
  width: 43px;
`;

export const NumberArrow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.inputIncrementBg};
  color: ${({ theme }) => theme.colors.inputIncrementText};
  border-left: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
  border-bottom: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export const NumberArrowUp = styled(NumberArrow)`
  &:before {
    border-width: 0 5px 8px 5px;
    border-color: transparent transparent ${({ theme }) => theme.colors.inputIncrementText}
      transparent;
  }
`;

export const NumberArrowDown = styled(NumberArrow)`
  &:before {
    border-width: 8px 5px 0 5px;
    border-color: ${({ theme }) => theme.colors.inputIncrementText} transparent transparent
      transparent;
  }
`;

export const NumberDropdownGroupContainer = styled(InputWrapper)`
  display: flex;
  height: ${inputHeight}px;
  overflow: visible;

  ${InputWrapper} {
    border: none;
    border-radius: ${inputRadius}px 0 0 ${inputRadius}px;
    border-right: ${inputBorderWidth}px solid ${({ theme }) => theme.colors.inputBorder};
    width: ${numberGroupWidth}px;

    ${InputBase} {
      padding: 4px;
      text-align: center;
    }
  }

  ${SelectWrapper} {
    border: none;
    flex: 1;
  }
`;
