import styled from "styled-components";

import { THMixin } from "uikit/Typography/Typography";

export const Container = styled.td`
  ${THMixin}
  box-sizing: border-box;
  height: 47px;
  padding: 17px 0;
  border-radius: inherit;
`;

export const Title = styled.span``;

export const Button = styled.button`
  border: none;
  background: transparent;
`;
