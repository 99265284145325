import { ButtonHTMLAttributes, lazy, Suspense } from "react";
import { Trans } from "react-i18next";
import PromoImage from "assets/png/promo_woman_alt.png";
import { ArrowIconSmall } from "assets/svg";

const KYCVerificationWidget = lazy(
  () => import("components/CompanyBankAccountComponents/KYCVerificationWidget"),
);
const PaidCardShippingDetailsForm = lazy(
  () => import("components/CompanyBankAccountComponents/PaidCardShippingDetailsForm"),
);

import MultiFactorAuthorization from "components/MultiFactorAuthorization";
import MFASMS from "components/MultiFactorAuthorization/components/sms/MFASMSSetup";
import { EWidgetType as EMultiFactorWidgetType } from "components/MultiFactorAuthorization/types";

import { Loader } from "uikit";
import { EModalTypes } from "uikit/Modal";

import { renderResponseSection } from "../components/renderElements";
import { HDescription, Header, HTitle, StepText } from "../components/styles";
import { TotalStepsNumber } from "../components/styles";
import EarlyPay from "../EarlyPay";
import { COMPANY_CARD_CREATION_STEPS, getSteps, promoStepsDescriptionsList } from "./constants";
import { ICompanyBankAccountCreationModalProps } from "./types";
import { useCompanyBankAccountCreationModal } from "./useCompanyBankAccountCreationModal";
import {
  BackBtn,
  BankAccountSetupCompletedSection,
  BtnsWrapper,
  ButtonsGroup,
  Container,
  MFAWidgetContainer,
  ModalHeader,
  PromoImg,
  PromoSection,
  PromoSectionInnerContent,
  PromoSectionTextContainer,
  ScrollableContainer,
  SkipBtn,
  StepsList,
  StepsListItem,
  StepsListItemDescription,
  StepsListItemNumber,
  StepsListItemNumberContainer,
  StepsListItemNumberContainerInner,
  StepsListItemTextContainer,
  StepsListItemTitle,
  StyledUIModal,
  StyleStepIndicator,
  SubmitBtn,
} from "./styles";

const CompanyBankAccountCreationModal: React.FC<ICompanyBankAccountCreationModalProps> = (
  props,
) => {
  const translationPrefixOrigin = `components.company_bank_account_components`;

  const { isOpen } = props;
  const { metadata, pageData, actions } = useCompanyBankAccountCreationModal(props);
  const { verificationStep, shippingDetails, promoSection, earlyPay } = pageData;
  const { kyc, currentUser, step } = metadata;
  const { currentStep } = step;

  const shouldHideBackBtn = kyc.isKycPending || pageData.isBankAccountSetupCompletedSectionVisible;
  const buttonsWrapperClassName = `${shouldHideBackBtn ? "center" : ""}`;
  const shouldShowCompletedSections =
    pageData.isBankAccountSetupCompletedSectionVisible ||
    pageData.shippingDetails.physicalCardSuccessfullyOrdered;

  const renderPrimaryBtn = () => {
    const kycBtnType = !currentUser?.kycStatus || kyc.isKycRejected ? "submit" : "button";

    const getKycStepBtnTitle = () => {
      if (!currentUser?.kycStatus || !verificationStep.kycResponseType) return "buttons.submit";
      if (kyc.isKycAccepted) return "buttons.continue";
      if (kyc.isKycPending) return "buttons.got_it";
      if (kyc.isKycRejected && verificationStep.kycResponseType)
        return `${translationPrefixOrigin}.kyc_verification_widget.verification_step.restart_verification_btn`;
    };

    const getMfaStepBtnTitle = () => {
      if (pageData.isBankAccountSetupCompletedSectionVisible) return "buttons.finish";

      return "buttons.continue";
    };

    const shouldShowArrowIcon =
      (!!currentUser?.kycStatus && !kyc.isKycRejected && !kyc.isKycPending) ||
      (currentStep === COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION &&
        !pageData.isBankAccountSetupCompletedSectionVisible);

    const config: Record<COMPANY_CARD_CREATION_STEPS, ButtonHTMLAttributes<HTMLButtonElement>> = {
      [COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION]: {
        type: kycBtnType,
        disabled: metadata.isPrimaryBtnDisabled(),
        children: <Trans i18nKey={getKycStepBtnTitle()} />,
        onClick: verificationStep.kycResponseType ? actions.handlePrimaryBtnClick : undefined,
        id: "cbacm-kyc-form-submit-button",
      },
      [COMPANY_CARD_CREATION_STEPS.EARLY_PAY]: {
        type: "button",
        disabled: metadata.isPrimaryBtnDisabled(),
        children: <Trans i18nKey={getKycStepBtnTitle()} />,
        onClick: actions.handlePrimaryBtnClick,
        id: "cbacm-early-pay-form-submit-button",
      },
      [COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS]: {
        type: "submit",
        disabled: metadata.isPrimaryBtnDisabled(),
        children: <Trans i18nKey={"buttons.submit"} />,
        onClick: undefined,
        id: "cbacm-shipping-form-submit-button",
      },
      [COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION]: {
        type: "button",
        disabled: metadata.isPrimaryBtnDisabled(),
        children: <Trans i18nKey={getMfaStepBtnTitle()} />,
        onClick: actions.handlePrimaryBtnClick,
        id: "cbacm-mfa-submit-button",
      },
    };

    return (
      <SubmitBtn
        type={config[metadata.step.currentStep].type}
        disabled={config[metadata.step.currentStep].disabled}
        onClick={config[metadata.step.currentStep].onClick}
        id={config[metadata.step.currentStep].id || ""}
        data-testid={config[metadata.step.currentStep].id || ""}
      >
        {config[metadata.step.currentStep].children}
        {shouldShowArrowIcon && <ArrowIconSmall />}
      </SubmitBtn>
    );
  };

  const renderSkipBtn = () => {
    if (pageData.isBankAccountSetupCompletedSectionVisible || pageData.mfa.isMfaFinished)
      return <></>;

    switch (currentStep) {
      case COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION:
        return (
          <SkipBtn onClick={actions.handleSkipMfaStep}>
            <Trans
              i18nKey={`${translationPrefixOrigin}.shared.steps.skip_button`}
              data-testid={"cbacm-mfa-skip-button"}
            />
          </SkipBtn>
        );
      default:
        return <></>;
    }
  };

  const renderBackBtn = () => {
    if (shouldHideBackBtn) return;

    const config: Record<COMPANY_CARD_CREATION_STEPS, ButtonHTMLAttributes<HTMLButtonElement>> = {
      [COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION]: {
        type: "button",
        children: <Trans i18nKey="buttons.cancel" />,
        onClick: actions.handleBackBtnClick,
        id: "cbacm-kyc-form-cancel-button",
      },
      [COMPANY_CARD_CREATION_STEPS.EARLY_PAY]: {
        type: "button",
        children: <Trans i18nKey="buttons.cancel" />,
        onClick: actions.handleBackBtnClick,
        id: "cbacm-early-form-cancel-button",
      },
      [COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS]: {
        type: "button",
        children: <Trans i18nKey="buttons.cancel" />,
        onClick: actions.handleBackBtnClick,
        id: "cbacm-shipping-form-cancel-button",
      },
      [COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION]: {
        type: "button",
        children: <Trans i18nKey="buttons.cancel" />,
        onClick: actions.handleBackBtnClick,
        id: "cbacm-shipping-form-cancel-button",
      },
    };

    return (
      <BackBtn
        onClick={config[metadata.step.currentStep].onClick}
        type={config[metadata.step.currentStep].type}
        disabled={metadata.isSomeFormLoading}
        id={config[metadata.step.currentStep].id || ""}
        data-testid={config[metadata.step.currentStep].id || ""}
      >
        {config[metadata.step.currentStep].children}
      </BackBtn>
    );
  };

  const renderStepsListItem = (title: string, description: string, number: number) => {
    return (
      <StepsListItem>
        <StepsListItemNumberContainer>
          <StepsListItemNumberContainerInner>
            <StepsListItemNumber>{number}</StepsListItemNumber>
          </StepsListItemNumberContainerInner>
        </StepsListItemNumberContainer>

        <StepsListItemTextContainer>
          <StepsListItemTitle>
            <Trans i18nKey={title} />
          </StepsListItemTitle>
          <StepsListItemDescription>
            <Trans i18nKey={description} />
          </StepsListItemDescription>
        </StepsListItemTextContainer>
      </StepsListItem>
    );
  };

  const renderPromoSection = () => {
    const _translationPrefix = `${translationPrefixOrigin}.shared.promo_section`;
    const stepsDescriptionsList = promoStepsDescriptionsList();
    return (
      <PromoSection>
        <PromoSectionInnerContent>
          <PromoSectionTextContainer>
            <HTitle>
              <Trans i18nKey={`${_translationPrefix}.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${_translationPrefix}.description`} />
            </HDescription>
            <TotalStepsNumber>
              <Trans
                i18nKey={`${_translationPrefix}.sub_title`}
                values={{ stepsNumber: stepsDescriptionsList?.length }}
              />
            </TotalStepsNumber>

            <StepsList>
              {stepsDescriptionsList.map((step, index) =>
                renderStepsListItem(step.title, step.description, index + 1),
              )}
            </StepsList>
          </PromoSectionTextContainer>

          <PromoImg src={PromoImage} alt="Promo image" />
        </PromoSectionInnerContent>

        <BtnsWrapper>
          <BackBtn
            onClick={actions.handleBackBtnClick}
            type={"button"}
            data-testid="company-bank-account-creation-modal-cancel-button"
          >
            <Trans i18nKey="buttons.cancel" />
          </BackBtn>

          <ButtonsGroup>
            <SubmitBtn
              onClick={() => actions.handleShowPromoSection()}
              type={"button"}
              data-testid="company-bank-account-creation-modal-submit-button"
            >
              <Trans i18nKey="buttons.get_started" />
            </SubmitBtn>
          </ButtonsGroup>
        </BtnsWrapper>
      </PromoSection>
    );
  };

  const renderCompletedSections = () => {
    if (pageData.isBankAccountSetupCompletedSectionVisible) {
      const _prefix = `${translationPrefixOrigin}.bank_account_setup_completed_section`;
      return (
        <BankAccountSetupCompletedSection>
          {renderResponseSection({
            title: <Trans i18nKey={`${_prefix}.title`} />,
            message: <Trans i18nKey={`${_prefix}.message`} />,
            iconType: EModalTypes.SUCCESS,
            dataTestId: `bank-account-setup-completed-response-${EModalTypes.SUCCESS}`,
          })}

          <BtnsWrapper className="center">
            <SubmitBtn
              onClick={actions.handleFinishedSetupSectionBtnClick}
              type={"button"}
              data-testid="company-bank-account-creation-modal-submit-button"
            >
              <Trans i18nKey="buttons.done" />
            </SubmitBtn>
          </BtnsWrapper>
        </BankAccountSetupCompletedSection>
      );
    }

    if (pageData.shippingDetails.physicalCardSuccessfullyOrdered) {
      const _prefix = `${translationPrefixOrigin}.physical_card_order_success_modal`;
      return (
        <BankAccountSetupCompletedSection>
          {renderResponseSection({
            title: <Trans i18nKey={`${_prefix}.title`} />,
            iconType: EModalTypes.SUCCESS,
            dataTestId: `physical-card-ordering-completed`,
          })}

          <BtnsWrapper className="center">
            <SubmitBtn
              onClick={actions.handleFinishedSetupSectionBtnClick}
              type={"button"}
              data-testid={""}
            >
              <Trans i18nKey="buttons.done" />
            </SubmitBtn>
          </BtnsWrapper>
        </BankAccountSetupCompletedSection>
      );
    }

    return <></>;
  };

  const stepsList = getSteps(pageData.mfa.isMfaFinished);

  return (
    <StyledUIModal
      isOpen={isOpen}
      showCloseButton={false}
      className={`${promoSection.showPromoSection ? "small" : ""} `}
    >
      {metadata.isLoading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          {promoSection.showPromoSection ? (
            renderPromoSection()
          ) : (
            <>
              {!props.hideHeader && (
                <ModalHeader>
                  <StyleStepIndicator steps={stepsList} currentStep={metadata.step.currentStep} />

                  {!pageData.isBankAccountSetupCompletedSectionVisible && (
                    <StepText>
                      <Trans
                        i18nKey={`${translationPrefixOrigin}.shared.steps.steps_current_total_text`}
                        values={{
                          currentStep: metadata.step.currentStep,
                          totalSteps: stepsList?.length,
                        }}
                      />
                    </StepText>
                  )}
                </ModalHeader>
              )}

              {shouldShowCompletedSections ? (
                renderCompletedSections()
              ) : (
                <Container
                  onSubmit={(e) => {
                    e.preventDefault();
                    actions.formSubmitter();
                  }}
                >
                  {metadata.step.currentStep === COMPANY_CARD_CREATION_STEPS.KYC_VERIFICATION && (
                    <KYCVerificationWidget
                      profileData={{
                        onSubmit: verificationStep.handleProfileFormSubmit,
                        formData: verificationStep.kycFormData,
                        setFormData: verificationStep.setKycFormData,
                      }}
                      ref={verificationStep.ref}
                      userDetails={currentUser}
                      termAndConditionsCheckboxProps={{
                        isChecked: verificationStep.isTermsAndConditionsAccepted,
                        onChange: verificationStep.handleChangeTermsAndConditions,
                      }}
                      isLoading={verificationStep.isKycSubmitting}
                      kycResponseType={verificationStep.kycResponseType}
                      errorMessage={pageData.errorMessage}
                    />
                  )}

                  {metadata.step.currentStep === COMPANY_CARD_CREATION_STEPS.EARLY_PAY && (
                    <ScrollableContainer>
                      <EarlyPay
                        isChecked={!!earlyPay.isEarlyPayChecked}
                        onChange={actions.handleChangeEarlyPayValue}
                        isSubmitting={earlyPay.isEarlyPaySubmitting}
                        errorMessage={pageData.errorMessage}
                      />
                    </ScrollableContainer>
                  )}

                  {metadata.step.currentStep === COMPANY_CARD_CREATION_STEPS.SHIPPING_DETAILS && (
                    <PaidCardShippingDetailsForm
                      showCardTemplate={true}
                      formData={shippingDetails.shippingDetailsFormData}
                      setFormData={shippingDetails.setShippingDetailsFormData}
                      onSubmit={shippingDetails.handleSubmitShippingDetails}
                      ref={shippingDetails.ref}
                      agreementCheckboxProps={{
                        isChecked: shippingDetails.isCardholderAgreementAccepted,
                        onChange: shippingDetails.handleChangeCardholderAgreement,
                      }}
                      isLoading={shippingDetails.isShippingDetailsSubmitting}
                      errorMessage={pageData.errorMessage}
                    />
                  )}

                  {metadata.step.currentStep === COMPANY_CARD_CREATION_STEPS.MFA_CONFIGURATION && (
                    <>
                      <Header>
                        <HTitle>
                          <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.title`} />
                        </HTitle>
                        <HDescription>
                          <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.description`} />
                        </HDescription>
                      </Header>

                      <MFAWidgetContainer>
                        <MFASMS
                          widgetType={EMultiFactorWidgetType.SETUP}
                          onSubmitSuccess={actions.handleMfaStepSubmitSuccess}
                          defaultPhone={currentUser?.phone}
                        />
                      </MFAWidgetContainer>
                    </>
                  )}

                  <BtnsWrapper className={`${buttonsWrapperClassName}`}>
                    {renderBackBtn()}
                    <ButtonsGroup>
                      {renderSkipBtn()}
                      {renderPrimaryBtn()}
                    </ButtonsGroup>
                  </BtnsWrapper>
                </Container>
              )}
            </>
          )}
        </Suspense>
      )}
    </StyledUIModal>
  );
};

export default CompanyBankAccountCreationModal;
