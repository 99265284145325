import { FC } from "react";

import IconProps from "../IconProps";

const ModalShare: FC<IconProps> = ({ className }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="share-2" clipPath="url(#clip0_5594_45092)">
      <path
        id="Vector"
        d="M8.5 9H7.5C6.96957 9 6.46086 9.21071 6.08579 9.58579C5.71071 9.96086 5.5 10.4696 5.5 11V19C5.5 19.5304 5.71071 20.0391 6.08579 20.4142C6.46086 20.7893 6.96957 21 7.5 21H17.5C18.0304 21 18.5391 20.7893 18.9142 20.4142C19.2893 20.0391 19.5 19.5304 19.5 19V11C19.5 10.4696 19.2893 9.96086 18.9142 9.58579C18.5391 9.21071 18.0304 9 17.5 9H16.5"
        stroke="#627293"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.5 14V3"
        stroke="#627293"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M9.5 6L12.5 3L15.5 6"
        stroke="#627293"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5594_45092">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ModalShare;
