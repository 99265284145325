import { FC } from "react";

import IconProps from "./IconProps";

const CashBanknote: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7222_26502)">
      <path
        d="M9.25 12.5C9.25 13.2956 9.56607 14.0587 10.1287 14.6213C10.6913 15.1839 11.4544 15.5 12.25 15.5C13.0456 15.5 13.8087 15.1839 14.3713 14.6213C14.9339 14.0587 15.25 13.2956 15.25 12.5C15.25 11.7044 14.9339 10.9413 14.3713 10.3787C13.8087 9.81607 13.0456 9.5 12.25 9.5C11.4544 9.5 10.6913 9.81607 10.1287 10.3787C9.56607 10.9413 9.25 11.7044 9.25 12.5Z"
        stroke="#17AACF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 8.5C3.25 7.96957 3.46071 7.46086 3.83579 7.08579C4.21086 6.71071 4.71957 6.5 5.25 6.5H19.25C19.7804 6.5 20.2891 6.71071 20.6642 7.08579C21.0393 7.46086 21.25 7.96957 21.25 8.5V16.5C21.25 17.0304 21.0393 17.5391 20.6642 17.9142C20.2891 18.2893 19.7804 18.5 19.25 18.5H5.25C4.71957 18.5 4.21086 18.2893 3.83579 17.9142C3.46071 17.5391 3.25 17.0304 3.25 16.5V8.5Z"
        stroke="#17AACF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 12.5H18.26"
        stroke="#17AACF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 12.5H6.26"
        stroke="#17AACF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7222_26502">
        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CashBanknote;
