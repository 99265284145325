import { ReactNode } from "react";

export enum EDistributionOptions {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CHECK = "PAPER_CHECK",
}

export interface IDistributionOption {
  id: number;
  type: EDistributionOptions;
  isActive: boolean;
}

export const enum EWidgetType {
  ONBOARDING = "ONBOARDING",
  SETTINGS = "SETTINGS",
}

export type ICustomButtonCallback = (type?: EDistributionOptions, isInEdit?: boolean) => void;
export interface ICustomButton {
  text?: ReactNode | string;
  callback?: ICustomButtonCallback;
}

export interface ICommonProps {
  onNoAccountsModalConfirm?: () => void;
  onNoAccountsModalCancel?: () => void;
  widgetType: EWidgetType;
}

export type IInitialBankAccountsDataProps = ICommonProps;

export interface IProps extends ICommonProps {
  className?: string;
  initialStep?: EDistributionOptions;
  disabledSteps?: EDistributionOptions[];
  onBack?: (step?: EDistributionOptions) => void;
  onBankAccountAdd?: () => void;
}
