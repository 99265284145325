import { EyeIcon } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.contactInfoBg};
  padding: 16px;
  border-radius: 6px;

  ${breakpoint("xs", "md")`
    padding: 26px 16px;
  `}
`;

export const NameContainer = styled.div`
  padding: 16px 0;
  margin-bottom: 24px;

  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.title};
  color: ${({ theme }) => theme.colors.contactInfoName};
  border-bottom: 1px solid ${({ theme }) => theme.colors.contactInfoNameBorder};
`;

export const AddressContainer = styled.div``;
export const ACRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column;
  `}
`;

export const ACBlock = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 16px;

  ${breakpoint("xs", "md")`
    width: 100%;
  `}

  ${breakpoint("xs", "md")`
    flex-direction: column;

    &:nth-last-of-type(2) {
      margin-bottom: 24px;
    }
  `}
`;

export const ACBTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.contactInfoTitle};
  line-height: 1.3;
`;

export const ACBText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.contactInfoText};
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ACBMaskContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ACBVisibilityIconContainer = styled.div`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledEyeIcon = styled(EyeIcon)`
  height: 19px;
  &,
  & path {
    fill: ${({ theme }) => theme.colors.contactInfoText};
  }
`;
