import { FC, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { getEmployeePagesBlockCondition } from "routes/helpers";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { UserRole } from "types/BETypes";
import { getName } from "helpers/index";
import { useSwitchRole } from "hooks";
import useAuth from "hooks/useAuth";
import Avatar from "components/Avatar";

import { FullScreenLoader } from "uikit";

import { UpdateLastActiveRoleDto } from "utils/swagger_react_query";

import {
  ArrowRight,
  CheckIcon,
  CircleChecked,
  CompanyName,
  Container,
  DDArrow,
  InfoBlock,
  InfoCompanyName,
  MenuContainer,
  PILeft,
  PILLeft,
  PILRight,
  PILText,
  PILTitle,
  PIRight,
  PIRole,
  PITitle,
  ProfileButton,
  ProfileInfo,
  ProfileInfoLink,
  ProfileLogout,
  Role,
  RoleName,
  RoleSwitcherWrapper,
} from "./styles";

import "./styles.scss";

interface Props {
  className?: string;
}

const AccountMenu: FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { isLoading, userInfo, companyInfo, onChangeUserRole } = useSwitchRole();
  const currentUser = useAppSelector(userMetadataSelector);
  const [isProfileMenuOpen, setProfileMenu] = useState<boolean>(false);
  const handleToggle = () => setProfileMenu(!isProfileMenuOpen);

  const handleLogout = async () => {
    setProfileMenu(false);
    AppEvents.emit("RoleHasUpdated", { roleHasUpdated: true });
    AppEvents.emit("PromptsWereDisabled", { promptsWereDisabled: false });
    await logout(true, false);
    navigate(routes.SIGN_IN);
  };

  const isProfileButtonHidden = getEmployeePagesBlockCondition(currentUser);

  const getProfileLink = (role: UserRole) => {
    if ([UserRole.ADMIN, UserRole.OWNER, UserRole.SUPERADMIN].includes(role))
      return routes.ADMIN_PROFILE;

    return routes.EMPLOYEE_PROFILE;
  };

  const roleLabels: Record<UserRole, string> = {
    [UserRole.OWNER]: `components.role_switcher.roles.${UserRole.OWNER}`,
    [UserRole.ADMIN]: `components.role_switcher.roles.${UserRole.ADMIN}`,
    [UserRole.SUPERADMIN]: `components.role_switcher.roles.${UserRole.ADMIN}`,
    [UserRole.EMPLOYEE]: `components.role_switcher.roles.${UserRole.EMPLOYEE}`,
  };

  const roleLabelsTop: Record<UserRole, string> = {
    [UserRole.OWNER]: `common.roles.${UserRole.OWNER}`,
    [UserRole.ADMIN]: `common.roles.${UserRole.ADMIN}`,
    [UserRole.SUPERADMIN]: `common.roles.${UserRole.ADMIN}`,
    [UserRole.EMPLOYEE]: `common.roles.${UserRole.EMPLOYEE}`,
  };

  const renderRoleSwitcher = useMemo(() => {
    if (userInfo && userInfo.companyRoles.length > 0)
      return (
        <RoleSwitcherWrapper>
          <CompanyName>{companyInfo?.name}</CompanyName>
          {(userInfo.companyRoles as unknown as UpdateLastActiveRoleDto["role"][]).map(
            (role: UpdateLastActiveRoleDto["role"], index: number) => {
              const userRole = userInfo.lastActiveRole as UpdateLastActiveRoleDto["role"];
              return (
                <RoleName
                  key={role}
                  onClick={() => (userRole !== role ? onChangeUserRole(role) : {})}
                >
                  {<Trans i18nKey={roleLabels[role]} />}
                  {role === userInfo.lastActiveRole && (
                    <CircleChecked>
                      <CheckIcon />
                    </CircleChecked>
                  )}
                </RoleName>
              );
            },
          )}
        </RoleSwitcherWrapper>
      );
  }, [userInfo, companyInfo]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container className={className}>
        <InfoBlock>
          <InfoCompanyName>{companyInfo?.name}</InfoCompanyName>
          <Role>
            <Trans i18nKey={roleLabelsTop[userInfo?.lastActiveRole as UserRole]} />
          </Role>
        </InfoBlock>
        <OutsideClickHandler onOutsideClick={() => setProfileMenu(false)}>
          <ProfileButton onClick={handleToggle} type="button" data-testid="profile-button">
            <Avatar
              className="small"
              firstName={userInfo?.firstName}
              lastName={userInfo?.lastName}
              src={userInfo?.avatarUrl}
            />
            <DDArrow isOpen={isProfileMenuOpen} />
          </ProfileButton>
          {isProfileMenuOpen && (
            <MenuContainer>
              <ProfileInfo>
                <PILeft>
                  <Avatar
                    firstName={userInfo?.firstName}
                    lastName={userInfo?.lastName}
                    src={userInfo?.avatarUrl}
                  />
                </PILeft>

                <PIRight>
                  <PITitle>{getName(userInfo)}</PITitle>
                  <PIRole>
                    (<Trans i18nKey={`common.roles.${userInfo?.lastActiveRole}`} />)
                  </PIRole>
                </PIRight>
              </ProfileInfo>

              {!isProfileButtonHidden && (
                <ProfileInfoLink to={getProfileLink(userInfo?.lastActiveRole as UserRole)}>
                  <PILLeft>
                    <PILTitle>{getName(userInfo)}</PILTitle>
                    <PILText>
                      <Trans i18nKey={"components.account_menu.info"} />
                    </PILText>
                  </PILLeft>
                  <PILRight>
                    <ArrowRight />
                  </PILRight>
                </ProfileInfoLink>
              )}
              {renderRoleSwitcher}
              <ProfileLogout onClick={handleLogout}>
                <Trans i18nKey={"components.account_menu.log_out"} />
              </ProfileLogout>
            </MenuContainer>
          )}
        </OutsideClickHandler>
      </Container>
    </>
  );
};

export default AccountMenu;
