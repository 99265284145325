import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";

import { IMappedBankAccount } from "types/BankAccounts";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { BankAccountType, PayDistributionType } from "types/BETypes";

import {
  renderBankAccountName,
  renderBankAccountNumber,
  renderBankAccountStatus,
  renderBankAccountType,
  renderBankLogo,
  renderBankName,
  renderBaseLabelWithValue,
} from "./renderElements";
import { ExternalBankAccountInfoRowColumn } from "./types";
import { ActionsColumn, Container, InfoSection } from "./styles";

interface IProps {
  className?: string;
  bankAccountInfo?: IMappedBankAccount;
  displayedColumns?: ExternalBankAccountInfoRowColumn[];
  actionsColumn?: ReactNode;
  onPendingVerificationClick?: (verificationStatus: BankAccountVerifyStatus) => void;
}

const BankAccountInfoRow: FC<IProps> = ({
  className,
  bankAccountInfo,
  displayedColumns = [
    ExternalBankAccountInfoRowColumn.NAME,
    ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME,
    ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
    ExternalBankAccountInfoRowColumn.TYPE,
    ExternalBankAccountInfoRowColumn.STATUS,
  ],
  actionsColumn,
  onPendingVerificationClick,
}) => {
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.bank_account_info_row`;

  const infoSectionClassName = displayedColumns.length <= 3 ? "large" : "";

  const renderExternalBankAccountColumns = () => {
    return displayedColumns.map((column) => {
      if (column === ExternalBankAccountInfoRowColumn.NAME) {
        return renderBankName(bankAccountInfo, onPendingVerificationClick);
      }
      if (column === ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME) {
        return renderBankAccountName(bankAccountInfo?.institution?.name);
      }
      if (column === ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER) {
        return renderBankAccountNumber(bankAccountInfo?.last4Digits, bankAccountInfo?._accountType);
      }
      if (column === ExternalBankAccountInfoRowColumn.TYPE) {
        return renderBankAccountType(bankAccountInfo?.type as BankAccountType);
      }
      if (column === ExternalBankAccountInfoRowColumn.STATUS) {
        return renderBankAccountStatus(
          bankAccountInfo?.verificationStatus as BankAccountVerifyStatus,
        );
      }
      return <></>;
    });
  };

  const renderInternalBankAccountColumns = () => {
    return displayedColumns.map((column) => {
      if (column === ExternalBankAccountInfoRowColumn.NAME) {
        const label = <Trans i18nKey={`${translationPrefix}.columns.name`} />;
        const value = <Trans i18nKey={`${commonTranslationPrefix}.paid_bank_name`} />;
        return renderBaseLabelWithValue(label, value);
      }
      if (column === ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME) {
        const value = <Trans i18nKey={`${commonTranslationPrefix}.paid_bank_account_name`} />;

        return renderBankAccountName(value);
      }
      if (column === ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER) {
        return renderBankAccountNumber(bankAccountInfo?.last4Digits, bankAccountInfo?._accountType);
      }

      return <></>;
    });
  };

  return (
    <Container className={className}>
      <InfoSection className={`${infoSectionClassName}`}>
        <>{renderBankLogo(bankAccountInfo)}</>
        {bankAccountInfo?._accountType === PayDistributionType.PARTNER_ACCOUNT
          ? renderInternalBankAccountColumns()
          : renderExternalBankAccountColumns()}
      </InfoSection>

      {actionsColumn && (
        <ActionsColumn className={`${infoSectionClassName}`}>{actionsColumn}</ActionsColumn>
      )}
    </Container>
  );
};

export default BankAccountInfoRow;
