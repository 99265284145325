import { useEffect } from "react";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { SuccessCircledCheckMarkLarge } from "assets/svg";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";
import colors from "styles/colors";

import { AppEvents } from "services/events";
import { ErrorConst, UserRole } from "types/BETypes";
import { showErrorModal } from "helpers/index";

import { H2 } from "uikit";

import { mutationCompaniesControllerVerifyCompanyDomain } from "utils/swagger_react_query";

import useAuth from "./useAuth";

export interface IVerifiedDomainLocationState {
  verifiedDomain: string;
}

export const useVerifyCompanyDomain = (autoRequest: boolean = true) => {
  const DOMAIN_VERIFICATION_TOKEN = "domainVerificationToken";

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation() as unknown as { state: IVerifiedDomainLocationState };
  const { getDefaultAdminRoute, getDefaultEmployeeRoute, getDefaultRoute } = useRouteHelpers();
  const verifiedDomain = state?.verifiedDomain || "";

  const currentUser = useAppSelector(userMetadataSelector);
  const { getCurrentUser } = useAuth();

  const companyDomainVerificationToken = searchParams.get(DOMAIN_VERIFICATION_TOKEN);
  const isDomainVerificationFlow = searchParams.has(DOMAIN_VERIFICATION_TOKEN);

  const shouldShowVerifiedModal = !!verifiedDomain || "";

  useEffect(() => {
    if (!!isDomainVerificationFlow && !!autoRequest && !shouldShowVerifiedModal) {
      handleVerifyDomain();
    }
    if (shouldShowVerifiedModal) {
      showDomainVerifiedModal();
    }
  }, [isDomainVerificationFlow, autoRequest, shouldShowVerifiedModal]);

  const handleVerifyDomain = async (role?: UserRole) => {
    try {
      let _userRole: UserRole | undefined = role;
      const { domain } = await mutationCompaniesControllerVerifyCompanyDomain()({
        token: companyDomainVerificationToken || "",
      });
      if (!_userRole) {
        const userInfoRes = await getCurrentUser(true);
        _userRole = userInfoRes?.lastActiveRole as UserRole;
      }
      companyDomainVerificationNavigate(domain, _userRole as UserRole);
    } catch (error: any) {
      if (error?.data.error === ErrorConst.COMPANY_DOMAIN_VERIFICATION_LINK_EXPIRED) {
        showErrorModal(error);
      }
      setSearchParams("");
      navigate(getDefaultRoute());
    }
  };

  const companyDomainVerificationNavigate = (domain: string, role?: UserRole) => {
    const navState = { state: { verifiedDomain: domain } };
    if (!role) return navigate(routes.SIGN_IN, navState);
    if (role === UserRole.EMPLOYEE) {
      return navigate(getDefaultEmployeeRoute(), navState);
    } else {
      return navigate(routes.COMPANY_INTEGRATIONS_SSO, navState);
    }
  };

  const closeDomainVerifiedModal = () => {
    AppEvents.emit("CloseGlobalModal", { isOpen: false });
  };

  const showDomainVerifiedModal = () => {
    const role = currentUser?.lastActiveRole;
    const prefix = "company_SSO_integration_page.modals.verified_domain_modal";

    const mainBtnTranslationKeys = {
      [UserRole.ADMIN]: "admin_navigate_btn",
      [UserRole.SUPERADMIN]: "admin_navigate_btn",
      [UserRole.OWNER]: "admin_navigate_btn",
      [UserRole.EMPLOYEE]: "employee_navigate_btn",
      [""]: "new_user_btn",
    };

    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      icon: <SuccessCircledCheckMarkLarge />,
      title: <Trans i18nKey={`${prefix}.title`} />,
      message: (
        <Trans
          i18nKey={`${prefix}.message`}
          values={{ domain: verifiedDomain }}
          components={{ 1: <H2 style={{ color: colors.text }} /> }}
        />
      ),
      mainButton: {
        text: <Trans i18nKey={`${prefix}.${mainBtnTranslationKeys[role || ""]}`} />,
        onClick: closeDomainVerifiedModal,
      },
    });
    return window.history.replaceState({}, "");
  };

  return {
    isDomainVerificationFlow,
    handleVerifyDomain,
  };
};
