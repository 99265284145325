import { FC } from "react";
import { Trans } from "react-i18next";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";

import { EModalTypes } from "uikit/Modal";

import { StyledMessage, StyledUIModal, SubTitle } from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onBtnClick: () => void;
  verificationStatus: BankAccountVerifyStatus;
}

const MicroDepositInfoModal: FC<IProps> = ({ isOpen, onClose, onBtnClick, verificationStatus }) => {
  const translationPrefix = `components.bank_account_components.external_bank_account_modals.pending_verification.${verificationStatus}`;

  if (
    ![
      BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
      BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    ].includes(verificationStatus)
  )
    return;

  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      type={EModalTypes.PENDING}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      mainButton={{
        text: <Trans i18nKey={"buttons.ok"} />,
        onClick: onBtnClick,
      }}
    >
      <StyledMessage>
        <Trans i18nKey={`${translationPrefix}.message`} />
      </StyledMessage>

      {verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION && (
        <>
          <SubTitle>
            <Trans i18nKey={`${translationPrefix}.text_separator`} default={""} />
          </SubTitle>

          <StyledMessage>
            <Trans i18nKey={`${translationPrefix}.message_2`} default={""} />
          </StyledMessage>
        </>
      )}
    </StyledUIModal>
  );
};

export default MicroDepositInfoModal;
