import { FC, useEffect, useState } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";
import { useRouteHelpers } from "routes/helpers";

import { useVerifyCompanyDomain } from "hooks";
import useAuth from "hooks/useAuth";

import { FullScreenLoader } from "uikit";

const UnauthorizedRoute: FC<RouteProps> = ({ children }) => {
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const { getCurrentUser } = useAuth();
  const { getDefaultAdminRoute } = useRouteHelpers();
  const navigate = useNavigate();
  const location = useLocation();

  useVerifyCompanyDomain();

  useEffect(() => {
    async function checkUser() {
      const user = await getCurrentUser();
      if (user) {
        navigate(getDefaultAdminRoute());
      }
      setUserLoaded(true);
    }

    checkUser();
  }, [location]);

  return <>{!userLoaded ? <FullScreenLoader /> : <>{children}</>}</>;
};

export default UnauthorizedRoute;
