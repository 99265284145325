import { useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";
import { showErrorModal } from "helpers";
import useAuth from "hooks/useAuth";

import { mutationUsersControllerUpdateDefaultPayDistribution } from "utils/swagger_react_query";

import { BackButton, ConfirmPayDistributionButton } from "../../styles";
import { EWidgetType } from "../../types";
import useCommonModals from "../../useCommonModals";
import { IWidgetProps } from "./types";

export const useCheckForm = (props: IWidgetProps) => {
  const { widgetType, onBack } = props;
  const translationPrefix = `components.pay_distribution.paper_check_form`;
  const currentUser = useAppSelector(userMetadataSelector);
  const navigate = useNavigate();
  const { getCurrentUser } = useAuth();
  const {
    showPayDistributionSettingsSaveSuccessModal,
    showPayDistributionOnboardingSaveSuccessModal,
  } = useCommonModals();
  const [isLoading, setLoading] = useState<boolean>(false);
  const firstPayDistributionMethod = currentUser?.defaultPayDistribution?.[0];
  const confirmButtonIsVisible = firstPayDistributionMethod?.type !== PayDistributionType.CHECK;

  const refetchUserData = async () => {
    await getCurrentUser();
  };

  const redirectToSettings = () => {
    navigate(routes.EMPLOYEE_SETTINGS);
  };

  const handleBack = async () => {
    if (onBack) {
      onBack();
    } else {
      //TODO: Got some problem with navigate(-1) and RouterPrompt, investigate later
      redirectToSettings();
      return;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await mutationUsersControllerUpdateDefaultPayDistribution()({
        payDistributionRules: [
          {
            type: PayDistributionType.CHECK,
            ruleType: PayDistributionRuleType.REMAINDER,
          },
        ],
      });

      if (widgetType === EWidgetType.ONBOARDING) {
        showPayDistributionOnboardingSaveSuccessModal(async () => {
          await refetchUserData();
          navigate(routes.EMPLOYEE_PAY);
        });
      }
      if (widgetType === EWidgetType.SETTINGS) {
        await refetchUserData();
        showPayDistributionSettingsSaveSuccessModal();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const renderBackButton = () => {
    return (
      <BackButton onClick={handleBack} data-testid="bank-account-form-back-button">
        <Trans i18nKey={`buttons.back`} />
      </BackButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <>
        {!!confirmButtonIsVisible && (
          <ConfirmPayDistributionButton
            onClick={handleSubmit}
            data-testid="bank-account-form-confirm-pay-distribution-button"
          >
            <Trans i18nKey={`${translationPrefix}.submit_buttons.${widgetType}`} />
          </ConfirmPayDistributionButton>
        )}
      </>
    );
  };

  return {
    isLoading,
    confirmButtonIsVisible,
    renderBackButton,
    renderSubmitButton,
  };
};

export default useCheckForm;
