import styled, { css } from "styled-components";

import { breakpoint } from "helpers";
import CopyText from "components/CopyText";
import CurrencySumComponent from "components/CurrencySumComponent";

import { SectionInnerRow, SectionTitle } from "uikit";

export const Container = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  ${SectionTitle} {
    margin-bottom: 0;
  }
`;

export const BankAccountInfoContainer = styled.div`
  ${breakpoint("xs", "xlm")`
    width: 100%;
  `}

  ${breakpoint("xs", "md")`
    margin-bottom: 12px;
  `}

  ${breakpoint("md", "xlm")`
    margin-bottom: 28px;
  `}
`;

export const StyledSectionInnerRow = styled(SectionInnerRow)`
  align-items: stretch;
`;

export const BankAccountInfoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint("xs", "xlm")`
    width: 100%;
  `}
`;

const BankCurrencyTextMixin = css`
  display: inline-block;
  line-height: 48px;
  font-size: ${({ theme }) => theme.size.titleSemiLargeAlt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  vertical-align: top;

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.title};
  `}
`;

export const BankAccountAmount = styled(CurrencySumComponent)`
  display: inline-block;
  margin-bottom: 20px;

  ${breakpoint("xs", "md")`
    margin-bottom: 12px;
  `}

  ${breakpoint("md", "xlm")`
    margin-bottom: 24px;
  `}

  .csc-currency {
    ${BankCurrencyTextMixin}
    font-size: ${({ theme }) => theme.size.subtitle};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textAlt3};
    margin-right: 4px;

    ${breakpoint("xs", "xlm")`
      font-size: ${({ theme }) => theme.size.body2};
    `}
  }

  .csc-dollars {
    ${BankCurrencyTextMixin}
  }

  .csc-separator {
    ${BankCurrencyTextMixin}
  }

  .csc-cents {
    ${BankCurrencyTextMixin}

    ${breakpoint("xlm")`
      color: ${({ theme }) => theme.colors.textAlt3};
    `}
  }
`;

export const BANumbersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `}

  ${breakpoint("md", "xlm")`
    justify-content: space-between;
  `}
`;

export const BAItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 36px;
  box-sizing: border-box;

  &:last-of-type {
    margin-right: 0;
  }

  ${breakpoint("xs", "md")`
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid ${({ theme }) => theme.colors.contentBorder};
    border-radius: 4px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${breakpoint("xs", "xlm")`
    margin-right: 0;
  `}
`;

export const BANumberWrapper = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    align-items: flex-start;
  `}
`;
export const BATitle = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text};
  margin-right: 12px;

  ${breakpoint("xs", "md")`
    margin-right: 0;
    margin-bottom: 12px;
  `}
`;

export const BANumber = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text};
  margin-right: 8px;
`;

const BAButtonMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bankingUtilityButtonBg};
  cursor: pointer;

  svg {
    width: 16px;
  }
`;

export const BAButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const BAShowButton = styled.div`
  ${BAButtonMixin}
  margin-right: 12px;

  ${breakpoint("xs", "md")`
    margin-right: 16px;
  `}
`;

export const BACopyButton = styled(CopyText)`
  ${BAButtonMixin}
`;
