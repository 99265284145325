import { FC } from "react";
import { Trans } from "react-i18next";
import PromoManImage from "assets/png/promo_man.png";

import { Loader, Switch } from "uikit";

import { renderErrorMessage } from "../components/renderElements";
import { HDescription, Header, HTitle, StyledLoaderContainer } from "../components/styles";
import {
  Container,
  LeftSection,
  PromoImage,
  RightSection,
  StyledSectionAlt,
  SwitchTextDescription,
  SwitchTextTitle,
  SwitchTextWrapper,
  SwitchWrapper,
} from "./styles";

interface IProps {
  isChecked: boolean;
  onChange: () => void;
  isSubmitting: boolean;
  errorMessage?: string;
}

const EarlyPay: FC<IProps> = ({ isChecked, onChange, isSubmitting, errorMessage }) => {
  const originTranslationPrefix = "components.company_bank_account_components";
  const translationPrefix = `${originTranslationPrefix}.early_pay_form`;

  return (
    <Container>
      <LeftSection>
        <Header>
          <HTitle>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </HTitle>
          <HDescription>
            <Trans i18nKey={`${translationPrefix}.description`} />
          </HDescription>
        </Header>

        {renderErrorMessage(errorMessage)}

        <StyledSectionAlt>
          {isSubmitting && (
            <StyledLoaderContainer>
              <Loader />
            </StyledLoaderContainer>
          )}

          <SwitchWrapper>
            <Switch checked={isChecked} onChange={onChange} data-testId={"early-pay-switcher"} />
            <SwitchTextWrapper>
              <SwitchTextTitle>
                <Trans i18nKey={`${translationPrefix}.switch.title`} />
              </SwitchTextTitle>

              <SwitchTextDescription>
                <Trans i18nKey={`${translationPrefix}.switch.description`} />
              </SwitchTextDescription>
            </SwitchTextWrapper>
          </SwitchWrapper>
        </StyledSectionAlt>
      </LeftSection>

      <RightSection>
        <PromoImage src={PromoManImage} />
      </RightSection>
    </Container>
  );
};

export default EarlyPay;
