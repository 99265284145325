import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { salsaSelector, userMetadataSelector } from "store/selectors";

const useEmployeePayPage = () => {
  const salsaData = useAppSelector(salsaSelector);
  const { salsa, authToken } = salsaData || {};
  const frameId = "pay-frame-container";
  const frameSelector = `#${frameId}`;
  const currentUser = useAppSelector(userMetadataSelector);

  useEffect(() => {
    if (currentUser?.userId && salsa) {
      const salsaElement = salsa.elements.create("worker-payment-list", {
        userToken: authToken,
        workerId: currentUser.salsaWorkerId || "",
      });

      salsa.elements.mount(salsaElement, frameSelector);
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsa, currentUser]);

  return {
    frameId,
  };
};

export default useEmployeePayPage;
