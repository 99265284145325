import {
  APPLICATION_NAME,
  APPLICATION_NAME_UPPERCASE,
  APPLICATION_PARENT_NAME,
} from "constants/systemConstants";
import { EOptionIds } from "components/PaymentMethods/types";

export const paymentMethods = {
  "en-US": {
    title: `Bank Info`,
    description: `Choose your Payment Methods`,
    selected_label: `Selected method`,
    recommended_label: `Recommended`,

    promotion_points: {
      point_1: "Get Paid 2 Days Early!",
      point_2: "Earn rewards for banking and saving",
      point_3: "Cash back when you use your debit card",

      //Others are not always present
      point_4: "Free Checking",
      point_5: "Debit Card",
      point_6: "Send/receive money",
      point_7: "Check deposits",
      point_8: "Wire Transfers",
    },

    options: {
      [EOptionIds.COMPANY_BANK_ACCOUNT]: {
        title: `<1>${APPLICATION_NAME}</1> Bank Account`,
        description: `Get rewarded for positive financial behavior.`,
        description_active: `No application or set up. Payroll deposited <br />directly into your <1>${APPLICATION_NAME}</1> Checking account. <br />No hidden fees. `,

        buttons: {
          create: `Set Up ${APPLICATION_NAME} Bank Account`,
          info: "See More Info",
          info_alt: `Your ${APPLICATION_NAME} Bank Account`,
          select: `Select Method`,
        },

        modals: {
          method_selected: {
            title: "Success!",
            message: `Selected: ${APPLICATION_NAME_UPPERCASE} Bank Account`,
          },
        },
      },
      [EOptionIds.DIRECT_DEPOSIT]: {
        title: `Direct Deposit`,
        description: `Link your bank account to the <1>${APPLICATION_NAME}</1> platform <br />and receive compensation on your card.`,
        selected_bank_label: "Selected:",
        add_bank_account_button: `Add Bank Account`,
        back_button: `Back`,
        alert_label_one: `Alert`,
        alert_label_other: `Alerts`,
        pending_verification_label: "Pending Verification",
        buttons: {
          add: `Add your Bank Account`,
          info: "See More Info",
        },
        modals: {
          company_promoting_warning: {
            title: "Are you sure?",
            message: `Our free ${APPLICATION_NAME_UPPERCASE} bank account is significantly more efficient and user-friendly when it comes to managing compensation payments. Here are several advantages of opening a ${APPLICATION_NAME_UPPERCASE} account:`,
            cancelButton: "Create Paid Bank Account",
            submitButton: "Continue with Direct Deposit",
          },
          method_selected: {
            title: "Success!",
            message: "Selected: Direct Deposit",
          },
        },
      },
      [EOptionIds.PAPER_CHECK]: {
        title: `Paper Check`,
        description: `Your compensation will be mailed <br />to you as a paper check.`,

        buttons: {
          select: `Select Method`,
        },

        modals: {
          method_selected: {
            title: "Success!",
            message: "Selected: Paper Check",
          },
        },
      },
    },

    no_account_state: {
      title: `You don't have a bank account from ${APPLICATION_NAME_UPPERCASE}?`,
      subtitle_1: `The benefits of using a ${APPLICATION_NAME_UPPERCASE} account`,
      subtitle_2: `Would you like to compare and see which option would be more favorable for you?`,
      table_headers: {
        functions: "Functions",
        traditional_banking: "Traditional Bank",
        company_banking: `${APPLICATION_NAME} Banking`,
        company_banking_free: `Free`,
      },
      create_account_button: `Create ${APPLICATION_NAME} Bank Account`,
    },
  },
};

export default paymentMethods;
