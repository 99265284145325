import { FC } from "react";

import { ButtonsContainer, MainActionButton, SecondaryActionButton } from "../../styles";
import { EButtonsFlex, IModalBottomButtons } from "../../types";

const ModalBottomButtons: FC<IModalBottomButtons> = (props) => {
  const {
    type,
    mainButton,
    secondaryButton,
    onClose,
    onCloseOnButtons = true,
    buttonsFlex = EButtonsFlex.COLUMN,
    buttonsMobileFlex = EButtonsFlex.COLUMN,
    buttonsAlign,
    buttonsMobileAlign,
  } = props;

  const buttonsClassName = `flex-${buttonsFlex} mobile-flex-${buttonsMobileFlex} align-${buttonsAlign} mobile-align-${buttonsMobileAlign} ${type || ""}`;

  return (
    <ButtonsContainer className={buttonsClassName}>
      {mainButton && (
        <MainActionButton
          onClick={() => {
            mainButton.onClick?.();
            if (onCloseOnButtons) {
              onClose?.();
            }
          }}
          type={mainButton?.type || "button"}
          disabled={!!mainButton?.disabled}
          className={`${mainButton.autoWidth ? "auto-width" : ""}`}
          data-testId={`main-action-btn ${mainButton.dataTestId || ""}`}
        >
          {mainButton.text}
        </MainActionButton>
      )}

      {!!secondaryButton && (
        <SecondaryActionButton
          onClick={() => {
            secondaryButton.onClick?.();
            if (onCloseOnButtons) {
              onClose?.();
            }
          }}
          type={secondaryButton?.type || "button"}
          disabled={!!secondaryButton?.disabled}
          className={`${secondaryButton.autoWidth ? "auto-width" : ""}`}
          data-testId={`secondary-action-btn ${secondaryButton.dataTestId || ""}`}
        >
          {secondaryButton.text}
        </SecondaryActionButton>
      )}
    </ButtonsContainer>
  );
};

export default ModalBottomButtons;
