import { FC } from "react";

import IconProps from "./IconProps";

const TooltipCloud: FC<IconProps> = ({ className }) => (
  <svg
    width="171"
    height="77"
    viewBox="0 0 171 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Union" filter="url(#filter0_d_6037_10874)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0156 4.07422C7.14963 4.07422 4.01562 7.20823 4.01562 11.0742V57.1035C4.01562 60.9695 7.14963 64.1035 11.0156 64.1035H139.708L144.728 72.7979L149.747 64.1035H159.336C163.202 64.1035 166.336 60.9695 166.336 57.1035V11.0742C166.336 7.20823 163.202 4.07422 159.336 4.07422H11.0156Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6037_10874"
        x="0.015625"
        y="0.0742188"
        width="170.32"
        height="76.7227"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6037_10874" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6037_10874"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default TooltipCloud;
