import { FC } from "react";

import IconProps from "./IconProps";

const MakePayment: FC<IconProps> = ({ className }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.78857 6.55196C1.49631 6.84607 1.33326 7.24179 1.33326 7.65369V15.4315C1.33326 15.8465 1.49694 16.2416 1.78794 16.5326C2.08171 16.8264 2.47704 16.9895 2.88904 16.9895H13.9965C14.4098 16.9895 14.8054 16.8248 15.0976 16.5326C15.3914 16.2389 15.5545 15.8435 15.5545 15.4315V14.3195C15.5545 13.9516 15.8527 13.6533 16.2206 13.6533C16.5885 13.6533 16.8867 13.9516 16.8867 14.3195V15.4315C16.8867 16.197 16.5832 16.9312 16.0397 17.4747C15.4989 18.0155 14.765 18.3218 13.9965 18.3218H2.88904C2.12358 18.3218 1.38941 18.0182 0.845873 17.4747C0.303923 16.9327 0.000976562 16.1983 0.000976562 15.4315V7.65369C0.000976562 6.88841 0.304332 6.15489 0.844645 5.61175L0.845871 5.61052L0.845873 5.61052C1.38782 5.06857 2.12224 4.76562 2.88904 4.76562H13.9987C14.764 4.76562 15.4975 5.06898 16.0406 5.60929L16.0418 5.61052C16.5826 6.15129 16.8889 6.88521 16.8889 7.65369V8.76575C16.8889 9.13365 16.5907 9.43189 16.2228 9.43189C15.8549 9.43189 15.5566 9.13365 15.5566 8.76575V7.65369C15.5566 7.24066 15.3922 6.84538 15.1004 6.55326C14.8063 6.26097 14.4106 6.09791 13.9987 6.09791H2.88904C2.47432 6.09791 2.07951 6.26135 1.78857 6.55196Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.151 0.705018C11.3872 0.687183 11.6239 0.719407 11.8412 0.791839L11.8471 0.793836C12.0648 0.868686 12.2659 0.98259 12.4429 1.13529C12.6217 1.28949 12.7653 1.47551 12.8696 1.6841L14.5944 5.13364C14.7589 5.4627 14.6255 5.86283 14.2965 6.02736C13.9674 6.19189 13.5673 6.05852 13.4028 5.72946L11.678 2.27991C11.6514 2.22684 11.6163 2.18172 11.5726 2.14404C11.528 2.1055 11.4771 2.07584 11.417 2.05481C11.3606 2.03641 11.302 2.02968 11.2513 2.03352L11.2506 2.03358C11.1913 2.038 11.1339 2.05383 11.0802 2.08067L3.18685 6.02736C2.85779 6.19189 2.45766 6.05852 2.29313 5.72946C2.1286 5.4004 2.26198 5.00026 2.59104 4.83573L10.4844 0.889036C10.6923 0.785101 10.9182 0.722443 11.151 0.705018Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.007 9.10804C11.6532 8.46178 12.5292 8.09961 13.4426 8.09961H16.2206C16.6917 8.09961 17.1446 8.28758 17.4766 8.61961C17.8086 8.95164 17.9966 9.40452 17.9966 9.87562V13.2096C17.9966 13.6807 17.8086 14.1336 17.4766 14.4656C17.1608 14.7814 16.7356 14.9669 16.2895 14.9843C16.2668 14.9866 16.2439 14.9878 16.2206 14.9878H13.4426C12.5292 14.9878 11.6532 14.6256 11.007 13.9794C10.3607 13.3331 9.99854 12.4571 9.99854 11.5437C9.99854 10.6303 10.3607 9.7543 11.007 9.10804ZM16.1663 13.6555C16.1842 13.6541 16.2023 13.6533 16.2206 13.6533C16.3382 13.6533 16.4523 13.6058 16.5345 13.5235C16.6168 13.4413 16.6643 13.3272 16.6643 13.2096V9.87562C16.6643 9.75799 16.6168 9.64394 16.5345 9.56168C16.4523 9.47941 16.3382 9.43189 16.2206 9.43189H13.4426C12.882 9.43189 12.3451 9.65409 11.949 10.0501C11.553 10.4461 11.3308 10.9831 11.3308 11.5437C11.3308 12.1043 11.553 12.6413 11.949 13.0373C12.3451 13.4333 12.882 13.6555 13.4426 13.6555H16.1663Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4426 11.9329C13.6578 11.9329 13.8318 11.7589 13.8318 11.5437C13.8318 11.3284 13.6578 11.1544 13.4426 11.1544V10.6006C12.922 10.6006 12.4995 11.0231 12.4995 11.5437C12.4995 12.0642 12.922 12.4867 13.4426 12.4867V11.9329Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4427 10.6006C13.9632 10.6006 14.3858 11.0231 14.3858 11.5437C14.3858 12.0642 13.9632 12.4867 13.4427 12.4867V11.9329C13.2274 11.9329 13.0535 11.7589 13.0535 11.5437C13.0535 11.3284 13.2274 11.1544 13.4427 11.1544V10.6006Z"
      fill="#007D7B"
    />
  </svg>
);

export default MakePayment;
