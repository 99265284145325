import { FC } from "react";

import IconProps from "./IconProps";

const CheckmarkWhite: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07331 12.7279L17.6799 2.12132L15.5586 0L7.07227 8.48633L2.12132 3.53538L0 5.6567L7.07107 12.7278L7.07211 12.7267L7.07331 12.7279Z"
      fill="white"
    />
  </svg>
);

export default CheckmarkWhite;
