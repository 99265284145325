import { FC } from "react";

import IconProps from "./IconProps";

const LockLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="91"
    height="167"
    viewBox="0 0 91 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4162_2701)">
      <path
        d="M58.2711 165.974C57.8602 166.21 57.8602 166.588 58.2711 166.825C58.6819 167.061 59.342 167.061 59.7462 166.825C60.1571 166.588 60.1571 166.21 59.7462 165.974C59.3353 165.738 58.6752 165.738 58.2711 165.974Z"
        fill="#B4D7D6"
      />
      <path
        d="M54.4039 163.74C53.993 163.976 53.993 164.354 54.4039 164.59C54.8147 164.826 55.4749 164.826 55.879 164.59C56.2899 164.354 56.2899 163.976 55.879 163.74C55.4681 163.503 54.808 163.503 54.4039 163.74Z"
        fill="#B4D7D6"
      />
      <path
        d="M50.5445 161.512C50.1336 161.748 50.1336 162.126 50.5445 162.363C50.9554 162.599 51.6155 162.599 52.0196 162.363C52.4305 162.126 52.4305 161.748 52.0196 161.512C51.6087 161.276 50.9486 161.276 50.5445 161.512Z"
        fill="#B4D7D6"
      />
      <path
        d="M46.6773 159.285C46.2664 159.521 46.2664 159.899 46.6773 160.135C47.0882 160.371 47.7483 160.371 48.1524 160.135C48.5633 159.899 48.5633 159.521 48.1524 159.285C47.7416 159.048 47.0814 159.048 46.6773 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M42.8179 157.058C42.407 157.294 42.407 157.672 42.8179 157.908C43.2288 158.145 43.8889 158.145 44.2931 157.908C44.7039 157.672 44.7039 157.294 44.2931 157.058C43.8822 156.822 43.2221 156.822 42.8179 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M38.9527 154.824C38.5418 155.06 38.5418 155.438 38.9527 155.674C39.3636 155.91 40.0237 155.91 40.4278 155.674C40.8387 155.438 40.8387 155.06 40.4278 154.824C40.0169 154.587 39.3568 154.587 38.9527 154.824Z"
        fill="#B4D7D6"
      />
      <path
        d="M35.0933 152.596C34.6824 152.832 34.6824 153.21 35.0933 153.447C35.5042 153.683 36.1643 153.683 36.5684 153.447C36.9793 153.21 36.9793 152.832 36.5684 152.596C36.1576 152.36 35.4975 152.36 35.0933 152.596Z"
        fill="#B4D7D6"
      />
      <path
        d="M31.2261 150.369C30.8152 150.605 30.8152 150.983 31.2261 151.219C31.637 151.455 32.2971 151.455 32.7013 151.219C33.1121 150.983 33.1121 150.605 32.7013 150.369C32.2904 150.132 31.6303 150.132 31.2261 150.369Z"
        fill="#B4D7D6"
      />
      <path
        d="M27.3589 148.134C26.9481 148.37 26.9481 148.748 27.3589 148.985C27.7698 149.221 28.4299 149.221 28.8341 148.985C29.245 148.748 29.245 148.37 28.8341 148.134C28.4232 147.898 27.7631 147.898 27.3589 148.134Z"
        fill="#B4D7D6"
      />
      <path
        d="M23.4996 145.908C23.0887 146.144 23.0887 146.522 23.4996 146.758C23.9104 146.994 24.5706 146.994 24.9747 146.758C25.3856 146.522 25.3856 146.144 24.9747 145.908C24.5638 145.671 23.9037 145.671 23.4996 145.908Z"
        fill="#B4D7D6"
      />
      <path
        d="M19.6343 143.68C19.2235 143.916 19.2235 144.294 19.6343 144.531C20.0452 144.767 20.7053 144.767 21.1095 144.531C21.5136 144.294 21.5203 143.916 21.1095 143.68C20.6986 143.444 20.0385 143.444 19.6343 143.68Z"
        fill="#B4D7D6"
      />
      <path
        d="M15.775 141.446C15.3641 141.682 15.3641 142.06 15.775 142.296C16.1858 142.532 16.8459 142.532 17.2501 142.296C17.661 142.06 17.661 141.682 17.2501 141.446C16.8392 141.209 16.1791 141.209 15.775 141.446Z"
        fill="#B4D7D6"
      />
      <path
        d="M11.9078 139.218C11.4969 139.454 11.4969 139.832 11.9078 140.069C12.3187 140.305 12.9788 140.305 13.3829 140.069C13.7938 139.832 13.7938 139.454 13.3829 139.218C12.972 138.982 12.3119 138.982 11.9078 139.218Z"
        fill="#B4D7D6"
      />
      <path
        d="M8.04254 136.991C7.63165 137.227 7.63165 137.605 8.04254 137.841C8.45342 138.077 9.11352 138.077 9.51767 137.841C9.92855 137.605 9.92855 137.227 9.51767 136.991C9.10678 136.754 8.44668 136.754 8.04254 136.991Z"
        fill="#B4D7D6"
      />
      <path
        d="M65.9957 165.974C65.5848 166.21 65.5848 166.588 65.9957 166.825C66.4065 167.061 67.0666 167.061 67.4708 166.825C67.8817 166.588 67.8817 166.21 67.4708 165.974C67.0599 165.738 66.3998 165.738 65.9957 165.974Z"
        fill="#B4D7D6"
      />
      <path
        d="M62.1363 163.74C61.7254 163.976 61.7254 164.354 62.1363 164.59C62.5472 164.826 63.2073 164.826 63.6114 164.59C64.0223 164.354 64.0223 163.976 63.6114 163.74C63.2005 163.503 62.5404 163.503 62.1363 163.74Z"
        fill="#B4D7D6"
      />
      <path
        d="M58.2711 161.512C57.8602 161.748 57.8602 162.126 58.2711 162.363C58.6819 162.599 59.342 162.599 59.7462 162.363C60.1571 162.126 60.1571 161.748 59.7462 161.512C59.3353 161.276 58.6752 161.276 58.2711 161.512Z"
        fill="#B4D7D6"
      />
      <path
        d="M54.4039 159.285C53.993 159.521 53.993 159.899 54.4039 160.135C54.8147 160.371 55.4749 160.371 55.879 160.135C56.2899 159.899 56.2899 159.521 55.879 159.285C55.4681 159.048 54.808 159.048 54.4039 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M50.5445 157.058C50.1336 157.294 50.1336 157.672 50.5445 157.908C50.9554 158.145 51.6155 158.145 52.0196 157.908C52.4305 157.672 52.4305 157.294 52.0196 157.058C51.6087 156.822 50.9486 156.822 50.5445 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M46.6773 154.824C46.2664 155.06 46.2664 155.438 46.6773 155.674C47.0882 155.91 47.7483 155.91 48.1524 155.674C48.5633 155.438 48.5633 155.06 48.1524 154.824C47.7416 154.587 47.0814 154.587 46.6773 154.824Z"
        fill="#B4D7D6"
      />
      <path
        d="M42.8179 152.596C42.407 152.832 42.407 153.21 42.8179 153.447C43.2288 153.683 43.8889 153.683 44.2931 153.447C44.7039 153.21 44.7039 152.832 44.2931 152.596C43.8822 152.36 43.2221 152.36 42.8179 152.596Z"
        fill="#B4D7D6"
      />
      <path
        d="M38.9527 150.369C38.5418 150.605 38.5418 150.983 38.9527 151.219C39.3636 151.455 40.0237 151.455 40.4278 151.219C40.8387 150.983 40.8387 150.605 40.4278 150.369C40.0169 150.132 39.3568 150.132 38.9527 150.369Z"
        fill="#B4D7D6"
      />
      <path
        d="M35.0855 148.134C34.6746 148.37 34.6746 148.748 35.0855 148.985C35.4964 149.221 36.1565 149.221 36.5606 148.985C36.9715 148.748 36.9715 148.37 36.5606 148.134C36.1498 147.898 35.4896 147.898 35.0855 148.134Z"
        fill="#B4D7D6"
      />
      <path
        d="M31.2261 145.908C30.8152 146.144 30.8152 146.522 31.2261 146.758C31.637 146.994 32.2971 146.994 32.7013 146.758C33.1121 146.522 33.1121 146.144 32.7013 145.908C32.2904 145.671 31.6303 145.671 31.2261 145.908Z"
        fill="#B4D7D6"
      />
      <path
        d="M27.3589 143.68C26.9481 143.916 26.9481 144.294 27.3589 144.531C27.7698 144.767 28.4299 144.767 28.8341 144.531C29.245 144.294 29.245 143.916 28.8341 143.68C28.4232 143.444 27.7631 143.444 27.3589 143.68Z"
        fill="#B4D7D6"
      />
      <path
        d="M23.4996 141.446C23.0887 141.682 23.0887 142.06 23.4996 142.296C23.9104 142.532 24.5706 142.532 24.9747 142.296C25.3856 142.06 25.3856 141.682 24.9747 141.446C24.5638 141.209 23.9037 141.209 23.4996 141.446Z"
        fill="#B4D7D6"
      />
      <path
        d="M19.6343 139.218C19.2235 139.454 19.2235 139.832 19.6343 140.069C20.0452 140.305 20.7053 140.305 21.1095 140.069C21.5203 139.832 21.5203 139.454 21.1095 139.218C20.6986 138.982 20.0385 138.982 19.6343 139.218Z"
        fill="#B4D7D6"
      />
      <path
        d="M15.775 136.991C15.3641 137.227 15.3641 137.605 15.775 137.841C16.1858 138.077 16.8459 138.077 17.2501 137.841C17.661 137.605 17.661 137.227 17.2501 136.991C16.8392 136.754 16.1791 136.754 15.775 136.991Z"
        fill="#B4D7D6"
      />
      <path
        d="M11.9078 134.763C11.4969 134.999 11.4969 135.377 11.9078 135.614C12.3187 135.85 12.9788 135.85 13.3829 135.614C13.7938 135.377 13.7938 134.999 13.3829 134.763C12.972 134.527 12.3119 134.527 11.9078 134.763Z"
        fill="#B4D7D6"
      />
      <path
        d="M73.7222 165.974C73.3113 166.21 73.3113 166.588 73.7222 166.825C74.1331 167.061 74.7932 167.061 75.1974 166.825C75.6082 166.588 75.6082 166.21 75.1974 165.974C74.7865 165.738 74.1264 165.738 73.7222 165.974Z"
        fill="#B4D7D6"
      />
      <path
        d="M69.8628 163.74C69.452 163.976 69.452 164.354 69.8628 164.59C70.2737 164.826 70.9338 164.826 71.338 164.59C71.7489 164.354 71.7489 163.976 71.338 163.74C70.9271 163.503 70.267 163.503 69.8628 163.74Z"
        fill="#B4D7D6"
      />
      <path
        d="M65.9957 161.512C65.5848 161.748 65.5848 162.126 65.9957 162.363C66.4065 162.599 67.0666 162.599 67.4708 162.363C67.8817 162.126 67.8817 161.748 67.4708 161.512C67.0599 161.276 66.3998 161.276 65.9957 161.512Z"
        fill="#B4D7D6"
      />
      <path
        d="M62.1363 159.285C61.7254 159.521 61.7254 159.899 62.1363 160.135C62.5472 160.371 63.2073 160.371 63.6114 160.135C64.0223 159.899 64.0223 159.521 63.6114 159.285C63.2005 159.048 62.5404 159.048 62.1363 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M58.2711 157.058C57.8602 157.294 57.8602 157.672 58.2711 157.908C58.6819 158.145 59.342 158.145 59.7462 157.908C60.1571 157.672 60.1571 157.294 59.7462 157.058C59.3353 156.822 58.6752 156.822 58.2711 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M54.4039 154.824C53.993 155.06 53.993 155.438 54.4039 155.674C54.8147 155.91 55.4749 155.91 55.879 155.674C56.2899 155.438 56.2899 155.06 55.879 154.824C55.4681 154.587 54.808 154.587 54.4039 154.824Z"
        fill="#B4D7D6"
      />
      <path
        d="M52.0196 152.596C51.6087 152.36 50.9486 152.36 50.5445 152.596C50.1336 152.832 50.1336 153.21 50.5445 153.447C50.9554 153.683 51.6155 153.683 52.0196 153.447C52.4305 153.21 52.4305 152.832 52.0196 152.596C51.6087 152.36 52.4305 152.832 52.0196 152.596Z"
        fill="#B4D7D6"
      />
      <path
        d="M46.6773 150.369C46.2664 150.605 46.2664 150.983 46.6773 151.219C47.0882 151.455 47.7483 151.455 48.1524 151.219C48.5633 150.983 48.5633 150.605 48.1524 150.369C47.7416 150.132 47.0814 150.132 46.6773 150.369Z"
        fill="#B4D7D6"
      />
      <path
        d="M42.8179 148.134C42.407 148.37 42.407 148.748 42.8179 148.985C43.2288 149.221 43.8889 149.221 44.2931 148.985C44.7039 148.748 44.7039 148.37 44.2931 148.134C43.8822 147.898 43.2221 147.898 42.8179 148.134Z"
        fill="#B4D7D6"
      />
      <path
        d="M38.9527 145.908C38.5418 146.144 38.5418 146.522 38.9527 146.758C39.3636 146.994 40.0237 146.994 40.4278 146.758C40.8387 146.522 40.8387 146.144 40.4278 145.908C40.0169 145.671 39.3568 145.671 38.9527 145.908Z"
        fill="#B4D7D6"
      />
      <path
        d="M35.0855 143.68C34.6746 143.916 34.6746 144.294 35.0855 144.531C35.4964 144.767 36.1565 144.767 36.5606 144.531C36.9715 144.294 36.9715 143.916 36.5606 143.68C36.1498 143.444 35.4896 143.444 35.0855 143.68Z"
        fill="#B4D7D6"
      />
      <path
        d="M31.2261 141.446C30.8152 141.682 30.8152 142.06 31.2261 142.296C31.637 142.532 32.2971 142.532 32.7013 142.296C33.1121 142.06 33.1121 141.682 32.7013 141.446C32.2904 141.209 31.6303 141.209 31.2261 141.446Z"
        fill="#B4D7D6"
      />
      <path
        d="M27.3589 139.218C26.9481 139.454 26.9481 139.832 27.3589 140.069C27.7698 140.305 28.4299 140.305 28.8341 140.069C29.245 139.832 29.245 139.454 28.8341 139.218C28.4232 138.982 27.7631 138.982 27.3589 139.218Z"
        fill="#B4D7D6"
      />
      <path
        d="M23.4996 136.991C23.0887 137.227 23.0887 137.605 23.4996 137.841C23.9104 138.077 24.5706 138.077 24.9747 137.841C25.3856 137.605 25.3856 137.227 24.9747 136.991C24.5638 136.754 23.9037 136.754 23.4996 136.991Z"
        fill="#B4D7D6"
      />
      <path
        d="M19.6343 134.763C19.2235 134.999 19.2235 135.377 19.6343 135.614C20.0452 135.85 20.7053 135.85 21.1095 135.614C21.5203 135.377 21.5203 134.999 21.1095 134.763C20.6986 134.527 20.0385 134.527 19.6343 134.763Z"
        fill="#B4D7D6"
      />
      <path
        d="M15.775 132.53C15.3641 132.766 15.3641 133.144 15.775 133.38C16.1858 133.616 16.8459 133.616 17.2501 133.38C17.661 133.144 17.661 132.766 17.2501 132.53C16.8392 132.293 16.1791 132.293 15.775 132.53Z"
        fill="#B4D7D6"
      />
      <path
        d="M77.5875 163.74C77.1766 163.976 77.1766 164.354 77.5875 164.59C77.9983 164.826 78.6584 164.826 79.0626 164.59C79.4735 164.354 79.4735 163.976 79.0626 163.74C78.6517 163.503 77.9916 163.503 77.5875 163.74Z"
        fill="#B4D7D6"
      />
      <path
        d="M73.7222 161.512C73.3113 161.748 73.3113 162.126 73.7222 162.363C74.1331 162.599 74.7932 162.599 75.1974 162.363C75.6082 162.126 75.6082 161.748 75.1974 161.512C74.7865 161.276 74.1264 161.276 73.7222 161.512Z"
        fill="#B4D7D6"
      />
      <path
        d="M69.8628 159.285C69.452 159.521 69.452 159.899 69.8628 160.135C70.2737 160.371 70.9338 160.371 71.338 160.135C71.7489 159.899 71.7489 159.521 71.338 159.285C70.9271 159.048 70.267 159.048 69.8628 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M65.9957 157.058C65.5848 157.294 65.5848 157.672 65.9957 157.908C66.4065 158.145 67.0666 158.145 67.4708 157.908C67.8817 157.672 67.8817 157.294 67.4708 157.058C67.0599 156.822 66.3998 156.822 65.9957 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M81.4546 161.512C81.0438 161.748 81.0438 162.126 81.4546 162.363C81.8655 162.599 82.5256 162.599 82.9298 162.363C83.3407 162.126 83.3407 161.748 82.9298 161.512C82.5189 161.276 81.8588 161.276 81.4546 161.512Z"
        fill="#B4D7D6"
      />
      <path
        d="M77.5875 159.285C77.1766 159.521 77.1766 159.899 77.5875 160.135C77.9983 160.371 78.6584 160.371 79.0626 160.135C79.4735 159.899 79.4735 159.521 79.0626 159.285C78.6517 159.048 77.9916 159.048 77.5875 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M73.7222 157.058C73.3113 157.294 73.3113 157.672 73.7222 157.908C74.1331 158.145 74.7932 158.145 75.1974 157.908C75.6082 157.672 75.6082 157.294 75.1974 157.058C74.7865 156.822 74.1264 156.822 73.7222 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M85.314 159.285C84.9031 159.521 84.9031 159.899 85.314 160.135C85.7249 160.371 86.385 160.371 86.7891 160.135C87.2 159.899 87.2 159.521 86.7891 159.285C86.3783 159.048 85.7182 159.048 85.314 159.285Z"
        fill="#B4D7D6"
      />
      <path
        d="M81.4546 157.058C81.0438 157.294 81.0438 157.672 81.4546 157.908C81.8655 158.145 82.5256 158.145 82.9298 157.908C83.3407 157.672 83.3407 157.294 82.9298 157.058C82.5189 156.822 81.8588 156.822 81.4546 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M77.5875 154.824C77.1766 155.06 77.1766 155.438 77.5875 155.674C77.9983 155.91 78.6584 155.91 79.0626 155.674C79.4735 155.438 79.4735 155.06 79.0626 154.824C78.6517 154.587 77.9916 154.587 77.5875 154.824Z"
        fill="#B4D7D6"
      />
      <path
        d="M89.1812 157.058C88.7703 157.294 88.7703 157.672 89.1812 157.908C89.5921 158.145 90.2522 158.145 90.6563 157.908C91.0672 157.672 91.0672 157.294 90.6563 157.058C90.2455 156.822 89.5854 156.822 89.1812 157.058Z"
        fill="#B4D7D6"
      />
      <path
        d="M85.314 154.824C84.9031 155.06 84.9031 155.438 85.314 155.674C85.7249 155.91 86.385 155.91 86.7891 155.674C87.2 155.438 87.2 155.06 86.7891 154.824C86.3783 154.587 85.7182 154.587 85.314 154.824Z"
        fill="#B4D7D6"
      />
      <path
        d="M81.4546 152.596C81.0438 152.832 81.0438 153.21 81.4546 153.447C81.8655 153.683 82.5256 153.683 82.9298 153.447C83.3407 153.21 83.3407 152.832 82.9298 152.596C82.5189 152.36 81.8588 152.36 81.4546 152.596Z"
        fill="#B4D7D6"
      />
      <path
        d="M89.2144 152.569C88.8035 152.805 88.8035 153.183 89.2144 153.419C89.6253 153.655 90.2854 153.655 90.6895 153.419C91.1004 153.183 91.1004 152.805 90.6895 152.569C90.2787 152.333 89.6186 152.333 89.2144 152.569Z"
        fill="#B4D7D6"
      />
      <path
        d="M85.3472 150.341C84.9363 150.577 84.9363 150.955 85.3472 151.192C85.7581 151.428 86.4182 151.428 86.8223 151.192C87.2332 150.955 87.2332 150.577 86.8223 150.341C86.4115 150.105 85.7514 150.105 85.3472 150.341Z"
        fill="#B4D7D6"
      />
      <path
        d="M89.2144 148.115C88.8035 148.351 88.8035 148.729 89.2144 148.965C89.6253 149.201 90.2854 149.201 90.6895 148.965C91.1004 148.729 91.1004 148.351 90.6895 148.115C90.2787 147.878 89.6186 147.878 89.2144 148.115Z"
        fill="#B4D7D6"
      />
      <path
        d="M85.3472 145.887C84.9363 146.123 84.9363 146.501 85.3472 146.738C85.7581 146.974 86.4182 146.974 86.8223 146.738C87.2332 146.501 87.2332 146.123 86.8223 145.887C86.4115 145.651 85.7514 145.651 85.3472 145.887Z"
        fill="#B4D7D6"
      />
      <path
        d="M5.65048 134.79C5.24633 134.554 4.57949 134.554 4.17535 134.79C3.76447 135.027 3.76447 135.405 4.17535 135.641C4.57949 135.877 5.24633 135.877 5.65048 135.641C6.06136 135.405 6.06136 135.027 5.65048 134.79Z"
        fill="#B4D7D6"
      />
      <path
        d="M0.302301 132.563C-0.10858 132.799 -0.10858 133.177 0.302301 133.413C0.713182 133.65 1.37329 133.65 1.77743 133.413C2.18831 133.177 2.18831 132.799 1.77743 132.563C1.36655 132.327 0.706446 132.327 0.302301 132.563Z"
        fill="#B4D7D6"
      />
      <path
        d="M9.51767 132.563C9.11352 132.327 8.44668 132.327 8.04254 132.563C7.63165 132.799 7.63165 133.177 8.04254 133.413C8.44668 133.65 9.11352 133.65 9.51767 133.413C9.92855 133.177 9.92855 132.799 9.51767 132.563Z"
        fill="#B4D7D6"
      />
      <path
        d="M4.16949 130.329C3.75861 130.565 3.75861 130.943 4.16949 131.179C4.58037 131.415 5.24047 131.415 5.64462 131.179C6.0555 130.943 6.0555 130.565 5.64462 130.329C5.23374 130.092 4.57363 130.092 4.16949 130.329Z"
        fill="#B4D7D6"
      />
      <path
        d="M0.302301 128.102C-0.10858 128.338 -0.10858 128.716 0.302301 128.952C0.713182 129.189 1.37329 129.189 1.77743 128.952C2.18831 128.716 2.18831 128.338 1.77743 128.102C1.36655 127.866 0.706446 127.866 0.302301 128.102Z"
        fill="#B4D7D6"
      />
      <path
        d="M13.377 130.335C12.9662 130.099 12.3061 130.099 11.9019 130.335C11.491 130.572 11.491 130.95 11.9019 131.186C12.3128 131.422 12.9729 131.422 13.377 131.186C13.7879 130.95 13.7879 130.572 13.377 130.335Z"
        fill="#B4D7D6"
      />
      <path
        d="M8.03472 128.102C7.62384 128.338 7.62384 128.716 8.03472 128.952C8.4456 129.189 9.10571 129.189 9.50985 128.952C9.92073 128.716 9.92073 128.338 9.50985 128.102C9.09897 127.866 8.43887 127.866 8.03472 128.102Z"
        fill="#B4D7D6"
      />
      <path
        d="M4.16949 125.874C3.75861 126.111 3.75861 126.489 4.16949 126.725C4.58037 126.961 5.24047 126.961 5.64462 126.725C6.0555 126.489 6.0555 126.111 5.64462 125.874C5.23374 125.638 4.57363 125.638 4.16949 125.874Z"
        fill="#B4D7D6"
      />
      <path
        d="M0.302301 123.64C-0.10858 123.876 -0.10858 124.254 0.302301 124.491C0.713182 124.727 1.37329 124.727 1.77743 124.491C2.18831 124.254 2.18831 123.876 1.77743 123.64C1.36655 123.404 0.706446 123.404 0.302301 123.64Z"
        fill="#B4D7D6"
      />
      <path
        d="M84.9844 145.475C85.0046 145.225 85.0046 144.976 84.9844 144.726V71.6742L9.8808 41.9893L1.66992 43.0557V116.856C1.56888 118.983 2.77458 121.028 5.39479 122.54L58.0751 153.102C63.6254 156.315 73.3114 155.91 79.7171 152.204C83.0445 150.281 84.816 147.837 84.9912 145.488V145.475H84.9844Z"
        fill="#B4D7D6"
      />
      <path
        d="M67.465 156.146C63.7738 156.146 60.251 155.397 57.6174 153.878L4.94378 123.316C2.13497 121.689 0.653107 119.381 0.781086 116.809V42.2728L9.99559 41.0781L10.2111 41.1591L85.8873 71.0669V144.726C85.9075 144.949 85.9075 145.246 85.8873 145.536C85.665 148.357 83.6376 150.976 80.1754 152.981C76.5515 155.073 71.9039 156.146 67.4852 156.146H67.465ZM2.55932 43.8455V116.857C2.47176 118.767 3.63031 120.495 5.8329 121.764L58.5132 152.326C63.8008 155.39 73.1096 154.985 79.2593 151.428C82.1759 149.741 83.9339 147.554 84.0888 145.421L84.978 145.475L84.0888 145.414C84.1023 145.205 84.1091 145.003 84.0888 144.793V72.2885L9.76658 42.9073L2.56606 43.8455H2.55932Z"
        fill="#007D7B"
      />
      <path
        d="M58.0694 79.3016L5.38912 48.7392C-0.154404 45.5331 0.539378 39.9241 6.94508 36.2186C13.3508 32.513 23.0435 32.108 28.587 35.3209L81.2674 65.8833C86.8176 69.0961 86.1171 74.6983 79.7114 78.4039C73.3057 82.1095 63.6129 82.5144 58.0694 79.3016Z"
        fill="#B4D7D6"
      />
      <path
        d="M58.5136 85.0184L5.83326 54.456C0.283005 51.2431 0.983523 45.6409 7.38922 41.9354C13.7949 38.2298 23.4877 37.8248 29.0312 41.0376L81.7115 71.6001C87.2618 74.8129 86.5612 80.4151 80.1555 84.1207C73.7498 87.8263 64.0571 88.2312 58.5136 85.0184Z"
        fill="white"
      />
      <path
        d="M67.4648 82.3454C63.7736 82.3454 60.2508 81.5961 57.6171 80.0775L4.94354 49.515C2.24251 47.9491 0.753906 45.742 0.753906 43.2986C0.753906 40.4165 2.84872 37.5546 6.4995 35.442C13.141 31.6014 23.2513 31.1965 29.0373 34.5443L81.7176 65.1067C84.4187 66.6726 85.9073 68.8798 85.9073 71.3232C85.9073 74.2053 83.8125 77.0671 80.1617 79.1798C76.5378 81.2722 71.8902 82.3454 67.4715 82.3454H67.4648ZM58.513 78.525C63.8073 81.5894 73.1094 81.1844 79.2658 77.6273C82.3508 75.8454 84.1156 73.5438 84.1156 71.3232C84.1156 69.548 82.9436 67.8876 80.815 66.6592L28.1415 36.0967C22.8472 33.0324 13.5384 33.4373 7.38862 36.9944C4.30365 38.7763 2.53888 41.078 2.53888 43.2986C2.53888 45.0738 3.7109 46.7342 5.8394 47.9626L58.5197 78.525H58.513Z"
        fill="#007D7B"
      />
      <path
        d="M66.7991 71.7358H62.7644C60.326 71.7358 58.3524 69.7581 58.3524 67.3148V46.4853C58.3524 35.3821 50.2426 21.3158 40.6509 15.7609C37.2426 13.79 34.1172 13.2365 32.2918 14.2962C30.4664 15.3492 29.382 18.3393 29.382 22.2878V43.1105C29.382 45.5539 27.4084 47.5315 24.9701 47.5315H20.9354C18.497 47.5315 16.5234 45.5539 16.5234 43.1105V22.2878C16.5234 13.5537 19.925 6.5746 25.8659 3.13902C31.8001 -0.296551 39.5328 0.236672 47.0835 4.6037C60.6157 12.4333 71.2177 30.8329 71.2177 46.4853V67.3148C71.2177 69.7581 69.2442 71.7358 66.8058 71.7358H66.7991Z"
        fill="#B4D7D6"
      />
      <path
        d="M37.881 99.3823C37.881 95.5283 35.18 90.8373 31.8458 88.9069C28.5116 86.9765 25.8105 88.5424 25.8105 92.3964C25.8105 95.4675 27.5349 99.0381 29.9126 101.353V114.927L33.5567 117.033V103.54C36.0489 104.127 37.881 102.541 37.881 99.3823Z"
        fill="#B4D7D6"
      />
      <path
        d="M41.7619 97.8296C41.7619 93.9756 39.0608 89.2845 35.7266 87.3541C32.3924 85.4237 29.6914 86.9897 29.6914 90.8437C29.6914 93.9148 31.4158 97.4854 33.7935 99.8005V113.374L37.4375 115.48V101.987C39.9297 102.575 41.7619 100.988 41.7619 97.8296Z"
        fill="white"
      />
      <path
        d="M34.4462 118.591L29.0172 115.446V101.724C26.5519 99.1857 24.9219 95.4869 24.9219 92.4023C24.9219 90.2019 25.71 88.5348 27.1379 87.7113C28.5659 86.8879 30.398 87.0363 32.2975 88.1365C35.9348 90.2424 38.7773 95.1832 38.7773 99.3882C38.7773 101.298 38.1576 102.837 37.0328 103.728C36.3188 104.295 35.4027 104.558 34.4462 104.558V118.591ZM30.8022 114.413L32.6613 115.486V102.412L33.7592 102.669C34.3654 102.81 35.2276 102.864 35.9214 102.318C36.6084 101.771 36.9856 100.725 36.9856 99.3815C36.9856 95.8177 34.4799 91.4641 31.3949 89.6822C30.068 88.9127 28.869 88.771 28.0203 89.257C27.1716 89.7497 26.7001 90.8634 26.7001 92.3956C26.7001 95.1427 28.2359 98.477 30.526 100.704L30.7955 100.968V114.406L30.8022 114.413Z"
        fill="#007D7B"
      />
      <path
        d="M22.5176 47.4298V25.0682C22.5176 22.78 23.1642 20.5459 24.3766 18.6087C25.6362 16.6108 27.5155 15.0787 29.7248 14.2552L32.7425 13.1348L30.4523 15.9696L29.4487 20.1139L29.2264 43.9604L28.0207 46.7616L22.5243 47.4365L22.5176 47.4298Z"
        fill="#007D7B"
      />
      <path
        d="M64.2988 71.4994V46.114C64.2988 44.4738 64.6558 42.8471 65.3361 41.3555C66.2859 39.2833 67.8351 37.5419 69.7817 36.3607L69.8558 36.3135L71.2097 41.072V68.2529L69.4449 70.838L64.2988 71.4994Z"
        fill="#007D7B"
      />
      <path
        d="M64.2996 81.4681V141.884C64.2996 143.369 63.781 144.807 62.838 145.948C61.3292 147.777 58.938 148.614 56.6209 148.128L56.4996 148.101C51.0437 146.953 45.8033 144.942 40.9805 142.141L49.5147 148.02L59.7934 153.689L67.7551 155.255L76.6261 153.541L83.1799 149.511L84.9851 144.712L85.881 71.8701L80.6473 77.7221L75.7976 80.1384L68.7318 81.4681H64.2996Z"
        fill="#007D7B"
      />
      <path
        d="M67.7545 30.9746C67.8757 30.9139 67.9768 30.8329 68.0711 30.7451C63.9622 19.9524 56.1892 9.88193 47.0757 4.6037C39.5317 0.236672 31.799 -0.296551 25.8581 3.13902C19.9239 6.5746 16.5156 13.5537 16.5156 22.2878V25.7369L16.5965 25.8246C16.5965 25.8246 18.7182 12.6155 30.3374 8.69399C41.9566 4.77919 50.6659 15.7474 55.2462 23.0235C59.8265 30.2996 63.3897 33.2087 67.7545 30.9746Z"
        fill="white"
      />
      <path
        d="M66.8061 72.6329H62.7714C59.848 72.6329 57.4636 70.2503 57.4636 67.3142V46.4915C57.4636 35.6718 49.5625 21.9565 40.2066 16.5432C37.1216 14.7613 34.2589 14.2011 32.7366 15.0786C31.2144 15.956 30.2714 18.7234 30.2714 22.2939V43.1234C30.2714 46.0527 27.8936 48.4421 24.9636 48.4421H20.9289C18.0055 48.4421 15.6211 46.0595 15.6211 43.1234V22.2939C15.6211 13.2292 19.191 5.96653 25.4081 2.36896C31.632 -1.22861 39.6879 -0.695383 47.5216 3.83364C61.303 11.8117 72.1004 30.5488 72.1004 46.4915V67.3142C72.1004 70.2435 69.7227 72.6329 66.7926 72.6329H66.8061ZM34.5351 12.8647C36.4144 12.8647 38.6776 13.5869 41.0957 14.9841C50.9366 20.6808 59.2486 35.1048 59.2486 46.4847V67.3074C59.2486 69.2513 60.8247 70.8307 62.7646 70.8307H66.7993C68.7392 70.8307 70.3154 69.2513 70.3154 67.3074V46.4847C70.3154 31.1225 59.9154 13.0604 46.6325 5.37931C39.3646 1.17427 31.9553 0.64105 26.3107 3.91463C20.6594 7.18147 17.4128 13.8839 17.4128 22.2872V43.1166C17.4128 45.0605 18.989 46.64 20.9289 46.64H24.9636C26.9035 46.64 28.4796 45.0605 28.4796 43.1166V22.2872C28.4796 17.9472 29.7056 14.7546 31.8408 13.5194C32.5952 13.0807 33.5045 12.8647 34.5351 12.8647Z"
        fill="#007D7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_4162_2701">
        <rect width="91" height="167" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LockLarge;
