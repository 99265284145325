export const spouseNotificationTranslations = {
  "en-US": {
    text: "<1>{{state}} residents</1> are required by law to inform their spouses upon submitting loan applications. Each Keep Vesting Cash Bonus is structured as a 0% loan that would require repayment in the event of exit before fully vesting.",
    default_state: "State",
    switch_label: "Are you currently married?",
    switch_checked: "Yes",
    switch_unchecked: "No",
    email_label: "Spouse email address",
    wrong_email_format: "Wrong email format",
    email_is_required: "Email is required",
  },
};

export default spouseNotificationTranslations;
