import useAuth from "hooks/useAuth";

import { queryEmployeesControllerGetEmployee, UserResponseDto } from "utils/swagger_react_query";

export const getDeferredData = async (
  request: () => {},
  condition: (data: any) => {},
  intervalTime: number = 2000,
) => {
  const data = await request();

  return await new Promise<any>((resolve) => {
    const successFunction = condition;
    if (successFunction(data)) {
      resolve(data);
    } else {
      let attempts = 5;
      const interval = setInterval(async () => {
        const data = await request();
        attempts--;
        if (successFunction(data) || !attempts) {
          resolve(data);
          clearInterval(interval);
        }
      }, intervalTime);
    }
  });
};

export const getDeferredEmployeeData = async (id: string) => {
  const request = async () => {
    const data: UserResponseDto = await queryEmployeesControllerGetEmployee({
      employeeId: id || "",
    });
    return data;
  };

  const condition = (data: UserResponseDto) => {
    return !!data.salsaWorkerId;
  };

  return await getDeferredData(request, condition);
};
