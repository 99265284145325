import styled from "styled-components";

import { breakpoint } from "helpers";

import { UIModal } from "uikit";
import { Container } from "uikit/Input/styles";

export const StyledUIModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 480px !important;
  `}
`;

export const ContentItem = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  margin-bottom: 8px;
  border-radius: 4px;
`;

export const CILabel = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const CIValue = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const MFAVerifyCodeContainer = styled.div`
  text-align: initial;
  margin: 32px 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  ${Container} {
    width: 100%;
  }
`;

export const FormItemLabel = styled.p`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.size.body2};
`;
