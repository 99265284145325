import { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { debounce } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { showErrorModal } from "helpers";
import ListEmployeeInfo from "components/ListEmployeeInfo";
import { UserStatusString } from "components/UserStatus";

import { ContextMenu } from "uikit";
import { EColumnType, IColumn } from "uikit/Table/types";

import {
  mutationEmployeesControllerListEmployeesByFilterLite,
  UserShortResponseDto,
} from "utils/swagger_react_query";

import useWorkerActions from "../WorkerActions/useWorkerActions";
import { ETableVariants, IProps } from "./types";

const useWorkersList = (props: IProps) => {
  const { onListChange, perPage = 20 } = props;

  //const perPage = 20;
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  //PAGE DATA
  const [data, setData] = useState<UserShortResponseDto[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>();

  const translationPrefix = `people_page.index`;

  const redirectToWorkerDetails = (data: UserShortResponseDto) => {
    navigate(
      generatePath(routes.PEOPLE_WORKER_DETAILS, {
        workerId: data.userId || "",
      }),
    );

    //Uncomment when tab duplication bug is fixed
    // window.open(
    // generatePath(routes.PEOPLE_WORKER_DETAILS, {
    //   workerId: data.user?.userId || "",
    // }),
    //   "_blank",
    // );
  };

  const fetchEmployeesList = useCallback(
    debounce(async (page?: number, searchValue?: string) => {
      try {
        setTableLoading(true);
        const employeesListRes = await mutationEmployeesControllerListEmployeesByFilterLite({
          page: (page || 1)?.toString(),
          perPage: perPage.toString(),
        })({
          searchString: searchValue || "",
        });

        setData(employeesListRes.users);
        setTotalCount(employeesListRes.total);
      } catch (error) {
        showErrorModal(error);
      } finally {
        setTableLoading(false);
      }
    }, 500),
    [],
  );

  const {
    editedUser,
    isLoading,
    isAddWorkerFormOpen,
    disabledResends,
    getContextMenuOptions,
    setLoading,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    closeAddWorkerForm,
    handleAddWorker,
    handleSaveEditedWorker,
    handleCancelAddWorkerForm,
    renderWorkerActionsElements,
  } = useWorkerActions({
    onAddWorker: () => fetchEmployeesList(page, searchValue),
    onEditWorker: () => fetchEmployeesList(page, searchValue),
    onDeleteWorker: () => fetchEmployeesList(page, searchValue),
    onInviteWorker: () => fetchEmployeesList(page, searchValue),
  });

  const columnsSet = {
    userInfo: {
      key: "userInfo",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.user_info`} />,
      className: "userInfo",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => {
        return (
          <ListEmployeeInfo
            id={row.userId}
            firstName={row.firstName}
            lastName={row.lastName}
            avatar={row.avatarUrl}
            onClick={() => redirectToWorkerDetails(row)}
          />
        );
      },
    },
    status: {
      key: "status",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.status`} />,
      className: "status",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => {
        return <UserStatusString data={row} />;
      },
    },
    contextMenu: {
      key: "contextMenu",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.contextMenu`} />,
      className: "contextMenu",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => {
        const menuItems = getContextMenuOptions(row);
        const resendItem = menuItems.find((item) => item?.value === "resendInvite");
        if (resendItem) {
          resendItem.disabled = !!disabledResends.find((item) => item.id === row.userId);
        }

        return <ContextMenu options={menuItems} />;
      },
    },
    email: {
      key: "email",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.email`} />,
      className: "email",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => <>{row.email}</>,
    },
    department: {
      key: "department",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.department`} />,
      className: "department",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => <>{row.department}</>,
    },
    employmentType: {
      key: "employmentType",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.employment_type`} />,
      className: "employmentType",
      type: EColumnType.Component,
      component: (row: UserShortResponseDto) => (
        <>
          {row.employmentType ? (
            <Trans i18nKey={`common.worker_employment_type_short.${row.employmentType}`} />
          ) : (
            ""
          )}
        </>
      ),
    },
  };

  const getColumns = (type: ETableVariants): IColumn[] => {
    const { userInfo, status, contextMenu, email, department, employmentType } = columnsSet;
    switch (type) {
      case ETableVariants.ONBOARDING_LIST:
        return [userInfo, email, employmentType, status, contextMenu];
      case ETableVariants.PEOPLE_LIST:
        return [userInfo, employmentType, status, contextMenu];
      default:
        return [];
    }
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearchValue(value);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchEmployeesList(page, searchValue);
  }, [page, searchValue]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    onListChange?.(data);
  }, [data]);

  return {
    data,
    currentUser,
    editedUser,
    isLoading,
    isTableLoading,
    isAddWorkerFormOpen,
    paginationProps: {
      page,
      perPage,
      searchValue,
      totalCount,
      onSearch: handleSearch,
      onPageChange: setPage,
    },
    setLoading,
    getColumns,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    closeAddWorkerForm,
    handleAddWorker,
    handleSaveEditedWorker,
    handleCancelAddWorkerForm,
    renderWorkerActionsElements,
    fetchEmployeesList,
  };
};

export default useWorkersList;
