import * as yup from "yup";

import { MAX_INPUT_LENGTH } from "constants/form";

export const BusinessDetailsFormValidationSchema = yup.object({
  businessName: yup
    .string()
    .required("validationErrors.common.is_required")
    .test("long company name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    })
    .test("is required", "validationErrors.common.is_required", function (value) {
      return (value?.trim() || "").length > 0;
    }),
});

export type BusinessDetailsFormType = yup.InferType<typeof BusinessDetailsFormValidationSchema>;
