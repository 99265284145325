import { FC } from "react";

import IconProps from "../IconProps";

const ModalSuccess: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group">
      <circle
        id="Oval"
        cx="11.9998"
        cy="11.9998"
        r="9.00375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path"
        d="M8.44238 12.3387L10.6103 14.5066L10.5963 14.4926L15.4873 9.60156"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ModalSuccess;
