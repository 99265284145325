import { KycStatus } from "types/BETypes";

import { UserResponseDto } from "utils/swagger_react_query";

export const isKycAccepted = (user?: Partial<UserResponseDto> | null) => {
  return user?.kycStatus === KycStatus.ACCEPTED;
};

export const isKycRejected = (user?: Partial<UserResponseDto> | null) => {
  return user?.kycStatus === KycStatus.REJECTED;
};

export const isKycPending = (user?: Partial<UserResponseDto> | null) => {
  return (
    user?.kycStatus === KycStatus.PENDING ||
    user?.kycStatus === KycStatus.REVIEW ||
    user?.kycStatus === KycStatus.PROVISIONAL
  );
};
