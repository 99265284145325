import styled from "styled-components";

import { Body2, H3, PrimaryButton } from "uikit";

const imageWidth = 135;
const imageHeight = 132;

export const Container = styled.div`
  position: relative;
  height: ${imageHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 32px;
`;

export const Img = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 135px;
  height: 132px;
`;

export const Content = styled.div`
  width: 100%;
  height: 98px;
  background-color: ${({ theme }) => theme.colors.primaryAlt6};
  border: 1px solid ${({ theme }) => theme.colors.primaryAlt4};
  border-radius: 12px;
  padding: 23px 24px 29px ${imageWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const Message = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const ContentInnerLeft = styled.div``;
export const ContentInnerRight = styled.div``;

export const ContinueSetupBtn = styled(PrimaryButton)``;
