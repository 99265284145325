import { FC } from "react";

import IconProps from "./IconProps";

const BankIconPlaceholder: FC<IconProps> = ({ className }) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5_9)">
      <path
        d="M43 0H6C2.68629 0 0 2.68629 0 6V43C0 46.3137 2.68629 49 6 49H43C46.3137 49 49 46.3137 49 43V6C49 2.68629 46.3137 0 43 0Z"
        fill="#E0E0E0"
      />
      <g clipPath="url(#clip1_5_9)">
        <path
          d="M37.8329 36.2329H11.2104V37.7733H10V39.9999H38.9567V37.7733H37.8329V36.2329Z"
          fill="#848484"
        />
        <path
          d="M24.4792 9L10.0432 15.68V17.9067H11.2103V19.4471H37.8328V17.9067H38.9999V15.68L24.4792 9Z"
          fill="#848484"
        />
        <path
          d="M12.3618 33.0644V35.6323H18.7601V33.0644H17.6363V21.8452H18.7601V20.3887H17.6363H13.4856H12.3618V21.8452H13.4856V33.0644H12.3618Z"
          fill="#848484"
        />
        <path
          d="M21.2812 33.0644V35.6323H27.6776V33.0644H26.5538V21.8452H27.6776V20.3887H26.5538H22.405H21.2812V21.8452H22.405V33.0644H21.2812Z"
          fill="#848484"
        />
        <path
          d="M30.2695 33.0644V35.6323H36.6659V33.0644H35.544V21.8452H36.6659V20.3887H35.544H31.3933H30.2695V21.8452H31.3933V33.0644H30.2695Z"
          fill="#848484"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5_9">
        <rect width="49" height="49" fill="white" />
      </clipPath>
      <clipPath id="clip1_5_9">
        <rect width="29" height="31" fill="white" transform="translate(10 9)" />
      </clipPath>
    </defs>
  </svg>
);

export default BankIconPlaceholder;
