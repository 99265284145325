import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, FullScreenLoader } from "uikit";

import { useAdminSetup } from "./useAdminSetup";

interface IProps {}

const AdminSetupPage: FC<IProps> = () => {
  const translationPrefix = `admin_setup_page`;

  const { isLoading, renderContent } = useAdminSetup();

  return (
    <DashboardLayout showTitle={false} showBreadcrumbs={false}>
      {isLoading && <FullScreenLoader />}
      <>{renderContent()}</>
    </DashboardLayout>
  );
};

export default AdminSetupPage;
