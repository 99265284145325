import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { SalsaWorkerCategoryEnum } from "types/BETypes";
import SelectOption from "types/SelectOption";

import { UserResponseDto } from "utils/swagger_react_query";

import { initialValues } from "./constants";
import { IPropsExtended } from "./types";
import { FormType } from "./validationSchema";

export const useFormValues = () => {
  const userToFormValues = (user: Partial<UserResponseDto>): FormType => {
    return {
      ...user,
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      phone: user.phone || null,
      employmentType: user.employmentType || SalsaWorkerCategoryEnum.EMPLOYEE,
      overtimeEligible: user.overtimeEligible || false,
    };
  };

  const formValuesToUser = (user: FormType): Partial<UserResponseDto> => {
    return {
      ...user,
      phone: user.phone || undefined,
      employmentType: user.employmentType as SalsaWorkerCategoryEnum,
    };
  };

  return {
    userToFormValues,
    formValuesToUser,
  };
};

export const useAddWorkerForm = (props: IPropsExtended) => {
  const { formResetFlag, onAddSubmit, onEditSubmit, user, onCancel, _onSubmitAttempt } = props;
  const formikContext = useFormikContext<FormType>();
  const translationPrefix = `people_page.index.add_worker_form`;
  const { t } = useTranslation();

  const { values, validateForm, setValues } = formikContext;

  const handleSubmit = () => {
    validateForm(values).then((errors) => {
      _onSubmitAttempt?.();

      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (!hasErrors) {
        if (user) {
          onEditSubmit(values);
        } else {
          onAddSubmit(values);
        }
      }
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const overtimeEligibleOptions: SelectOption<boolean>[] = [
    {
      label: t(`${translationPrefix}.overtime_elligible_options.yes`),
      value: true,
    },
    {
      label: t(`${translationPrefix}.overtime_elligible_options.no`),
      value: false,
    },
  ];

  // useEffect(() => {
  //   setValues(initialValues);
  // }, [formResetFlag]);

  return {
    overtimeEligibleOptions,
    handleSubmit,
    handleCancel,
  };
};
