import { FC } from "react";

import IconProps from "./IconProps";

const BackButton: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_594_1339)">
      <circle cx="20.7656" cy="20.5938" r="16" fill="white" />
    </g>
    <path
      opacity="0.8"
      d="M24.7656 26.6308L16.7656 20.1123L24.7656 13.5937"
      stroke="#17AACF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <defs>
      <filter
        id="filter0_d_594_1339"
        x="0.765625"
        y="0.59375"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_594_1339" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_594_1339"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default BackButton;
