import { FC } from "react";

import IconProps from "./IconProps";

const CurrencyDollarIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11075_3404)">
      <path
        d="M16.7 8.19922C16.501 7.63446 16.1374 7.14218 15.6563 6.78575C15.1751 6.42933 14.5983 6.22505 14 6.19922H10C9.20435 6.19922 8.44129 6.51529 7.87868 7.0779C7.31607 7.64051 7 8.40357 7 9.19922C7 9.99487 7.31607 10.7579 7.87868 11.3205C8.44129 11.8831 9.20435 12.1992 10 12.1992H14C14.7956 12.1992 15.5587 12.5153 16.1213 13.0779C16.6839 13.6405 17 14.4036 17 15.1992C17 15.9949 16.6839 16.7579 16.1213 17.3205C15.5587 17.8831 14.7956 18.1992 14 18.1992H10C9.40175 18.1734 8.82491 17.9691 8.34373 17.6127C7.86255 17.2563 7.49905 16.764 7.3 16.1992"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 3.19922V6.19922M12 18.1992V21.1992"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11075_3404">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199219)" />
      </clipPath>
    </defs>
  </svg>
);

export default CurrencyDollarIcon;
