import { FC } from "react";

import IconProps from "./IconProps";

const CopyIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="copy" clipPath="url(#clip0_3756_59109)">
      <path
        id="Vector"
        d="M5.33301 6.66536C5.33301 6.31174 5.47348 5.9726 5.72353 5.72256C5.97358 5.47251 6.31272 5.33203 6.66634 5.33203H11.9997C12.3533 5.33203 12.6924 5.47251 12.9425 5.72256C13.1925 5.9726 13.333 6.31174 13.333 6.66536V11.9987C13.333 12.3523 13.1925 12.6915 12.9425 12.9415C12.6924 13.1916 12.3533 13.332 11.9997 13.332H6.66634C6.31272 13.332 5.97358 13.1916 5.72353 12.9415C5.47348 12.6915 5.33301 12.3523 5.33301 11.9987V6.66536Z"
        stroke="#78808F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.667 5.33464V4.0013C10.667 3.64768 10.5265 3.30854 10.2765 3.05849C10.0264 2.80844 9.68728 2.66797 9.33366 2.66797H4.00033C3.6467 2.66797 3.30756 2.80844 3.05752 3.05849C2.80747 3.30854 2.66699 3.64768 2.66699 4.0013V9.33464C2.66699 9.68826 2.80747 10.0274 3.05752 10.2774C3.30756 10.5275 3.6467 10.668 4.00033 10.668H5.33366"
        stroke="#78808F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3756_59109">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
