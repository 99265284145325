import { APPLICATION_NAME, APPLICATION_NAME_UPPERCASE } from "constants/systemConstants";

export const companyBankAccountPromoCardsTranslations = {
  "en-US": {
    shared: {
      title_common: "Checking Account",
      popup_cloud_text: "Pay Deposited <br />Early Pay Ready",
      selected_label: `Selected method`,
      recommended_label: `Recommended`,
      promo_points: {
        p1: `<1>2.50%</1>
            <2>APY**</2>
            <3>on average daily balance*</3>`,
        p1_alt: `<1>2.50%</1>
            <2>APY**</2>
            <3>on average daily balance*</3>`,
        p2: `<1>Get 25%</1>
            <2>of Net Pay***</2>
            <3>2 Days Early</3>`,
        p3: `<1>Zero</1>
            <2>Hidden Fees</2>
            `,
        p3_alt: `<1>Zero</1>
            <2>Hidden Fees</2>
            <3>No application or setup fees</3>`,
      },
      buttons: {
        default: `Your ${APPLICATION_NAME} Bank Account`,
      },

      pending_verification_label: "Pending Verification",
      verification_failed_label: "Verification Failed",
      restart_verification_label: "Restart Verification",
    },
    card1: {
      title: "Setup in less than 2 minutes",
      description: `<1>Hi {{firstName}}!</1>
        <2>Did you know that your existing checking account is likely giving you <3>0.01%</3> on your money?</2> 
        <2>Earn <4>2.50%</4> <5>Annual Percentage Yield</5> with a Paid  Checking Account</2>`,
      disclaimer: `*Disclaimer: Average Daily Balance is calculated as the average of the balance that is available in your Paid account at the close of business each day of the statement period.`,
    },
    card2: {
      title: `${APPLICATION_NAME_UPPERCASE} Free Bank Account`,
    },
    card3: {
      title: "Setup in less than 5 minutes",
      description: `<1>Hi {{firstName}}!</1>
        <2>Did you know that your existing checking account is likely giving you <3>0.01%</3> on your money?</2> 
        <2>Earn <4>2.50%</4> <5>Annual Percentage Yield</5> with a Paid  Checking Account</2>`,
      disclaimer: `*Disclaimer: Average Daily Balance is calculated as the average of the balance that is available in your ${APPLICATION_NAME} account at the close of business each day of the statement period.`,
    },
  },
};

export default companyBankAccountPromoCardsTranslations;
