import React, { FC } from "react";

import { DotContainer, DotContainerInner, DotsSpinner } from "./styles";

import "./styles.scss";

interface IProps {
  className?: string;
}

const DotsSpinnerLoader: FC<IProps> = (props) => (
  <DotContainer className={props.className}>
    <DotContainerInner>
      <DotsSpinner />
    </DotContainerInner>
  </DotContainer>
);

export default DotsSpinnerLoader;
