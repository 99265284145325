export const vestingScheduleDetailedTranslations = {
  "en-US": {
    add_milestone: "Add Milestone",
    milestone_title: "Milestone",
    date_title: "Date",
    units_quantity_title: "Number of Units",
    percentage_title: "% Vested",
    amount_title: "$ Vested",
    milestone_value: "Milestone {{number}}",
    unit_quantity_value: "{{number}} units",
    unit_quantity_total: "{{number}} units total",
    reward_value: "Reward Earned",
    start_date: "Start Date",
    start_date_unit_date: ": {{date}}",
    end_date: "End Date",
    end_date_unit_date: "- End Date: {{date}}",
    empty_list_message: "Please define your bonus details above.",
    table_footer: {
      title: "Total Vesting Period",
      years_and_days: "{{years}} Years and {{days}} Days",
      full_days: "({{days}} total days)",
    },
    quantity_tip: "Must add up to <1>{{quantity}}</1>",
    percentage_tip: "Must add up to 100%",
    amount_tip: "Must add up to <1></1>",
    errors: {
      milestones_count_is_zero: "There should be at least 1 milestone",
      milestone_date_is_smaller_than_previous_milestones_date:
        "This milestone must be after the previous milestone.",
      milestone_date_cannot_exceed_max: "Total vesting period cannot exceed {{maxYears}} years",
      milestone_date_is_too_close_too_start_date:
        "Milestone date and Start date must be at least 2 days apart",
      amount_cannot_be_zero: "Amount must be greater than zero.",
      milestone_quantity_is_required: "Number of units must be greater than zero.",
      milestone_quantity_cannot_be_zero: "Number of units must be greater than zero.",
    },
    tooltips: {
      admin_employee_has_reached_the_milestone_faster:
        "Employee has reached this milestone faster.",
      admin_employee_has_reached_the_milestone_faster_with_balance:
        "Employee has reached this milestone faster. The last milestone date and amount due have been updated to reflect the reward.",
      employee_employee_has_reached_the_milestone_faster:
        "Congrats! You have reached this milestone faster thanks to your great performance.",
      employee_employee_has_reached_the_milestone_faster_with_balance:
        "Congrats! You have reached this milestone faster thanks to your great performance. The date of your last milestone and amount due has been updated to reflect the reward.",
    },
  },
};

export default vestingScheduleDetailedTranslations;
