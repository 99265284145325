import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { salsaSelector, userMetadataSelector } from "store/selectors";

import { showErrorModal } from "helpers";
import useAuth from "hooks/useAuth";

export const useEmployeeProfilePage = () => {
  const translationPrefix = `my_profile`;
  const [isLoading, setLoading] = useState<boolean>(true);
  const currentUser = useAppSelector(userMetadataSelector);
  const salsaData = useAppSelector(salsaSelector);
  const { getCurrentUser } = useAuth();

  const { salsa: salsaInstance, authToken: authToken } = salsaData || {};
  const salsaFrameName = "worker-self-managed-profile";
  const salsaFrameId = "profile-container";
  const frameSelector = `#${salsaFrameId}`;

  const fetchUserInfo = async () => {
    try {
      setLoading(true);
      await getCurrentUser();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (currentUser?.userId && salsaData?.salsa) {
      const salsaElement = salsaInstance.elements.create(salsaFrameName, {
        userToken: authToken,
        workerId: currentUser.salsaWorkerId,
      });

      salsaInstance.elements.mount(salsaElement, frameSelector);

      return () => {
        salsaInstance.elements.destroy(salsaElement);
      };
    }
  }, [salsaData?.salsa, currentUser]);

  return {
    metadata: {
      currentUser,
      translationPrefix,
      isLoading,
    },
    frameData: {
      salsaFrameId,
    },
  };
};
