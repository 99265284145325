import { FC } from "react";

import IconProps from "./IconProps";

const Logo: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 17.5C0.5 7.83502 8.33502 0 18 0C27.665 0 35.5 7.83502 35.5 17.5C35.5 27.165 27.665 35 18 35C8.33502 35 0.5 27.165 0.5 17.5Z"
      fill="white"
    />
    <path
      d="M16.2174 8.93964C16.6986 8.59732 17.2325 8.33686 17.804 8.13593C18.3829 7.94245 18.9394 7.8457 19.4958 7.8457C20.3304 7.8457 21.1049 8.03175 21.8192 8.40384C22.5336 8.77592 23.3231 9.3415 23.8344 9.99638C24.9021 11.3508 26.5188 15.8233 23.3983 19.373C22.6238 20.2586 21.9095 20.72 21.0372 21.1293C20.165 21.5386 19.2251 21.7395 18.225 21.7395C17.443 21.7395 16.7738 21.6204 16.2249 21.3823V26.8222L12.2698 27.9385V8.15082H16.2249V8.93964H16.2174ZM16.2174 18.5246C16.2174 18.9488 16.2851 19.3358 16.4204 19.693C16.5558 20.0502 16.7287 20.3553 16.9468 20.6083C17.1648 20.8614 17.413 21.0623 17.6836 21.2037C17.9619 21.3451 18.2326 21.4195 18.4957 21.4195C18.8642 21.4195 19.2101 21.3004 19.5334 21.0623C19.8567 20.8242 20.1424 20.4967 20.3831 20.0725C20.6237 19.6484 20.8192 19.1498 20.962 18.5767C21.1049 18.0037 21.1726 16.0689 21.1726 15.3917C21.1726 14.6475 21.0899 12.6308 20.9244 11.961C20.759 11.2912 20.5334 10.7033 20.2628 10.1973C19.9845 9.69126 19.6612 9.28197 19.2852 8.97685C18.9093 8.67174 18.5032 8.5229 18.0746 8.5229C17.7739 8.5229 17.4505 8.61221 17.0971 8.79081C16.7437 8.96941 16.458 9.20011 16.2249 9.49033V18.5321L16.2174 18.5246Z"
      fill="#0A252E"
    />
    <ellipse cx="23.1448" cy="25.8923" rx="2.07253" ry="2.04662" fill="#EE3824" />
  </svg>
);

export default Logo;
