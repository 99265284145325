import styled from "styled-components";

export const TestingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 200px;
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.sectionContainerAlt3};
  border: 2px dashed ${({ theme }) => theme.colors.danger};
  position: relative;
`;

export const TestingContentTitle = styled.h2`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.5;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};
  margin-bottom: 10px;
  text-transform: uppercase;
`;
