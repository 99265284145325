import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButtonIconed, SecondaryButton } from "uikit";

export const Container = styled.div``;

export const Header = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint("xs", "md")`
    margin-bottom: 26px;
  `}
`;

export const AddAccountButton = styled(PrimaryButtonIconed)`
  width: auto;
  min-width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    margin: 16px auto 0 auto;
  `}
`;

export const BackButton = styled(SecondaryButton)`
  margin-top: 50px;
  width: 100px;
`;
