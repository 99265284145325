import styled from "styled-components";

import { breakpoint } from "helpers";

import { Input, PrimaryButtonIconed } from "uikit";

export const Container = styled.form``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  & > * {
    width: 100%;
    flex: 1;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    margin-bottom: 20px;
  `}
`;

export const FromInput = styled(Input)`
  input {
    height: 64px;
  }
`;

export const TransferFundsButton = styled(PrimaryButtonIconed)`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex: 1;

  svg {
    path {
      fill: none;
      stroke: ${({ theme }) => theme.colors.primaryText};
    }
  }

  &:disabled {
    svg {
      path {
        fill: none;
        stroke: ${({ theme }) => theme.colors.primaryTextDisabled};
      }
    }
  }
`;

export const RowDud = styled.div`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  flex: 1;
`;
