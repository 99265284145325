import { FC } from "react";

import IconProps from "./IconProps";

const SuccessCircledCheckMarkLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="39.5"
      cy="39.5"
      r="38.5"
      fill="white"
      stroke="url(#paint0_linear_1_207)"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.1828 31.9795C59.9639 31.1985 59.9639 29.9321 59.1828 29.1511L56.6175 26.5858C55.8365 25.8047 54.5702 25.8047 53.7891 26.5858L35.7861 44.5888L26.9795 35.7823C26.1985 35.0013 24.9322 35.0013 24.1511 35.7823L21.5858 38.3476C20.8047 39.1287 20.8047 40.395 21.5858 41.176L31.7819 51.3722C31.79 51.3805 31.7981 51.3888 31.8063 51.397L34.3716 53.9623C35.1527 54.7434 36.419 54.7434 37.2001 53.9623L59.1828 31.9795Z"
      fill="url(#paint1_linear_1_207)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_207"
        x1="8.22917"
        y1="1.61503e-08"
        x2="8.76042"
        y2="48.8096"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_207"
        x1="25.0384"
        y1="26"
        x2="25.1798"
        y2="43.6392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
    </defs>
  </svg>
);

export default SuccessCircledCheckMarkLarge;
