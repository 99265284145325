import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import { Container, ErrorContainer, InputBase, InputWrapper, Percentage } from "./styles";

export type Props = {
  name?: string;
  onChange?: (value: string) => void;
  value?: string;
  label?: ReactNode | string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  optional?: boolean;
  currency?: string;
} & NumberFormatProps &
  BaseInputProps;

const PercentInput: FC<Props> = (props) => {
  const {
    className,
    label,
    error,
    onFocus,
    onBlur,
    readOnly,
    value,
    onChange,
    onKeyDown,
    alwaysShowErrorBlock = true,
    optional = false,
    currency,
    unitsPosition = "left",
    ...rest
  } = props;
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const isFloatingLabel = !props.readOnly && label && (isFocused || value);

  return (
    <Container
      className={`number-input money-input ${className} ${
        isFloatingLabel ? "with-floating-label" : ""
      } ${optional ? "optional" : ""}`}
    >
      <InputWrapper>
        {unitsPosition === "left" && <Percentage className={"units-left"}>%</Percentage>}
        <NumberFormat
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowNegative={false}
          allowEmptyFormatting={true}
          customInput={InputBase}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          onValueChange={({ value }) => onChange?.(value)}
          value={value}
          {...rest}
        />
        {unitsPosition === "right" && <Percentage className={"units-right"}>%</Percentage>}
        {renderLabel(label, optional)}
      </InputWrapper>
      {(alwaysShowErrorBlock || error) && <ErrorContainer>{error} </ErrorContainer>}
    </Container>
  );
};

export default PercentInput;
