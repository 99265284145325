import { PhoneNumberUtil } from "google-libphonenumber";

export const validatePhoneNumber = (phone: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  //We have prefilled the country code in phone number input
  //And if the user doesn't enter any number, we should not show error, so we return true
  if (phone?.length <= 2) return true;
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
