export const page404Translation = {
  "en-US": {
    title: "Page not found!",
    text: "Sorry, but the page you were looking for could not be found.",
    dashboard_link: "return to your dashboard",
    text2:
      "You can <1>{{dashboardLink}}</1>, or reach out to our support team at <2>{{supportLink}}</2> <br /> if you can’t find what you are looking for.",
  },
};

export default page404Translation;
