import { FC } from "react";

import IconProps from "./IconProps";

const Tooltip: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9189_47054)">
      <path
        d="M8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6682 9.75029 13.9812 11.4309 12.7541 12.6791C11.527 13.9272 9.85826 14.6427 8.10818 14.671C6.35809 14.6994 4.66706 14.0384 3.40016 12.8306C2.13327 11.6229 1.39216 9.96542 1.33683 8.21598L1.3335 7.99998L1.33616 7.81331C1.43483 4.21798 4.38016 1.33331 8.00016 1.33331ZM8.00016 7.33331H7.3335L7.2555 7.33798C7.09346 7.35725 6.94413 7.43528 6.83577 7.55729C6.72742 7.6793 6.66757 7.8368 6.66757 7.99998C6.66757 8.16316 6.72742 8.32066 6.83577 8.44267C6.94413 8.56468 7.09346 8.64271 7.2555 8.66198L7.3335 8.66665V10.6666L7.33816 10.7446C7.35571 10.8935 7.42288 11.032 7.52884 11.138C7.63479 11.2439 7.77335 11.3111 7.92216 11.3286L8.00016 11.3333H8.66683L8.74483 11.3286C8.89364 11.3111 9.0322 11.2439 9.13815 11.138C9.24411 11.032 9.31128 10.8935 9.32883 10.7446L9.3335 10.6666L9.32883 10.5886C9.31289 10.4526 9.25545 10.3248 9.16432 10.2226C9.07319 10.1204 8.9528 10.0487 8.8195 10.0173L8.74483 10.004L8.66683 9.99998V7.99998L8.66216 7.92198C8.64461 7.77317 8.57744 7.63461 8.47149 7.52865C8.36553 7.4227 8.22697 7.35553 8.07816 7.33798L8.00016 7.33331ZM8.00683 5.33331L7.92216 5.33798C7.76013 5.35725 7.61079 5.43528 7.50244 5.55729C7.39408 5.6793 7.33424 5.8368 7.33424 5.99998C7.33424 6.16316 7.39408 6.32066 7.50244 6.44267C7.61079 6.56468 7.76013 6.64271 7.92216 6.66198L8.00016 6.66665L8.08483 6.66198C8.24686 6.64271 8.3962 6.56468 8.50455 6.44267C8.61291 6.32066 8.67275 6.16316 8.67275 5.99998C8.67275 5.8368 8.61291 5.6793 8.50455 5.55729C8.3962 5.43528 8.24686 5.35725 8.08483 5.33798L8.00683 5.33331Z"
        fill="#17AACF"
      />
    </g>
    <defs>
      <clipPath id="clip0_9189_47054">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Tooltip;
