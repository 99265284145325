import { FC } from "react";
import { ModalClose } from "assets/svg";

import { Container, IconContainer, Image, Message, StyledCloseButton, Title } from "../../styles";
import { IContentAndButtonsWrapper, IModalProps } from "../../types";
import ModalBottomButtons from "../ModalBottomButtons";
import ModalStatusIcon from "../ModalStatus/ModalStatusIcon";
import ModalStatusTitle from "../ModalStatus/ModalStatusTitle";

const DefaultContentAndButtonsWrapper: FC<IContentAndButtonsWrapper> = ({ children }) => {
  return <>{children}</>;
};

const ModalContent: FC<Partial<IModalProps>> = (props) => {
  const {
    imageURL,
    icon,
    type,
    title,
    statusTitle,
    message,
    secondaryMessage,
    mainButton,
    secondaryButton,
    onClose,
    canClose = true,
    showCloseButton = false,
    children = null,
    childrenBeforeButtons = true,
    contentAndButtonsWrapper,
    showBottomButtons = true,
  } = props;

  const _showCloseButton = showCloseButton && canClose;
  const ContentAndButtonsWrapper = contentAndButtonsWrapper || DefaultContentAndButtonsWrapper;

  return (
    <Container className={`${!_showCloseButton ? "no-close-button" : ""}`}>
      {_showCloseButton && (
        <StyledCloseButton onClick={onClose} type="button" data-testId={"close-modal-btn"}>
          <ModalClose />
        </StyledCloseButton>
      )}

      {(!!icon || !!imageURL || !!type) && (
        <IconContainer>
          {icon}
          {imageURL && <Image src={imageURL} alt="" />}
          {type && <ModalStatusIcon type={type} />}
        </IconContainer>
      )}

      {statusTitle && (
        <ModalStatusTitle type={type} data-testId={`modal-status-title`}>
          {statusTitle}
        </ModalStatusTitle>
      )}
      {title && <Title data-testId={`modal-title`}>{title}</Title>}

      <ContentAndButtonsWrapper>
        {message && <Message data-testId={`modal-message`}>{message}</Message>}
        {secondaryMessage && (
          <Message data-testId={`modal-secondary-message`}>{secondaryMessage}</Message>
        )}

        {!!childrenBeforeButtons && children}

        {!!showBottomButtons && (mainButton || secondaryButton) && (
          <ModalBottomButtons {...props} />
        )}

        {!childrenBeforeButtons && children}
      </ContentAndButtonsWrapper>
    </Container>
  );
};

export default ModalContent;
