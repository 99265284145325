import styled from "styled-components";

import { CloseModalButton, SlidingPane } from "uikit";

export const AddWorkerPane = styled(SlidingPane)`
  width: 680px !important;
`;

export const AddWorkerCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
