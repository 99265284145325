import { BonusStatus } from "types/BETypes";
import { ExtensionBonusStatuses } from "helpers";

export const bonusDetailsFooterTranslations = {
  "en-US": {},
  accordion1_title: "Vesting Milestones",
  accordion2_title: "Bonus Contract",
  accordion1_subtitle_collapsed: "Show Milestones",
  accordion1_subtitle_expanded: "Hide Milestones",
  accordion2_subtitle_collapsed: "View Contract",
  accordion2_subtitle_collapsed_kyc_accepted: "Generate Preview",
  accordion2_subtitle_expanded: "Hide Contract",
  descriptions: {
    [BonusStatus.VESTING_STOPPED]: "<1>Vesting has been <br />stopped for this bonus.</1>",
    [BonusStatus.COMPLETED]: "<3>FULLY VESTED</3>",
    [ExtensionBonusStatuses.PAST_DUE]:
      "Complete all units by date <br /><1>{{date}}</1> <2>has passed.</2>",
  },
};

export default bonusDetailsFooterTranslations;
