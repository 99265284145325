import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import NavigationCardWithDetails from "components/NavigationCardWithDetails";

import { Loader } from "uikit";

import { useAdminSettingsPage } from "./useAdminSettingsPage";
import { OptionsSection } from "./styles";

interface IProps {}

const AdminSettingsPage: FC<IProps> = () => {
  const { metadata, pageData } = useAdminSettingsPage();
  const { optionsList } = pageData;
  return (
    <DashboardLayout>
      {metadata.isLoading ? (
        <Loader />
      ) : (
        <>
          <OptionsSection>
            {optionsList.map((option) => {
              return <NavigationCardWithDetails {...option} key={option.id} />;
            })}
          </OptionsSection>
        </>
      )}
    </DashboardLayout>
  );
};

export default AdminSettingsPage;
