import styled from "styled-components";

import AuthLayout from "layouts/AuthLayout";

import { BoxMixin } from "../_shared/components/styles";

export const StyledAuthLayout = styled(AuthLayout)`
  background: ${({ theme }) => theme.colors.employeeAuthLayoutBg};
`;

export const Container = styled.div``;

export const Section = styled.div``;

export const TermsAndPrivacyDisclaimerSection = styled.div`
  ${BoxMixin}
`;
