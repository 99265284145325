import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { isBankAccountVerified } from "helpers/bankCards";
import BankAccountInfo from "components/BankAccountInfo";
import BankAccountInfoLong from "components/BankAccountInfoLong";
import MicroDepositInfoModal from "components/MicroDepositInfoModal";

import { BelowMiddleContent, MiddleContent, RadioButton } from "uikit";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import {
  BankAccountListRow,
  BankAccountsListWrapper,
  ConnectBankBtn,
  EmptyStateContainer,
  ESMessage,
  ESTitle,
  RadioBtnWrapper,
  Wrapper,
} from "./styles";

export interface IBankAccountSectionProps {
  bankAccounts: GetPlaidBankAccountsResponseDto[];
  onConnectBankAccount?: () => void;
  onUpdateDefaultBankAccount: (bankAccountId: string) => void;
  onRemoveBankAccount: (bankAccountId: string) => void;
  onVerifyBankAccount?: (bankAccountId: string, accessToken: string) => void;
}

export const BankAccountSection: FC<IBankAccountSectionProps> = ({
  bankAccounts,
  onConnectBankAccount,
  onUpdateDefaultBankAccount,
  onRemoveBankAccount,
  onVerifyBankAccount,
}) => {
  const { t } = useTranslation();
  const translationPrefix = `components.bank_account_section`;
  const addBankAccountBtnLabel = isEmpty(bankAccounts) ? "connect_btn" : `add_btn`;

  const [isMicroDepositInfoModalOpen, setMicroDepositInfoModal] = useState<boolean>(false);
  const [microDepositInfoModalType, setMicroDepositInfoModalType] = useState<
    | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
    | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION
  >();

  const onPendingVerificationClick = (
    verificationStatus:
      | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
      | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
  ) => {
    setMicroDepositInfoModalType(verificationStatus);
    setMicroDepositInfoModal(true);
  };

  const renderBankAccountsList = () =>
    bankAccounts?.map((item) => {
      const shouldShowRadioButton = !!item.verificationStatus && isBankAccountVerified(item);

      const canRemoveBankAccount =
        !item.isDefault ||
        [
          BankAccountVerifyStatus.VERIFICATION_EXPIRED,
          BankAccountVerifyStatus.VERIFICATION_FAILED,
        ].includes(item.verificationStatus as BankAccountVerifyStatus);

      return (
        <BankAccountListRow key={item.bankAccountId}>
          <RadioBtnWrapper>
            {shouldShowRadioButton && (
              <RadioButton
                id={item.bankAccountId}
                value={item.isDefault ? item.bankAccountId : ""}
                name={"defaultBankAccount"}
                onChange={onUpdateDefaultBankAccount}
              />
            )}
          </RadioBtnWrapper>

          <MiddleContent>
            <BankAccountInfoLong
              bankAccountInfo={item}
              secondaryBtn={{
                needRender: canRemoveBankAccount,
                label: t(`${translationPrefix}.remove_account_btn`),
                onClick: () => onRemoveBankAccount(item.bankAccountId),
              }}
              onVerify={onVerifyBankAccount}
              onPendingVerificationClick={onPendingVerificationClick}
            />
          </MiddleContent>

          <BelowMiddleContent>
            <BankAccountInfo
              bankAccountInfo={item}
              secondaryBtn={{
                needRender: canRemoveBankAccount,
                label: t(`${translationPrefix}.remove_account_btn`),
                onClick: () => onRemoveBankAccount(item.bankAccountId),
              }}
              onVerify={onVerifyBankAccount}
              onPendingVerificationClick={() => setMicroDepositInfoModal(true)}
            />
          </BelowMiddleContent>
        </BankAccountListRow>
      );
    });

  return (
    <Wrapper>
      {isEmpty(bankAccounts) ? (
        <>
          <EmptyStateContainer data-testid="external_bank_account_empty_state_text">
            <Trans
              i18nKey={`${translationPrefix}.empty_state.message`}
              components={{
                1: <ESTitle />,
                2: <ESMessage />,
              }}
            />
          </EmptyStateContainer>
        </>
      ) : (
        <BankAccountsListWrapper>{renderBankAccountsList()}</BankAccountsListWrapper>
      )}

      {onConnectBankAccount && (
        <ConnectBankBtn onClick={onConnectBankAccount}>
          <Trans i18nKey={`${translationPrefix}.${addBankAccountBtnLabel}`} />
        </ConnectBankBtn>
      )}

      {microDepositInfoModalType && (
        <MicroDepositInfoModal
          isOpen={isMicroDepositInfoModalOpen}
          onClose={() => setMicroDepositInfoModal(false)}
          onBtnClick={() => setMicroDepositInfoModal(false)}
          verificationStatus={microDepositInfoModalType}
        />
      )}
    </Wrapper>
  );
};
