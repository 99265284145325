import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import { BankAccountSection, IBankAccountSectionProps } from "components/BankAccountSection";

import { FullScreenLoader } from "uikit";

interface IProps {
  isLoading: boolean;
  bankAccountSectionProps: IBankAccountSectionProps;
  handleBackBtnClick: () => void;
}

const BankAccountsPage: FC<IProps> = ({
  isLoading,
  bankAccountSectionProps,
  handleBackBtnClick,
}) => {
  return (
    <DashboardLayout showResponsiveMenu={false} onBackBtnClick={() => handleBackBtnClick()}>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <>
          <BankAccountSection {...bankAccountSectionProps} />
        </>
      )}
    </DashboardLayout>
  );
};

export default BankAccountsPage;
