import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { SecondaryButton } from "uikit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 300px;
  padding: 40px 48px;
  background: ${({ theme }) => theme.colors.emptyListBg};

  ${breakpoint("xs", "lg")`
    background: ${({ theme }) => theme.colors.emptyListBgMobile};
    min-height: 150px;
    padding: 24px 24px;
  `}
`;

export const IconContainer = styled.div`
  margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt2};
  line-height: 1.55;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.body1};
    line-height: 1.75;
  `}
`;

export const messageMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 1.62;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.body2};
    line-height: 1.42;
  `}
`;

export const MessageContainer = styled.div`
  ${messageMixin}
`;

export const ChildrenContainer = styled.div`
  ${messageMixin}
`;

export const ActionButton = styled(SecondaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  max-width: 180px;
  min-height: 36px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primaryAlt};
  background: ${({ theme }) => theme.colors.emptyListButtonBg};
  border: 1px solid ${({ theme }) => theme.colors.primaryAlt};
  margin-top: 40px;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondaryButtonBgAlt};
    color: ${({ theme }) => theme.colors.primaryAlt};
  }

  ${breakpoint("xs", "xl")`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const MessageText = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const MessageTextEmphasized = styled(MessageText)`
  font-size: ${({ theme }) => theme.size.subtitle};
  margin-bottom: 5px;
`;
