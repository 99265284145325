import { FC, ReactNode } from "react";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { isPermitted, PermissionProps } from "permissions";

type Props = {
  elseContent?: ReactNode;
  children?: ReactNode;
} & PermissionProps;

export const PermissionContent: FC<Props> = ({
  permissions,
  roles,
  children,
  elseContent = <></>,
}) => {
  const currentUser = useAppSelector(userMetadataSelector);

  return <>{isPermitted(currentUser, permissions, roles) ? <>{children}</> : <>{elseContent}</>}</>;
};
export default PermissionContent;
