import { FC } from "react";

import IconProps from "./IconProps";

const EditIcon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7956_13910)">
      <path
        d="M2.66602 13.8332H5.33268L12.3327 6.83321C12.5078 6.65811 12.6467 6.45024 12.7414 6.22147C12.8362 5.9927 12.885 5.7475 12.885 5.49988C12.885 5.25225 12.8362 5.00705 12.7414 4.77828C12.6467 4.54951 12.5078 4.34164 12.3327 4.16654C12.1576 3.99145 11.9497 3.85255 11.7209 3.75779C11.4922 3.66303 11.247 3.61426 10.9993 3.61426C10.7517 3.61426 10.5065 3.66303 10.2778 3.75779C10.049 3.85255 9.84111 3.99145 9.66602 4.16654L2.66602 11.1665V13.8332Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.83301L11.6667 7.49967"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7956_13910">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon;
