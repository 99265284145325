import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRouteHelpers } from "routes/helpers";
import actions from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { UserRole } from "types/BETypes";
import { CURRENT_ROLE_KEY } from "constants/auth";

import {
  mutationUsersControllerUpdateLastActiveRole,
  UpdateLastActiveRoleDto,
} from "utils/swagger_react_query";

import useAuth from "./useAuth";

export const useSwitchRole = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(userMetadataSelector);
  const companyInfo = useAppSelector(companyMetadataSelector);
  const navigate = useNavigate();
  const { getDefaultRoute, getDefaultEmployeeRoute, getDefaultAdminRoute } = useRouteHelpers();
  const { getCurrentUserAndCompany } = useAuth();

  const redirectToDashboard = (key: string | null, newValue: string | null) => {
    if (key === CURRENT_ROLE_KEY) {
      setTimeout(() => {
        window.location.href = getDefaultRoute();
      });
    }
  };

  useEffect(() => {
    window.addEventListener("storage", ({ key, newValue }) => redirectToDashboard(key, newValue));
    return () =>
      window.removeEventListener("storage", ({ key, newValue }) =>
        redirectToDashboard(key, newValue),
      );
  }, []);

  const onChangeUserRole = async (role: UpdateLastActiveRoleDto["role"]) => {
    try {
      setLoading(true);
      await mutationUsersControllerUpdateLastActiveRole()({
        role: role,
      });
      const userAndCompanyResult = await getCurrentUserAndCompany();
      const user = userAndCompanyResult?.user;
      const company = userAndCompanyResult?.company;
      AppEvents.emit("RoleHasUpdated", { roleHasUpdated: true, userData: user });
      dispatch(actions.userMetaData.updateUserMetaDataLastActiveRole(role));
      window.localStorage.setItem(CURRENT_ROLE_KEY, role);
      navigate(
        role === UserRole.EMPLOYEE
          ? getDefaultEmployeeRoute(user, company)
          : getDefaultAdminRoute(user, company),
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setTimeout(() => AppEvents.emit("RoleHasUpdated", { roleHasUpdated: false }));
    }
  };

  return {
    isLoading,
    userInfo,
    companyInfo,
    onChangeUserRole,
  };
};
