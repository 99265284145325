import { CheckmarkWhiteThin } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers";

export const Container = styled.div`
  display: flex;
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: baseline;
  background: ${({ theme }) => theme.colors.contentBg}10;
  border-radius: 8px;
  margin-bottom: 16px;

  ${breakpoint("xs", "md")`
    margin-bottom: 0;
    margin-right: 16px;
    height: 40px;
    box-sizing: border-box;
    flex-wrap: nowrap;
  `}

  &:last-of-type {
    margin-bottom: 0;

    ${breakpoint("xs", "md")`
      margin-right: 0;
    `}
  }
`;

export const Icon = styled(CheckmarkWhiteThin)`
  margin-right: 23px;

  ${breakpoint("md", "lg")`
    margin-right: 20px;
  `}
  ${breakpoint("xs", "md")`
    margin-right: 12px;
  `}
`;

export const Label = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.caption};
    line-height: 20px;
    white-space: nowrap;
  `}
`;
