import styled from "styled-components";

import { SectionTitle } from "uikit/Typography/Typography";

export const ExpandIcon = styled.div``;

export const CollapseIcon = styled.div``;

export const Header = styled(SectionTitle)`
  margin-bottom: 0;
`;

export const Container = styled.div`
  .accordion-component-base {
    margin: 0;
    border: none;
    background: transparent;
    box-shadow: none;
  }

  .accordion-component-summary {
    padding: 0;
    min-height: 0;

    &.Mui-expanded {
      min-height: auto;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionSummary-expandIconWrapper {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.contentBg};
    }
  }

  .accordion-component-details {
    margin-top: 16px;
    padding: 0;
  }
`;
