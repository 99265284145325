import styled from "styled-components";

import { BlockLoader } from "uikit";

export const Container = styled.div`
  text-align: left;
`;

export const ContainerInner = styled.div`
  position: relative;
`;

export const ContainerInnerLoader = styled(BlockLoader)`
  border-radius: 0;
  background: ${({ theme }) => theme.colors.navigationCardActiveLabelText}80;
`;

export const StepContainer = styled.div``;
