import { FC } from "react";

import IconProps from "./IconProps";

const CupActive: FC<IconProps> = ({ className }) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1779_30542)">
      <path
        d="M36.5 72C56.3823 72 72.5 55.8823 72.5 36C72.5 16.1177 56.3823 0 36.5 0C16.6177 0 0.5 16.1177 0.5 36C0.5 55.8823 16.6177 72 36.5 72Z"
        fill="#F29127"
      />
      <path
        d="M53.7902 15.8972H48.6921C48.7071 15.364 48.7171 14.8308 48.7171 14.2876C48.7171 13.8441 48.3583 13.4902 47.9197 13.4902H25.0759C24.6323 13.4902 24.2785 13.849 24.2785 14.2876C24.2785 14.8258 24.2885 15.364 24.3034 15.8972H19.2054C18.4978 15.8972 17.9297 16.4703 17.9297 17.173C17.9297 21.1248 19.0061 24.967 20.9546 27.9869C21.9014 29.452 23.0028 30.653 24.2287 31.55C25.5343 32.5068 26.9098 33.075 28.3201 33.2394L28.33 33.1696C29.7702 35.8358 31.4795 37.7195 33.3383 38.7162C34.1805 39.2593 34.2553 40.7245 34.2254 41.5617C34.1755 42.9022 33.7968 44.5019 33.1838 46.0268H28.1008C27.6573 46.0268 27.3034 46.3856 27.3034 46.8241V53.2477H25.026C24.5825 53.2477 24.2287 53.6065 24.2287 54.0451V57.7078C24.2287 58.1514 24.5875 58.5052 25.026 58.5052H47.8649C48.3084 58.5052 48.6622 58.1464 48.6622 57.7078V54.0451C48.6622 53.6015 48.3034 53.2477 47.8649 53.2477H45.7071V46.8241C45.7071 46.3806 45.3483 46.0268 44.9098 46.0268H39.8317C38.4662 42.7078 38.3965 39.4786 39.7121 38.6912C41.5559 37.6896 43.2503 35.8158 44.6755 33.1696L44.6855 33.2394C46.0958 33.075 47.4762 32.5068 48.7769 31.55C50.0028 30.653 51.1091 29.452 52.0509 27.9869C53.9995 24.967 55.0759 21.1298 55.0759 17.173C55.0759 16.4653 54.5028 15.8972 53.8001 15.8972H53.7902ZM23.0975 26.6015C21.6174 24.3142 20.7254 21.4587 20.526 18.4537H24.443C24.7818 22.7793 25.649 26.8208 26.9795 30.2245C25.5642 29.5716 24.2088 28.3158 23.0975 26.6015ZM49.8981 26.6015C48.7868 28.3158 47.4313 29.5716 46.0161 30.2245C47.3466 26.8208 48.2137 22.7793 48.5526 18.4537H52.4696C52.2702 21.4587 51.3732 24.3142 49.8981 26.6015Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1779_30542">
        <rect width="72" height="72" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CupActive;
