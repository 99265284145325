import styled from "styled-components";

import { SyncteraTransactionStatus } from "types/BETypes";

import { TransactionType } from "./types";

export const Container = styled.div``;

export const TooltipContainer = styled.span`
  .__react_component_tooltip {
    max-width: 150px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const Status = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &.${SyncteraTransactionStatus.PENDING} {
    color: ${({ theme }) => theme.colors.transactionStatusPending};
  }
  &.${SyncteraTransactionStatus.REPLACED} {
    color: ${({ theme }) => theme.colors.transactionStatusDeposited};
  }
  &.${SyncteraTransactionStatus.DECLINED} {
    color: ${({ theme }) => theme.colors.transactionStatusError};
  }
  &.${SyncteraTransactionStatus.RELEASED} {
    color: ${({ theme }) => theme.colors.transactionStatusDeposited};
  }
  &.${SyncteraTransactionStatus.PARTCLEARED} {
    color: ${({ theme }) => theme.colors.transactionStatusDeposited};
  }
  &.${SyncteraTransactionStatus.CLEARED} {
    color: ${({ theme }) => theme.colors.transactionStatusDeposited};
  }
  &.${SyncteraTransactionStatus.EXPIRED} {
    color: ${({ theme }) => theme.colors.transactionStatusError};
  }
  &.${SyncteraTransactionStatus.INTERNAL_ERROR} {
    color: ${({ theme }) => theme.colors.transactionStatusError};
  }
  &.${SyncteraTransactionStatus.POSTED} {
    color: ${({ theme }) => theme.colors.transactionStatusSuccess};
  }
`;
