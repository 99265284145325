import { useRef, useState } from "react";

import { s3UploadFromBlob, showErrorModal } from "helpers";

import {
  ImportEmployeeFromCSVOutput,
  mutationImportEmployeesControllerImportEmployees,
  queryImportEmployeesControllerCsvUploadUrl,
  queryImportEmployeesControllerGenerateTemplate,
  queryImportEmployeesControllerGetReport,
  UserResponseDto,
} from "utils/swagger_react_query";

interface IParams {
  isLoading?: boolean;
  setLoading: (state: boolean) => void;
  currentUser?: UserResponseDto | null;
  refetchEmployeesList?: () => void;
}

export const useImportEmployees = ({ setLoading, currentUser, refetchEmployeesList }: IParams) => {
  const [currentModalStep, setCurrentModalStep] = useState<"upload" | "congrats">("upload");

  const [isUploadingCsvFile, setUploadingCsvFile] = useState<boolean>(false);
  const [openCsvImportModal, setOpenCsvImportModal] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [importEmployeeResult, setImportEmployeeResult] =
    useState<ImportEmployeeFromCSVOutput | null>(null);
  const [templateWasDownloaded, setTemplateWasDownloaded] = useState<boolean>(false);
  const [errorFile, setErrorFile] = useState<string>("");

  const controllerRef = useRef<AbortController | null>();

  const onDownloadCsvTemplate = async () => {
    setTemplateWasDownloaded(true);
    try {
      setLoading(true);
      const template: any = await queryImportEmployeesControllerGenerateTemplate();
      const blob = new Blob([`${template}`], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url || "";
      link.setAttribute("download", "employees-csv-import-template");
      link.click();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitUploadedFile = async (file: File) => {
    try {
      setUploadError(null);
      if (!window.navigator.onLine) return openNetworkErrorModal();
      setUploadingCsvFile(true);
      const controller = new AbortController();
      controllerRef.current = controller;
      window.addEventListener("offline", () => openNetworkErrorModal());
      const { presignedPost } = await queryImportEmployeesControllerCsvUploadUrl(
        { fileName: file.name || "" },
        { signal: controllerRef.current.signal },
      );

      const { s3Key } = await s3UploadFromBlob(file, presignedPost.url, presignedPost.fields, {
        signal: controllerRef.current.signal,
      });

      const uploadEmployeesRes = await mutationImportEmployeesControllerImportEmployees({
        signal: controllerRef.current.signal,
      })({ key: s3Key });
      setImportEmployeeResult(uploadEmployeesRes);

      if (uploadEmployeesRes.failedTotal !== 0) {
        const fetchErrorsFiles = await queryImportEmployeesControllerGetReport({
          companyId: currentUser?.companyId!,
          fileName: uploadEmployeesRes.fileName,
        });
        setErrorFile(fetchErrorsFiles as any);
      }
      setCurrentModalStep("congrats");
    } catch (error: any) {
      const errorMessage =
        error?.data?.error ||
        (typeof error === "string" && error) ||
        (typeof error?.message === "string" && error?.message) ||
        ""; //Move to a service later

      //if (window.navigator.onLine) showErrorModal(error);
      setUploadError(errorMessage);
    } finally {
      setUploadingCsvFile(false);
      window.removeEventListener("offline", () => openNetworkErrorModal());
    }
  };

  const onDownloadErrorsFile = () => {
    const rows = [(errorFile as any).split("\n").join("\n")];
    const blob = new Blob([`${rows}`], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url || "";
    link.setAttribute("download", "employees-csv-import-errors-file");
    link.click();
  };

  const onCloseCsvImportModal = () => {
    if (isUploadingCsvFile) controllerRef.current?.abort();
    if (currentModalStep === "congrats") refetchEmployeesList?.();

    setUploadError(null);
    setOpenCsvImportModal(false);
    setCurrentModalStep("upload");
    controllerRef.current = null;
    setTemplateWasDownloaded(false);
  };

  const openNetworkErrorModal = () => {
    controllerRef.current?.abort();
    onCloseCsvImportModal();
    showErrorModal("NETWORK_CONNECTION_LOST");
  };

  return {
    currentModalStep,
    isUploadingCsvFile,
    openCsvImportModal,
    uploadError,
    setUploadError,
    setOpenCsvImportModal,
    onDownloadCsvTemplate,
    onSubmitUploadedFile,
    onCloseCsvImportModal,
    importEmployeeResult,
    onDownloadErrorsFile,
    templateWasDownloaded,
  };
};
