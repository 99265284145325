import { FC } from "react";

import IconProps from "../IconProps";

const ModalDelete: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group">
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5418 21.0022H8.456C7.27981 21.0022 6.30191 20.0966 6.2117 18.9239L5.24609 6.37109H18.7517L17.7861 18.9239C17.6959 20.0966 16.718 21.0022 15.5418 21.0022V21.0022Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M20.0028 6.3713H3.99609"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18602 2.99609H14.8134C15.4349 2.99609 15.9388 3.49998 15.9388 4.12156V6.3725H8.06055V4.12156C8.06055 3.49998 8.56444 2.99609 9.18602 2.99609Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M13.969 10.873V16.5004"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M10.0295 10.873V16.5004"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ModalDelete;
