import { FC } from "react";
import { Trans } from "react-i18next";
import { EmptyEmployeesList } from "assets/svg";

import EmptyList from "./EmptyList";
import { IProps } from "./types";
import { MessageText, MessageTextEmphasized } from "./styles";

const EmptyWorkersList: FC<IProps> = ({ icon, children, showIcon = true, ...rest }) => {
  const translationPrefix = `components.empty_list.empty_workers_list`;
  const _icon = icon || <EmptyEmployeesList />;

  return (
    <EmptyList icon={!!showIcon && _icon} {...rest}>
      {children || (
        <Trans
          i18nKey={`${translationPrefix}.message`}
          components={{
            1: <MessageTextEmphasized />,
            2: <MessageText />,
          }}
        />
      )}
    </EmptyList>
  );
};

export default EmptyWorkersList;
