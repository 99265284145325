import { AttentionCircleSmallAltIcon, PaidCardBack, PaidCardFront } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers";

import {
  H3,
  LinkTextMixin,
  PrimaryButton,
  PrimaryButtonDangerMixin,
  SecondaryButton,
  Section,
  SectionTitle,
} from "uikit";
import { Loader, LoaderContainer } from "uikit/Loader/styles";

export const Container = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.contentBorder};
  margin-bottom: 32px;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 0;
`;

export const StatusWrapper = styled.div``;

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoint("xs", "lg")`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const MCLeft = styled.div`
  flex: 1;

  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;

export const MCRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;

  ${breakpoint("xs", "lg")`
    width: 100%;
    align-items: center;
  `}
`;

export const SwitchContainer = styled.div`
  padding: 0 65px;
`;

export const CardNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const CardNumberLabel = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 12px;
`;

export const CardNumber = styled.span`
  font-size: ${({ theme }) => theme.size.titleSemiLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const MessageSection = styled.div`
  max-width: 350px;
  width: 100%;
  margin-top: 24px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primary}25;
  display: flex;
  box-sizing: border-box;
  gap: 12px;

  &:last-child {
    margin-bottom: 16px;
  }

  ${breakpoint("xs", "xlm")`
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
  `};
`;

export const MIcon = styled(AttentionCircleSmallAltIcon)`
  position: relative;
`;

export const MContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 295px;

  ${breakpoint("xs", "xlm")`
    width: 100%;
  `}
`;

export const MTitle = styled.span`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 16px;
`;

export const MText = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 16px;
`;

export const ActionBtnsSection = styled.div`
  display: flex;
  margin-top: 24px;

  & > * {
    margin-right: 16px;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column;
    margin-top: 20px;

    & > * {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `}

  ${breakpoint("md", "xlm")`
    justify-content: space-between;
    gap: 16px;
    & > * {
      flex: 1;
    }
  `}
`;

export const PrimaryActionBtn = styled(PrimaryButton)`
  width: auto;
`;

export const SecondaryActionBtn = styled(SecondaryButton)`
  width: auto;
`;

export const CardViewContainer = styled.div`
  ${breakpoint("xs", "lg")`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0;
  `}
`;
export const CardViewContainerInner = styled.div`
  position: relative;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const StyledPaidCardFront = styled(PaidCardFront)``;
export const StyledPaidCardBack = styled(PaidCardBack)``;

export const StyledLoaderContainer = styled.div`
  min-height: auto;
  height: 188px;
  width: 296px;

  ${LoaderContainer} {
    min-height: auto;
    height: 160px;

    ${Loader} {
      /* height: auto; */
    }
  }
`;

export const CVCardEmbossNameArea = styled.span`
  position: absolute;
  top: 160px;
  left: 10px;
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const CVCardInfoLabel = styled.span`
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const PrivacyCardInfo = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`;

export const CVCardNumberArea = styled.div`
  position: absolute;
  top: 130px;
  left: 10px;

  iframe {
    height: 22px;
    width: 155px;
  }
`;
export const CVCardExpirationDateArea = styled.div`
  position: absolute;
  top: 130px;
  left: 175px;

  iframe {
    height: 22px;
    width: 50px;
  }
`;
export const CVCardCvvArea = styled.div`
  position: absolute;
  top: 130px;
  left: 235px;

  iframe {
    height: 22px;
    width: 50px;
  }
`;

export const CardViewBtn = styled.span`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.caption};
  /* align-self: center; */
`;

export const DangerBtn = styled(PrimaryButton)`
  ${PrimaryButtonDangerMixin};
  width: auto;
`;

export const MarqetaContentContainer = styled.div``;
