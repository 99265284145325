import { FC } from "react";

import IconProps from "./IconProps";

const UserIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="146"
    height="153"
    viewBox="0 0 146 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.1559 84.1716C106.337 78.7316 112.558 70.785 115.896 61.5095C119.234 52.2341 119.512 42.1236 116.688 32.677C113.864 23.2304 108.089 14.9503 100.218 9.06437C92.3482 3.17843 82.802 0 72.9945 0C63.1871 0 53.6407 3.17843 45.7704 9.06437C37.9002 14.9503 32.1252 23.2304 29.3013 32.677C26.4774 42.1236 26.7551 52.2341 30.0931 61.5095C33.4311 70.785 39.6517 78.7316 47.833 84.1716C19.9634 94.499 0 121.424 0 153H12.1636C12.1636 119.263 39.4501 91.8175 72.9926 91.8175C106.535 91.8175 133.833 119.263 133.833 153H146C145.989 121.42 126.025 94.5137 98.1559 84.1716ZM72.9926 79.5797C66.3761 79.579 59.9084 77.6049 54.4074 73.9071C48.9063 70.2093 44.6188 64.9538 42.0873 58.8052C39.5558 52.6566 38.8937 45.8911 40.185 39.3641C41.4763 32.8371 44.6629 26.8417 49.3417 22.1362C54.0205 17.4307 59.9816 14.2264 66.471 12.9283C72.9605 11.6303 79.6869 12.2969 85.7997 14.8438C91.9125 17.3907 97.137 21.7036 100.813 27.237C104.489 32.7705 106.451 39.276 106.451 45.9309C106.441 54.8528 102.913 63.4063 96.6402 69.7146C90.3676 76.023 81.8629 79.5709 72.9926 79.5797V79.5797Z"
      fill="#D0D0D0"
    />
  </svg>
);

export default UserIcon;
