import {
  BonusChallengeGoalType,
  BonusChallengeRewardType,
  ChallengeGoalConditionOperator,
} from "types/BETypes";

export const MAX_RANGE_VALUES = {
  default: 999999999, //999.999.999
  [BonusChallengeGoalType.PERCENTAGE]: 100,
};

export const MAX_AWARD_VALUE = 999999; //999.999
export const MAX_STRETCH_GOALS_QUANTITY = 9;

export const RewardTypeOperators = {
  [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: [
    ChallengeGoalConditionOperator.GREATER_THAN_OR_EQUAL,
    ChallengeGoalConditionOperator.LESS_THAN_OR_EQUAL,
  ],
  [BonusChallengeRewardType.PAY_TIERS]: [ChallengeGoalConditionOperator.GREATER_THAN],
};
