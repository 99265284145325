import { FC } from "react";

import IconProps from "./IconProps";

const ErrorCircledCrossLarge: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1_220)">
      <circle
        cx="43.5"
        cy="39.5"
        r="38.5"
        fill="white"
        stroke="url(#paint0_linear_1_220)"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0422 24.8573C54.2612 24.0762 52.9949 24.0762 52.2138 24.8573L43.7281 33.343L35.2426 24.8575C34.4616 24.0764 33.1953 24.0764 32.4142 24.8575L29.5858 27.6859C28.8047 28.467 28.8047 29.7333 29.5858 30.5143L38.0713 38.9998L29.5864 47.4847C28.8053 48.2657 28.8053 49.5321 29.5864 50.3131L32.4148 53.1415C33.1959 53.9226 34.4622 53.9226 35.2432 53.1415L43.7281 44.6567L52.2132 53.1418C52.9942 53.9228 54.2606 53.9228 55.0416 53.1418L57.8701 50.3133C58.6511 49.5323 58.6511 48.2659 57.8701 47.4849L49.385 38.9998L57.8707 30.5141C58.6517 29.7331 58.6517 28.4667 57.8707 27.6857L55.0422 24.8573Z"
        fill="url(#paint1_linear_1_220)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_220"
        x="0"
        y="0"
        width="87"
        height="87"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_220" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_220" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_1_220"
        x1="43.5"
        y1="0"
        x2="43.5"
        y2="79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE0000" />
        <stop offset="1" stopColor="#D90000" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_220"
        x1="43.7282"
        y1="24.2715"
        x2="43.7282"
        y2="53.7275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE0000" />
        <stop offset="1" stopColor="#D90000" />
      </linearGradient>
    </defs>
  </svg>
);

export default ErrorCircledCrossLarge;
