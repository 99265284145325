import { FC } from "react";

import IconProps from "../IconProps";

const ModalUser: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8984_11152)">
      <path
        d="M8 7.54688C8 8.60774 8.42143 9.62516 9.17157 10.3753C9.92172 11.1254 10.9391 11.5469 12 11.5469C13.0609 11.5469 14.0783 11.1254 14.8284 10.3753C15.5786 9.62516 16 8.60774 16 7.54688C16 6.48601 15.5786 5.46859 14.8284 4.71845C14.0783 3.9683 13.0609 3.54688 12 3.54688C10.9391 3.54688 9.92172 3.9683 9.17157 4.71845C8.42143 5.46859 8 6.48601 8 7.54688Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 21.5469V19.5469C6 18.486 6.42143 17.4686 7.17157 16.7184C7.92172 15.9683 8.93913 15.5469 10 15.5469H14C15.0609 15.5469 16.0783 15.9683 16.8284 16.7184C17.5786 17.4686 18 18.486 18 19.5469V21.5469"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8984_11152">
        <rect width="24" height="24" fill="white" transform="translate(0 0.546875)" />
      </clipPath>
    </defs>
  </svg>
);

export default ModalUser;
