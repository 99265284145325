export const HRISIntegrationInfoRowTranslations = {
  "en-US": {
    records_synced: "Records Synced",
    records_count_label: "{{count}} Records",
    records_failed_count_label: "<1>{{count}} Records</1> <2>Failed</2> to Sync",
    last_sync_date: "Last Sync:<br/> {{dateTime}}",
    remove_btn: "Remove",
    tooltips: {
      data_sync_tooltip:
        "This data connection with the HR system syncs every 24 hours at midnight EST.",
      disconnected_tooltip:
        "There appears to be a lost connection with the HR system. Please remove and reconnect.",
      records_failed_tooltip:
        "Please click on the number of records that did not sync link to review the data. Please ensure the data is correct and try again.",
    },
    reconnect_btn: "Reconnect",
    disconnect_btn: "Disconnect",
  },
};

export default HRISIntegrationInfoRowTranslations;
