import styled from "styled-components";

import { tableHeaderHPadding, tableHeaderVPadding, tableRowHeight } from "uikit/Table/constants";

import { TD } from "../../../Typography/Typography";

interface ITableContainer {
  maxHeight: number;
}

interface ITBodyProps {
  minRowsCount?: number;
  emptySpaceHeight?: number;
}

export const TableContainer = styled.div<ITableContainer>`
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 8px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "none")};
  overflow: ${({ maxHeight }) => (maxHeight ? "auto" : "initial")};
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};

  &.empty-state {
    border: none;
  }
`;

export const TableInner = styled.table`
  width: 100%;
  table-layout: fixed;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.colors.contentBg};
`;

export const AdditionalTableHeader = styled.div`
  display: flex;
  padding: ${tableHeaderVPadding}px ${tableHeaderHPadding}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};
  box-sizing: border-box;
`;

export const TableHeaderWrapper = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};
  border-radius: 8px;

  tr {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    td {
      vertical-align: middle;
      border-radius: 0;

      &:nth-child(1) {
        border-top-left-radius: inherit;
      }

      &:nth-last-child(1) {
        border-top-right-radius: inherit;
      }
    }
  }
`;

export const TBody = styled.tbody<ITBodyProps>`
  background-color: ${({ theme }) => theme.colors.emptyListBg};
  border-radius: inherit;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &.fixed_height {
    height: ${({ minRowsCount }) => tableRowHeight * (minRowsCount || 0)}px;
    background-color: ${({ theme }) => theme.colors.contentBg};

    &::after {
      content: "";
      width: 1px;
      height: 0;
    }
  }
`;

export const Tr = styled.tr`
  height: 47px;
  background-color: ${({ theme }) => theme.colors.contentBg};
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder};

  &:last-of-type {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &.bordered_last_row {
    border-bottom: 1px solid ${({ theme }) => theme.colors.contentBorder} !important;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Td = styled(TD)`
  border-collapse: collapse;
  &:nth-of-type(1) {
    border-bottom-left-radius: inherit;
  }
  &:last-of-type {
    border-bottom-right-radius: inherit;
  }
`;

export const TdContent = styled.div`
  height: min-content;
`;

export const LoaderWrapper = styled.td`
  height: 300px;
  width: 100%;
  min-width: 100%;
  vertical-align: middle;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

export const LoaderContainer = styled.div`
  position: relative;

  & > div {
    min-height: 60px;
  }
`;

export const TFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 19px 57px;
  border-radius: 0 0 8px 8px;
  height: 80px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.tableFooterBg};
`;

export const TFCell = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterColumnLabel = styled.span`
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.tableFooterText};
  line-height: 1.6;
`;

export const FooterColumnValue = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.tableFooterText};
`;

export const EmptyListComponentContainer = styled.div<ITBodyProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: ${({ minRowsCount }) => (minRowsCount ? tableRowHeight * (minRowsCount || 0) : 0)}px;
`;
