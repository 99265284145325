import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import { KycStatus } from "types/BETypes";
import DashboardLayout from "layouts/DashboardLayout";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import FinishCompanyBankAccountSetupBanner from "components/CompanyBankAccountComponents/FinishCompanyBankAccountSetupBanner";
import NoAccountState from "components/PaymentMethods/components/NoAccountState";

import { FullScreenLoader } from "uikit";

import useEmployeeBankingPage from "./useEmployeeBankingPage";
import { AddAccountButton } from "./styles";

interface IProps {}

const EmployeeBankingPage: FC<IProps> = () => {
  const noStateTranslationPrefix = `components.payment_methods.no_account_state`;

  const {
    isLoading,
    requestsLoading,
    currentUser,
    handleAddAccount,
    renderBankAccountSection,
    renderCardsSection,
    renderTransactionsSection,
    isCreationCompanyBankAccountModalOpen,
    setIsCreationCompanyBankAccountModalOpen,
    refetchData,
    cardsList,
    factorsData,
  } = useEmployeeBankingPage();

  const globalLoading = isLoading || requestsLoading;
  const hasUserCompanyAccount = currentUser?.kycStatus === KycStatus.ACCEPTED;
  const hasVerifiedFactor = !!factorsData?.some((factor) => factor.isVerified);

  const shouldShowFinishSetupBanner = !globalLoading && hasUserCompanyAccount && !hasVerifiedFactor;

  return (
    <DashboardLayout
      titleRightContent={
        <>
          {!requestsLoading && !hasUserCompanyAccount && (
            <AddAccountButton
              onClick={handleAddAccount}
              data-testid="banking-page-create-company-account-button"
            >
              <PlusThin />
              <Trans i18nKey={`${noStateTranslationPrefix}.create_account_button`} />
            </AddAccountButton>
          )}
        </>
      }
    >
      <>
        {globalLoading && <FullScreenLoader />}

        {shouldShowFinishSetupBanner && (
          <FinishCompanyBankAccountSetupBanner onContinueSetupClick={handleAddAccount} />
        )}

        {!hasUserCompanyAccount ? (
          <NoAccountState onCreateAccount={handleAddAccount} />
        ) : (
          <>
            {!requestsLoading && (
              <>
                {renderBankAccountSection()}
                {renderCardsSection()}
                {renderTransactionsSection()}
              </>
            )}
          </>
        )}
        {!requestsLoading && (
          <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer>
        )}
      </>

      <CompanyBankAccountCreationModal
        isOpen={isCreationCompanyBankAccountModalOpen}
        setIsOpen={setIsCreationCompanyBankAccountModalOpen}
        onFinished={refetchData}
        refetchPageContentCallback={refetchData}
        hookData={{
          bankCardsList: cardsList || [],
          userData: currentUser,
        }}
      />
    </DashboardLayout>
  );
};

export default EmployeeBankingPage;
