import { SalsaWorkerCategoryEnum } from "types/BETypes";

export const initialValues = {
  employmentType: SalsaWorkerCategoryEnum.EMPLOYEE,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  overtimeEligible: false,
};
