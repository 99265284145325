import { FC } from "react";

import IconProps from "./IconProps";

const OnboardingIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11075_3396)">
      <path
        d="M12 3.19922C10.8181 3.19922 9.64778 3.43201 8.55585 3.8843C7.46392 4.3366 6.47177 4.99953 5.63604 5.83526C4.80031 6.67098 4.13738 7.66314 3.68508 8.75507C3.23279 9.847 3 11.0173 3 12.1992C3 13.3811 3.23279 14.5514 3.68508 15.6434C4.13738 16.7353 4.80031 17.7275 5.63604 18.5632C6.47177 19.3989 7.46392 20.0618 8.55585 20.5141C9.64778 20.9664 10.8181 21.1992 12 21.1992C14.3869 21.1992 16.6761 20.251 18.364 18.5632C20.0518 16.8754 21 14.5862 21 12.1992C21 9.81227 20.0518 7.52308 18.364 5.83526C16.6761 4.14743 14.3869 3.19922 12 3.19922Z"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 12.1992L12 8.19922"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 12.1992H8"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 16.1992L16 12.1992"
        stroke="#F96331"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11075_3396">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199219)" />
      </clipPath>
    </defs>
  </svg>
);

export default OnboardingIcon;
