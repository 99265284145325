import { FC } from "react";

import IconProps from "./IconProps";

const DollarIconCircled: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1829_1136)">
      <path
        d="M1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10V10Z"
        stroke="#0F1826"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 11.75C8.25 12.0961 8.35264 12.4345 8.54493 12.7222C8.73722 13.01 9.01053 13.2343 9.33031 13.3668C9.65008 13.4992 10.0019 13.5339 10.3414 13.4664C10.6809 13.3989 10.9927 13.2322 11.2374 12.9874C11.4822 12.7427 11.6489 12.4309 11.7164 12.0914C11.7839 11.7519 11.7492 11.4001 11.6168 11.0803C11.4843 10.7605 11.26 10.4872 10.9722 10.2949C10.6845 10.1026 10.3461 10 10 10C9.65388 10 9.31554 9.89737 9.02775 9.70507C8.73997 9.51278 8.51567 9.23947 8.38321 8.9197C8.25076 8.59993 8.2161 8.24806 8.28363 7.90859C8.35115 7.56913 8.51782 7.25731 8.76256 7.01256C9.00731 6.76782 9.31913 6.60115 9.65859 6.53363C9.99806 6.4661 10.3499 6.50076 10.6697 6.63321C10.9895 6.76566 11.2628 6.98997 11.4551 7.27775C11.6474 7.56554 11.75 7.90388 11.75 8.25"
        stroke="#0F1826"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5.33398V6.50065"
        stroke="#0F1826"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.5V14.6667"
        stroke="#0F1826"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1829_1136">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DollarIconCircled;
