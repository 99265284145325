import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, salsaSelector } from "store/selectors";

const useAdminDocumentsPage = () => {
  const frameId = "employer-container";
  const frameSelector = `#${frameId}`;
  const salsaData = useAppSelector(salsaSelector);
  const { salsa, authToken } = salsaData || {};
  const currentCompany = useAppSelector(companyMetadataSelector);

  useEffect(() => {
    if (salsa) {
      const salsaElement = salsa.elements.create("employer-documents", {
        userToken: authToken,
        employerId: currentCompany?.salsaEmployerId,
      });

      salsa.elements.mount(salsaElement, frameSelector);
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsa]);

  return {
    frameId,
  };
};

export default useAdminDocumentsPage;
