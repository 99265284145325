import { FC } from "react";

import IconProps from "./IconProps";

const PieChart: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7110_26338)">
      <path
        d="M8.33359 2.92113C7.03001 3.22333 5.83102 3.86879 4.86099 4.79058C3.89095 5.71236 3.18521 6.87688 2.81694 8.16336C2.44868 9.44984 2.43132 10.8114 2.76666 12.1069C3.102 13.4023 3.77783 14.5845 4.72404 15.5307C5.67026 16.4769 6.8524 17.1527 8.14785 17.4881C9.4433 17.8234 10.8049 17.806 12.0914 17.4378C13.3778 17.0695 14.5424 16.3638 15.4641 15.3937C16.3859 14.4237 17.0314 13.2247 17.3336 11.9211C17.3336 11.7001 17.2458 11.4882 17.0895 11.3319C16.9332 11.1756 16.7213 11.0878 16.5003 11.0878H10.8336C10.3916 11.0878 9.96764 10.9122 9.65508 10.5996C9.34252 10.2871 9.16692 9.86316 9.16692 9.42113V3.5878C9.15657 3.48907 9.12672 3.39337 9.0791 3.30627C9.03148 3.21916 8.96705 3.14238 8.88953 3.08036C8.81201 3.01835 8.72296 2.97234 8.62752 2.94501C8.53209 2.91767 8.43218 2.90956 8.33359 2.92113Z"
        stroke="#627293"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3.1709C13.5581 3.54347 14.5192 4.14865 15.3124 4.94186C16.1056 5.73508 16.7108 6.69613 17.0833 7.75423H13.3333C13.1123 7.75423 12.9004 7.66643 12.7441 7.51015C12.5878 7.35387 12.5 7.14191 12.5 6.9209V3.1709Z"
        stroke="#627293"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7110_26338">
        <rect width="20" height="20" fill="white" transform="translate(0 0.254395)" />
      </clipPath>
    </defs>
  </svg>
);

export default PieChart;
