import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { FrameContainer, FullScreenLoader } from "uikit";

import { useEmployeeProfilePage } from "./useEmployeeProfilePage";

interface IProps {}

const EmployeeProfilePage: FC<IProps> = () => {
  const { metadata, frameData } = useEmployeeProfilePage();

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <>
        <FrameContainer id={frameData.salsaFrameId} />
      </>
    </DashboardLayout>
  );
};

export default EmployeeProfilePage;
