export const navigationPromptTranslations = {
  "en-US": {
    title: "Are you sure?",
    message: "The changes won’t be saved if you leave the page.",
    confirm: "Leave the page",
    cancel: "Cancel",
  },
};

export default navigationPromptTranslations;
