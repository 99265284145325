import { SyncteraAccountTransactionType } from "types/BETypes";

export const transactionTypeBankingTranslations = {
  "en-US": {
    [SyncteraAccountTransactionType.ACH]: "ACH",
    [SyncteraAccountTransactionType.CARD]: "Card",
    [SyncteraAccountTransactionType.CHECK]: "Check",
    [SyncteraAccountTransactionType.EXTERNAL_CARD]: "External Card",
    [SyncteraAccountTransactionType.INTERNAL_TRANSFER]: "Internal Transfer",
    [SyncteraAccountTransactionType.WIRE]: "Wire",
  },
};

export default transactionTypeBankingTranslations;
