import { FC } from "react";

import IconProps from "./IconProps";

const LogoSimple: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
  >
    <path
      d="M2.50766 0.65519C2.78343 0.4533 3.08494 0.293312 3.41587 0.175225C3.74312 0.0571386 4.06669 0 4.37923 0C4.85724 0 5.29848 0.110468 5.70662 0.335213C6.11476 0.556149 6.56335 0.898981 6.85751 1.28752C7.46789 2.09889 8.39081 4.77298 6.60748 6.89473C6.16256 7.42421 5.7581 7.70229 5.25803 7.94608C4.75796 8.18987 4.22112 8.31176 3.65119 8.31176C3.2026 8.31176 2.82388 8.23939 2.50766 8.09845V11.3477L0.25 12.0143V0.182843H2.50766V0.65519ZM2.50766 6.3881C2.50766 6.63951 2.54443 6.87568 2.62164 7.08519C2.69886 7.29851 2.79814 7.48135 2.92316 7.63372C3.04817 7.78609 3.1879 7.90418 3.34601 7.99179C3.50412 8.0794 3.65855 8.1213 3.8093 8.1213C4.01889 8.1213 4.21745 8.04893 4.4013 7.90798C4.58882 7.76704 4.75061 7.56896 4.88666 7.31374C5.02638 7.06233 5.13301 6.7614 5.21391 6.41857C5.2948 6.07574 5.33525 4.91773 5.33525 4.51395C5.33525 4.06827 5.28744 2.86455 5.19184 2.46458C5.09624 2.06461 4.97123 1.71416 4.81312 1.40942C4.65501 1.10468 4.47116 0.860889 4.25422 0.681854C4.04095 0.499011 3.8093 0.407589 3.55927 0.407589C3.38645 0.407589 3.2026 0.460918 3.00037 0.567577C2.80182 0.674236 2.63267 0.811368 2.5003 0.986594V6.39191L2.50766 6.3881Z"
      fill="white"
    />
  </svg>
);

export default LogoSimple;
