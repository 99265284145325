import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton } from "uikit";

export const Wrapper = styled.div`
  padding-bottom: 25px;

  & > p:nth-child(1) {
    font-size: ${({ theme }) => theme.size.title};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.textAlt};
    margin-bottom: 14px;
  }
`;

export const EmptySection = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoint("xs", "md")`
      flex-direction: column;
  `};
`;

export const ConnectBankBtn = styled(PrimaryButton)`
  padding: 0;
  margin: 24px 0 50px auto;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 180px;

  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 430px;
  `};
`;

export const BankAccountsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
`;

export const BankAccountListRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.widgetInnerBorder};

  &:nth-of-type(1) {
    border-top: 1px solid ${({ theme }) => theme.colors.widgetInnerBorder};
  }

  & > div:nth-child(1) {
    margin-right: 15px;
  }

  & > div:nth-child(2) {
    width: 100%;
  }

  ${breakpoint("xs", "md")`
      & > div:nth-child(2) {
         display: none;
      };
   `};

  ${breakpoint("md")`
      & > div:nth-child(3) {
         display: none;
      };
   `};
`;

export const RadioBtnWrapper = styled.div`
  min-width: 20px;
  min-height: 20px;
`;

export const EmptyStateContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.widgetInnerBorder};
  background-color: ${({ theme }) => theme.colors.emptyListBg};
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ESTitle = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.55;
  margin-bottom: 12px;
`;

export const ESMessage = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 1.55;
`;
