import { FC } from "react";

import IconProps from "./IconProps";

const AttentionCircleIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="39.5"
      cy="39.5"
      r="38.5"
      fill="white"
      stroke="url(#paint0_linear_1_219)"
      strokeWidth="2"
    />
    <g clipPath="url(#clip0_1_219)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.607 47.7635C41.4333 48.6462 40.5429 49.2922 39.4999 49.2922V49.2925C38.457 49.2925 37.5665 48.6462 37.3929 47.7638L33.1748 26.3266C32.2336 21.8243 35.1969 18 39.4999 18C43.803 18 46.7663 21.8243 45.8251 26.3266L41.607 47.7635ZM36.2202 53.9934C34.4083 55.8249 34.4083 58.7943 36.2202 60.6257C38.0321 62.4572 40.9698 62.4572 42.7817 60.6257C44.5937 58.7943 44.5937 55.8249 42.7817 53.9934C40.9698 52.162 38.0321 52.162 36.2202 53.9934Z"
        fill="url(#paint1_linear_1_219)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_219"
        x1="39.5"
        y1="0"
        x2="39.5"
        y2="79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F29128" />
        <stop offset="1" stopColor="#F96331" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_219"
        x1="39.4999"
        y1="18"
        x2="39.4999"
        y2="61.9993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F29128" />
        <stop offset="1" stopColor="#F96331" />
      </linearGradient>
      <clipPath id="clip0_1_219">
        <rect width="13" height="44" fill="white" transform="matrix(-1 0 0 1 46 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default AttentionCircleIcon;
