import currency from "currency.js";

export const symbol = "$";
export const separator = ".";

export const defaultOptions = { separator: ",", decimal: ".", symbol };

export const getCents = (sum: number | string) => {
  if (sum.toString().length < 2) {
    return `0${sum}`;
  }
  return sum;
};

export const getDollars = (sum: number | string, options: currency.Options = defaultOptions) => {
  const dollars = currency(Number(sum), options).dollars();
  return currency(dollars, { ...options, precision: 0 }).format();
};

export const getSymbol = () => {
  return symbol;
};

export const getFormattedSum = (
  sum: number | string,
  options: currency.Options = defaultOptions,
) => {
  const amount = currency(Number(sum), options).value;
  return currency(amount, {
    ...options,
    precision: options.precision !== undefined ? options.precision : 2,
  }).format();
};
