import _ from "lodash";

export enum CardStatus {
  ACTIVE = "ACTIVE",
  IMAGE_PENDING = "IMAGE_PENDING",
  IMAGE_REJECTED = "IMAGE_REJECTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  UNACTIVATED = "UNACTIVATED",
}

export enum CardFulfillmentStatus {
  DIGITALLY_PRESENTED = "DIGITALLY_PRESENTED",
  ISSUED = "ISSUED",
  ORDERED = "ORDERED",
  REISSUED = "REISSUED",
  REJECTED = "REJECTED",
  REORDERED = "REORDERED",
  SHIPPED = "SHIPPED",
}

export enum CardShippingMethod {
  INTERNATIONAL = "INTERNATIONAL",
  /** only available for addresses in Canada and Mexico */
  INTERNATIONAL_GROUND = "INTERNATIONAL_GROUND",
  INTERNATIONAL_PRIORITY = "INTERNATIONAL_PRIORITY",
  LOCAL_MAIL = "LOCAL_MAIL",
  LOCAL_PRIORITY = "LOCAL_PRIORITY",
  OVERNIGHT = "OVERNIGHT",
  TWO_DAY = "TWO_DAY",
}

//Active card has "NEW" as the reason by default, when card is reissued it can have other reasons
export enum CardReissueReason {
  EXPIRATION = "EXPIRATION",
  LOST = "LOST",
  STOLEN = "STOLEN",
  // APPEARANCE = "APPEARANCE",
  // DAMAGED = "DAMAGED",
  // PRODUCT_CHANGE = "PRODUCT_CHANGE",
}

export enum CardForm {
  PHYSICAL = "PHYSICAL",
  VIRTUAL = "VIRTUAL",
}

export enum CardShipmentExpedited {
  REGULAR_DELIVERY = "REGULAR_DELIVERY",
  EXPEDITED_DELIVERY = "EXPEDITED_DELIVERY",
}

export enum CardShipmentExpeditedBoolean {
  REGULAR_DELIVERY = 0,
  EXPEDITED_DELIVERY = 1,
}

export enum CardStatusReason {
  ACT = "ACT",
  ADD = "ADD",
  AUX = "AUX",
  CLO = "CLO",
  COM = "COM",
  DOB = "DOB",
  EML = "EML",
  EXP = "EXP",
  FRD = "FRD",
  FUL = "FUL",
  INA = "INA",
  INF = "INF",
  ISS = "ISS",
  KYC = "KYC",
  LOS = "LOS",
  MAT = "MAT",
  NAM = "NAM",
  NEG = "NEG",
  NEW = "NEW", // new card
  OTH = "OTH",
  OUT = "OUT",
  PHO = "PHO",
  PIN = "PIN",
  PRC = "PRC",
  REQ = "REQ", //	Requested by you
  REV = "REV",
  SSN = "SSN",
  STO = "STO",
  SUS = "SUS",
  TMP = "TMP",
  UNK = "UNK",
}

export const CardStatusToUpdate = _.pick(CardStatus, ["ACTIVE", "SUSPENDED", "TERMINATED"]);
