import { FC } from "react";
import { Trans } from "react-i18next";
import { CircledDots } from "assets/svg";
import moment from "moment";

import { defaultDateFormat } from "constants/shared";
import { getName } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, ContextMenu, FrameContainer, FullScreenLoader, PageTitle } from "uikit";

import useAdminWorkerDetails from "./useAdminWorkerDetails";
import {
  MenuButton,
  StartDateLabelText,
  StartDateValueText,
  StyledEmployeeInfo,
  StyledUserStatus,
  TCLeft,
  TCRight,
  TopContainer,
} from "./styles";

interface Props {
  className?: string;
}

const EmptyPage: FC<Props> = ({ className }) => {
  const translationPrefix = `people_page.worker_details`;

  const {
    employeeData,
    isLoading,
    actionsLoading,
    frameId,
    handleBack,
    getContextMenuOptions,
    renderWorkerActionsElements,
  } = useAdminWorkerDetails();

  const translationValues = { fullName: getName(employeeData) || "" };

  return (
    <DashboardLayout
      onBackBtnClick={handleBack}
      className={className}
      metaDataLoading={!employeeData}
      showTitle={false}
      translationValues={translationValues}
      titleRightContent={
        <TCRight>
          {employeeData && <StyledUserStatus data={employeeData} />}
          <MenuButton>
            <ContextMenu
              component={<CircledDots />}
              options={getContextMenuOptions()}
              dataTestId="actions-menu"
            />
          </MenuButton>
        </TCRight>
      }
    >
      {(!!isLoading || !!actionsLoading) && <FullScreenLoader />}
      {!!employeeData && (
        <>
          <StyledEmployeeInfo
            user={employeeData}
            topRightContent={
              !!employeeData?.startDate && (
                <StartDateLabelText>
                  <Trans i18nKey={`components.employee_info.started_at`} />
                  <StartDateValueText>
                    {moment(employeeData?.startDate).format(defaultDateFormat) || "-"}
                  </StartDateValueText>
                </StartDateLabelText>
              )
            }
          />

          <FrameContainer id={frameId} />

          {renderWorkerActionsElements()}
        </>
      )}
    </DashboardLayout>
  );
};

export default EmptyPage;
