import { SalsaWorkerCategoryEnum, UserStatus } from "types/BETypes";
import { StateSelectOptions } from "constants/shared";
import { getFormattedPhoneNumber, getNoonDate, s3UploadFromDataUrl } from "helpers/index";
import { FormType } from "components/CreateUpdateEmployeeForm";

import {
  CreateEmployeeDto,
  queryEmployeesControllerAvatarUploadUrl,
  UserResponseDto,
} from "utils/swagger_react_query";

export const employeeRequiredFields = ["firstName", "lastName", "email"];
export const employeeRequiredFieldsWithBonus = [
  ...employeeRequiredFields,
  "birthDate",
  "socialSecurityNumber",
  "address",
  "phone",
  "city", //Clarify if needed
  "state", //Clarify if needed
  "zip", //Clarify if needed
];

export const employeeDisabledFields = [
  "firstName",
  "lastName",
  "email",
  "personalEmail",
  "jobTitle",
  "startDate",
  "companyEmployeeId",
];

export const isFieldRequired = (field: string) => {
  return employeeRequiredFields.indexOf(field) !== -1;
};

export const isFieldRequiredWithBonus = (field: string) => {
  return employeeRequiredFieldsWithBonus.indexOf(field) !== -1;
};

export const isFieldDisabledForEmployee = (field: string) => {
  return employeeDisabledFields.indexOf(field) !== -1;
};

export const employeeDtoToUserResponse = (values: FormType, avatarUrl?: string) => {
  const result: Partial<CreateEmployeeDto> = {
    firstName: values.firstName || "",
    lastName: values.lastName || "",
    avatarUrl: avatarUrl || values.avatarUrl || null,
    startDate: values.startDate ? getNoonDate(values.startDate) : null,
    birthDate: values.birthDate ? getNoonDate(values.birthDate) : null,
    socialSecurityNumber: values.socialSecurityNumber?.toString() || null,
    jobTitle: values.jobTitle || null,
    department: values.department || null,
    phone: values.phone ? getFormattedPhoneNumber(values.phone) : null,
    companyEmployeeId: values.companyEmployeeId || null,
    address: values.address || null,
    city: values.city || null,
    state: (values.state?.value as CreateEmployeeDto["state"]) || null,
    suite: values.suite || null,
    zip: values.zip || null,
    email: values.email || "",
    employmentType:
      (values.employmentType as SalsaWorkerCategoryEnum) || SalsaWorkerCategoryEnum.EMPLOYEE,
  };
  return result;
};
export const userResponseToEmployeeDto = (values: UserResponseDto) => {
  const state = StateSelectOptions.find((state) => state.value === values.state) || {
    label: "",
    value: "",
  };

  const result = {
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    avatarUrl: values?.avatarUrl || null,
    avatarFileName: values?.avatarUrl || null,
    jobTitle: values?.jobTitle || "",
    department: values?.department || "",
    phone: values?.phone || "",
    companyEmployeeId: values?.companyEmployeeId || "",
    socialSecurityNumber: values?.socialSecurityNumber || "",
    companyRoles: values?.companyRoles || null,
    startDate: values?.startDate ? new Date(values?.startDate) : null,
    birthDate: values?.birthDate ? new Date(values?.birthDate) : null,
    active: values.status !== UserStatus.PROSPECT,
    address: values.address || "",
    suite: values.suite || "",
    city: values.city || "",
    state: state,
    zip: values.zip || "",
    email: values.email || "",
    personalEmail: values.personalEmail || "",
  };

  return result;
};

export const uploadUserAvatar = async (
  values: FormType,
  companyId: string,
  employeeId?: string,
) => {
  if (!values.avatarUrl) return "";

  const { presignedPost } = await queryEmployeesControllerAvatarUploadUrl(
    {
      employeeId: employeeId || values?.companyEmployeeId!,
    },
    { fileName: values.avatarFileName || "" },
  );
  const { s3AssetUrl } = await s3UploadFromDataUrl(
    values.avatarUrl,
    presignedPost.url,
    presignedPost.fields,
  );
  return s3AssetUrl;
};
