import { ChangeEvent, FC } from "react";
import { Trans } from "react-i18next";
import { ArrowIcon } from "assets/svg";
import { useFormik } from "formik";
import routes from "routes/routes";

import { HELP_CENTER } from "constants/shared";
import AuthLayout from "layouts/AuthLayout";
import TooManyAttemptsErrorModal from "components/TooManyAttemptsErrorModal";

import { FullScreenLoader } from "uikit";

import { BusinessDetails, ContactDetails, SignInForm, SignUpNestedFormName } from "../_shared";
import {
  BtnsWrapper,
  Footer,
  FooterLinkExternal,
  StyledPrimaryBtn,
  StyledSecondaryBtn,
} from "../_shared/components/styles";
import { authDefaultLeftSectionParams } from "../_shared/constants";
import { useSignUpPage } from "./useSignUpPage";
import { ParentFormType, ParentFormValidationSchema } from "./validationSchema";
import { Wrapper } from "./styles";

const SignUpPage: FC = () => {
  const translationPrefix = `auth_pages.admin.sign_up_page`;
  const { metadata, actions, formData, modals } = useSignUpPage({
    errorModalsTranslationsPrefix: `auth_pages.shared.error_modals`,
  });

  const setNestedFormPrefix = (prefix: string, fieldName: string) => {
    if (fieldName.includes(prefix)) return fieldName;

    return `${prefix}.${fieldName}`;
  };

  const formik = useFormik<ParentFormType>({
    initialValues: {
      [SignUpNestedFormName.SignIn]: formData.signInInitValues,
      [SignUpNestedFormName.BusinessDetails]: formData.businessInitialValues,
      [SignUpNestedFormName.ContactDetails]: formData.contactDetailsInitialValues,
    },
    validationSchema: () => {
      if (metadata.currentStep === 0)
        return ParentFormValidationSchema.pick([SignUpNestedFormName.SignIn]);
      if (metadata.currentStep === 1)
        return ParentFormValidationSchema.pick([SignUpNestedFormName.BusinessDetails]);
      if (metadata.currentStep === 2)
        return ParentFormValidationSchema.pick([SignUpNestedFormName.ContactDetails]);

      return ParentFormValidationSchema;
    },
    validateOnChange: metadata.svoc,
    onSubmit: (values, formikActions) => actions.submitHandler(values, formikActions.validateForm),
  });

  if (metadata.pageLoader) return <FullScreenLoader />;

  return (
    <AuthLayout
      isLoading={metadata.contentLoader}
      leftSectionParams={authDefaultLeftSectionParams()}
    >
      <Wrapper onSubmit={formik.handleSubmit}>
        {metadata.currentStep === 0 && (
          <SignInForm
            isLoading={metadata.contentLoader}
            handleGoogleAuth={actions.handleGoogleAuth}
            translationPrefix={`${translationPrefix}.sign_in_step`}
            values={formik.values.signIn}
            errors={formik?.errors?.signIn || {}}
            handleChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.currentTarget.name = setNestedFormPrefix(
                SignUpNestedFormName.SignIn,
                e.currentTarget.name,
              );
              return formik.handleChange(e);
            }}
            navigationLink={routes.SIGN_IN}
          />
        )}
        {metadata.currentStep === 1 && (
          <BusinessDetails
            values={formik.values.businessDetails}
            errors={formik?.errors?.businessDetails || {}}
            handleChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.currentTarget.name = setNestedFormPrefix(
                SignUpNestedFormName.BusinessDetails,
                e.currentTarget.name,
              );
              return formik.handleChange(e);
            }}
          />
        )}
        {metadata.currentStep === 2 && (
          <ContactDetails
            values={formik.values.contactDetails}
            errors={formik?.errors?.contactDetails || {}}
            setFieldValue={async (field: string, value: string) => {
              formik.setFieldValue(`${SignUpNestedFormName.ContactDetails}.${field}`, value);
              return;
            }}
            handleChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.currentTarget.name = setNestedFormPrefix(
                SignUpNestedFormName.ContactDetails,
                e.currentTarget.name,
              );
              return formik.handleChange(e);
            }}
          />
        )}

        {!metadata.contentLoader && metadata.currentStep > 0 && (
          <BtnsWrapper>
            {metadata.currentStep > 1 && (
              <StyledSecondaryBtn type={"button"} onClick={() => actions.onBackClick()}>
                <Trans i18nKey={`buttons.back`} />
              </StyledSecondaryBtn>
            )}
            <StyledPrimaryBtn type="submit">
              <Trans i18nKey={`buttons.apply`} />
            </StyledPrimaryBtn>
          </BtnsWrapper>
        )}

        {metadata.currentStep > 0 && (
          <Footer style={{ marginTop: "20px" }}>
            <FooterLinkExternal href={HELP_CENTER} target="_blank">
              <Trans i18nKey={`buttons.need_help_btn`} />
            </FooterLinkExternal>
          </Footer>
        )}
      </Wrapper>

      <TooManyAttemptsErrorModal
        isOpen={modals.tooManyAttemptsErrorModalIsOpen}
        onClose={() => modals.setOpenTooManyAttemptsErrorModal(false)}
      />
    </AuthLayout>
  );
};

export default SignUpPage;
