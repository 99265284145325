import * as yup from "yup";

import {
  BusinessDetailsFormValidationSchema,
  ContactDetailsFormTypeValidationSchema,
  signInValidationSchema,
  SignUpNestedFormName,
} from "../_shared";

export const ParentFormValidationSchema = yup.object({
  [SignUpNestedFormName.SignIn]: signInValidationSchema,
  [SignUpNestedFormName.BusinessDetails]: BusinessDetailsFormValidationSchema,
  [SignUpNestedFormName.ContactDetails]: ContactDetailsFormTypeValidationSchema,
});

export type ParentFormType = yup.InferType<typeof ParentFormValidationSchema>;
