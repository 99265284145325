import {
  BonusChallengeGoalType,
  BonusChallengeRewardType,
  ChallengeGoalConditionOperator,
  PerformanceRewardStatus,
  RewardActionActionEnum,
  RewardActionArgMeasure,
} from "types/BETypes";
import { MAX_TEXTAREA_LENGTH } from "constants/form";

export const performanceChallengeTranslations = {
  "en-US": {
    accordion_title: "Performance Challenges",
    accordion_title_optional: "Optional",
    calculation_is_impossible:
      "Please complete the form above to view Performance Challenge options.",
    no_records_title: "Who’s up for a challenge?",
    no_records_description: `Motivate employees and help reduce their vesting period or balance due with a performance challenge. 
      <br /><br />
      <1>Define the goals and acomplish them by a date</1> 
      <1>Choose the reward you wish to provide if the employee accomplishes the goal. </1> 
      <1>At any point you may review and confirm the goal(s) have been accomplished. </1> 
      <br />
      If the goal is achieved the employee’s cash bonus vesting schedule will be updated to reflect the reward. 
      Give it a try.
      `,
    no_records_create_button_text: "Create Performance Challenge",
    if_label: "If",
    if_label_detailed: "the employee achieves the following goal(s)...",
    if_label_detailed_alt: "the employee achieves the following...",
    if_description_placeholder: "Type in your desired goals",
    if_date_placeholder: "By date",
    if_date_label: "By {{date}}",
    if_cancel_goal: "Cancel",
    if_save_goal: "Save",
    if_save_goal_new: "Add Goal",
    if_edit_goal: "Edit",
    if_delete_goal: "Delete",
    if_update_goal_btn_text: "Update Goal",
    if_and: "AND",
    then_label: "Then",
    then_label_detailed: "award them the following...",
    then_reduce_balance: "Reduce their balance due by",
    then_reduce_schedule: "Reduce their bonus vesting schedule by",
    then_edit_reward: "Edit",
    then_delete_reward: "Delete",
    add_challenge: "Create Additional Challenge",
    cancel_challenge: "Cancel",
    add_additional_goal: "Add Additional Goal",
    delete_challenge: "Delete",
    clear_challenge: "Clear All",

    challenge_create_date_label: "Created: ",
    challenge_status_label: "Status: ",
    challenge_approvers: {
      label: "Approvers",
      close_button: "Close",
      text: "Any Super Admin or Owner has permissions to approve or decline active performance challenges. ",
    },

    challenge_standalone: {
      goal_descriptions: {
        [BonusChallengeGoalType.NUMBER]: `<1>{{name}}</1> {{operator}} <1>{{value}}</1> by <1>{{date}}</1>`,
        [BonusChallengeGoalType.PERCENTAGE]: `<1>{{name}}</1> {{operator}} <1>{{value}}%</1> by <1>{{date}}</1>`,
        [BonusChallengeGoalType.MONETARY]: `<1>{{name}}</1> {{operator}} <1>\${{value}}</1> by <1>{{date}}</1>`,
        [BonusChallengeGoalType.TEXT]: `<1>{{name}}</1> by <1>{{date}}</1>`,
        [BonusChallengeGoalType.STATUS]: "",
      },

      award_options: {
        [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: "Flat Payment Upon Completion",
        [BonusChallengeRewardType.PAY_TIERS]: "Pay Tiers: Different pay rates per goal",
      },

      comparison_operators: {
        [ChallengeGoalConditionOperator.EQUAL_TO]: "is equal to",
        [ChallengeGoalConditionOperator.LESS_THAN]: "is less than",
        [ChallengeGoalConditionOperator.LESS_THAN_OR_EQUAL]: "is less than or equal to",
        [ChallengeGoalConditionOperator.GREATER_THAN]: "is greater than",
        [ChallengeGoalConditionOperator.GREATER_THAN_OR_EQUAL]: "is greater than or equal to",
      },

      goal_description_operators: {
        [ChallengeGoalConditionOperator.EQUAL_TO]: "is equal to",
        [ChallengeGoalConditionOperator.LESS_THAN]: "is less than",
        [ChallengeGoalConditionOperator.LESS_THAN_OR_EQUAL]: "is less than or equal to",
        [ChallengeGoalConditionOperator.GREATER_THAN]: "is greater than",
        [ChallengeGoalConditionOperator.GREATER_THAN_OR_EQUAL]: "is greater than or equal to",
      },

      forms: {
        if_goal_type: "Goal Type",
        if_goal_name_description: "Example: # Shifts worked, Customer Score, Website Visits, etc.",
        if_by_date_label: "By Date",
        if_range_label: "Range",
        if_to_label: "To",
        then_save_reward: "Add Reward",
        then_award_options_label: "Reward Options",
        then_award_label: "Reward",
        then_award_label_pay_rate: "Pay Rate",
        then_award_label_pay_rate_tooltip:
          "Employee earns this amount for each consecutive number until they hit the next tier.",
        then_award_option_placeholder: "Please Choose",
        then_award_option_description: "Note: Keep will not deliver funds to the employee.",
        add_stretch_goal_btn: "Add stretch goal",
        add_stretch_goal_btn_tier: "Add Tier",
        add_stretch_goal_btn_tier_decription:
          "Employee earns this amount for each consecutive number until they hit the next tier. ",
        delete_stretch_goal_btn: "Delete",
        stretch_goal_label: "Stretch Goal #{{count}}",
        max_reward_label: "Max Reward Amount",

        option_change_confirmation_modal: {
          message: "Changing this option will reset your data.",
          submit_btn: "Yes, switch option",
        },

        goal_types: {
          [BonusChallengeGoalType.NUMBER]: "Numeric",
          [BonusChallengeGoalType.PERCENTAGE]: "Percentage",
          [BonusChallengeGoalType.MONETARY]: "Monetary",
          [BonusChallengeGoalType.TEXT]: "Text only",
          [BonusChallengeGoalType.STATUS]: "Status",
        },

        goal_type_descriptions: {
          [BonusChallengeGoalType.NUMBER]: "Goals that tracks a numeric value.",
          [BonusChallengeGoalType.PERCENTAGE]: "Goals that tracks a percentage value.",
          [BonusChallengeGoalType.MONETARY]: "Goals that tracks a monetary value.",
          [BonusChallengeGoalType.TEXT]:
            "Text only goal. Employee either achieves or does not achieve by due date.",
          [BonusChallengeGoalType.STATUS]: "Goals that tracks a status label.",
        },

        reward_descriptions: {
          [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: `<1>{{value}}</1> Flat Payment`,
        },

        errors: {
          title: {
            required: "Title is required",
            too_long: "Title must not exceed {{maxValue}} characters",
          },
          goal_type: {
            required: "Type is required",
          },
          goal_name: {
            required: "Name is required",
            too_long: "Name must not exceed {{maxValue}} characters",
          },
          goal_condition_operator: {
            required: "Condition is required",
          },
          goal_condition_value: {
            required: "Value is required",
            too_large: "Value must not exceed {{maxValue}}",
            not_unique: "Value must be unique",
          },
          goal_by_date: {
            required: "Date is required",
            invalid_format: "Date format is invalid",
          },
          goal_from: {
            required: `"From" value is required`,
            too_large: `"From" value must not exceed {{maxValue}}`,
            exceeds_condition_value: `“From” value can not be higher than the smallest entered goal value`,
          },
          goal_to: {
            required: `"To" value is required`,
            too_large: `"To" value must not exceed {{maxValue}}`,
            less_than_condition_value: `"To" value can not be smaller than the biggest entered goal value`,
            less_or_equal_to_from_value: `"To" value can not be smaller or equal to "From" value`,
          },

          award_type: {
            required: "Award type is required",
          },

          award_amount: {
            required: "Amount is required",
            too_large: "Amount must not exceed {{maxValue}}",
          },

          max_reward_amount: {
            required: "Max reward amount is required",
            too_large: "Max reward amount must not exceed {{maxValue}}",
            less_than_awards: "Max reward amount must not be less than any goal reward",
          },
        },

        draft_created_modal: {
          title: "Draft Performance Challenge Created",
          message: "",
        },

        send_edited_confirmation_modal: {
          title: "Are you sure?",
          message: `Are you sure you wish to update the active performance challenge for<br /><br/>
          <1>{{fullName}}?</1>
          <br/><br/>
          Once updated, the employee will be notified of the changes. All recorded progress will be reset.`,
        },

        invitation_sent_modal: {
          title_new: "Performance Challenge Created",
          title_edit: "Performance Challenge Updated",
          message_new: "Performance Challenge was successfully created",
          message_edit: "Performance Challenge was successfully updated",
        },

        reassign_confirmation_modal: {
          title: "Warning",
          message_both:
            "This action will assign <1>{{newCount}} New Bonuses</1> and will void <1>{{voidCount}} Bonuses</1> for your employees.<br /><br />Are you sure you want to continue?",
          message_assign_only:
            "This action will assign <1>{{newCount}} New Bonuses</1> for your employees.<br /><br />Are you sure you want to continue?",
          message_void_only:
            "This action will void <1>{{voidCount}} Bonuses</1> for your employees.<br /><br />Are you sure you want to continue?",
        },
        remove_all_assignees_confirmation_modal: {
          title: "Warning",
          message:
            "This action will <1>void all {{voidCount}} Bonuses</1> and will end this Challenge. If you want to assign new Employees to this Challenge, please, create a new one.<br/><br/> Are you sure you want to  continue?",
        },
      },

      details: {
        title: "Performance Challenge",

        days_left: "Days Left: <1>{{days}}</1>",
        goal_descriptions_prefix: "<1>Goal: </1>",
        stretch_goal_descriptions_prefix: "<1>IF <1/>",
        reward_description: "<1>Reward: </1><2></2>",
        reward_description_per_event: "<1>Reward: </1><2></2> <3>per event</3>",
        bonus_opportunity_label: "Potential Bonus Amount",
        bonus_opportunity_label_potential: "Total Rewarded",
        bonus_opportunity_current_tier_label: "Current tier",
        bonus_opportunity_current_tier_each: " each",
        complete_by: "Complete By",
        due_date_passed: "Challenge due date has passed",
        employee_goal_achieved_message: "<1>Congratulations!</1> you achieved your goal!",
        employee_goal_not_achieved_message:
          "Unfortunately the goal was not achieved, therefore a bonus was not issued.",
        record_progress: "Record Progress",
        record_progress_completed: "Recorded Progress",
        last_updated: "Last Updated: {{date}}",

        stretch_goals_section_title: "Stretch Goals:",
        stretch_goals_section_title_tiers: "Tiers:",

        buttons: {
          edit_button: "Edit",
          update_button: "Update",
          delete_button: "Delete",
          challenge_incomplete_button: "Challenge Incomplete",
          challenge_completed_button: "Challenge Completed",
        },

        modals: {
          complete_confirmation: {
            title: "Challenge Complete Confirmation",
            text: "Please confirm that you wish to record the challenge as complete and pay the following reward amount to <1>{{fullName}}</1>?",
            text_goal_incomplete:
              "<1>{{fullName}}</1> did not achieved the set goals <1>{{fullName}}</1> will not be eligible to receive the reward",
            submit_btn: "Yes, record as complete",
          },
          incomplete_confirmation: {
            title: "Are you sure?",
            text: "Are you sure you wish to record the challenge as incomplete? The employee will be notified of the incomplete challenge and <1>{{fullName}}</1> will not be eligible to receive the reward.",
            submit_btn: "Yes, record as incomplete",
          },
          delete_confirmation: {
            title: "Are you sure?",
            text: "Are you sure you want to delete Challenge? <br />This can not be undone.",
            submit_btn: "Yes, delete",
          },
          update_confirmation: {
            title: "Save Changes Confirmation",
            text: "Please confirm you wish to update the progress for <br /><br /><1>{{fullName}}</1>",
            submit_btn: "Save Changes",
          },
          update_confirmation_success: {
            title: "Progress updated",
          },
        },
      },
    },

    challenge_statuses: {
      [PerformanceRewardStatus.UNRESOLVED]: "Unresolved",
      [PerformanceRewardStatus.ACHIEVED]: "Goal Achieved",
      [PerformanceRewardStatus.NOT_ACHIEVED]: "Goal Not Achieved",
      LIVE: "LIVE",
      default: "N/A",
    },

    challenge_action_units: {
      [RewardActionArgMeasure.MONTH]: "month",
      [RewardActionArgMeasure.QUARTER]: "quarter",
      [RewardActionArgMeasure.YEAR]: "year",
      [RewardActionArgMeasure.CURRENCY]: "$",
    },

    challenge_award_templates: {
      [RewardActionActionEnum.REDUCE_SCHEDULE]:
        "Reduce bonus vesting schedule by {{value}} {{unit}}(s)",
      [RewardActionActionEnum.REDUCE_BALANCE_DUE]: "Reduce bonus due by ${{balance}}",
    },

    challenge_award_reduce_template: "",

    challenge_edit_buttons: {
      delete: "Delete",
      edit: "Edit Challenge",
      approve: "Approve Challenge",
      decline: "Decline Challenge",
      save: "Send Performance Challenge",
      cancel: "Cancel",
    },

    confirmation_modals: {
      cancel: {
        title: "Are you sure?",
        message: "The changes won’t be saved if you cancel your action",
        confirm_button: "Yes, I am sure",
      },
      delete: {
        title: "Are you sure?",
        message: "Are you sure you want to delete Challenge? This can not be undone",
        confirm_button: "Yes, I am sure",
      },
      approve: {
        title: "Are you sure?",
        message: "Are you sure you want to approve this Challenge?",
        confirm_button: "Yes, I am sure",
      },
      decline: {
        title: "Are you sure?",
        message: "Are you sure you want to decline this Challenge?",
        confirm_button: "Yes, I am sure",
      },
    },

    operators: {
      and: "AND",
      or: "OR",
    },

    employee_notification_modals: {
      success: {
        title: "Congratulations!",
        reward_templates: {
          [RewardActionActionEnum.REDUCE_SCHEDULE]:
            "{{value}} {{unit}} reduction to your vesting plan",
          [RewardActionActionEnum.REDUCE_BALANCE_DUE]: "Reduce balance due by ${{balance}}",
        },
        text: `
          <1>{{userName}}</1> <br /> 
          <1>{{companyName}}</1> has confirmed that you have achieved the following goals. <br /><br />
          {{goalValues}} <br /><br />
          Your reward for achieving the goal(s) is a
          <br /><br />
          <1>{{rewards}}</1><br /><br />
          Your vesting cash bonus will now fully vest on <br /><br />
          <1>{{date}}</1>`,
      },
      decline: {
        title: "Goal not reached",
        reward_temlates: {
          [RewardActionActionEnum.REDUCE_SCHEDULE]:
            "{{value}} {{unit}} reduction to your vesting plan",
          [RewardActionActionEnum.REDUCE_BALANCE_DUE]: "Reduce bonus due by ${{balance}}",
        },
        text: `
          <1>{{userName}}</1> <br /> 
          Unfortunately the performance challenge attached to your vesting cash bonus was not achieved by the date defined. Not to worry. There will likely be more fun challenges to come.
          <2></2><br /><br />
          {{goalValues}}
          `,
      },

      button_view_bonus: "View Bonus",
      goal_template: "{{goalText}} by {{goalDate}}",
    },

    errors: {
      add_goal_form: {
        description: {
          required: "Goal is required",
          too_long: `Goal description must not exceed ${MAX_TEXTAREA_LENGTH} characters`,
        },
        due_date: {
          required: "Due date is required",
          invalid_format: "Due date format is invalid",
          before_start_date: "Goal due date must be after the Bonus start date",
          exceeds_end_date: "Goal due date can not be after the Bonus end date",
        },
      },
      reduce_forms: {
        action: {
          required: "Action is required",
          cannot_be_scheduled:
            "Bonus vesting schedule cannot be reduced for custom schedule bonuses",
        },
      },
      reduce_balance_form: {
        value: {
          required: "Challenge amount is required",
          exceeds_bonus_amount: "Challenge amount can not be higher than Bonus amount.",
        },
      },
      reduce_schedule_form: {
        measure_unit: {
          required: "Unit of measure is required",
        },
        value: {
          required: "Time period value is required",
        },
        period_cannot_exceed_max: "Period cannot exceed {{maxYears}} years",
      },

      validate_before_new_challenge:
        "You have to complete or cancel editing a previous challenge before adding a new one",
    },

    employee_status_panel: {
      title: "Upon Review & Approval By:",
      status: "Status: ",
      [PerformanceRewardStatus.UNRESOLVED]: {
        text: "",
        status: "",
      },
      LIVE: {
        text: `There is <1>1 administrator</1> who has the ability to review and approve that your goals have been achived.<br /><br />
        If you feel you have completed your goal(s) before the date listed above, <1>contact an approver</1> to confirm.`,
        status: "",
      },
      [PerformanceRewardStatus.ACHIEVED]: {
        text: "",
        status: "<1>Approved:</1> <3>{{date}}</3>",
      },
      [PerformanceRewardStatus.NOT_ACHIEVED]: {
        text: "{{firstName}} has reviewed the goal(s) defined above and determined the objectives were not met by the date defined. If you have question regarding the performance challenge, <2>please contact {{firstName}}.</2>",
        status: "<2>Goal not achieved:</2> <3>{{date}}</3>",
      },
      default: {
        text: "",
      },
    },
  },
};

export default performanceChallengeTranslations;
