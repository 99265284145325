export const peoplePage = {
  "en-US": {
    admin: {
      index: {},
    },
    employee: {
      index: {},
    },
  },
};

export default peoplePage;
