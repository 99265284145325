import { SyncteraTransactionStatus } from "types/BETypes";

export const transactionStatusBankingTranslations = {
  "en-US": {
    [SyncteraTransactionStatus.PENDING]: "Pending...",
    [SyncteraTransactionStatus.REPLACED]: "Replaced",
    [SyncteraTransactionStatus.DECLINED]: "Declined",
    [SyncteraTransactionStatus.RELEASED]: "Released",
    [SyncteraTransactionStatus.PARTCLEARED]: "Cleared",
    [SyncteraTransactionStatus.CLEARED]: "Cleared",
    [SyncteraTransactionStatus.EXPIRED]: "Expired",
    [SyncteraTransactionStatus.INTERNAL_ERROR]: "Error",
    [SyncteraTransactionStatus.POSTED]: "Success",
  },
};

export default transactionStatusBankingTranslations;
