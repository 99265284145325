import { FC } from "react";
import { Trans } from "react-i18next";

import { getComprehensibleCardStatus } from "helpers/assets/bankCards";

import { BankCardResDto } from "utils/swagger_react_query";

import { Container, StatusContainer } from "./styles";

interface Props {
  className?: string;
  data?: Partial<BankCardResDto>;
  shouldShowAdditionalStatus?: boolean;
}

const BankCardStatus: FC<Props> = ({ className, data, shouldShowAdditionalStatus }) => {
  const translationPrefix = `common.bank_cards`;
  const cardStatuses = getComprehensibleCardStatus(data);

  const statusText = (
    <Trans i18nKey={`${translationPrefix}.card_statuses.${cardStatuses.status}`} />
  );

  const additionalStatusText = cardStatuses.additionalStatus && (
    <Trans
      i18nKey={`${translationPrefix}.card_additional_statuses.${cardStatuses.additionalStatus}`}
    />
  );

  return (
    <Container className={className} data-testId={"bank-card-status"}>
      <StatusContainer className={`${cardStatuses.status}`}>{statusText}</StatusContainer>

      {shouldShowAdditionalStatus && cardStatuses.additionalStatus && (
        <StatusContainer className={`additional_status_${cardStatuses.additionalStatus}`}>
          {additionalStatusText}
        </StatusContainer>
      )}
    </Container>
  );
};

export default BankCardStatus;
