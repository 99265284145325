import { FC } from "react";

import IconProps from "./IconProps";

const SearchIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7855 2.36155C12.7109 1.28771 11.3522 0.542787 9.86904 0.214274C8.38583 -0.11424 6.83968 -0.0127044 5.41217 0.506955C3.98466 1.02661 2.73507 1.94282 1.81017 3.14796C0.885261 4.35311 0.323443 5.79715 0.190696 7.3105C0.057948 8.82384 0.359783 10.3436 1.06075 11.6914C1.76172 13.0392 2.83272 14.1589 4.14796 14.9192C5.46319 15.6794 6.96806 16.0486 8.48581 15.9833C10.0036 15.918 11.4712 15.421 12.7163 14.5507L18.2644 20.0988C18.5253 20.3598 18.8792 20.5065 19.2482 20.5065C19.6173 20.5066 19.9712 20.3601 20.2322 20.0992C20.4932 19.8383 20.6399 19.4844 20.64 19.1153C20.6401 18.7463 20.4935 18.3923 20.2326 18.1313L14.6837 12.5832C15.7569 11.0448 16.255 9.17789 16.0908 7.30928C15.9266 5.44066 15.1106 3.68928 13.7855 2.36155ZM12.4766 12.3419C11.6196 13.1989 10.5276 13.7826 9.3389 14.019C8.15017 14.2554 6.91802 14.1341 5.79826 13.6702C4.6785 13.2064 3.72143 12.421 3.04808 11.4132C2.37472 10.4054 2.01531 9.22065 2.01531 8.00863C2.01531 6.79661 2.37472 5.61181 3.04808 4.60405C3.72143 3.5963 4.6785 2.81084 5.79826 2.34702C6.91802 1.88319 8.15017 1.76183 9.3389 1.99827C10.5276 2.23471 11.6196 2.81834 12.4766 3.67536C13.6225 4.82571 14.2657 6.38339 14.2652 8.00709C14.2647 9.63079 13.6207 11.1881 12.4741 12.3378L12.4766 12.3419Z"
      fill="#5B5B5B"
    />
  </svg>
);

export default SearchIcon;
