import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { FrameContainer } from "uikit";

import useAdminPayPage from "./useAdminPayPage";

interface Props {
  className?: string;
}

const AdminPayPage: FC<Props> = () => {
  const { frameId } = useAdminPayPage();

  return (
    <DashboardLayout showTitle={false} showBreadcrumbs={false}>
      <>
        <FrameContainer id={frameId} />
      </>
    </DashboardLayout>
  );
};

export default AdminPayPage;
