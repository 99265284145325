import { FC } from "react";

import IconProps from "./IconProps";

const MakePaymentCardIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_37_4029)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9502 42.5L71.0073 49.5572L49.5615 71.003L36.1094 57.5509L43.1666 50.4937L49.5615 56.8887L63.9502 42.5ZM63.9502 47.0219L49.5615 61.4106L43.1666 55.0156L40.6313 57.5509L49.5615 66.4811L66.4854 49.5572L63.9502 47.0219Z"
        fill="#F29127"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5735 3.19748C16.7904 3.19748 3.19748 16.7904 3.19748 33.5735C3.19748 50.3566 16.7904 63.9496 33.5735 63.9496C35.2106 63.9496 36.807 63.8132 38.3801 63.5688L38.8709 66.7284C37.1507 66.9956 35.3897 67.147 33.5735 67.147C15.0245 67.147 0 52.1225 0 33.5735C0 15.0245 15.0245 0 33.5735 0C52.1225 0 67.147 15.0245 67.147 33.5735C67.147 36.1079 66.8617 38.5731 66.3424 40.9353L63.2195 40.2487C63.6913 38.1024 63.9496 35.8674 63.9496 33.5735C63.9496 16.7904 50.3566 3.19748 33.5735 3.19748Z"
        fill="#007D7B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4634 25.5647C29.8516 25.5647 28.7935 26.8748 28.7935 27.739V30.201C28.7935 30.739 29.2653 31.5486 30.5316 31.8855L37.5021 33.74C39.7433 34.3363 41.5835 36.1295 41.5835 38.5145V40.9765C41.5835 42.4526 40.8406 43.7994 39.8124 44.7426C38.7702 45.6987 37.3254 46.3483 35.7161 46.3483H31.4954C29.8825 46.3483 28.4285 45.702 27.3766 44.7495C26.3396 43.8103 25.5801 42.462 25.5801 40.9765V39.09H28.7776V40.9765C28.7776 41.3615 28.9933 41.8997 29.523 42.3794C30.0379 42.8458 30.7422 43.1508 31.4954 43.1508H35.7161C36.457 43.1508 37.1464 42.8492 37.651 42.3863C38.1696 41.9106 38.386 41.3709 38.386 40.9765V38.5145C38.386 37.9577 37.9239 37.161 36.6799 36.83L29.7094 34.9755C27.4904 34.3851 25.5961 32.6047 25.5961 30.201V27.739C25.5961 24.8621 28.343 22.3672 31.4634 22.3672H35.7321C37.3413 22.3672 38.7862 23.0168 39.8284 23.9729C40.8566 24.916 41.5994 26.2629 41.5994 27.739V29.6255H38.402V27.739C38.402 27.3445 38.1856 26.8048 37.667 26.3291C37.1624 25.8663 36.4729 25.5647 35.7321 25.5647H31.4634Z"
        fill="#007D7B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9746 51.1606V44.7656H35.1721V51.1606H31.9746Z"
        fill="#007D7B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9746 23.9809V17.5859H35.1721V23.9809H31.9746Z"
        fill="#007D7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_37_4029">
        <rect width="71" height="71" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MakePaymentCardIcon;
