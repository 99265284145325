import * as yup from "yup";

import { getPhoneNumberOrNull, validatePhoneNumber } from "helpers";

export const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("validationErrors.phone.phone_required")

    .test(
      "invalid phone",
      "validationErrors.phone.phone_wrong_format",
      (value) => !value || validatePhoneNumber(value),
    )
    .test(
      "required phone",
      "validationErrors.phone.phone_required",
      (value) => !!getPhoneNumberOrNull(value),
    ),
});

export type FormType = yup.InferType<typeof validationSchema>;
