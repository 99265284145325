import { FC } from "react";
import { Trans } from "react-i18next";

import { Container, Paragraph } from "./styles";

interface Props {
  className?: string;
}

const BankingDisclosureComponent: FC<Props> = ({ className }) => {
  const translationPrefix = `components.banking_disclosure`;
  return (
    <Container className={className}>
      <Paragraph>
        <Trans i18nKey={`${translationPrefix}.point_1`} />
      </Paragraph>
      <Paragraph>
        <Trans i18nKey={`${translationPrefix}.point_2`} />
      </Paragraph>
      <Paragraph>
        <Trans i18nKey={`${translationPrefix}.point_3`} />
      </Paragraph>
    </Container>
  );
};

export default BankingDisclosureComponent;
