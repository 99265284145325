import { FC } from "react";
import { Trans } from "react-i18next";

import { PinModalType } from "../../constants";
import { IFrame, StyledUIModal } from "./styles";

interface IProps {
  originTranslationPrefix: string;
  marqetaUrl: string;
  isOpen: boolean;
  onClose: () => void;
  type: PinModalType;
}

export const SetPinModal: FC<IProps> = ({
  originTranslationPrefix,
  isOpen,
  onClose,
  marqetaUrl,
  type,
}) => {
  // reset_pin_modal_component
  const translationPrefix = `${originTranslationPrefix}.pin_modal_component.${type}`;

  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      message={<Trans i18nKey={`${translationPrefix}.message`} />}
    >
      <IFrame src={marqetaUrl} />
    </StyledUIModal>
  );
};
