import { FC } from "react";

import IconProps from "./IconProps";

const Settings: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11075_3415)">
      <path
        d="M10.325 4.51622C10.751 2.76022 13.249 2.76022 13.675 4.51622C13.7389 4.78002 13.8642 5.025 14.0407 5.23122C14.2172 5.43744 14.4399 5.59907 14.6907 5.70296C14.9414 5.80686 15.2132 5.85007 15.4838 5.82909C15.7544 5.80811 16.0162 5.72352 16.248 5.58222C17.791 4.64222 19.558 6.40822 18.618 7.95222C18.4769 8.18387 18.3924 8.44556 18.3715 8.71599C18.3506 8.98643 18.3938 9.25799 18.4975 9.5086C18.6013 9.75921 18.7627 9.9818 18.9687 10.1583C19.1747 10.3347 19.4194 10.4601 19.683 10.5242C21.439 10.9502 21.439 13.4482 19.683 13.8742C19.4192 13.9381 19.1742 14.0634 18.968 14.2399C18.7618 14.4164 18.6001 14.6391 18.4963 14.8899C18.3924 15.1406 18.3491 15.4124 18.3701 15.683C18.3911 15.9536 18.4757 16.2155 18.617 16.4472C19.557 17.9902 17.791 19.7572 16.247 18.8172C16.0153 18.6761 15.7537 18.5917 15.4832 18.5707C15.2128 18.5498 14.9412 18.593 14.6906 18.6967C14.44 18.8005 14.2174 18.962 14.0409 19.1679C13.8645 19.3739 13.7391 19.6187 13.675 19.8822C13.249 21.6382 10.751 21.6382 10.325 19.8822C10.2611 19.6184 10.1358 19.3734 9.95929 19.1672C9.7828 18.961 9.56011 18.7994 9.30935 18.6955C9.05859 18.5916 8.78683 18.5484 8.51621 18.5693C8.24559 18.5903 7.98375 18.6749 7.752 18.8162C6.209 19.7562 4.442 17.9902 5.382 16.4462C5.5231 16.2146 5.60755 15.9529 5.62848 15.6824C5.64942 15.412 5.60624 15.1404 5.50247 14.8898C5.3987 14.6392 5.23726 14.4166 5.03127 14.2402C4.82529 14.0637 4.58056 13.9383 4.317 13.8742C2.561 13.4482 2.561 10.9502 4.317 10.5242C4.5808 10.4603 4.82578 10.335 5.032 10.1585C5.23822 9.98202 5.39985 9.75933 5.50375 9.50857C5.60764 9.2578 5.65085 8.98605 5.62987 8.71543C5.60889 8.44481 5.5243 8.18297 5.383 7.95122C4.443 6.40822 6.209 4.64122 7.753 5.58122C8.753 6.18922 10.049 5.65122 10.325 4.51622Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12.1992C9 12.9949 9.31607 13.7579 9.87868 14.3205C10.4413 14.8831 11.2044 15.1992 12 15.1992C12.7956 15.1992 13.5587 14.8831 14.1213 14.3205C14.6839 13.7579 15 12.9949 15 12.1992C15 11.4036 14.6839 10.6405 14.1213 10.0779C13.5587 9.51529 12.7956 9.19922 12 9.19922C11.2044 9.19922 10.4413 9.51529 9.87868 10.0779C9.31607 10.6405 9 11.4036 9 12.1992Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11075_3415">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199219)" />
      </clipPath>
    </defs>
  </svg>
);

export default Settings;
