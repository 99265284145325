import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MessageIcon, PhoneFilled } from "assets/svg";
import { FormikContextType } from "formik";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { MFAFactorType } from "types/BETypes";
import { HELP_EMAIL } from "constants/shared";
import { getHiddenNumber, showErrorModal } from "helpers";
import VerifyCode from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode";
import {
  SPCIcon,
  SPCText,
} from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/components/DefaultForm/styles";
import { IVerifyCodeStepProps } from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/types";
import { FormType as VerifyCodeFormType } from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/validationSchema";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { EWidgetType } from "components/MultiFactorAuthorization/types";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

import { SecondaryButton } from "uikit";
import { EButtonsAlign, EButtonsFlex, EModalTypes } from "uikit/Modal";

import { MfaFactorDto, mutationAuthControllerDeleteMfaFactor } from "utils/swagger_react_query";

import {
  CardAdditionContent,
  SmsCardDescriptionContainer,
  StyledMfaAuthenticationStatus,
  StyledPrimaryButton,
  StyledSelectedPhoneContainer,
} from "./styles";

export const useEmployeeSettingsAuthenticationPage = () => {
  const translationPrefix = `settings_pages.employee.two_factor_authentication_page`;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const [isAddSMSFactorModalOpen, setAddSMSFactorModalOpen] = useState<boolean>(false);
  const [isRemoveSMSFactorModalOpen, setRemoveSMSFactorModalOpen] = useState<boolean>(false);
  const {
    factorsData,
    isDataLoading,
    currentFactorChallenge,
    errorsPrefix: mfaErrorsPrefix,
    selectFactor,
    fetchData,
    resendVerificationCode,
    createFactorChallenge,
  } = useMFASMS({
    widgetType: EWidgetType.MISC,
  });

  const smsFactor = factorsData?.find((factor) => factor?.type === MFAFactorType.SMS);
  const hiddenPhoneNumber = getHiddenNumber(smsFactor?.phoneNumber || "", 4);

  const removeMFAFactor = async (
    factor: MfaFactorDto,
    formikContext: FormikContextType<VerifyCodeFormType>,
  ) => {
    if (!factor?.factorId) return;

    const { values, setFieldError, validateForm } = formikContext;

    const _translationPrefix = `components.multi_factor_authorization.errors`;

    validateForm(values).then(async (errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (hasErrors) return;

      try {
        setLoading(true);

        try {
          await mutationAuthControllerDeleteMfaFactor({ factorId: factor.factorId })([
            {
              factorId: factor.factorId,
            },
            {
              challengeId: currentFactorChallenge?.challengeId,
              code: values.code,
            },
          ]);
        } catch (error) {
          setFieldError(
            "code",
            t(`${mfaErrorsPrefix}.${(error as any).data.error}`, { helpEmail: HELP_EMAIL }),
          );
          setLoading(false);
          return;
        }

        setRemoveSMSFactorModalOpen(false);
        await fetchData();
      } catch (error) {
        showErrorModal(`${_translationPrefix}.${(error as any)?.error}`);
      } finally {
        setLoading(false);
      }
    });
  };

  const handleRemoveFactorClick = async (factor: MfaFactorDto) => {
    if (factor.type === MFAFactorType.SMS) {
      try {
        setLoading(true);
        selectFactor(factor);
        await createFactorChallenge(smsFactor?.factorId || "");
        setRemoveSMSFactorModalOpen(true);
      } catch (error) {
        showErrorModal(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderRemoveSMSFactorModalContent = () => {
    const _translationPrefix = `components.multi_factor_authorization.common.verification_modal`;

    const config: IVerifyCodeStepProps = {
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      description: (
        <Trans i18nKey={`${_translationPrefix}.message`} values={{ phone: hiddenPhoneNumber }} />
      ),
      mainButtonText: <Trans i18nKey={`${_translationPrefix}.confirm_button`} />,
      showButtons: true,
      selectedMfaFactor: smsFactor,
      showPhoneNumber: false,
      onCancel: () => {
        setRemoveSMSFactorModalOpen(false);
      },
      onSubmit: (formikContext) => {
        if (smsFactor) {
          removeMFAFactor(smsFactor, formikContext);
        }
      },
      onResend: async (callback: () => void) => {
        setLoading(true);
        await resendVerificationCode(callback);
        setLoading(false);
      },
    };
    return <VerifyCode {...config} />;
  };

  const showAddFactorSuccessModal = (callback?: () => void) => {
    const _translationPrefix = `components.multi_factor_authorization.common.add_factor_sucess_modal`;

    AppEvents.emit("SetGlobalModal", {
      className: "remove-mfa-factor-modal",
      isOpen: true,
      type: EModalTypes.SUCCESS,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.done`} />,
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      buttonsAlign: EButtonsAlign.CENTER,
      onClose: callback,
    });
  };

  const handleActivateFactorClick = (factorType: MFAFactorType) => {
    if (factorType === MFAFactorType.SMS) {
      setAddSMSFactorModalOpen(true);
    }
  };

  const renderSmsTextMessageCardAdditionalContent = () => {
    return (
      <CardAdditionContent>
        <StyledMfaAuthenticationStatus isVerified={!!smsFactor?.isVerified} />
        {smsFactor?.isVerified ? (
          <SecondaryButton
            onClick={() => handleRemoveFactorClick(smsFactor)}
            data-testid="remove-sms-factor-button"
          >
            <Trans i18nKey={`buttons.remove`} />
          </SecondaryButton>
        ) : (
          <StyledPrimaryButton
            onClick={() => handleActivateFactorClick(MFAFactorType.SMS)}
            data-testid="activate-sms-factor-button"
          >
            <Trans i18nKey={`buttons.activate`} />
          </StyledPrimaryButton>
        )}
      </CardAdditionContent>
    );
  };

  const renderSmsTextCardDescription = () => {
    return (
      <SmsCardDescriptionContainer>
        <Trans i18nKey={`${translationPrefix}.cards_list.sms_text_message.description`} />

        {smsFactor && (
          <StyledSelectedPhoneContainer>
            <SPCIcon>
              <PhoneFilled />
            </SPCIcon>
            <SPCText>{hiddenPhoneNumber}</SPCText>
          </StyledSelectedPhoneContainer>
        )}
      </SmsCardDescriptionContainer>
    );
  };

  const cardsList: NavigationCardProps[] = [
    {
      id: "sms_text_message",
      icon: <MessageIcon />,
      title: <Trans i18nKey={`${translationPrefix}.cards_list.sms_text_message.title`} />,
      description: renderSmsTextCardDescription(),
      button: renderSmsTextMessageCardAdditionalContent(),
    },
  ];

  const handleAddSMSModalEnterPhoneCancel = () => {
    setAddSMSFactorModalOpen(false);
  };

  const handleAddSMSModalVerifyCodeCancel = () => {
    setAddSMSFactorModalOpen(false);
  };

  const handleAddSMSModalSubmitSuccess = () => {
    setAddSMSFactorModalOpen(false);
    showAddFactorSuccessModal(() => {
      fetchData();
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
      isDataLoading,
      translationPrefix,
      isAddSMSFactorModalOpen,
      isRemoveSMSFactorModalOpen,
    },
    pageData: {
      cardsList,
    },
    actions: {
      handleAddSMSModalEnterPhoneCancel,
      handleAddSMSModalVerifyCodeCancel,
      handleAddSMSModalSubmitSuccess,
    },
    render: {
      renderRemoveSMSFactorModalContent,
    },
  };
};

export default useEmployeeSettingsAuthenticationPage;
