export const peoplePage = {
  "en-US": {
    admin: {
      index: {},
    },
    employee: {
      index: {
        manage_bank_accounts_button: "Manage Bank Accounts",
      },
    },
  },
};

export default peoplePage;
