export const translations = {
  "en-US": {
    edge_markers: {
      date: {
        start: "Start Date",
        end: "End Date",
      },
      number: {
        start: "Start",
        end: "End",
      },
    },
  },
};

export default translations;
