import { CardReissueReason } from "types/CardShipping";
import SelectOption from "types/SelectOption";

export const ReissuePhysicalCardReasonOptions: SelectOption<CardReissueReason>[] = [
  {
    value: CardReissueReason.LOST,
    label: `card_reissue_reasons.${CardReissueReason.LOST}`,
  },
  {
    value: CardReissueReason.STOLEN,
    label: `card_reissue_reasons.${CardReissueReason.STOLEN}`,
  },
  {
    value: CardReissueReason.EXPIRATION,
    label: `card_reissue_reasons.${CardReissueReason.EXPIRATION}`,
  },
];

export const ReissueVirtualCardReasonOptions: SelectOption<CardReissueReason>[] = [
  {
    value: CardReissueReason.EXPIRATION,
    label: `card_reissue_reasons.${CardReissueReason.EXPIRATION}`,
  },
];
