import { FC, ReactNode } from "react";

import { EModalTypes } from "uikit/Modal/types";

import { TitleContainer } from "./styles";

interface Props {
  className?: string;
  type?: EModalTypes;
  children?: ReactNode;
}

const ModalStatusTitle: FC<Props> = ({ className, type = EModalTypes.SUCCESS, children }) => {
  const _className = `${className} ${type}`;

  return <TitleContainer className={_className}>{children}</TitleContainer>;
};

export default ModalStatusTitle;
