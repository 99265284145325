import { FC } from "react";
import { Formik, FormikProps } from "formik";

import DashboardLayout from "layouts/DashboardLayout";

import { FullScreenLoader } from "uikit";

import { AdminProfileForm, AdminProfileFormType, AdminProfileValidationSchema } from "./components";
import { useAdminProfilePage } from "./useAdminProfilePage";
import { Container, FormContainer } from "./styles";

interface IProps {}

const AdminProfilePage: FC<IProps> = () => {
  const { metadata, actions } = useAdminProfilePage();
  const { translationPrefix } = metadata;

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <Container>
        <Formik<AdminProfileFormType>
          initialValues={metadata.initialValues}
          onSubmit={(values, { resetForm }) => actions.handleSubmit(values, resetForm)}
          validationSchema={AdminProfileValidationSchema}
          validateOnChange={metadata.svoc}
        >
          {(formikProps: FormikProps<AdminProfileFormType>) => (
            <FormContainer onSubmit={formikProps.handleSubmit}>
              <AdminProfileForm
                translationPrefix={translationPrefix}
                formikProps={formikProps}
                onTouch={metadata.setCanLeave}
              />
            </FormContainer>
          )}
        </Formik>
      </Container>

      {/* <RouterPrompt //TODO: Uncomment when you start working with PAIDSW-247 
        when={!metadata.canLeave}
        defaultPromptModalActions={{
          onMainButtonClick: () => metadata.setCanLeave(true),
          onSecondaryButtonClick: () => metadata.setCanLeave(false),
          onClose: () => metadata.setCanLeave(false),
        }}
      ></RouterPrompt> */}
    </DashboardLayout>
  );
};

export default AdminProfilePage;
