import { Trans } from "react-i18next";

import { AppEvents } from "services/events";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

export const showNoMFAModal = (callback?: () => void) => {
  const translationPrefix = `banking_pages.shared.transactions_mfa_modal`;
  AppEvents.emit("SetGlobalModal", {
    isOpen: true,
    type: EModalTypes.WARNING,
    title: <Trans i18nKey={`${translationPrefix}.title`} />,
    message: <Trans i18nKey={`${translationPrefix}.message`} />,
    mainButton: {
      text: <Trans i18nKey={`${translationPrefix}.enable_btn`} />,
      onClick: callback,
      autoWidth: true,
    },
    secondaryButton: {
      text: <Trans i18nKey={`common.modal.cancel`} />,
      autoWidth: true,
    },
    buttonsFlex: EButtonsFlex.ROW_REVERSE,
  });
};
