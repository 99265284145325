import { FC } from "react";

import IconProps from "./IconProps";

const Calendar: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1829_862)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 2.86719C0.25 2.45297 0.585786 2.11719 1 2.11719H15C15.4142 2.11719 15.75 2.45297 15.75 2.86719V15.0005C15.75 15.4147 15.4142 15.7505 15 15.7505H1C0.585786 15.7505 0.25 15.4147 0.25 15.0005V2.86719ZM1.75 3.61719V14.2505H14.25V3.61719H1.75Z"
        fill="#0F1826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 6.59961C0.25 6.1854 0.585786 5.84961 1 5.84961H15C15.4142 5.84961 15.75 6.1854 15.75 6.59961C15.75 7.01382 15.4142 7.34961 15 7.34961H1C0.585786 7.34961 0.25 7.01382 0.25 6.59961Z"
        fill="#0F1826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73438 0.25C5.14859 0.25 5.48438 0.585786 5.48438 1V4.26667C5.48438 4.68088 5.14859 5.01667 4.73438 5.01667C4.32016 5.01667 3.98438 4.68088 3.98438 4.26667V1C3.98438 0.585786 4.32016 0.25 4.73438 0.25Z"
        fill="#0F1826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2656 0.25C11.6798 0.25 12.0156 0.585786 12.0156 1V4.26667C12.0156 4.68088 11.6798 5.01667 11.2656 5.01667C10.8514 5.01667 10.5156 4.68088 10.5156 4.26667V1C10.5156 0.585786 10.8514 0.25 11.2656 0.25Z"
        fill="#0F1826"
      />
    </g>
    <defs>
      <clipPath id="clip0_1829_862">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Calendar;
