import { FC } from "react";

import IconProps from "./IconProps";

const PlusThin: FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.8334 2.49984C10.8334 2.0396 10.4603 1.6665 10 1.6665C9.53978 1.6665 9.16669 2.0396 9.16669 2.49984V9.1665H2.50002C2.03978 9.1665 1.66669 9.5396 1.66669 9.99984C1.66669 10.4601 2.03978 10.8332 2.50002 10.8332H9.16669V17.4998C9.16669 17.9601 9.53978 18.3332 10 18.3332C10.4603 18.3332 10.8334 17.9601 10.8334 17.4998V10.8332H17.5C17.9603 10.8332 18.3334 10.4601 18.3334 9.99984C18.3334 9.5396 17.9603 9.1665 17.5 9.1665H10.8334V2.49984Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default PlusThin;
