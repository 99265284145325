export const bankAccountSectionTranslation = {
  "en-US": {
    connect_btn: "Connect your Bank",
    add_btn: "Add Account",
    remove_account_btn: "Remove",
    empty_state: {
      message:
        "<1>You have no Active Bank Accounts</1><2>Would you like to connect your bank account?</2>",
    },
  },
};

export default bankAccountSectionTranslation;
