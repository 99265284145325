import { ReactSwitchProps } from "react-switch";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const SwitchContent = styled.div``;

export const SwitchContainer = styled.div<Partial<ReactSwitchProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.switchText};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.caption};
`;
