import styled from "styled-components";

import { LinkTextMixin } from "uikit";

export const Container = styled.div`
  text-align: initial;
`;

export const SelectedPhoneContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 1.3;
`;

export const SPCIcon = styled.div`
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SPCLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const SPCText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EditPhoneButton = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ResendCodeContainer = styled.div`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.3;
`;

export const ResendCodeLink = styled.span`
  ${LinkTextMixin}
`;

export const ResendCodeCountDown = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
