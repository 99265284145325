import { FC } from "react";

import IconProps from "./IconProps";

const Dashboard: FC<IconProps> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.88922 9.88406C3.25745 9.88406 3.55596 10.1826 3.55596 10.5508V15.9959H6.66745V12.2177C6.66745 11.7448 6.85591 11.2949 7.18514 10.9622C7.52114 10.6228 7.97751 10.4397 8.44543 10.4397H9.55668C10.0295 10.4397 10.4795 10.6281 10.8121 10.9574C11.1516 11.2934 11.3347 11.7498 11.3347 12.2177V15.9959H14.4461V10.5508C14.4461 10.1826 14.7447 9.88406 15.1129 9.88406C15.4811 9.88406 15.7796 10.1826 15.7796 10.5508V16.6626C15.7796 17.0309 15.4811 17.3294 15.1129 17.3294H10.6679C10.2997 17.3294 10.0012 17.0309 10.0012 16.6626V12.2177C10.0012 12.0958 9.95345 11.9837 9.87406 11.9051C9.78784 11.8198 9.67365 11.7732 9.55668 11.7732H8.44543C8.32354 11.7732 8.21147 11.8209 8.13289 11.9003C8.04755 11.9865 8.00094 12.1007 8.00094 12.2177V16.6626C8.00094 17.0309 7.70242 17.3294 7.33419 17.3294H2.88922C2.52099 17.3294 2.22247 17.0309 2.22247 16.6626V10.5508C2.22247 10.1826 2.52099 9.88406 2.88922 9.88406Z"
      fill="#007D7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00107 2.23358C8.94648 2.23358 8.88738 2.24502 8.83016 2.26863C8.77809 2.29012 8.72762 2.32375 8.68611 2.36525L1.13814 9.91109C0.877722 10.1714 0.455562 10.1714 0.195219 9.91095C-0.065125 9.65054 -0.0650652 9.22838 0.195352 8.96803L7.74319 1.42233C7.74321 1.42231 7.74317 1.42235 7.74319 1.42233C7.90682 1.25872 8.10428 1.12561 8.32145 1.03599C8.53349 0.94848 8.76503 0.900085 9.00107 0.900085C9.22925 0.900085 9.46307 0.943375 9.68478 1.03768C9.89951 1.12693 10.0936 1.25695 10.2589 1.42233L17.8069 8.97024C18.0672 9.23062 18.0672 9.65278 17.8069 9.91316C17.5465 10.1735 17.1243 10.1735 16.8639 9.91316L9.31603 2.36525C9.27303 2.32226 9.22488 2.29047 9.17198 2.26863L9.16369 2.26515C9.11738 2.2453 9.06207 2.23358 9.00107 2.23358Z"
      fill="#007D7B"
    />
  </svg>
);

export default Dashboard;
