import { FC } from "react";
import {
  ModalAlert,
  ModalCard,
  ModalDelete,
  ModalPending,
  ModalRefresh,
  ModalShare,
  ModalSuccess,
  ModalSwitch,
  ModalUser,
} from "assets/svg";

import { EModalTypes } from "uikit/Modal/types";

import {
  IconContainer,
  IconContainerInner,
  IconContainerMiddle,
  IconContainerOuter,
} from "./styles";

interface Props {
  className?: string;
  type?: EModalTypes;
}

const ModalStatusIcon: FC<Props> = ({ className, type }) => {
  const _className = `${className} ${type}`;

  const getIcon = () => {
    switch (type) {
      case EModalTypes.SUCCESS:
        return <ModalSuccess />;
      case EModalTypes.WARNING:
        return <ModalAlert />;
      case EModalTypes.CONFIRM:
        return <ModalSuccess />;
      case EModalTypes.CARD:
        return <ModalCard />;
      case EModalTypes.ERROR:
        return <ModalAlert />;
      case EModalTypes.DELETE:
        return <ModalDelete />;
      case EModalTypes.REFRESH:
        return <ModalRefresh />;
      case EModalTypes.SHARE:
        return <ModalShare />;
      case EModalTypes.SWITCH:
        return <ModalSwitch />;
      case EModalTypes.PENDING:
        return <ModalPending />;
      case EModalTypes.USER:
        return <ModalUser />;
      default:
        return <ModalSuccess />;
    }
  };

  return (
    <IconContainer className={className}>
      <IconContainerOuter className={_className}>
        <IconContainerMiddle className={_className}>
          <IconContainerInner className={_className} data-testId={`main-modal-icon-${type}`}>
            {getIcon()}
          </IconContainerInner>
        </IconContainerMiddle>
      </IconContainerOuter>
    </IconContainer>
  );
};

export default ModalStatusIcon;
