export const nextMilestoneWidgetTranslations = {
  "en-US": {
    congratulations_state: {
      title: "Congratulations!",
      text: "You just hit a bonus milestone today.",
      text_completed: "You earned your bonus",
      view_btn: "View",
    },
    upcoming_state: {
      title: "Upcoming...",
      subTitle: "Just {{daysCount}} <1/>",
      text: "Until your next milestone!",
      view_btn: "View Details",
    },
  },
};

export default nextMilestoneWidgetTranslations;
