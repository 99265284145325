import styled from "styled-components";

import { TextOverflowMixin } from "uikit";
import { Title } from "uikit/TextWithTooltip/styles";

export const Container = styled.div`
  max-width: 1280px;
  margin: 40px auto;
  padding: 32px 8px;
  background-color: ${({ theme }) => theme.colors.contentBg};
`;

export const TitleContainer = styled.div`
  text-align: center;

  ${Title} {
    margin-bottom: 30px;
  }
`;

export const IconCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const IconCard = styled.div`
  width: 155px;
  border: 2px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: 6px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: rgba(52, 235, 213, 0.1);
`;

export const ICText = styled.div`
  min-height: 50px;
  line-height: 50px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textEmphasized};
  text-align: center;
  padding: 0 10px;
  ${TextOverflowMixin}
`;

export const ICIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};

  svg {
    width: auto;
    height: 80px;
  }
`;
