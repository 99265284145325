import * as yup from "yup";

export const ReissueCardReasonStepFormValidationSchema = yup.object({
  reason: yup
    .object({
      label: yup.string().required("validationErrors.common.is_required"),
      value: yup.string().required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
});

export type ReissueCardReasonStepFormType = yup.InferType<
  typeof ReissueCardReasonStepFormValidationSchema
>;
