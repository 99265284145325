import { BonusType } from "types/BETypes";

export const bonusTypeSelectModalTranslations = {
  "en-US": {
    title: "Choose Bonus Type",
    cards: {
      [BonusType.TIME]: {
        title: "Time Vesting Bonus",
        text: "Reward employees with a bonus that vests over time.",
      },
      [BonusType.UNIT]: {
        title: "Unit Vesting Bonus",
        text: "Reward employees with a bonus that vests over units of completed work milestones.",
      },
      [BonusType.CHALLENGE]: {
        title: "Performance Challenge",
        text: "Offer employees compensation for completing challenges or hitting KPIs.",
      },
    },
  },
};

export default bonusTypeSelectModalTranslations;
