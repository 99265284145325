import { Trans } from "react-i18next";
import { FormikContextType } from "formik";
import { t } from "i18next";
import { get } from "lodash";

export const getFieldErrorBase = (fieldName?: string, errors?: object) => {
  if (!errors || !fieldName) return false;
  return get(errors, fieldName);
};

export const getFieldErrorSpecific = (
  fieldName?: string,
  errors?: object,
  specificFields?: object,
) => {
  if (!errors || !fieldName) return false;
  return get(specificFields, fieldName) ? get(errors, fieldName) : undefined;
};

export const getFieldErrorForBlurred = (
  fieldName?: string,
  formikContext?: FormikContextType<any>,
  params?: object,
) => {
  if (!formikContext || !fieldName) return false;
  const { errors, touched, validateOnBlur } = formikContext;
  const fieldError = validateOnBlur
    ? getFieldErrorSpecific(fieldName, errors, touched)
    : getFieldErrorBase(fieldName, errors);
  return fieldError ? <Trans i18nKey={fieldError} values={params} /> : undefined;
};

export const getFieldError = (
  fieldName?: string,
  formikContext?: FormikContextType<any>,
  params?: object,
) => {
  if (!formikContext || !fieldName) return false;
  const { errors } = formikContext;
  const fieldError = getFieldErrorBase(fieldName, errors);
  return fieldError ? <Trans i18nKey={fieldError} values={params} /> : undefined;
};

export const getFieldPlaceholderBase = (fieldName: string) => {
  const placeholder = t(`common.input.placeholder_base`, { field: t(fieldName) });

  return placeholder;
};
