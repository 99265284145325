import { FC } from "react";

import IconProps from "./IconProps";

const QuestionmarkCircleIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="39.5"
      cy="39.5"
      r="38.5"
      fill="white"
      stroke="url(#paint0_linear_1_218)"
      strokeWidth="2"
    />
    <g clipPath="url(#clip0_1_218)">
      <rect width="28" height="47" transform="translate(26 16)" fill="url(#paint1_linear_1_218)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.031 19.5888C51.2865 20.7203 52.2613 22.0662 52.9567 23.6276L52.9573 23.6264C53.6527 25.1879 54.0001 26.8897 54.0001 28.7312C54.0001 30.8538 53.5367 32.7852 52.6099 34.5271C51.7411 36.289 50.0995 38.2009 47.6858 40.2628C46.643 41.1635 45.8029 41.9597 45.1655 42.6503C44.5287 43.3408 44.1034 43.9367 43.8913 44.4371C43.6312 45.011 43.4472 45.9931 43.3394 47.3833C43.183 49.3985 41.5632 50.9533 39.6135 50.9533C37.4335 50.9533 35.7105 49.0262 35.8869 46.7736C35.9332 46.1778 35.9894 45.619 36.0556 45.0973C36.2583 43.4963 36.5811 42.2252 37.0258 41.2838C37.8946 39.4423 39.4682 37.5407 41.7471 35.5784C42.7319 34.7178 43.5088 33.9823 44.0788 33.3713C44.6482 32.7609 45.0777 32.2204 45.3676 31.7497C45.6576 31.2796 45.8357 30.8393 45.9037 30.4287C45.9711 30.0187 46.0051 29.5729 46.0051 29.0925C46.0051 28.1918 45.8504 27.3659 45.5416 26.6152C45.2323 25.8639 44.8029 25.2086 44.2528 24.648C43.7027 24.0874 43.0459 23.6519 42.2832 23.3416C41.5204 23.0318 40.6756 22.8764 39.7488 22.8764C36.7832 22.8764 34.7673 24.6146 33.7017 28.0904C33.209 29.6975 31.7502 30.7743 30.1233 30.7743H29.7425C27.1214 30.7743 25.3129 28.0497 26.2509 25.5128C26.3598 25.2195 26.4752 24.9316 26.5983 24.6486C27.3897 22.8272 28.4232 21.2754 29.6974 19.9939C30.9716 18.713 32.4532 17.7267 34.1434 17.0361C35.833 16.3456 37.6813 16 39.6902 16C41.8145 16 43.7554 16.3152 45.5124 16.9462C47.2693 17.5767 48.7755 18.4579 50.031 19.5888ZM39.5866 54.0086H39.7073C42.0689 54.0086 43.9834 56.0213 43.9834 58.5041C43.9834 60.987 42.0689 62.9997 39.7073 62.9997H39.5866C37.2251 62.9997 35.3105 60.987 35.3105 58.5041C35.3105 56.0213 37.2251 54.0086 39.5866 54.0086Z"
        fill="url(#paint2_linear_1_218)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_218"
        x1="39.5"
        y1="0"
        x2="39.5"
        y2="79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC808" />
        <stop offset="1" stopColor="#F29128" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_218"
        x1="14"
        y1="0"
        x2="14"
        y2="47"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_218"
        x1="40.0001"
        y1="16"
        x2="40.0001"
        y2="62.9997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC808" />
        <stop offset="1" stopColor="#F29128" />
      </linearGradient>
      <clipPath id="clip0_1_218">
        <rect width="28" height="47" fill="white" transform="translate(26 16)" />
      </clipPath>
    </defs>
  </svg>
);

export default QuestionmarkCircleIcon;
