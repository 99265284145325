import { FC } from "react";

import { Loader } from "uikit";

import { IProps } from "./types";
import useBankAccountsStep from "./useBankAccountsStep";
import { Container } from "./styles";

const BankAccountsStep: FC<IProps> = (props) => {
  const { className } = props;
  const { isLoading, renderBankSections, renderCreateCompanyBankAccountWidget } =
    useBankAccountsStep(props);

  const renderContent = () => {
    return (
      <>
        {renderCreateCompanyBankAccountWidget()}
        {renderBankSections()}
      </>
    );
  };

  return (
    <Container className={className}>{isLoading ? <Loader /> : <>{renderContent()}</>}</Container>
  );
};

export default BankAccountsStep;
