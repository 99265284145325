import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textEmphasized};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  cursor: pointer;
  caret-color: transparent;
`;

export const CheckboxInput = styled.input`
  display: none;
`;
export const Text = styled.span``;

export const Checkmark = styled.span`
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  transition: all 50ms linear;
  padding: 2px;

  border-radius: 6px;

  ${CheckboxInput}:not(:checked) ~ & {
    border: 1px solid ${({ theme }) => theme.colors.checkboxInactiveBorder};
    background-color: ${({ theme }) => theme.colors.checkboxInactiveBg};
    svg {
      display: none;
    }
  }

  ${CheckboxInput}:checked ~ & {
    border: 1px solid ${({ theme }) => theme.colors.checkboxActiveBorder};
    background-color: ${({ theme }) => theme.colors.checkboxActiveBg};
  }

  ${CheckboxInput}:disabled ~ & {
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
    border: 1px solid ${({ theme }) => theme.colors.primaryDisabled};
  }
`;
