import styled from "styled-components";

import { breakpoint } from "helpers";

export const Button = styled.span`
  box-shadow: 0px 1px 5px 0px ${({ theme }) => theme.colors.contentBoxShadow};
  background-color: ${({ theme }) => theme.colors.contentBg};
  max-width: 480px;
  width: 100%;
  height: 56px;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${breakpoint("xs", "md")`
    height: 48px;
    
  `}
`;

export const Text = styled.span`
  margin-left: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};
`;
