import { FC } from "react";
import { Trans } from "react-i18next";

import { getMfaStatus, getMfaStatusTranslation } from "helpers/mfa/mfaStatus";

import { Container, StatusContainer } from "./styles";

interface IProps {
  isVerified: boolean;
  isDefault?: boolean;
  className?: string;
}

const MfaAuthenticationStatus: FC<IProps> = ({ isVerified, isDefault, className }) => {
  const translationPrefix = `components.mfa_status`;
  const serializedStatus = getMfaStatus(isVerified);

  return (
    <Container className={className}>
      <StatusContainer className={`${serializedStatus}`}>
        {getMfaStatusTranslation(isVerified)}
      </StatusContainer>

      {isDefault && (
        <StatusContainer className={`${isDefault ? "default" : ""}`}>
          <Trans i18nKey={`${translationPrefix}.default_label`} />
        </StatusContainer>
      )}
    </Container>
  );
};

export default MfaAuthenticationStatus;
