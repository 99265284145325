import React, { FC } from "react";

import IconProps from "./IconProps";

const CloseRoundIconDanger: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8.5" r="8.5" fill="#E20000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.94234 8.49967L12.3463 6.09576L10.9038 4.65326L8.49985 7.05717L6.09609 4.65342L4.6536 6.09592L7.05735 8.49967L4.65293 10.9041L6.09543 12.3466L8.49985 9.94217L10.9044 12.3467L12.3469 10.9042L9.94234 8.49967Z"
      fill="white"
    />
  </svg>
);

export default CloseRoundIconDanger;
