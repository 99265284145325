import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import CsvTableIcon from "assets/svg/CsvTableIcon.svg";

import { PrimaryButton } from "uikit";

import { ImportEmployeeFromCSVOutput } from "utils/swagger_react_query";

import { Content } from "./styles";

interface IProps {
  importEmployeeResult: ImportEmployeeFromCSVOutput;
  onDownloadErrorsFile: () => void;
  onClose: () => void;
}

export const CongratsContent: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const translationPrefix = "people_page.index.csv_import_modal.congrats";

  return (
    <Content>
      <img src={CsvTableIcon} />
      <div>
        <p data-testid="num-success-workers">
          {props?.importEmployeeResult?.total - props.importEmployeeResult.failedTotal}
        </p>
        <p>{t(`${translationPrefix}.addedSuccessfully`)}</p>
      </div>

      <div>
        {props.importEmployeeResult.failedTotal !== 0 && (
          <>
            <p>
              <span data-testid="num-failed-workers">
                {props?.importEmployeeResult?.failedTotal}{" "}
              </span>
              <Trans i18nKey={`${translationPrefix}.failed`} components={{ 1: <span /> }} />
            </p>
            <p>
              <Trans
                i18nKey={`${translationPrefix}.downloadErrorFile`}
                components={{ 1: <span onClick={() => props.onDownloadErrorsFile()} /> }}
              />
            </p>
          </>
        )}
      </div>
      <PrimaryButton onClick={() => props.onClose()}>
        {t(`${translationPrefix}.okBtn`)}
      </PrimaryButton>
    </Content>
  );
};
