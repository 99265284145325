// employee_selection_table

export const employeeSelectionTableTranslations = {
  "en-US": {
    search_placeholder: "Search by Name, Role or Department",
    columns: {
      employee: "Employee",
      department: "Department",
      added: "Added",
    },
    select_all: "Select All",
    add_btn: "Add",
    remove_bth: "Remove",

    employees_list_empty_state_message: "Add members to begin building your group.",
    added_list_empty_state_message: "There are no employees available to be added",
  },
};

export default employeeSelectionTableTranslations;
