import { FC } from "react";

import IconProps from "./IconProps";

const DollarRoundFilled: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="11" r="9" fill="#16A767" />
    <path
      d="M9.056 15.144C8.488 15.144 7.96 15.04 7.472 14.832C6.992 14.624 6.576 14.336 6.224 13.968C5.88 13.592 5.628 13.156 5.468 12.66L6.824 12.072C7.04 12.584 7.352 12.988 7.76 13.284C8.168 13.572 8.628 13.716 9.14 13.716C9.428 13.716 9.676 13.672 9.884 13.584C10.1 13.488 10.264 13.36 10.376 13.2C10.496 13.04 10.556 12.848 10.556 12.624C10.556 12.36 10.476 12.144 10.316 11.976C10.164 11.8 9.932 11.664 9.62 11.568L7.904 11.016C7.208 10.8 6.684 10.476 6.332 10.044C5.98 9.612 5.804 9.104 5.804 8.52C5.804 8.008 5.928 7.556 6.176 7.164C6.432 6.772 6.784 6.468 7.232 6.252C7.688 6.028 8.208 5.916 8.792 5.916C9.328 5.916 9.82 6.012 10.268 6.204C10.716 6.388 11.1 6.648 11.42 6.984C11.748 7.312 11.992 7.7 12.152 8.148L10.808 8.748C10.632 8.3 10.368 7.956 10.016 7.716C9.664 7.468 9.256 7.344 8.792 7.344C8.52 7.344 8.28 7.392 8.072 7.488C7.864 7.576 7.7 7.704 7.58 7.872C7.468 8.032 7.412 8.224 7.412 8.448C7.412 8.696 7.492 8.916 7.652 9.108C7.812 9.292 8.056 9.436 8.384 9.54L10.028 10.056C10.74 10.288 11.272 10.608 11.624 11.016C11.984 11.424 12.164 11.928 12.164 12.528C12.164 13.04 12.032 13.492 11.768 13.884C11.504 14.276 11.14 14.584 10.676 14.808C10.212 15.032 9.672 15.144 9.056 15.144ZM8.48 16.2V4.86H9.5V16.2H8.48Z"
      fill="white"
    />
  </svg>
);

export default DollarRoundFilled;
