import React, { FC, ReactNode, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { IContextMenuOption } from "./types";
import { Dot, DotsBtn, MenuList, MenuListContainer, MenuListItem, Wrapper } from "./styles";

interface IProps {
  options: Array<IContextMenuOption | undefined>;
  /**
   * Wrapper class name
   */
  className?: string;
  component?: ReactNode;
  dataTestId?: string;
}

export const ContextMenu: FC<IProps> = ({ options, className, component, dataTestId }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <Wrapper className={className} data-testid={dataTestId}>
        {component ? (
          <span onClick={() => setOpen((currentState) => !currentState)}>{component}</span>
        ) : (
          <DotsBtn onClick={() => setOpen((currentState) => !currentState)}>
            <Dot />
            <Dot />
            <Dot />
          </DotsBtn>
        )}

        {!!options.length && isOpen && (
          <MenuListContainer>
            <MenuList>
              {options.map((item) => {
                if (!item || item.hide) return;
                return (
                  <MenuListItem
                    className={`${item.disabled ? "disabled" : ""}`}
                    key={item.value}
                    onClick={() => {
                      if (!item.disabled) {
                        item.onClick();
                        setOpen(false);
                      }
                    }}
                  >
                    {item.label}
                  </MenuListItem>
                );
              })}
            </MenuList>
          </MenuListContainer>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};
