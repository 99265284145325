import styled from "styled-components";

import { Checkbox, PrimaryButton } from "uikit";

export const Container = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AddBankAccountBtn = styled(PrimaryButton)`
  width: 177px;
`;

export const SubmitButton = styled(PrimaryButton)`
  min-width: 177px;
`;

export const AgreementBlock = styled.div`
  box-sizing: border-box;
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

export const AgreementBlockTitle = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.47;
`;

export const AgreementBlockMessage = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.47;
`;

export const AgreementCheckbox = styled(Checkbox)`
  align-items: flex-start;
`;
