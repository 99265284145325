import { FC } from "react";
import { APP_ENVIRONMENT } from "index";

import { Environment } from "constants/systemConstants";

import { TestingContentContainer, TestingContentTitle } from "./styles";

interface IProps {
  allowedEnvs: Environment[];
  children: React.ReactNode;
}

const TestingContainer: FC<IProps> = ({ children, allowedEnvs }) => {
  const isRenderAllowedForEnv = allowedEnvs.includes(APP_ENVIRONMENT);

  if (!isRenderAllowedForEnv) return;

  return (
    <TestingContentContainer>
      <TestingContentTitle>Testing Only Content</TestingContentTitle>
      {children}
    </TestingContentContainer>
  );
};

export default TestingContainer;
