import styled from "styled-components";

import { PrimaryButtonIconed, SecondaryButton } from "uikit";

export const TabsContainer = styled.div``;

export const TabsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
