import { FC } from "react";

import IconProps from "./IconProps";

const ConfigurePaymentPlanCardIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="81"
    height="70"
    viewBox="0 0 81 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_37_4021)">
      <path
        d="M47.1052 70.0016C47.1052 70.0016 47.0059 70.0016 46.9491 70.0016C43.3028 69.8744 35.5135 66.9347 26.4473 63.4015C23.4962 62.2426 20.9423 61.2533 19.8924 60.9566C19.0553 60.7163 18.5729 59.8683 18.8141 59.0345C19.0553 58.2006 19.9208 57.7343 20.7437 57.9604C21.9355 58.2996 24.4326 59.2747 27.5823 60.5043C33.9812 63.0058 43.6433 66.7652 47.0484 66.8923C47.0768 66.8923 47.1052 66.8923 47.1478 66.8923C51.9717 66.8923 73.3532 55.0349 77.184 48.3359C77.9786 46.9368 77.8651 46.4562 77.8651 46.4562C75.9922 45.0571 61.6196 50.8657 59.2786 52.6323C58.5976 53.1552 57.6186 53.028 57.0936 52.3496C56.5687 51.6713 56.6822 50.7102 57.349 50.1873C58.0584 49.6361 75.595 40.4639 79.9649 44.1809C80.9439 45.0147 81.7242 46.6824 79.9081 49.8764C75.7085 57.1972 53.5892 70.0016 47.1052 70.0016Z"
        fill="#007D7B"
      />
      <path
        d="M52.1268 57.4097C51.233 57.4097 50.3107 57.3249 49.3743 57.1412C44.3233 56.2084 40.0385 55.3746 35.0301 53.7917C34.2072 53.5373 33.7532 52.6611 34.0086 51.8414C34.264 51.0217 35.1578 50.5694 35.9665 50.8238C40.8047 52.336 44.9902 53.1557 49.9418 54.0744C53.9996 54.8375 56.5819 53.3819 56.8231 52.3784C57.0075 51.6576 55.8583 50.1454 51.9566 48.9441C45.4158 46.9797 41.5567 44.1672 38.4637 41.9201C36.4064 40.422 34.7889 39.249 32.9303 38.6837C28.5603 37.2563 21.7926 39.1218 16.997 41.5951C16.2309 41.9908 15.2803 41.694 14.883 40.9308C14.4857 40.1676 14.7837 39.2207 15.5498 38.825C20.7711 36.1256 28.3191 33.8927 33.8667 35.7017C36.1936 36.4083 38.0948 37.7933 40.3081 39.3903C43.3727 41.6092 46.8346 44.139 52.8788 45.948C60.2424 48.2234 60.2282 51.7283 59.8735 53.1275C59.2209 55.742 56.0427 57.3956 52.141 57.3956L52.1268 57.4097Z"
        fill="#007D7B"
      />
      <path
        d="M6.99484 66.9741C6.27125 66.9741 5.6186 66.4653 5.46253 65.7304L0.0284749 40.0793C-0.0566539 39.6695 0.0284749 39.2596 0.255485 38.9063C0.482495 38.5671 0.837198 38.3127 1.24865 38.2279L9.98854 36.3906C10.8398 36.2211 11.6627 36.7581 11.8472 37.5919L17.267 63.2431C17.3522 63.6529 17.267 64.0628 17.04 64.4161C16.813 64.7694 16.4583 65.0097 16.0469 65.0945L7.30698 66.9317C7.19348 66.96 7.09416 66.96 6.98066 66.96L6.99484 66.9741ZM3.41944 40.9556L8.20084 63.554L13.8761 62.3668L9.09469 39.7684L3.41944 40.9556Z"
        fill="#007D7B"
      />
      <path
        d="M74.687 40.3069C74.4174 40.3069 74.1478 40.2362 73.9066 40.0949C73.1546 39.6709 72.8993 38.7099 73.3249 37.9608C75.4105 34.3428 76.5172 30.3291 76.5172 26.3719C76.5172 13.5393 66.0322 3.10923 53.1636 3.10923C40.2949 3.10923 29.7957 13.5534 29.7957 26.3719C29.7957 27.6439 29.8809 28.8593 30.0511 30.2019C30.1646 31.0499 29.5687 31.8413 28.7032 31.9544C27.8378 32.0674 27.0574 31.4597 26.9439 30.6118C26.7453 29.1419 26.6602 27.7852 26.6602 26.3719C26.6743 11.8292 38.564 0 53.1636 0C67.7632 0 79.6528 11.8292 79.6528 26.386C79.6528 30.8944 78.4042 35.4311 76.049 39.5296C75.7653 40.0242 75.2261 40.3069 74.687 40.3069Z"
        fill="#F29127"
      />
      <path
        d="M46.8057 38.0153C46.522 38.0153 46.2382 37.9446 45.9828 37.775C45.245 37.3227 45.018 36.3617 45.472 35.6268L58.0711 15.3744C58.5251 14.6395 59.4899 14.4134 60.2277 14.8656C60.9655 15.3179 61.1925 16.2789 60.7385 17.0138L48.1394 37.2662C47.8414 37.7467 47.3307 38.0011 46.8057 38.0011V38.0153Z"
        fill="#F29127"
      />
      <path
        d="M45.9834 25.3862C43.2451 25.3862 41.0176 22.8423 41.0176 19.7048C41.0176 16.5673 43.2451 14.0234 45.9834 14.0234C48.7217 14.0234 50.9493 16.525 50.9493 19.7048C50.9493 22.8847 48.7643 25.3862 45.9834 25.3862ZM45.9834 17.1468C45.1179 17.1468 44.139 18.1926 44.139 19.7048C44.139 21.2171 45.1038 22.2629 45.9834 22.2629C47.0192 22.2629 47.8279 21.1323 47.8279 19.7048C47.8279 18.2774 47.0192 17.1468 45.9834 17.1468Z"
        fill="#F29127"
      />
      <path
        d="M60.2432 38.6675C57.5049 38.6675 55.2773 36.1236 55.2773 32.9861C55.2773 29.8486 57.5049 27.3047 60.2432 27.3047C62.9815 27.3047 65.209 29.8062 65.209 32.9861C65.209 36.166 63.0241 38.6675 60.2432 38.6675ZM60.2432 30.428C59.3777 30.428 58.3987 31.4739 58.3987 32.9861C58.3987 34.4983 59.3635 35.5441 60.2432 35.5441C61.2789 35.5441 62.0876 34.4135 62.0876 32.9861C62.0876 31.5587 61.2789 30.428 60.2432 30.428Z"
        fill="#F29127"
      />
    </g>
    <defs>
      <clipPath id="clip0_37_4021">
        <rect width="81" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ConfigurePaymentPlanCardIcon;
