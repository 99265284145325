import styled from "styled-components";

import { LinkTextMixin } from "uikit";

export const Container = styled.div``;

export const ResendCodeContainer = styled.div`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.3;
`;

export const ResendCodeLink = styled.span`
  ${LinkTextMixin}
`;

export const ResendCodeCountDown = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
