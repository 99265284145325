import { getPhoneNumberOrNull } from "helpers/shared";
import { BusinessDetailsFormType, ContactDetailsFormType } from "pages/Auth/_shared";

import { CreateCompanyAndSuperadminDto } from "utils/swagger_react_query";

export const getBusinessDetails = (
  values: BusinessDetailsFormType,
): CreateCompanyAndSuperadminDto["companyInfo"] => {
  return {
    name: values.businessName.trim(),
  };
};

export const getSuperAdminDetails = (
  values: ContactDetailsFormType,
): CreateCompanyAndSuperadminDto["userInfo"] => {
  return {
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    jobTitle: values?.jobTitle?.trim() || (null as any),
    phone: getPhoneNumberOrNull(values?.phone || "") as any,
  };
};
