import currency from "currency.js";

import { getFormattedSum } from "./currency";

export enum NumberType {
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  MONETARY = "MONETARY",
}

export const getFormattedNumber = (value: number, type: NumberType = NumberType.NUMBER) => {
  switch (type) {
    case NumberType.MONETARY:
      return getFormattedSum(value, { precision: currency(value).cents() ? 2 : 0 });
    case NumberType.PERCENTAGE:
      return `${value}%`;
    case NumberType.NUMBER:
    default:
      return value.toString();
  }
};

export const getSeparatedNumber = (value: number | string, fragmentSize: number = 3) => {
  const string = value.toString();
  const regexp = new RegExp(String.raw`.{1,${fragmentSize}}`, "g");

  const result = string.match(regexp)?.join(" ");
  return result;
};

export const getHiddenNumber = (
  value: number | string,
  fragmentSize: number = 3,
  spaceSeparator = " ",
) => {
  const string = value.toString();
  const slices = [string.slice(0, -fragmentSize), string.slice(-fragmentSize)];
  const maskedPart = "*".repeat(slices[0].length);
  const regexp = new RegExp(String.raw`.{1,${fragmentSize}}`, "g");

  const result = maskedPart.match(regexp)?.join(spaceSeparator) + " " + slices[1];
  return result;
};
