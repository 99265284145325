import { FC } from "react";

import IconProps from "./IconProps";

const BlankIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1888_24066)">
      <path
        d="M1.65289 31C0.741651 31 0 30.2583 0 29.3471V5.60918C0 4.69794 0.741651 3.95629 1.65289 3.95629H3.62979C3.8095 3.95629 3.95378 3.81201 3.95378 3.63229V2.64258C3.95378 2.27555 4.25247 1.97939 4.61697 1.97939H8.12779C8.24422 1.97939 8.35054 1.91865 8.40875 1.8174C9.05675 0.69606 10.2566 0.00250244 11.5399 0.00250244C12.8232 0.00250244 14.0256 0.698591 14.6736 1.8174C14.7318 1.91865 14.8381 1.97939 14.9545 1.97939H18.4653C18.8324 1.97939 19.1311 2.27808 19.1311 2.64258V3.63229C19.1311 3.81201 19.2753 3.95629 19.4551 3.95629H21.4319C22.3432 3.95629 23.0848 4.69794 23.0848 5.60918V7.58607C23.0848 7.9531 22.7862 8.24926 22.4191 8.24926C22.0521 8.24926 21.7559 7.95057 21.7559 7.58607V5.60918C21.7559 5.42946 21.6117 5.28518 21.4319 5.28518H19.4551C19.2753 5.28518 19.1311 5.42946 19.1311 5.60918V6.59889C19.1311 6.96592 18.8324 7.26208 18.4653 7.26208H4.6195C4.25247 7.26208 3.95632 6.96339 3.95632 6.59889V5.60918C3.95632 5.42946 3.81204 5.28518 3.63232 5.28518H1.65289C1.47318 5.28518 1.3289 5.42946 1.3289 5.60918V29.3471C1.3289 29.5268 1.47318 29.6711 1.65289 29.6711H19.4576C19.8246 29.6711 20.1208 29.9698 20.1208 30.3343C20.1208 30.6988 19.8221 30.9974 19.4576 30.9974H1.65289V31ZM5.60921 3.30576C5.42949 3.30576 5.28521 3.45004 5.28521 3.62976V5.60665C5.28521 5.78637 5.42949 5.93065 5.60921 5.93065H17.4782C17.6579 5.93065 17.8022 5.78637 17.8022 5.60665V3.62976C17.8022 3.45004 17.6579 3.30576 17.4782 3.30576H14.3293C14.0483 3.30576 13.7977 3.12857 13.7041 2.86279C13.3776 1.94396 12.5093 1.32634 11.545 1.32634C10.5806 1.32634 9.71234 1.94396 9.38581 2.86279C9.29215 3.12604 9.03903 3.30576 8.7606 3.30576H5.61174H5.60921Z"
        fill="#007D7B"
      />
      <path
        d="M4.61948 13.1978C4.25245 13.1978 3.9563 12.8991 3.9563 12.5346C3.9563 12.1701 4.25498 11.8715 4.61948 11.8715H7.58609C7.95311 11.8715 8.24927 12.1701 8.24927 12.5346C8.24927 12.8991 7.95058 13.1978 7.58609 13.1978H4.61948Z"
        fill="#007D7B"
      />
      <path
        d="M4.61948 17.1542C4.25245 17.1542 3.9563 16.8555 3.9563 16.491C3.9563 16.1265 4.25498 15.8278 4.61948 15.8278H7.58609C7.95311 15.8278 8.24927 16.1265 8.24927 16.491C8.24927 16.8555 7.95058 17.1542 7.58609 17.1542H4.61948Z"
        fill="#007D7B"
      />
      <path
        d="M4.61948 21.1079C4.25245 21.1079 3.9563 20.8092 3.9563 20.4447C3.9563 20.0802 4.25498 19.7816 4.61948 19.7816H7.58609C7.95311 19.7816 8.24927 20.0802 8.24927 20.4447C8.24927 20.8092 7.95058 21.1079 7.58609 21.1079H4.61948Z"
        fill="#007D7B"
      />
      <path
        d="M4.61948 25.0643C4.25245 25.0643 3.9563 24.7656 3.9563 24.4011C3.9563 24.0366 4.25498 23.7379 4.61948 23.7379H7.58609C7.95311 23.7379 8.24927 24.0366 8.24927 24.4011C8.24927 24.7656 7.95058 25.0643 7.58609 25.0643H4.61948Z"
        fill="#007D7B"
      />
      <path
        d="M28.527 30.9975C27.8663 30.9975 27.2436 30.7418 26.7779 30.2735L22.6849 26.1805C22.6216 26.1173 22.5406 26.0843 22.4545 26.0843C22.4039 26.0843 22.3558 26.097 22.3077 26.1198C21.0953 26.7298 19.8018 27.0412 18.4628 27.0412C13.7344 27.0412 9.88696 23.1937 9.88696 18.4653C9.88696 13.737 13.7344 9.88953 18.4628 9.88953C23.1911 9.88953 27.0386 13.737 27.0386 18.4653C27.0386 19.8044 26.7298 21.0978 26.1172 22.3103C26.0539 22.4343 26.0793 22.5862 26.178 22.6849L30.271 26.7779C31.2354 27.7423 31.2354 29.3091 30.271 30.2761C29.8052 30.7418 29.1825 31 28.5219 31L28.527 30.9975ZM25.2946 23.8265C25.2034 23.8316 25.1174 23.8721 25.0592 23.9429C24.7225 24.3479 24.3479 24.7225 23.9429 25.0592C23.872 25.1174 23.8315 25.2035 23.8265 25.2946C23.8214 25.3857 23.8568 25.4743 23.9201 25.5376L27.717 29.3344C27.9321 29.5496 28.2207 29.6686 28.527 29.6686C28.8332 29.6686 29.1193 29.5496 29.3344 29.3344C29.7799 28.889 29.7799 28.1625 29.3344 27.717L25.5376 23.9201C25.4768 23.8594 25.3933 23.824 25.3072 23.824C25.3022 23.824 25.2971 23.824 25.292 23.824L25.2946 23.8265ZM18.4653 11.2184C14.4685 11.2184 11.2184 14.4685 11.2184 18.4653C11.2184 22.4622 14.4685 25.7123 18.4653 25.7123C22.4621 25.7123 25.7122 22.4622 25.7122 18.4653C25.7122 14.4685 22.4596 11.2184 18.4653 11.2184Z"
        fill="#F29127"
      />
      <path
        d="M17.4781 21.108C17.3009 21.108 17.1339 21.0396 17.0073 20.9131L15.0279 18.9362C14.7697 18.678 14.7697 18.2553 15.0279 17.9971C15.1544 17.8705 15.319 17.8022 15.4987 17.8022C15.6784 17.8022 15.8429 17.8705 15.9695 17.9971L17.2478 19.2754C17.311 19.3386 17.3946 19.3715 17.4781 19.3715C17.5616 19.3715 17.6452 19.3386 17.7084 19.2754L20.9661 16.0177C21.0927 15.8911 21.2572 15.8228 21.4369 15.8228C21.6167 15.8228 21.7812 15.8911 21.9078 16.0177C22.1659 16.2758 22.1659 16.6986 21.9078 16.9567L17.9514 20.9131C17.8249 21.0396 17.6578 21.108 17.4806 21.108H17.4781Z"
        fill="#F29127"
      />
    </g>
    <defs>
      <clipPath id="clip0_1888_24066">
        <rect width="31" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BlankIcon;
