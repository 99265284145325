import { FC } from "react";

import IconProps from "./IconProps";

const People: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11075_2892)">
      <path
        d="M21 21.1991V19.1991C20.9949 18.3163 20.6979 17.4599 20.1553 16.7635C19.6126 16.0671 18.8548 15.5698 18 15.3491M3 21.1992V19.1992C3 18.1384 3.42143 17.1209 4.17157 16.3708C4.92172 15.6206 5.93913 15.1992 7 15.1992H11C12.0609 15.1992 13.0783 15.6206 13.8284 16.3708C14.5786 17.1209 15 18.1384 15 19.1992V21.1992M16 3.3291C16.8604 3.5494 17.623 4.0498 18.1676 4.75141C18.7122 5.45302 19.0078 6.31593 19.0078 7.2041C19.0078 8.09227 18.7122 8.95518 18.1676 9.65679C17.623 10.3584 16.8604 10.8588 16 11.0791M5 7.19922C5 8.26008 5.42143 9.2775 6.17157 10.0276C6.92172 10.7778 7.93913 11.1992 9 11.1992C10.0609 11.1992 11.0783 10.7778 11.8284 10.0276C12.5786 9.2775 13 8.26008 13 7.19922C13 6.13835 12.5786 5.12094 11.8284 4.37079C11.0783 3.62065 10.0609 3.19922 9 3.19922C7.93913 3.19922 6.92172 3.62065 6.17157 4.37079C5.42143 5.12094 5 6.13835 5 7.19922Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11075_2892">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199219)" />
      </clipPath>
    </defs>
  </svg>
);

export default People;
