import React, { FC, useRef, useState } from "react";

import { useEventsListener } from "hooks";

import UIModal, { IModalProps } from "uikit/Modal";

export const GlobalModal: FC = () => {
  const [modal, setModal] = useState<boolean>(false);

  const modalRef = useRef<IModalProps>({
    isOpen: modal,
  });

  useEventsListener<"SetGlobalModal">(
    "SetGlobalModal",
    (data) => {
      try {
        modalRef.current = {
          shouldCloseOnOverlayClick: false,
          ...data,
          mainButton: data.mainButton
            ? {
                ...data.mainButton,
                onClick: () => {
                  data?.onCloseOnButtons !== false && setModal(false);
                  data?.mainButton?.onClick?.();
                },
              }
            : undefined,
          secondaryButton: data.secondaryButton
            ? {
                ...data.secondaryButton,
                onClick: () => {
                  data?.onCloseOnButtons !== false && setModal(false);
                  data?.secondaryButton?.onClick?.();
                },
              }
            : undefined,
          onClose: () => {
            setModal(false);
            data.onClose && data.onClose();
          },
        };
        setModal(true);
      } catch (e) {
        console.log(e);
      }
    },
    [modalRef],
  );

  useEventsListener<"CloseGlobalModal">("CloseGlobalModal", () => {
    setModal(false);
  });

  return <UIModal {...modalRef.current} isOpen={modal} />;
};
