import styled from "styled-components";

import { breakpoint } from "helpers";

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const CountText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text};

  ${breakpoint("xs", "lg")`
    width: 100%;
  `}
`;
