import { FC } from "react";

import IconProps from "./IconProps";

const GradientArrow: FC<IconProps> = ({ className }) => (
  <svg
    width="76"
    height="54"
    viewBox="0 0 76 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5 53.8459L76 26.9991L47.5 0.152344V12.9991H0V41.9991H47.5V53.8459Z"
      fill="url(#paint0_linear_2710_24623)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2710_24623"
        x1="76"
        y1="26.9991"
        x2="3.60593e-08"
        y2="26.9991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60AEAC" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default GradientArrow;
