export enum EStatesShort {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  DC = "DC",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum EStates {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District of Columbia",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const StateSelectOptions: { label: EStates; value: EStatesShort }[] = [
  { label: EStates.AL, value: EStatesShort.AL },
  { label: EStates.AK, value: EStatesShort.AK },
  { label: EStates.AZ, value: EStatesShort.AZ },
  { label: EStates.AR, value: EStatesShort.AR },
  { label: EStates.CA, value: EStatesShort.CA },
  { label: EStates.CO, value: EStatesShort.CO },
  { label: EStates.CT, value: EStatesShort.CT },
  { label: EStates.DE, value: EStatesShort.DE },
  { label: EStates.DC, value: EStatesShort.DC },
  { label: EStates.FL, value: EStatesShort.FL },
  { label: EStates.GA, value: EStatesShort.GA },
  { label: EStates.HI, value: EStatesShort.HI },
  { label: EStates.ID, value: EStatesShort.ID },
  { label: EStates.IL, value: EStatesShort.IL },
  { label: EStates.IN, value: EStatesShort.IN },
  { label: EStates.IA, value: EStatesShort.IA },
  { label: EStates.KS, value: EStatesShort.KS },
  { label: EStates.KY, value: EStatesShort.KY },
  { label: EStates.LA, value: EStatesShort.LA },
  { label: EStates.ME, value: EStatesShort.ME },
  { label: EStates.MD, value: EStatesShort.MD },
  { label: EStates.MA, value: EStatesShort.MA },
  { label: EStates.MI, value: EStatesShort.MI },
  { label: EStates.MN, value: EStatesShort.MN },
  { label: EStates.MS, value: EStatesShort.MS },
  { label: EStates.MO, value: EStatesShort.MO },
  { label: EStates.MT, value: EStatesShort.MT },
  { label: EStates.NE, value: EStatesShort.NE },
  { label: EStates.NV, value: EStatesShort.NV },
  { label: EStates.NH, value: EStatesShort.NH },
  { label: EStates.NJ, value: EStatesShort.NJ },
  { label: EStates.NM, value: EStatesShort.NM },
  { label: EStates.NY, value: EStatesShort.NY },
  { label: EStates.NC, value: EStatesShort.NC },
  { label: EStates.ND, value: EStatesShort.ND },
  { label: EStates.OH, value: EStatesShort.OH },
  { label: EStates.OK, value: EStatesShort.OK },
  { label: EStates.OR, value: EStatesShort.OR },
  { label: EStates.PA, value: EStatesShort.PA },
  { label: EStates.RI, value: EStatesShort.RI },
  { label: EStates.SC, value: EStatesShort.SC },
  { label: EStates.SD, value: EStatesShort.SD },
  { label: EStates.TN, value: EStatesShort.TN },
  { label: EStates.TX, value: EStatesShort.TX },
  { label: EStates.UT, value: EStatesShort.UT },
  { label: EStates.VT, value: EStatesShort.VT },
  { label: EStates.VA, value: EStatesShort.VA },
  { label: EStates.WA, value: EStatesShort.WA },
  { label: EStates.WV, value: EStatesShort.WV },
  { label: EStates.WI, value: EStatesShort.WI },
  { label: EStates.WY, value: EStatesShort.WY },
];
