import {
  BonusChallengeRewardType,
  BonusStatus,
  BonusType,
  VestingScheduleType,
} from "types/BETypes";
import { ProgressItemActivityType } from "helpers/index";
import { ProgressItemType } from "components/ProgressBar/types";

export const bonusCardTranslations = {
  "en-US": {
    label_bonus: "Total Bonus Amount",
    label_bonus_tooltip:
      "<1>Bonus Amount</1> <2>The bonus amount is the gross amount minus the future anticipated payroll taxes resulting in the net amount shown here. Each time vesting bonus is a 0% interest loan. At each milestone, the portion of the loan that vests is paid down by the employer on behalf of the employee. Complete all the milestones and the full amount of the loan will have been paid by the employer.</2>",
    label_unvested: "Unvested Amount",
    label_unvested_tooltip:
      "<1>Unvested Amount</1> <2>The unvested amount of the bonus is the portion of the loan that has not yet been paid by the employer because the employee has not yet reached those milestones.</2>",
    label_vested: "Vested Amount",
    label_vested_tooltip:
      "<1>Vested Amount</1> <2>The vested amount of the bonus is the portion of the loan that has been paid by the employer and is no longer due by the employee.</2>",
    label_vesting_term: "<1>Vesting Term</1> - {{startDate}} - {{endDate}}",
    label_vesting_term_mobile_label: "<1>Vesting Term</1>",
    label_vesting_term_mobile_value: "{{startDate}} - {{endDate}}",
    label_first_vesting: "<1>1st Vesting Date</1> - {{date}}",
    label_first_vesting_mobile_label: "<1>1st Vesting Date</1>",
    label_first_vesting_mobile_value: "{{date}}",
    label_start_date: "Start Date",
    label_days_left: "Days Left:",
    label_due_date: "Due Date:",
    label_recorded_progress: "Recorded Progress",
    label_last_updated: "Last Updated",
    unit_bonus_quantity_description:
      "<1>Goal:</1> Complete <2>{{quantity}} “{{unitName}}”</2> by <2>{{date}}</2>.",
    unit_bonus_quantity_mobile_label: "<1>Goal:</1>",
    unit_bonus_quantity_mobile_value: "Complete {{quantity}} “{{unitName}}” by {{date}}.",
    unit_bonus_completed_goals: "<1>{{quantity}} completed</1>",
    unit_bonus_completed_goals_balance_due:
      "<1>{{quantity}} Completed</1> - <2>Balance Due: {{amount}}</2>",
    unit_bonus_completed_goals_balance_due_mobile_label: "<1>Balance Due: </1>",
    unit_bonus_completed_goals_balance_due_mobile_value: "<1>{{amount}}</1>",
    unit_bonus_goals_last_updated: "Last updated: {{date}}",

    mobile_time_period_labels: {
      [VestingScheduleType.EQUALLY]: "<1>Vests Every</1>",
      [VestingScheduleType.EQUALLY_AFTER_CLIFF]: "<1>Vests Every</1>",
      [VestingScheduleType.VEST_100_PERCENTS_ON_END_DATE]: "",
      [VestingScheduleType.CUSTOM]: "",
    },
    mobile_time_period_values: {
      [VestingScheduleType.EQUALLY]: "<1>{{unit}}</1>",
      [VestingScheduleType.EQUALLY_AFTER_CLIFF]: "<1>{{unit}} Thereafter</1>",
      [VestingScheduleType.VEST_100_PERCENTS_ON_END_DATE]: "<1>Vests 100% at the end</1>",
      [VestingScheduleType.CUSTOM]: "<1>Custom Vesting Schedule</1>",
    },

    performance_challenges: "{{count}} Performance Challenge(s)",
    thereafter: "Thereafter",
    view_details: "View Details",
    view_details_short: "View",
    review_and_sign_bonus: "Review & Sign Bonus Contract",
    delete: "Delete",
    resend_invitation: "Resend Bonus Invitation",
    edit_draft: "Edit Draft",
    void: "Void",
    status_label_summary: "Summary",

    basic_type_labels: {
      [BonusType.TIME]: "{{status}} Time Vesting Bonus",
      [BonusType.UNIT]: "{{status}} Unit Vesting Bonus",
      [BonusType.CHALLENGE]: "{{status}} Performance Challenge",
    },

    detailed_type_labels: {
      [BonusType.TIME]: "Time Vesting Bonus - {{status}}",
      [BonusType.UNIT]: "Unit Vesting Bonus - {{status}}",
      [BonusType.CHALLENGE]: "Performance Challenge - {{status}}",
    },

    time_bonus_period_descriptions: {
      [VestingScheduleType.EQUALLY]: "<1>Vests Every {{unit}}</1>",
      [VestingScheduleType.EQUALLY_AFTER_CLIFF]: "<1>Vests Every {{unit}} thereafter</1>",
      [VestingScheduleType.VEST_100_PERCENTS_ON_END_DATE]: "<1>Vests 100% at the end</1>",
      [VestingScheduleType.CUSTOM]: "<1>Custom Vesting Schedule</1>",
    },

    unit_bonus_period_descriptions: {
      [VestingScheduleType.UNIT_EQUALLY]: "Vests equally every {{quantity}} “{{unitName}}”",
      [VestingScheduleType.UNIT_ON_EACH_UNIT]: "Vests on each unit completed",
      [VestingScheduleType.UNIT_VEST_100_PERCENTS_ON_END_DATE]:
        "Vests 100% upon completion of the goal",
      [VestingScheduleType.UNIT_CUSTOM]: "Custom Vesting",
    },

    unit_bonus_details: {
      record_progress: "Record Progress",
      record_progress_with_count: "Record Progress: {{count}}",
      last_updated: "Last Updated: {{date}}",
      update_button: "Update",
    },

    small_card: {
      earned_label: "Earned",
      earned_label_mobile: "Earned:",
      updated_at: "<1>Updated:</1> <2>{{date}}</2>",
      due: "<1>Due:</1> <2>{{date}}</2>",
      days_left: "<1>Days Left:</1> <2><3>{{days}}</3></2>",
      total_bonus_amount: "Total Bonus <1></1>",
      vesting_schedule_description: {
        vesting_schedule_label: "Vesting Schedule",
        custom_vesting_schedule: "Custom Vesting",
      },
      progress_tooltip: {
        vested: "Vested Amount",
        days_till_next_milestone: "Days till next milestone",
        units_till_next_milestone: "Units till next milestone",
      },
    },

    progress_bar_tooltips: {
      [BonusType.TIME]: {
        [ProgressItemType.RANGE]: {
          [ProgressItemActivityType.ACTIVE]:
            "<1>Total Vested</1><3></3><1>Next Milestone</1><2>{{nextMilestoneDate}}</2>",
          [ProgressItemActivityType.INACTIVE]:
            "<1>Days till next milestone</1><2>{{daysTillNextMilestone}}</2>",
          [ProgressItemActivityType.EMPTY]:
            "<1>Total Unvested</1><4></4><1>Next Milestone</1><2>{{nextMilestoneDate}}</2>",
        },
        [ProgressItemType.POINT]: {
          [ProgressItemActivityType.ACTIVE]:
            "<1>Milestone {{index}} Vesting</1><4></4><1>Date</1><2>{{date}}</2>",
          [ProgressItemActivityType.INACTIVE]:
            "<1>Milestone {{index}} Vesting</1><4></4><1>Date</1><2>{{date}}</2>",
          [ProgressItemActivityType.LEADING]:
            "<1>Current Day</1><2>{{date}}</2><1>Next Milestone</1><2>{{nextMilestoneDate}}</2>",
          [ProgressItemActivityType.LEADING_NOT_STARTED_YET]: "<1>Start Date</1><2>{{date}}</2>",
          [ProgressItemActivityType.LEADING_FINISHED]: "<1>Current Day</1><2>{{date}}</2>",
          [ProgressItemActivityType.BOOSTER_VESTED]:
            "<1>Vested Milestone</1><3></3><2>{{date}}</2>",
          [ProgressItemActivityType.BOOSTER_REWARD]: "<1>Reward Earned</1><3></3><2>{{date}}</2>",
        },
      },

      [BonusType.UNIT]: {
        [ProgressItemType.RANGE]: {
          [ProgressItemActivityType.ACTIVE]:
            "<1>Total Vested</1><3></3><1>Next Milestone</1><2>{{nextMilestoneQuantity}}</2>",
          [ProgressItemActivityType.INACTIVE]:
            "<1>Next Milestone</1><2>{{nextMilestoneQuantity}}</2>",
          [ProgressItemActivityType.EMPTY]:
            "<1>Total Unvested</1><4></4><1>Next Milestone</1><2>{{nextMilestoneQuantity}}</2>",
        },
        [ProgressItemType.POINT]: {
          [ProgressItemActivityType.ACTIVE]: "<1>Completed</1><2>{{quantity}}</2>",
          [ProgressItemActivityType.INACTIVE]: "<1>Incomplete</1><2>{{quantity}}</2>",
          [ProgressItemActivityType.LEADING]:
            "<1>Total Completed</1><2>{{quantity}}</2><1>Next Milestone</1><2>{{nextMilestoneQuantity}}</2>",
          [ProgressItemActivityType.LEADING_FINISHED]: "<1>Total Completed</1><2>{{quantity}}</2>",
        },
      },

      [BonusType.CHALLENGE]: {
        [BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION]: {
          [ProgressItemType.RANGE]: {
            [ProgressItemActivityType.ACTIVE]:
              "<1>Total Progress</1><2>{{progress}}</2><1>Reward Earned</1><3></3>",
            [ProgressItemActivityType.INACTIVE]: "<1>Total Progress</1><2>{{progress}}</2>",
          },
          [ProgressItemType.POINT]: {
            [ProgressItemActivityType.ACTIVE]:
              "<1>Goal {{index}}</1><2>{{progress}}</2><1>Reward</1><3></3>",
            [ProgressItemActivityType.INACTIVE]:
              "<1>Goal {{index}}</1><2>{{progress}}</2><1>Reward</1><3></3>",
          },
        },
        [BonusChallengeRewardType.PAY_TIERS]: {
          [ProgressItemType.RANGE]: {
            [ProgressItemActivityType.ACTIVE]:
              "<1>Total Progress</1><2>{{progress}}</2><1>Reward Earned</1><3></3>",
            [ProgressItemActivityType.INACTIVE]: "<1>Total Progress</1><2>{{progress}}</2>",
          },
          [ProgressItemType.POINT]: {
            [ProgressItemActivityType.ACTIVE]:
              "<1>Goal {{index}}</1><2>{{tierAmount}}</2><1>Per Event</1>",
            [ProgressItemActivityType.INACTIVE]:
              "<1>Goal {{index}}</1><2>{{tierAmount}}</2><1>Per Event</1>",
          },
        },
      },
    },

    performance_challenge_card: {
      max_amount: "/ Max ",
      goal_description: "<1>{{conditionValue}}</1> <2>{{goalName}}</2>",
      goal_description_text: "{{goalName}}",
    },

    last_edited_by: "Last edited by",
    last_edited: "Last edited",
    invitation_sent: "Invitation sent",
    declined_on: "Declined on",
    accepted_on: "Accepted on",

    waiting_for_funds: "Waiting on funds to transfer",
    waiting_for_funds_tooltip:
      "The bonus is awaiting funding from Keep. Once funded the bonus will appear as active and you can begin drawing money from your bonus.",

    bonus_alert: {
      date_label: "Date",
      amount_label: "Amount: {{amount}}",
      view_details_link: "View Bonus Details",

      bonus_prefix: {
        [BonusStatus.ACCEPTED]: "Accepted: ",
        [BonusStatus.PENDING]: "Pending: ",
      },
    },
  },
};

export default bonusCardTranslations;
