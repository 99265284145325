import React, { FC, ReactNode } from "react";
import ReactPaginate from "react-paginate";
import { ChevronRight } from "assets/svg";

import { Wrapper } from "./styles";

const PaginationControl: FC<{ children: ReactNode }> = ({ children }) => (
  <button className="pagination__control">{children}</button>
);

const defaultPaginationConfig = {
  pageCount: 0,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 2,
};

export interface Props {
  className?: string;
  onPageChange: (page: number) => void;
  totalCount: number;
  limit: number;
  page?: number;
  useCustomPaginationLogic?: boolean;
}

export const Pagination: FC<Props> = (props) => {
  const pageCount = Math.ceil(props.totalCount / props.limit);

  return (
    <Wrapper style={props.totalCount <= props.limit ? { display: "none" } : {}}>
      <ReactPaginate
        pageCount={pageCount || defaultPaginationConfig.pageCount}
        forcePage={props.page && props.page - 1}
        pageRangeDisplayed={defaultPaginationConfig.pageRangeDisplayed}
        marginPagesDisplayed={defaultPaginationConfig.marginPagesDisplayed}
        disableInitialCallback={true}
        onPageChange={
          props.useCustomPaginationLogic
            ? undefined
            : ({ selected }) => props.onPageChange(selected + 1)
        }
        breakClassName="pagination__link pagination__break"
        pageLinkClassName="pagination__link"
        containerClassName="pagination"
        previousLabel={
          pageCount > 0 && (
            <PaginationControl>
              <ChevronRight />
            </PaginationControl>
          )
        }
        previousLinkClassName="pagination__prev"
        nextLabel={
          pageCount > 0 && (
            <PaginationControl>
              <ChevronRight />
            </PaginationControl>
          )
        }
        onClick={
          props.useCustomPaginationLogic
            ? (e) => {
                const page = e.nextSelectedPage !== undefined ? e.nextSelectedPage : e.selected;
                props.onPageChange((page || 0) + 1);
                return false;
              }
            : undefined
        }
        nextLinkClassName="pagination__next"
      />
    </Wrapper>
  );
};

export default Pagination;
