import { FC } from "react";

import IconProps from "./IconProps";

const AttentionCircleSmallIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_7911_51034)">
      <circle cx="16.5" cy="16.5" r="11.5" fill="#F29128" stroke="white" strokeWidth="2" />
      <path
        d="M16.7285 19.7883C16.2523 19.7883 15.8502 19.6016 15.8294 19.3615L15.1162 11.1046C15.0449 10.2793 15.7641 9.55469 16.7285 9.55469C17.693 9.55469 18.4121 10.2793 18.3409 11.1046L17.6276 19.3615C17.6068 19.6016 17.2048 19.7883 16.7285 19.7883Z"
        fill="white"
      />
      <path
        d="M18.118 22.1067C18.118 22.8453 17.4952 23.4439 16.727 23.4439C15.9587 23.4439 15.3359 22.8453 15.3359 22.1067C15.3359 21.3682 15.9587 20.7695 16.727 20.7695C17.4952 20.7695 18.118 21.3682 18.118 22.1067Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7911_51034"
        x="0"
        y="0"
        width="33"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7911_51034" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7911_51034"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default AttentionCircleSmallIcon;
