export const phoneRegExp = /^[\+1-]?[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}[-\s]?[0-9]{0,4}$/i;
export const emailRegExp = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
export const yahooAndGmailDomains = /.+@(gmail|yahoo)\.com$/;
export const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
export const creditCardRegExp =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const cardHolderRegExp = /^([A-Za-z]){3,}\\s([A-Za-z]){3,}$/;
export const cardExpirationDateRegExp = /^([0-9]{4})$/;
export const cardCvvRegExp = /^([0-9]{3,4})$/;
//export const urlRegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; //Invalid, allows coma
export const userNameRegExp = /^[a-z0-9 ,.'-]{1,100}$/i;
export const filePathRegExp = /[A-Za-z0-9_\-\.]+\.[A-Za-z0-9]+$/;
export const zipInputFormat = "#####";
