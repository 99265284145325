import { FC } from "react";

import IconProps from "./IconProps";

const BonusTypeUnit: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="86"
    viewBox="0 0 65 86"
    fill="none"
  >
    <g clipPath="url(#clip0_3505_36356)">
      <path
        d="M18.9107 37.2215C18.405 37.2215 17.9204 37.0189 17.5622 36.6564L13.875 32.9247L16.5614 30.2058L18.7421 32.4129L23.9252 25.7598L26.9171 28.1481L20.4066 36.4964C20.0695 36.9229 19.5638 37.1895 19.0265 37.2215C18.9949 37.2215 18.9528 37.2215 18.9212 37.2215H18.9107Z"
        fill="url(#paint0_linear_3505_36356)"
      />
      <path
        d="M50.7344 29.5234H32.9727V33.3724H50.7344V29.5234Z"
        fill="url(#paint1_linear_3505_36356)"
      />
      <path
        d="M50.7344 46.209H32.9727V50.058H50.7344V46.209Z"
        fill="url(#paint2_linear_3505_36356)"
      />
      <path
        d="M50.7344 62.8955H32.9727V66.7445H50.7344V62.8955Z"
        fill="url(#paint3_linear_3505_36356)"
      />
      <path
        d="M63.1037 85.9996H1.90681C0.853323 85.9996 0 85.136 0 84.0698V5.77846C0 4.71226 0.853323 3.84863 1.90681 3.84863H10.4927V7.69763H3.80308V82.1506H61.1969V7.69763H54.423V3.84863H63.1037C64.1572 3.84863 65.0105 4.71226 65.0105 5.77846V84.0805C65.0105 85.1467 64.1572 86.0103 63.1037 86.0103V85.9996Z"
        fill="url(#paint4_linear_3505_36356)"
      />
      <path
        d="M18.9107 53.8328C18.405 53.8328 17.9204 53.6302 17.5622 53.2677L13.875 49.536L16.5614 46.8172L18.7421 49.0242L23.9252 42.3711L26.9171 44.7594L20.4066 53.1078C20.0695 53.5449 19.5638 53.8008 19.0265 53.8328C18.9949 53.8328 18.9528 53.8328 18.9212 53.8328H18.9107Z"
        fill="url(#paint5_linear_3505_36356)"
      />
      <path
        d="M18.9107 70.5935C18.405 70.5935 17.9204 70.3909 17.5622 70.0284L13.875 66.2967L16.5614 63.5779L18.7421 65.7849L23.9252 59.1318L26.9171 61.5201L20.4066 69.8685C20.0695 70.295 19.5638 70.5615 19.0265 70.5935H18.9212H18.9107Z"
        fill="url(#paint6_linear_3505_36356)"
      />
      <path
        d="M47.3666 0H17.6373C16.5838 0 15.7305 0.863625 15.7305 1.92983V8.56162C15.7305 13.0397 19.3334 16.6861 23.758 16.6861H41.2248C45.6494 16.6861 49.2523 13.0397 49.2523 8.56162V1.92983C49.2523 0.863625 48.399 0 47.3561 0H47.3666ZM45.4598 8.56162C45.4598 10.9179 43.5635 12.8371 41.2353 12.8371H23.7686C21.4404 12.8371 19.5441 10.9179 19.5441 8.56162V3.849H45.4598V8.56162Z"
        fill="url(#paint7_linear_3505_36356)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3505_36356"
        x1="15.2336"
        y1="25.7598"
        x2="15.3013"
        y2="32.8415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3505_36356"
        x1="34.8228"
        y1="29.5234"
        x2="34.8284"
        y2="31.9018"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3505_36356"
        x1="34.8228"
        y1="46.209"
        x2="34.8284"
        y2="48.5873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3505_36356"
        x1="34.8228"
        y1="62.8955"
        x2="34.8284"
        y2="65.2739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3505_36356"
        x1="6.77193"
        y1="3.84863"
        x2="7.47016"
        y2="54.6081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3505_36356"
        x1="15.2336"
        y1="42.3711"
        x2="15.3013"
        y2="49.4528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3505_36356"
        x1="15.2336"
        y1="59.1318"
        x2="15.3013"
        y2="66.2136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3505_36356"
        x1="19.2223"
        y1="3.41121e-09"
        x2="19.2782"
        y2="10.3103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22BBA7" />
        <stop offset="1" stopColor="#40B380" />
      </linearGradient>
      <clipPath id="clip0_3505_36356">
        <rect width="65" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BonusTypeUnit;
