import React, { FC } from "react";

import IconProps from "./IconProps";

const StarWithRadius: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2995_35174)">
      <path
        d="M0.0439548 5.28343C0.150038 4.96603 0.431246 4.73489 0.770052 4.68684L4.86346 4.10671L6.69422 0.488583C6.84569 0.189343 7.15812 0 7.50022 0C7.84233 0 8.15456 0.189343 8.30622 0.488583L10.137 4.1069L14.2304 4.68703C14.569 4.73489 14.8506 4.96622 14.9561 5.28362C15.0616 5.60121 14.9736 5.94944 14.7284 6.18248L11.7662 8.99878L12.4654 12.9752C12.5234 13.3043 12.3847 13.6366 12.108 13.8328C11.8312 14.0288 11.4643 14.0547 11.1615 13.8994L7.50003 12.022L3.83851 13.8994C3.70702 13.9669 3.56332 14.0002 3.42019 14.0002C3.23401 14.0002 3.04861 13.9436 2.89191 13.8328C2.61516 13.6366 2.4763 13.3043 2.53448 12.9752L3.23362 8.99878L0.27183 6.18248C0.0263062 5.94944 -0.0615463 5.60121 0.0439548 5.28362V5.28343Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2995_35174">
        <rect width="15" height="14" fill="white" transform="matrix(-1 0 0 1 15 0)" />
      </clipPath>
    </defs>
  </svg>
);

export default StarWithRadius;
