import styled from "styled-components";

import { breakpoint } from "helpers";
import PaidCardShippingDetailsForm from "components/CompanyBankAccountComponents/PaidCardShippingDetailsForm";

import { ButtonsContainer, SectionAltRounded, SectionTitle, UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 560px !important;
  `}
`;

export const ContentWrapper = styled.form`
  margin-bottom: 32px;
`;

export const ReasonSection = styled.div`
  margin-bottom: 24px;
`;

export const StyledPaidCardShippingDetailsForm = styled(PaidCardShippingDetailsForm)`
  ${SectionAltRounded} {
    padding: 0;
    margin: 0;
    background: none;
  }
  ${SectionTitle} {
    text-align: left;
  }
`;

export const StyledButtonsContainer = styled(ButtonsContainer)`
  margin-top: 32px;
  gap: 16px;

  button {
  }
`;
